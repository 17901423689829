<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:33%;border:1px solid #aaaaaa">Age From</div>
                <div style="width:33%;border:1px solid #aaaaaa">Age To</div>
                <div style="width:34%;border:1px solid #aaaaaa">Price</div>
                <div style="width:30px">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <div style="margin-top:5px" v-for="item in keysListRoomExtraInfo" :key="item">
                <a-row :gutter="0" style="width:100%;">
                    <div style="display:flex;width:100%;">
                        <a-form-item style="width:33%;margin-bottom: 0;">
                            <a-input-number style="width:100%;padding:2px" :min="1" :max="100000" placeholder=""
                              v-decorator="[
                                    `${title}ageFrom[${item}]`,
                                    {
                                        initialValue: arr[item] ? arr[item].agefrom : undefined,
                                        rules: [{ required: true, message: 'Input agefrom!' }]
                                    }
                                ]"
                            />
                        </a-form-item>
                        <a-form-item style="width:33%;margin-bottom: 0;">
                            <a-input-number style="width:100%;padding:2px" :min="1" :max="100000" placeholder=""
                              v-decorator="[
                                    `${title}ageTo[${item}]`,
                                    {
                                        initialValue: arr[item] ? arr[item].ageto : undefined,
                                        rules: [{ required: false, message: 'ageto Number!' }]
                                    }
                                ]"
                            />
                        </a-form-item>
                        <a-form-item style="width:34%;margin-bottom: 0;">
                            <a-input-number style="width:100%;padding:2px" :min="1" :max="100000" placeholder=""
                              v-decorator="[
                                    `${title}price[${item}]`,
                                    {
                                        initialValue: arr[item] ? arr[item].price : undefined,
                                        rules: [{ required: false, message: 'price Number!' }]
                                    }
                                ]"
                            />
                        </a-form-item>
                        <div style="width:30px;padding:5px">
                            <a-button type="dashed" icon="minus" @click="removeRowRoomExtraInfo(item)" class="minusRowBtn" :size="'small'"></a-button>
                        </div>
                    </div>
                </a-row>
            </div>
            
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowRoomExtraInfo" type="primary">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      idRoomExtraInfo: 0,
      keysListRoomExtraInfo: [],
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    this.init()
  },
  methods: {
    init () {
      const arr = []
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idRoomExtraInfo = 0;
        for (let i = 0; i < (this.arr).length; i++) {
          arr.push(i)
          this.idRoomExtraInfo = this.idRoomExtraInfo + 1
        }
      }
      this.keysListRoomExtraInfo = arr
    },
    removeRowRoomExtraInfo (k) {
      if (this.keysListRoomExtraInfo.length === 0) { 
        return
      }
      this.keysListRoomExtraInfo = this.keysListRoomExtraInfo.filter(item => item !== k)
    },
    addRowRoomExtraInfo () {
      this.keysListRoomExtraInfo = this.keysListRoomExtraInfo.concat(this.idRoomExtraInfo)
      this.idRoomExtraInfo = this.idRoomExtraInfo + 1
    },
  }
}
</script>