<template>
  <div style="min-height:85vh">
    <a-form style="display:flex;width:100%;" :form="dashboardInspirationTagForm">
      <div>
          <a-table
          :columns="columnsInspirationTag"
          :data-source="inspirationTagList"
          bordered
          size="middle"
          :pagination="false"
          :scroll="{ x: 0, y: 0 }"
      >
              <span slot="name" slot-scope="text, record, index">
                  <a-form-item style="margin-bottom: 0px;">
                      <a-input
                          v-decorator="[
                                  `${INSPIRATIONTAG}name[${index}]`,
                                  {
                                      initialValue: text ? text : undefined,
                                      rules: [{ required: false, message: 'Input Name !' }]
                                  }
                              ]"
                          />
                  </a-form-item>
              </span>
              <span slot="operation" slot-scope="text, record">
                  <a @click="DeleteOneInspirationTag(record.key)">Delete</a>
              </span>
          </a-table>
          
          <div style="display:flex;margin-top:10px">                            
              <a-button class="editable-add-btn" @click="addInspirationTagData">
                  Add Inpiration Tag
              </a-button>
              <a-button class="editable-add-btn" style="margin-left:10px" @click="saveInspirationTagData" type="primary">
                  Save Inpiration Tag
              </a-button>
          </div>
      </div>
    </a-form>
  </div>
</template>
<script>
import { api } from '../helpers/Helpers';

const INSPIRATIONTAG = 'tableInspirationTag'

const inspirationTagList = [];
const columnsInspirationTag = [
  {
    title: 'Tag Name',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    key: 'operation',
    width: 200,
    scopedSlots: { customRender: 'operation' },
  },
];

export default {
    
  data() {
    return {
      dashboardInspirationTagForm: this.$form.createForm(this),
      columnsInspirationTag,
      inspirationTagList,
      INSPIRATIONTAG,    
    }
  },
  created () {
      
    this.getInspirationTags();

  },
  methods:{
    getInspirationTags:async function(){
      const res = await api.getInspirationTagInfo();
      // console.log(res);
      if (res.length > 0){
          this.inspirationTagList = res[0].datalist;
          // console.log(this.inspirationTagList);
      }
    },

    addInspirationTagData(){
      const newData = {
          key: this.inspirationTagList.length,
          name:'',
      };
      this.inspirationTagList.push(newData);
    },

    DeleteOneInspirationTag(_key){
      let objIndex = this.inspirationTagList.findIndex((obj => obj.key == _key));
      this.inspirationTagList.splice(objIndex, 1);
      this.updateInspirationTagList();
    },
    updateInspirationTagList(){
      for(let i = 0; i < this.inspirationTagList.length; i++){
          this.inspirationTagList[i].key = i;
      }
    },

    saveInspirationTagData(){
      const { dashboardInspirationTagForm: { validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          let partOneArr = [];
          if (values[`${INSPIRATIONTAG}name`] != undefined){
            (values[`${INSPIRATIONTAG}name`]).forEach((item, index) => {
                // console.log(item)
                // console.log(index)
                const obj = {
                    name: item,
                    key: index
                }
                partOneArr.push(obj)
            })
          }
          // console.log(partOneArr)
          const lastInfo = {
            datalist: partOneArr
          }
          this.sendInspirationTagData(lastInfo)
          // this.arrAdultChildNumber = partOneArr;
        }
      });
    },
    sendInspirationTagData:async function(_array){        
      const res = await api.setInspirationTagInfo(_array);
      // console.log(res);
      if (res != undefined)
          this.$message.success('Saving Success!');
    },
    

  }
}
</script>