<template>
  <div>
    <div style="display:flex">
      <div style="display:flex;">
        <a-select style="width:110px;margin-top:auto;margin-bottom:auto" v-model="controlType" slot="addonAfter" default-value="default">
            <a-select-option value="default">
                <div style="font-size:12px">Bulk actions</div>
            </a-select-option>
            <a-select-option value="edit">
                <div style="font-size:12px">Edit</div>
            </a-select-option>
            <a-select-option value="delete">
                <div style="font-size:12px">Delete</div>
            </a-select-option>
            <a-select-option value="check">
                <div style="font-size:12px">Api Check</div>
            </a-select-option>
            <a-select-option value="uncheck">
                <div style="font-size:12px">Api Uncheck</div>
            </a-select-option>
        </a-select>
          <a-button slot="enterButton" style="margin-left:2px" @click="onApply" type="primary" ghost>
            Apply
          </a-button>
      </div>
      <div style="display:flex;margin-left:15px">
        <a-select style="width:100px;margin-top:auto;margin-bottom:auto" v-model="filterDate" slot="addonAfter" default-value="all">
            <a-select-option v-for="item in filterDateList" :key="item.name" :value="item.id">
               <div style="font-size:12px">{{ item.name }}</div>
            </a-select-option>
        </a-select>
        <a-select style="width:150px;margin-top:auto;margin-bottom:auto;margin-left:2px" v-model="filterSeoScore" slot="addonAfter" default-value="all">
            <a-select-option v-for="item in filterSeoScoreList" :key="item.name" :value="item.id">
                <div style="font-size:12px">{{ item.name }}</div>
            </a-select-option>
        </a-select>
        <a-select style="width:170px;margin-top:auto;margin-bottom:auto;margin-left:2px" v-model="filterReadScore" slot="addonAfter" default-value="all">
            <a-select-option v-for="item in filterReadScoreList" :key="item.name" :value="item.id">
                <div style="font-size:12px">{{ item.name }}</div>
            </a-select-option>
        </a-select>
        <a-button style="margin-left:2px" slot="enterButton" @click="onFilter" type="primary" ghost>
          Filter
        </a-button>
      </div>

    </div>
    <a-table :columns="columns" :data-source="roomlist" :pagination="pagination" :row-selection="rowSelection" rowKey="_id" 
    @change="handleTableChange">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
        <span slot="tags" slot-scope="tags">
        <a-tag
            v-for="tag in tags"
            :key="tag"
            :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
        >
            {{ tag }}
        </a-tag>
        </span>
        
        <span slot="apiintegrate" slot-scope="text, record">
          <a-checkbox v-model="record.apiintegrate" @change="onChangeApiIntegrate(record)" :disabled="record.hotelcode == undefined || record.hotelcode == ''">
            Check
          </a-checkbox>
        </span>
        <span slot="createdate" slot-scope="text, record">
          <div>{{record.createdate != undefined? record.createdate.toString().slice(0, 10) : ''}}</div>
        </span>
        <span slot="roomname" slot-scope="text, record">
          <div style="text-decoration-line: underline;color:#1890ff;cursor: pointer;" @click="clickRoom(record._id)" v-on:click.middle="clickRoom(record._id)">{{text}}</div>
        </span>
        <span slot="roomimage" slot-scope="text, record">
          <img :src="record.roomimage != '' && record.roomimage != undefined ? `${record.roomimage}`:''" alt="" width="50px" height="30px">
        </span>
        <span slot="action" slot-scope="text, record">
        <!-- <a>Invite 一 {{ record.name }}</a> -->
          <a @click="deleteRoomOne(record._id)">Delete</a>
          <a-divider type="vertical" />
          <a @click="editRoomOne(record)">Edit</a>
          <!-- <a-divider type="vertical" />
          <a v-if="(record.status === 'Pending' || record.status === 'Denied' || record.status === undefined)" @click="AproveUserOne(record.id)">Approve</a>
          <a v-if="(record.status == 'Approved')" @click="DenyUserOne(record.id)">Deny</a> -->
        </span>
    </a-table>
    
    <div style="display:flex;position:absolute;bottom: 10px;z-index: 1;">
      <a-select style="width:170px;margin-top:auto;margin-bottom:auto;margin-left:2px" v-model="filterRoomCount" slot="addonAfter" default-value=0>
          <a-select-option v-for="item in filterRoomList" :key="item.name" :value="item.id">
              <div style="font-size:12px">{{ item.name }}</div>
          </a-select-option>
      </a-select>
      <a-button style="margin-left:2px" slot="enterButton" @click="onFilterCount" type="primary" ghost>
        Filter Count
      </a-button>
    </div>
  </div>
</template>
<script>
import { api } from '../../helpers/Helpers';
var selecetedList = [];
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    selecetedList = selectedRows;
    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selecetedList);
  },
  onSelect: (record, selected, selectedRows) => {
    // console.log("onSelect === ");
    // console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    // console.log("onSelectAll === ");
    // console.log(selectedRows);
    // console.log(selected);
    // console.log(changeRows);
  },
};
const filterDateList = [
  {
    id:'all',
    name:'All dates'
  },
  {
    id:'2022-01-12T14:26:25.599Z',
    name:'March 2022'
  },
  {
    id:'2022-02-12T14:26:25.599Z',
    name:'February 2022'
  },
  {
    id:'2022-03-12T14:26:25.599Z',
    name:'March 2021'
  },
  {
    id:'2022-04-12T14:26:25.599Z',
    name:'April 2022'
  },
  {
    id:'2022-05-12T14:26:25.599Z',
    name:'May 2022'
  },
  {
    id:'2022-06-12T14:26:25.599Z',
    name:'June 2021'
  },
  {
    id:'2022-07-12T14:26:25.599Z',
    name:'July 2022'
  },
  {
    id:'2022-08-12T14:26:25.599Z',
    name:'August 2022'
  },
  {
    id:'2022-09-12T14:26:25.599Z',
    name:'September 2021'
  },
  {
    id:'2022-10-12T14:26:25.599Z',
    name:'October 2022'
  },
  {
    id:'2022-11-12T14:26:25.599Z',
    name:'November 2022'
  },
  {
    id:'2022-12-12T14:26:25.599Z',
    name:'December 2021'
  },
];
const filterSeoScoreList = [
  {
    id:'all',
    name:'All SEO Scores'
  },
  {
    id:'1',
    name:'SEO: Need Improvement'
  },
  {
    id:'2',
    name:'SEO: OK'
  },
  {
    id:'3',
    name:'SEO: Good'
  },
  {
    id:'4',
    name:'SEO: No Focus Keyphase'
  },
  {
    id:'5',
    name:'SEO: Post Noindexed'
  },
];
const filterReadScoreList = [
  {
    id:'all',
    name:'All Readability Scores'
  },
  {
    id:'1',
    name:'Readability: Need Improvement'
  },
  {
    id:'2',
    name:'Readability: OK'
  },
  {
    id:'3',
    name:'Readability: Good'
  },
];
const filterRoomList = [
  {
    id:10,
    name:'10 rooms'
  },
  {
    id:25,
    name:'25 rooms'
  },
  {
    id:50,
    name:'50 rooms'
  },
];
export default {
  data() {
    return {
      controlType:'default',
      filterDate:'all',
      filterSeoScore:'all',
      filterReadScore:'all',
      filterRoomCount:10,
      rowSelection,
      selecetedList,

      filterDateList,
      filterSeoScoreList,
      filterReadScoreList,
      filterRoomList,
      pagination: {
        onChange: page => {
          // console.log(page);
        },
        pageSize: 10,
      },

      originList:[]

    };
  },
  props: [
      'roomlist',
      'columns',
      'tableCellCount'
  ],
  components: {
  },
  
  created: function () {
    this.originList = this.roomlist;

    this.pagination.pageSize = this.tableCellCount;
    this.filterRoomCount = this.tableCellCount;
  },
  methods: {
      getRoomList(){
          
      },
      onApply(){
          // console.log(this.controlType)
        if (this.controlType == 'default'){
          return;
        }
        else if (this.controlType == 'edit'){
          this.editRoomApply();
        }
        else if (this.controlType == 'delete'){
          this.deleteRoom();
        }
        else if (this.controlType == 'check'){
          this.checkRoom();
        }
        else if (this.controlType == 'uncheck'){
          this.uncheckRoom();
        }
        
      },
      editRoomApply(){
        if (selecetedList.length > 0)
          this.$router.push({ path: 'editroom', query: {id:selecetedList[0]._id}})

      },
      deleteRoom:async function(){
        if (selecetedList.length <= 0)
          return;
           
        this.$confirm({
          title: 'Do you want to delete these rooms?',
          // content: h => <div style="color:red;">Some descriptions</div>,
          onOk: ()=> {
            this.deleteRoomResult()
          },
          onCancel() {
            
          },
          class: 'test',
        });
      },
      deleteRoomResult:async function(){

        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.deleteRoom(selecetedList[i]._id);
          // console.log(res, selecetedList[i]._id)
        }
        this.$emit('listUpdate');
      },
      
      checkRoom:async function(){
        if (selecetedList.length <= 0)
          return;
          
        for(let i = 0; i < selecetedList.length; i++){
          const res = await api.updateRoom(selecetedList[i]._id, {apiintegrate:true});
          // console.log(res, "-checkHotel--")
        }
        this.$emit('listUpdate');
      },
      uncheckRoom:async function(){
        if (selecetedList.length <= 0)
          return;
          
        for(let i = 0; i < selecetedList.length; i++){
          const res = await api.updateRoom(selecetedList[i]._id, {apiintegrate:false});
          // console.log(res, "-uncheckHotel--")
        }
        this.$emit('listUpdate');
      },

      deleteRoomOne:async function(_id){
        // console.log(_id)
        
        this.$confirm({
          title: 'Do you want to delete this room?',
          // content: h => <div style="color:red;">Some descriptions</div>,
          onOk: ()=> {
            this.deleteRoomOneResult(_id)
          },
          onCancel() {
            
          },
          class: 'test',
        });
      },
      
      deleteRoomOneResult:async function(_id){

        const res = await api.deleteRoom(_id);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },

      editRoomOne:async function(_info){
        this.$router.push({ path: 'editroom', query: {id:_info._id} })
        // window.location.href = '/admin/edituser/?id=' + _id;
      },
      clickRoom(_id){
        // console.log("---",_id)
        // this.$router.push({ path: 'editroom', query: {id:_id} })
        window.open(`/admin/editroom?id=${_id}`,"_blank");
        // window.location.href = '/admin/edituser/?id=' + _id;
      },
      
      onFilter(){
        this.$emit('listFilter', this.filterDate, this.filterSeoScore, this.filterReadScore);
      },
      onFilterCount(){
        this.$emit('listFilterCount', this.filterRoomCount);
      },

      
      handleTableChange(pagination, filters, sorter) {
        // console.log(pagination);
        // console.log(filters);
        // console.log(sorter);
        this.roomListOrder(sorter.field, sorter.order);
      },
      roomListOrder(_field, _order){
        // this.$emit('listOrder', _field, _order);
          if (_order == "ascend"){
            this.roomlist.sort(function(a,b) {
              let aValue = a[_field];
              if (aValue == undefined && _field == 'apiintegrate')
                aValue = false;
              let bValue = b[_field];
              if (bValue == undefined && _field == 'apiintegrate')
                bValue = false;
              return (aValue > bValue) ? 1 : ((bValue > aValue) ? -1 : 0);} );
          }
          else if(_order == "descend"){
            this.roomlist.sort(function(a,b) {
              let aValue = a[_field];
              if (aValue == undefined && _field == 'apiintegrate')
                aValue = false;
              let bValue = b[_field];
              if (bValue == undefined && _field == 'apiintegrate')
                bValue = false;
              return (aValue < bValue) ? 1 : ((bValue < aValue) ? -1 : 0);} );
          }
          else if(_field != undefined && _order == undefined){
            this.roomlist.sort(function(a,b) {return (a['roomname'] > b['roomname']) ? 1 : ((b['roomname'] > a['roomname']) ? -1 : 0);} );
          }
      },
      
      onChangeApiIntegrate:async function(data){
        // // console.log(`checked = ${e.target.checked}`);
        // console.log(`checked data=`,data);
        
        const res = await api.updateRoom(data._id, {apiintegrate:data.apiintegrate});
        // console.log(res, "-onChangeApiIntegrate--")
      }
  }
};
</script>