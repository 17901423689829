<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:50%;border:1px solid #aaaaaa">Period Duration</div>
                <div style="width:50%;border:1px solid #aaaaaa">Extra Detail Info</div>
                <div style="width:40px">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <div style="margin-top:5px" v-for="item in keysListRoomExtra" :key="item">
                <a-row :gutter="0" style="width:100%;">
                    <div style="display:flex;border:1px solid #bbb;padding:5px">
                        <div style="width:50%;display:flex;border:1px solid #bbb;padding:5px" >
                            <a-form-item style="width:50%;">
                                <div style="border:1px solid #bbb">Start Date</div>
                                <a-date-picker style="width:100%;padding:2px;border:1px solid #bbb;padding:5px" placeholder=""
                                  :showToday="false"
                                  valueFormat="YYYY-MM-DD"
                                  v-decorator="[
                                        `${title}startDate[${item}]`,
                                        {
                                            initialValue: arr[item] && arr[item].startdate ? arr[item].startdate.toString().split('T')[0] : null,
                                            rules: [{ required: true, message: 'Input startDate !' }]
                                        }
                                    ]"
                                    >
                                    <!-- <template slot="dateRender" slot-scope="current">
                                        <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                        v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                            {{ moment(current).utc().date() }}
                                        </div>
                                    </template> -->
                                </a-date-picker>
                            </a-form-item>
                            <a-form-item style="width:50%;">
                                <div style="border:1px solid #bbb">End Date</div>
                                <a-date-picker style="width:100%;padding:2px;border:1px solid #bbb;padding:5px" placeholder=""
                                  :showToday="false"
                                  valueFormat="YYYY-MM-DD"
                                  v-decorator="[
                                        `${title}endDate[${item}]`,
                                        {
                                            initialValue: arr[item] ? arr[item].enddate.toString().split('T')[0] : undefined,
                                            rules: [{ required: false, message: 'endDate Number!' }]
                                        }
                                    ]"
                                  >
                                  <!-- <template slot="dateRender" slot-scope="current">
                                      <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                      v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                          {{ moment(current).utc().date() }}
                                      </div>
                                  </template> -->
                              </a-date-picker>
                            </a-form-item>
                        </div>
                        <div style="width:50%;border:1px solid #bbb;padding:5px" >
                          
                            <a-form style="display:flex" :form="extraDetailInfoForm[item]">
                                <extra-detail-info-list
                                    :title="`${EXTRADETAILINFO + (item)}`"
                                    :arr="arrExtraDetailInfo[item]"
                                />
                            </a-form>
                        </div>
                        <div style="margin-top:auto;margin-bottom:auto;width:40px">
                            <a-button type="dashed" icon="minus" @click="removeRowRoomExtra(item)" class="minusRowBtn" :size="'small'"></a-button>
                        </div>
                    </div>
                </a-row>
            </div>
            
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowRoomExtra" type="primary">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import ExtraDetailInfoList from './ExtraDetailInfoList'
import moment from 'moment';
const EXTRADETAILINFO = 'extraDetailInfo'
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    ExtraDetailInfoList,
  },
  data () {
    return {
 
         
      idRoomExtra: 0,
      keysListRoomExtra: [],

      EXTRADETAILINFO,
      extraDetailInfoForm: [],
      arrExtraDetailInfo: [],
      
      dateFormat : (val) => {
        return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
      }
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    var mynumber = 50;
    this.extraDetailInfoForm = new Array(mynumber);

    for (var i = 0; i < mynumber; i++) {
        this.extraDetailInfoForm[i] = this.$form.createForm(this);
    }
    this.init()
  },
  methods: {
    moment,
    init () {
      const arr = []
      // console.log(this.arr)
      // console.log("this.arr")
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idRoomExtra = 0;
        for (let i = 0; i < (this.arr).length; i++) {
          this.arrExtraDetailInfo[i] = this.arr[i].extradetailinfo;
          arr.push(i)
          this.idRoomExtra = this.idRoomExtra + 1;
          // console.log(this.arrExtraDetailInfo[i],i)
        }
      }
      this.keysListRoomExtra = arr
    },

    removeRowRoomExtra (k) {
      if (this.keysListRoomExtra.length === 0) { 
        return
      }
      this.keysListRoomExtra = this.keysListRoomExtra.filter(item => item !== k)
    },
    addRowRoomExtra () {
      this.keysListRoomExtra = this.keysListRoomExtra.concat(this.idRoomExtra)
      this.idRoomExtra = this.idRoomExtra + 1
    },

    getExtraDetailInfo(_index){
        this.extraDetailInfoForm[_index].validateFields((err, values) => {
          if (!err) {
            let partOneArr = [];
                if (values[`${EXTRADETAILINFO + _index}ageFrom`] !== undefined){
                    (values[`${EXTRADETAILINFO + _index}ageFrom`]).forEach((item, index) => {
                        const obj = {
                            agefrom: item,
                            ageto: values[`${EXTRADETAILINFO + _index}ageTo`][index],
                            price: values[`${EXTRADETAILINFO + _index}price`][index],
                        }
                        partOneArr.push(obj)
                    })
                }
                else if (values[`${EXTRADETAILINFO + _index}SelectInput`] === undefined && this.arrExtraDetailInfo[_index] !== undefined && this.arrExtraDetailInfo[_index].length > 0){
                    partOneArr = this.arrExtraDetailInfo[_index];
                }
                this.$emit('list', partOneArr);
          }
        });
    }
  }
}
</script>