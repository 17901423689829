<template>
  <div id="app" >
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
      <a-layout id="components-layout-demo-custom-trigger" v-show="isLogin || isLoginPage">
        <a-layout-sider v-if="!isLoginPage" v-model="collapsed" :trigger="null" collapsible>
          <div v-if="!collapsed"  class="logo" >
            <img src="./assets/images/logo1.png" alt="" width="100%" height="100%">
          </div>
          <div v-if="collapsed" class="logo2" >
            <img src="./assets/images/logo2.png" alt="" width="100%" height="100%">
          </div>
          <!-- :default-open-keys="['sub1']" -->
          <a-menu style="text-align:left" theme="dark" :default-selected-keys="[selNavItem]" mode="inline" 
            :default-open-keys="[openNavItem]"
            :inline-collapsed="collapsed">
            
            <a-menu-item key="admindashboard">
              <a-icon type="dashboard" />
              <span>Dashboard</span>
              <router-link to="/admin/admindashboard">
                  
              </router-link>
            </a-menu-item>
            <a-sub-menu key="users">
              <span slot="title"><a-icon type="user" /><span>Users</span></span>
              <a-menu-item key="users">
                <router-link to="/admin/users">
                  Users
                </router-link>
              </a-menu-item>
              <a-menu-item key="adduser">
                <router-link to="/admin/adduser">
                  Add User
                </router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-sub-menu key="hotels">
              <span slot="title"><a-icon type="home" /><span>Hotels</span></span>
              <a-menu-item key="hotels">
                <router-link to="/admin/hotels">
                  Hotels
                </router-link>
              </a-menu-item>
              <a-menu-item key="addhotel">
                <router-link to="/admin/addhotel">
                  Add Hotel
                </router-link>
              </a-menu-item>
              <!-- <a-menu-item key="hotelcategories">
                <router-link to="/admin/hotelcategories">
                  Categories
                </router-link>
              </a-menu-item> -->
            </a-sub-menu>
            <a-sub-menu key="rooms">
              <span slot="title"><a-icon type="block" /><span>Rooms</span></span>
              <a-menu-item key="rooms">
                <router-link to="/admin/rooms">
                  Rooms
                </router-link>
                
              </a-menu-item>
              <a-menu-item key="addroom">
                <router-link to="/admin/addroom">
                  Add Room
                </router-link>
              </a-menu-item>
              <!-- <a-menu-item key="roomcategories">
                <router-link to="/admin/roomcategories">
                  Categories
                </router-link>
              </a-menu-item> -->
            </a-sub-menu>
            
            <a-sub-menu key="bookings">
              <span slot="title"><a-icon type="user" /><span>Booking</span></span>
              <a-menu-item key="bookings">
                <router-link to="/admin/bookings">
                  Bookings
                </router-link>
              </a-menu-item>
              <a-menu-item key="bookinggraphchart">
                <router-link to="/admin/bookinggraphchart">
                  Booking History
                </router-link>
              </a-menu-item>
            </a-sub-menu>
            
            <a-menu-item key="filemanager">
              <a-icon type="folder-open" />
              <span>File Manager</span>
              <router-link to="/admin/awsfilemanager">
              </router-link>
            </a-menu-item>
            <a-menu-item key="hotelroomprice">
              <a-icon type="account-book" />
              <span>Hotel-Rooms Price</span>
              <router-link to="/admin/hotelroomprice">
              </router-link>
            </a-menu-item>
            <a-sub-menu key="inspirations">
              <span slot="title"><a-icon type="block" /><span>Inspirations</span></span>
              <a-menu-item key="inspirations">
                <router-link to="/admin/inspirations">
                  Inspirations
                </router-link>
                
              </a-menu-item>
              <a-menu-item key="addinspiration">
                <router-link to="/admin/addinspiration">
                  Add Inspiration
                </router-link>
              </a-menu-item>
              <a-menu-item key="inspirationtags">
                <router-link to="/admin/inspirationtags">
                  Inspiration Tags
                </router-link>
              </a-menu-item>
              <!-- <a-menu-item key="roomcategories">
                <router-link to="/admin/roomcategories">
                  Categories
                </router-link>
              </a-menu-item> -->
            </a-sub-menu>
            
            <a-menu-item key="aboutcustomize">
              <a-icon type="block" />
              <span>About Customize</span>
              <router-link to="/admin/aboutcustomize">
              </router-link>
            </a-menu-item>
            
            <a-menu-item key="contactcustomize">
              <a-icon type="block" />
              <span>Contact Customize</span>
              <router-link to="/admin/contactcustomize">
              </router-link>
            </a-menu-item>
          </a-menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header v-if="!isLoginPage" style="background: #fff; padding: 0">
            <div style="margin-left:0px;display:flex">
              <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="() => (collapsed = !collapsed)"
              />
              
              <div style="display:flex;margin-top: auto;margin-bottom: auto;margin-left: auto;margin-right: 30px;">
                <!-- <a-button style="margin-right:10px" v-if="!isLogin" type="primary" @click="showLogin(true)">
                  Login
                </a-button>
                <a-button v-if="!isLogin" type="primary" @click="showSignup(true)">
                  Sign up
                </a-button> -->
                <!-- <a-popconfirm title="Are you sure to Logout?" ok-text="Yes" cancel-text="No" @confirm="logout"> -->
                  <!-- <a-button v-if="isLogin" type="primary">
                    {{userInfo.name}}
                  </a-button> -->
                  
                <a-popover v-model="visibleUserPanel" :title="`${userInfo.email}`" trigger="click" placement="bottomRight">
                  <div slot="content" style="text-align: center;">
                    <a @click="logoutUserPanel">Logout</a>
                  </div>
                  <div v-if="isLogin" style="width:50px;height:50px;background-color: rgb(221, 221, 221);border-radius: 25px;font-size: 20px;line-height: 53px;    cursor: pointer; ">
                    {{userInfo.name}}
                  </div>
                </a-popover>
                <!-- </a-popconfirm> -->
                <a-modal
                  v-model="visibleLogin"
                  title="Login"
                  :footer="null"
                >
                  <LoginView @close="showLogin(false)" @openSignup="closeShowSignup()" @openForgot="closeShowForgot()"/>
                </a-modal>
                <a-modal
                  v-model="visibleSignup"
                  title="Sign-up"
                  :footer="null"
                >
                  <SignupView @close="showSignup(false)" @openLogin="closeShowLogin()" @openForgot="closeShowForgot()"/>
                </a-modal>
                <a-modal
                  v-model="visibleForgot"
                  title="Reset Password"
                  :footer="null"
                >
                  <ForgotView @close="showForgot(false)" @openLogin="closeShowLogin()" @openSignup="closeShowSignup()"/>
                </a-modal>
              </div>
            </div>
          </a-layout-header>
          <a-layout-content
              :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }"
          >
              <router-view />
          </a-layout-content>
        </a-layout>
      </a-layout>
    
  </div>
</template>

<script>
import LoginView from './views/LoginDialog.vue'
import SignupView from './views/SignupDialog.vue'
import ForgotView from './views/ForgotDialog.vue'
import { api } from './helpers/Helpers';

import { io } from 'socket.io-client';
import { common } from './helpers/Common';

import { bus } from './main'
export default {
  data() {
    return {
      collapsed: false,
      selNavItem: "users",
      openNavItem: "users",

			isLogin:false,
      visibleLogin: false,
      visibleSignup: false,
			visibleForgot: false,
      userInfo: {
        name:""
      },

      isLoginPage:false,
      visibleUserPanel: false,

      socketResult:""
    };
  },
		components: {
			LoginView,
			SignupView,
      ForgotView
		},
  
    created: function(){
    // // console.log("setupSocketConnection run : ");
      // Socketio.setupSocketConnection();

      this.connectSocket();

      var currentUrl = window.location.pathname;
      const words = currentUrl.split('/');
      // console.log("-22--",words[2]);
      if (words[2].includes("admindashboard")) {
        this.selNavItem = "admindashboard";
        this.openNavItem = "admindashboard";
      }
      else if (words[2].includes("users")) {
        this.selNavItem = "users";
        this.openNavItem = "users";
      }
      else if (words[2].includes("adduser")) {
        this.selNavItem = "adduser";
        this.openNavItem = "users";
      }
      else if (words[2].includes("hotels")) {
        this.selNavItem = "hotels";
        this.openNavItem = "hotels";
      }
      else if (words[2].includes("addhotel")) {
        this.selNavItem = "addhotel";
        this.openNavItem = "hotels";
      }
      else if (words[2].includes("edithotel")) {
        this.selNavItem = "hotels";
        this.openNavItem = "hotels";
      }
      // else if (words[words.length - 1].includes("hotelcategories")) {
      //   this.selNavItem = "hotelcategories";
      //   this.openNavItem = "hotels";
      // }
      else if (words[2].includes("rooms")) {
        this.selNavItem = "rooms";
        this.openNavItem = "rooms";
      }
      else if (words[2].includes("addroom")) {
        this.selNavItem = "addroom";
        this.openNavItem = "rooms";
      }
      else if (words[2].includes("editroom")) {
        this.selNavItem = "rooms";
        this.openNavItem = "rooms";
      }
      // else if (words[words.length - 1].includes("roomcategories")) {
      //   this.selNavItem = "roomcategories";
      //   this.openNavItem = "rooms";
      // }
      else if (words[2].includes("bookings")) {
        this.selNavItem = "bookings";
        this.openNavItem = "bookings";
      }
      else if (words[2].includes("detailbooking")) {
        this.selNavItem = "bookings";
        this.openNavItem = "bookings";
      }
      else if (words[2].includes("bookinggraphchart")) {
        this.selNavItem = "bookinggraphchart";
        this.openNavItem = "bookings";
      }
      else if (words[2].includes("addbooking")) {
        this.selNavItem = "addbooking";
        this.openNavItem = "bookings";
      }
      else if (words[2].includes("editbooking")) {
        this.selNavItem = "bookings";
        this.openNavItem = "bookings";
      }
      else if (words[2].includes("filemanager")) {
        this.selNavItem = "filemanager";
        this.openNavItem = "filemanager";
      }
      else if (words[2].includes("hotelroomprice")) {
        this.selNavItem = "hotelroomprice";
        this.openNavItem = "hotelroomprice";
      }
      else if (words[2].includes("editroom")) {
        this.selNavItem = "rooms";
        this.openNavItem = "rooms";
      }
      else if (words[2].includes("inspirations")) {
        this.selNavItem = "inspirations";
        this.openNavItem = "inspirations";
      }
      else if (words[2].includes("addinspiration")) {
        this.selNavItem = "addinspiration";
        this.openNavItem = "inspirations";
      }
      else if (words[2].includes("editinspiration")) {
        this.selNavItem = "inspirations";
        this.openNavItem = "inspirations";
      }
      else if (words[2].includes("inspirationtags")) {
        this.selNavItem = "inspirationtags";
        this.openNavItem = "inspirations";
      }
      else if (words[2].includes("aboutcustomize")) {
        this.selNavItem = "aboutcustomize";
        this.openNavItem = "aboutcustomize";
      }
      else if (words[2].includes("contactcustomize")) {
        this.selNavItem = "contactcustomize";
        this.openNavItem = "contactcustomize";
      }

      // console.log(currentUrl);
      
      if (this.userInfo.accessToken !== undefined && this.userInfo.accessToken !== null)
        api.innitAxis( this.userInfo.accessToken);

      if (words[words.length - 1].includes("loginview") 
        || words[words.length - 1].includes("signupview")
        || words[words.length - 1].includes("forgotview")
        || words[words.length - 1].includes("reset-password")) {
        this.isLoginPage = true;
      }
      else{
        this.isLoginPage = false;
      }
        
			this.updateUserInfo();


    },
  methods: {
      connectSocket:async function(){
        const socketEndpoint = common.getSocketEndpoint();
        var socket = await io(socketEndpoint);
        // console.log("setupSocketConne ction : ", this.socket);
        
        socket.emit('message', 'Hello, I am an agent!');

        socket.on('broadcast', (data) => {
          // console.log('broadcast', data);
          this.socketResult = data;
        });
        socket.on('newagent', (data) => {
          if (this.isLogin){
            // console.log('newagent', data);
            
            // const email = JSON.parse(data).email;
            // this.openNotification(email);

            bus.$emit('newagent', data);
            // this.socketResult = data;
          }
        });
        socket.on('newbooking', (data) => {
          if (this.isLogin){
            // console.log('newbooking', data);
            bus.$emit('newbooking', data);
            // this.socketResult = data;
          }
        });
      },
      
      openNotification(_email) {
          const key = `open${Date.now()}`;
          this.$notification.open({
            message: 'Notification New Agent',
            description:
              `${_email} registered to our Maldive as agent.`,
            btn: h => {
              return h(
                'a-button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  on: {
                    click: () => this.getUsers(),
                  },
                },
                'Update Users',
              );
            },
            key,
            onClose: close,
          });
        },

      logoutUserPanel() {
				this.$store.commit('setLoginUser', null)
				this.updateUserInfo();
        this.visibleUserPanel = false;
      },
    
			showLogin(_isShow){
				this.visibleLogin = _isShow;
				if (!_isShow){
					this.updateUserInfo();
          
        }
			},
			showSignup(_isShow){
				this.visibleSignup = _isShow;
				if (!_isShow)
					this.updateUserInfo();
			},
			updateUserInfo(){
					// console.log("==",this.$store.state.loginAdmin)
				if (this.$store.state.loginAdmin !== null && this.$store.state.loginAdmin !== undefined){
					// console.log(this.$store.state.loginAdmin.loginType)
					if (this.$store.state.loginAdmin.loginType === 'api'){
						// console.log(this.$store.state.loginAdmin.api.user.name)
						this.userInfo.name = this.$store.state.loginAdmin.api.user.name;
            this.userInfo.accessToken = this.$store.state.loginAdmin.api.user.accessToken;
            this.userInfo.email = this.$store.state.loginAdmin.api.user.email;
            api.innitAxis( this.userInfo.accessToken);
						this.isLogin = true;
					}
					else if (this.$store.state.loginAdmin.loginType === 'google'){
						// console.log(this.$store.state.loginAdmin.google.user.name)
						this.userInfo.name = this.$store.state.loginAdmin.google.user.name;
						this.isLogin = true;
					}
					else if (this.$store.state.loginAdmin.loginType === 'fb'){
						// console.log(this.$store.state.loginAdmin.fb.user.name)
						this.userInfo.name = this.$store.state.loginAdmin.fb.user.name;
						this.isLogin = true;
					}
					else{
						this.userInfo.name = '';
            this.userInfo.accessToken = '';
            this.userInfo.email = '';
						this.isLogin = false;
            api.innitAxis( this.userInfo.accessToken);
					}
				}
				else{
					this.userInfo.name = ''
          this.userInfo.accessToken = '';
          this.userInfo.email = '';
					this.isLogin = false;
          api.innitAxis( this.userInfo.accessToken);
				}

        
        if (!this.isLoginPage){
          if(!this.isLogin){
            window.location.href = '/admin/loginview';
          }
        }
        else{
          if(this.isLogin){
            window.location.href = '/admin/users';
          }
        }
			},
			logout(){
				this.$store.commit('setLoginUser', null)
				this.updateUserInfo();
			},
      

      
			closeShowSignup(){
				this.visibleLogin = false;
				this.visibleForgot = false;
				this.visibleSignup = true;
			},
			closeShowForgot(){
				this.visibleLogin = false;
				this.visibleSignup = false;
				this.visibleForgot = true;
				this.visibleAgentSignup = false;
			},
			closeShowLogin(){
				this.visibleLogin = true;
				this.visibleForgot = false;
				this.visibleSignup = false;
				this.visibleAgentSignup = false;
			},
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  margin: 0 !important;;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

@font-face {
  font-family: "Calibri light";
  src: local("Calibri light"),
   url(./assets/fonts/calibril.ttf) format("truetype");
}
@font-face {
  font-family: "Calibri medium";
  src: local("Calibri medium"),
   url(./assets/fonts/calibri.ttf) format("truetype");
}
@font-face {
  font-family: "Calibri ExtraBold";
  src: local("Calibri ExtraBold"),
   url(./assets/fonts/calibrib.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat light";
  src: local("Montserrat light"),
   url(./assets/fonts/montserratl.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat medium";
  src: local("Montserrat medium"),
   url(./assets/fonts/montserrat.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat ExtraBold";
  src: local("Montserrat ExtraBold"),
   url(./assets/fonts/montserratb.ttf) format("truetype");
}

@font-face {
  font-family: "raleway light";
  src: local("raleway light"),
   url(./assets/fonts/ralewayl.ttf) format("truetype");
}
@font-face {
  font-family: "raleway medium";
  src: local("raleway medium"),
   url(./assets/fonts/raleway.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway ExtraBold";
  src: local("Raleway ExtraBold"),
   url(./assets/fonts/ralewayb.ttf) format("truetype");
}
.ant-popover-title {
    text-align: center;
}
</style>
