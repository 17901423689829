<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:50%;height:30px;border:1px solid #dddddd;font-size: 14px;line-height:28px">Booking Period Begin</div>
                <div style="width:50%;border:1px solid #dddddd;border-left:none;font-size: 14px;line-height:28px;">Booking Period End</div>
                <div style="width:30px;border:1px solid #dddddd;border-left:none;">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <div style="margin-top:0px" v-for="item in keysListBookingPeriod" :key="item">
                <a-row :gutter="0" style="width:100%;">
                    <div style="display:flex;border:1px solid #dddddd;border-top:none;background-color:#f5f5f5;">
                        <a-form-item style="width:50%;height:100%;padding:4px;background-color:white;margin-top:0px;margin-bottom:0;
                          border:1px solid #dddddd;border-left:none;border-top:none;border-bottom:none;" >
                            <a-date-picker style="width:100%;" placeholder="" 
                              :showToday="false"
                              valueFormat="YYYY-MM-DD"
                              v-decorator="[
                                `${title}Bookingperiodbegin[${item}]`,
                                {
                                    initialValue: arr[item] && arr[item].bookingperiodbegin ? arr[item].bookingperiodbegin.split('T')[0] : null,
                                    rules: [{ required: true, message: 'Input agefrom!' }]
                                }
                              ]"
                            >
                                <!-- <template slot="dateRender" slot-scope="current">
                                    <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                    v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                        {{ moment(current).utc().date() }}
                                    </div>
                                </template> -->
                            </a-date-picker>
                        </a-form-item>
                        <a-form-item style="width:50%;height:100%;padding:4px;background-color:white;margin-top:0px;margin-bottom:0;
                          border:1px solid #dddddd;border-left:none;border-top:none;border-bottom:none;" >
                            <a-date-picker style="width:100%;" placeholder="" 
                              :showToday="false"
                              valueFormat="YYYY-MM-DD"
                              v-decorator="[
                                `${title}Bookingperiodend[${item}]`,
                                {
                                    initialValue: arr[item] && arr[item].bookingperiodend ? arr[item].bookingperiodend.split('T')[0] : null,
                                    rules: [{ required: false, message: 'agefrom Number!' }]
                                }
                              ]"
                            >
                                <!-- <template slot="dateRender" slot-scope="current">
                                    <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                    v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                        {{ moment(current).utc().date() }}
                                    </div>
                                </template> -->
                            </a-date-picker>
                        </a-form-item>
                        <div style="margin-top:auto;margin-bottom:auto;width:30px">
                            <a-button shape="circle" type="dashed" icon="minus" @click="removeRowBookingPeriod(item)" class="minusRowBtn" :size="'small'"></a-button>
                        </div>
                    </div>
                </a-row>
            </div>
            
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowBookingPeriod" type="primary" :size="'small'">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      idBookingPeriod: 0,
      keysListBookingPeriod: [],
      
      dateFormat : (val) => {
        return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
      }
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    this.init()
  },
  methods: {
    moment,
    init () {
      const arr = []
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idBookingPeriod = 0;
        for (let i = 0; i < (this.arr).length; i++) {
          arr.push(i)
          this.idBookingPeriod = this.idBookingPeriod + 1
        }
      }
      this.keysListBookingPeriod = arr
    },
    removeRowBookingPeriod (k) {
      if (this.keysListBookingPeriod.length === 0) { 
        return
      }
      this.keysListBookingPeriod = this.keysListBookingPeriod.filter(item => item !== k)
    },
    addRowBookingPeriod () {
      this.keysListBookingPeriod = this.keysListBookingPeriod.concat(this.idBookingPeriod)
      this.idBookingPeriod = this.idBookingPeriod + 1
    },
  }
}
</script>