<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%;">
            
        <a-form-item style="width:10px" v-show="false">
            <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                v-decorator="[
                    `${title}SelectInput`,
                    {
                        initialValue: 0,
                        rules: [{ required: false, message: 'Input Adult Number!' }]
                    }
                ]"
            />
        </a-form-item>
          <a-tabs type="card" @change="callback">
            <a-tab-pane key="1" tab="Promotion">
                <draggable v-model="keysListPromotion" chosenClass="chosen" forceFallback="false" group="people" animation="1000" filter="aaa" @start="onStart" @end="onEnd" :options="{handle :'div.item'}">
                    <transition-group>
                        <div style="background-color:#f8f8f8;padding:5px;border:1px solid #dddddd;border-bottom:none" v-for="item, index in keysListPromotion" :key="item">
                            <a-row style="margin:0" :gutter="24">
                                <div style="display:flex">
                                    <div v-if="true" class="item" style="width:50px;height: inherit;display:flex;color:#000000a6">
                                        <div style="margin:auto">{{index + 1}}</div>
                                    </div>
                                    <div style="width:100%">
                                        <a-tabs style="width:100%;margin:0" type="card" @change="callback">
                                            <a-tab-pane key="1" tab="Title and Description">
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-top:auto;margin-bottom:auto;margin-left:20px;text-align:left;">Title</div>
                                                    <a-form-item style="margin-top:auto;margin-bottom:auto;width:100%">
                                                    <input class="textInput" style="margin-top:auto;margin-bottom:auto;width:100%" placeholder=""
                                                        v-decorator="[
                                                            `${title}Title[${item}]`,
                                                            {
                                                                initialValue: arr[item] ? arr[item].title : undefined,
                                                                rules: [{ required: false, message: 'Input title!' }]
                                                            }
                                                        ]"
                                                        />
                                                    </a-form-item>
                                                </div>
                                                <a-divider />
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-left:20px;text-align:left;">Description</div>
                                                    <a-form-item style="margin-top:auto;margin-bottom:auto;width:100%">
                                                    <textarea class="textAreaInput" style="width:100%" placeholder="" rows="5" 
                                                        v-decorator="[
                                                            `${title}Description[${item}]`,
                                                            {
                                                                initialValue: arr[item] ? arr[item].description : undefined,
                                                                rules: [{ required: false, message: 'description!' }]
                                                            }
                                                        ]"
                                                        />
                                                    </a-form-item>
                                                </div>
                                                <a-divider />
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-left:20px;text-align:left;">Terms & Conditions</div>
                                                    <a-form-item style="margin-top:auto;margin-bottom:auto;width:100%">
                                                    <textarea class="textAreaInput" style="width:100%" placeholder="" rows="5"  
                                                    v-decorator="[
                                                        `${title}Termsconditions[${item}]`,
                                                        {
                                                            initialValue: arr[item] ? arr[item].termsconditions : undefined,
                                                            rules: [{ required: false, message: 'termsconditions!' }]
                                                        }
                                                        ]"
                                                    />
                                                    </a-form-item>
                                                </div>
                                                <a-divider />
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-top:auto;margin-bottom:auto;margin-left:20px;text-align:left;">Booking Code</div>
                                                    <a-form-item style="margin-top:auto;margin-bottom:auto;width:100%">
                                                    <input class="textInput" style="margin-top:auto;margin-bottom:auto;width:100%" placeholder=""
                                                        v-decorator="[
                                                            `${title}Bookingcode[${item}]`,
                                                            {
                                                                initialValue: arr[item] ? arr[item].bookingcode : undefined,
                                                                rules: [{ required: false, message: 'bookingcode!' }]
                                                            }
                                                        ]"
                                                        />
                                                    </a-form-item>
                                                </div>
                                            </a-tab-pane>
                                            <a-tab-pane key="2" tab="Period">
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-left:20px;text-align:left;">Travel Period</div>
                                                    <div style="width:100%;border:1px solid #dddddd;padding:5px;background-color:white">
                                                        
                                                        <a-form style="display:flex" :form="travelPeriodForm[item]">
                                                            <travel-period-list
                                                                :title="`${TRAVELPERIOD + (item)}`"
                                                                :arr="arrTravelPeriod[item]"
                                                            />
                                                        </a-form>
                                                    </div>
                                                </div>
                                                <a-divider />
                                                
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-left:20px;text-align:left;">Booking Period</div>
                                                    <div style="width:100%;border:1px solid #dddddd;padding:5px;background-color:white">
                                                        <a-form style="display:flex" :form="bookingPeriodForm[item]">
                                                            <booking-period-list
                                                                :title="`${BOOKINGPERIOD + (item)}`"
                                                                :arr="arrBookingPeriod[item]"
                                                            />
                                                        </a-form>
                                                    </div>
                                                </div>
                                                <a-divider />
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-left:20px;text-align:left;">Black-out period</div>
                                                    <div style="width:100%;border:1px solid #dddddd;padding:5px;background-color:white">
                                                        <a-form style="display:flex" :form="blackPeriodForm[item]">
                                                            <black-period-list
                                                                :title="`${BLACKPERIOD + (item)}`"
                                                                :arr="arrBlackPeriod[item]"
                                                            />
                                                        </a-form>
                                                        
                                                    </div>
                                                </div>
                                            </a-tab-pane>
                                            <a-tab-pane key="3" tab="Condition">
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-top:auto;margin-bottom:auto;margin-left:20px;text-align:left;">Max Nights</div>
                                                    
                                                    <a-form-item style="margin-top:auto;margin-bottom:auto">
                                                        <input type="number" class="textInput" style="margin-top:auto;margin-bottom:auto" :min="1" :max="100000"  
                                                            v-decorator="[
                                                                `${title}Maxnights[${item}]`,
                                                                {
                                                                    initialValue: arr[item] ? arr[item].maxnights : undefined,
                                                                    rules: [{ required: false, message: 'maxnights!' }]
                                                                }
                                                            ]"
                                                            />
                                                    </a-form-item>
                                                </div>
                                                <a-divider />
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-top:auto;margin-bottom:auto;margin-left:20px;text-align:left;">Min Nights</div>
                                                    <a-form-item style="margin-top:auto;margin-bottom:auto">
                                                        <input type="number" class="textInput" style="margin-top:auto;margin-bottom:auto" :min="1" :max="100000"  
                                                            v-decorator="[
                                                                `${title}Minnights[${item}]`,
                                                                {
                                                                    initialValue: arr[item] ? arr[item].minnights : undefined,
                                                                    rules: [{ required: false, message: 'minnights!' }]
                                                                }
                                                            ]"
                                                            />
                                                    </a-form-item>
                                                </div>
                                                <a-divider />
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-top:auto;margin-bottom:auto;margin-left:20px;text-align:left;">Advance</div>
                                                    <a-form-item style="margin-top:auto;margin-bottom:auto">
                                                        <input type="number" class="textInput" style="margin-top:auto;margin-bottom:auto" placeholder=""
                                                            v-decorator="[
                                                                `${title}Advance[${item}]`,
                                                                {
                                                                    initialValue: arr[item] ? arr[item].advance : undefined,
                                                                    rules: [{ required: false, message: 'advance!' }]
                                                                }
                                                            ]"
                                                            />
                                                    </a-form-item>
                                                </div>
                                                <a-divider v-if="false" />
                                                <div v-if="false" style="display:flex;">
                                                    <div style="width:200px;margin-top:auto;margin-bottom:auto;;margin-left:20px;text-align:left;">Person</div>
                                                    <div style="border:1px solid #dddddd;margin-top:auto;margin-bottom:auto;display:flex;width:100%;padding:5px;padding-right: 0;">
                                                        <a-form-item style="width:33%;margin-bottom: 0px;">
                                                            <input type="number" class="textInput" style="width:100%" :min="1" :max="100000" @change="onChange" 
                                                                v-decorator="[
                                                                    `${title}Personnumber[${item}]`,
                                                                    {
                                                                        initialValue: arr[item] && arr[item].person != undefined ? arr[item].person.personnumber : undefined,
                                                                        rules: [{ required: false, message: 'personnumber!' }]
                                                                    }
                                                                ]"
                                                                />
                                                        </a-form-item>
                                                        <a-form-item style="width:33%;margin-bottom: 0px;padding-left:5px;">
                                                            <input type="number" class="textInput" style="width:100%;" :min="1" :max="100000" @change="onChange" 
                                                                v-decorator="[
                                                                    `${title}Adultnumber[${item}]`,
                                                                    {
                                                                        initialValue: arr[item] && arr[item].person != undefined ? arr[item].person.adultnumber : undefined,
                                                                        rules: [{ required: false, message: 'adultnumber!' }]
                                                                    }
                                                                ]"
                                                                />
                                                        </a-form-item>
                                                        <a-form-item style="width:33%;margin-bottom: 0px;padding-left:5px;padding-right:0px;">
                                                            <input type="number" class="textInput" style="width:100%" :min="1" :max="100000" @change="onChange" placeholder="Child Number"
                                                                v-decorator="[
                                                                    `${title}Childnumber[${item}]`,
                                                                    {
                                                                        initialValue: arr[item] && arr[item].person != undefined ? arr[item].person.childnumber : undefined,
                                                                        rules: [{ required: false, message: 'childnumber!' }]
                                                                    }
                                                                ]"
                                                                />
                                                        </a-form-item>

                                                    </div>
                                                </div>
                                                <a-divider />
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-top:auto;margin-bottom:auto;margin-left:20px;text-align:left;">Black-out rooms</div>
                                                    <a-form-item style="margin-top:auto;margin-bottom:auto;width: 100%">
                                                        <a-select style="margin-top:auto;margin-bottom:auto;width: 100%" mode="tags" placeholder="Select" v-decorator="[
                                                            `${title}Room[${item}]`,
                                                            {
                                                                initialValue: arr[item] ? arr[item].room : undefined,
                                                                rules: [{ required: false, message: 'room!' }]
                                                            }
                                                        ]">
                                                            <a-select-option v-for="item in roomList" :key="item._id" :value="item._id">
                                                            {{ item.roomname }}
                                                            </a-select-option>
                                                        </a-select>
                                                    </a-form-item>
                                                </div>
                                                <a-divider />
                                            </a-tab-pane>
                                            <a-tab-pane key="4" tab="Calculation">
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-top:auto;margin-bottom:auto;margin-left:20px;text-align:left;">Standard Discount</div>
                                                    <a-form-item style="margin-top:auto;margin-bottom:auto;width:200px;">
                                                        <a-input-number style="margin-top:auto;margin-bottom:auto" :min="0" :max="100000"  
                                                            v-decorator="[
                                                                `${title}Discount[${item}]`,
                                                                {
                                                                    initialValue: arr[item] ? arr[item].discount : undefined,
                                                                    rules: [{ required: false, message: 'discount!' }]
                                                                }
                                                            ]"
                                                            />
                                                    </a-form-item>
                                                    <a-form style="display:flex;width: 100%;" :form="discountCalculationForm[item]">
                                                        <discount-calculation-list
                                                            :title="`${DISCOUNTCALCULATION + (item)}`"
                                                            :arr="arrDiscountCalculation[item]"
                                                        />
                                                    </a-form>
                                                </div>
                                                <a-divider />
                                                <div style="display:flex;">
                                                    <div style="width:200px;margin-top:auto;margin-bottom:auto;margin-left:20px;text-align:left;">Stay offer</div>
                                                    
                                                    <div style="display:flex;width:100%">
                                                        <a-form-item style="width:50%">
                                                            <a-input-number style="width:100%" :min="1" :max="100000" placeholder="Stay day"
                                                                v-decorator="[
                                                                    `${title}Stayday[${item}]`,
                                                                    {
                                                                        initialValue: arr[item]? (arr[item].stayoffer ? arr[item].stayoffer.stayday : undefined) : undefined,
                                                                        rules: [{ required: false, message: 'stayday!' }]
                                                                    }
                                                                ]"
                                                                />
                                                        </a-form-item>
                                                        <a-form-item style="width:50%;margin-left:2px;margin-right:2px">
                                                            <a-input-number style="width:100%;margin-left:2px;margin-right:2px" :min="1" :max="100000" placeholder="Offer day"
                                                                v-decorator="[
                                                                    `${title}Offerday[${item}]`,
                                                                    {
                                                                        initialValue: arr[item]? (arr[item].stayoffer ? arr[item].stayoffer.offerday : undefined) : undefined,
                                                                        rules: [{ required: false, message: 'offerday!' }]
                                                                    }
                                                                ]"
                                                                />
                                                        </a-form-item>
                                                        
                                                    </div>
                                                </div>
                                            </a-tab-pane>
                                        </a-tabs>
                                    </div>
                                    <div style="width: 25px;margin-left:0;margin-top:auto;margin-bottom:auto">
                                        <a-col :span="2" style="padding-left: 3px">
                                            <a-form-item :labelCol="{span: 0}" :wrapperCol="{span: 24}">
                                                <template v-if="keysListPromotion.length > 0">
                                                    <a-button shape="circle" type="solid" icon="minus" @click="removeRowPromotion(item)" class="minusRowBtn" :size="'small'"></a-button>
                                                </template>
                                            </a-form-item>
                                        </a-col>

                                    </div>
                                </div>
                            </a-row>
                        </div>
                    </transition-group>
                </draggable> 
                <div v-if="keysListPromotion.length > 0" style="width:100%;height:1px;background-color:#dddddd"></div>
            </a-tab-pane>
          </a-tabs>
          <div style="display:flex;margin-top:10px">
              <a-button style="margin-left:auto;margin-right:0" :style="{ background: '#0071a1',border:'none'}" @click="addRowPromotion" type="primary">
                  Add Row
              </a-button>
          </div>
        </div>
    </div>
</template>
<script>
var moment = require('moment-timezone');
import draggable from 'vuedraggable'
import BlackPeriodList from './BlackPeriodList.vue'
import BookingPeriodList from './BookingPeriodList.vue'
import TravelPeriodList from './TravelPeriodList.vue'
import DiscountCalculationList from './DiscountCalculationList.vue'
const TRAVELPERIOD = 'travelPeriod'
const BOOKINGPERIOD = 'bookingPeriod'
const BLACKPERIOD = 'blackPeriod'
const DISCOUNTCALCULATION = 'dicountCalculation'
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    },
    roomList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    TravelPeriodList,
    BookingPeriodList,
    BlackPeriodList,
    DiscountCalculationList,
    draggable
  },
  data () {
    return {
 
         
      idPromotion: 0,
      keysListPromotion: [],

      TRAVELPERIOD,
      travelPeriodForm: [],
      arrTravelPeriod: [],
      
      BOOKINGPERIOD,
      bookingPeriodForm: [],
      arrBookingPeriod: [],
      
      BLACKPERIOD,
      blackPeriodForm: [],
      arrBlackPeriod: [],
      
      DISCOUNTCALCULATION,
      discountCalculationForm: [],
      arrDiscountCalculation: [],
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    var mynumber = 100;
    this.travelPeriodForm = new Array(mynumber);

    for (var i = 0; i < mynumber; i++) {
        this.travelPeriodForm[i] = this.$form.createForm(this);
    }
    this.bookingPeriodForm = new Array(mynumber);

    for (var i = 0; i < mynumber; i++) {
        this.bookingPeriodForm[i] = this.$form.createForm(this);
    }
    this.blackPeriodForm = new Array(mynumber);

    for (var i = 0; i < mynumber; i++) {
        this.blackPeriodForm[i] = this.$form.createForm(this);
    }

    for (var i = 0; i < mynumber; i++) {
        this.discountCalculationForm[i] = this.$form.createForm(this);
    }
    // this.init()
  },
  mounted(){
    this.init();
  },
  methods: {
    moment,
    init () {
      const arrTemp = []
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idPromotion = 0;
        for (let i = 0; i < (this.arr).length; i++) {
            
          this.arrTravelPeriod[i] = this.arr[i].travelperiod;
          this.arrBookingPeriod[i] = this.arr[i].bookingperiod;
          this.arrBlackPeriod[i] = this.arr[i].blackperiod;
          this.arrDiscountCalculation[i] = this.arr[i].discountcalculation;
          arrTemp.push(i)
          this.idPromotion = this.idPromotion + 1
        }
      }
      this.keysListPromotion = arrTemp
    },
    
    callback(key) {
      // console.log(key);
    },
    onChange(){

    },

    removeRowPromotion (k) {
      if (this.keysListPromotion.length === 0) { 
        return
      }
      this.keysListPromotion = this.keysListPromotion.filter(item => item !== k)
    },
    addRowPromotion () {
      this.keysListPromotion = this.keysListPromotion.concat(this.idPromotion)
      this.idPromotion = this.idPromotion + 1
    },

    getTravelPeriod(_index){
                // console.log(this.travelPeriodForm)
        this.travelPeriodForm[_index].validateFields((err, values) => {
          if (!err) {
            let partOneArr = [];
                // console.log(values,_index)
                if (values[`${TRAVELPERIOD + _index}Travelperiodbegin`] != undefined){
                    (values[`${TRAVELPERIOD + _index}Travelperiodbegin`]).forEach((item, index) => {
                        const obj = {
                            travelperiodbegin: moment.utc(item).tz("America/Los_Angeles"),
                            travelperiodend: moment.utc(values[`${TRAVELPERIOD + _index}Travelperiodend`][index]).tz("America/Los_Angeles"),
                        }
                        partOneArr.push(obj)
                    })
                }
                else if (values[`${TRAVELPERIOD + _index}SelectInput`] == undefined && this.arrTravelPeriod[_index] != undefined && this.arrTravelPeriod[_index].length > 0){
                    partOneArr = this.arrTravelPeriod[_index];
                }
                this.$emit('travellist', partOneArr);
          }
        });
    },
    getBookingPeriod(_index){
        this.bookingPeriodForm[_index].validateFields((err, values) => {
          if (!err) {
            let partOneArr = [];
                // console.log(values,_index)
                if (values[`${BOOKINGPERIOD + _index}Bookingperiodbegin`] != undefined){
                    (values[`${BOOKINGPERIOD + _index}Bookingperiodbegin`]).forEach((item, index) => {
                        const obj = {
                            bookingperiodbegin: moment.utc(item).tz("America/Los_Angeles"),
                            bookingperiodend: moment.utc(values[`${BOOKINGPERIOD + _index}Bookingperiodend`][index]).tz("America/Los_Angeles"),
                        };
                        partOneArr.push(obj);
                    });
                }
                else if (values[`${BOOKINGPERIOD + _index}SelectInput`] == undefined && this.arrBookingPeriod[_index] != undefined && this.arrBookingPeriod[_index].length > 0){
                    partOneArr = this.arrBookingPeriod[_index];
                }
                this.$emit('bookingllist', partOneArr);
          }
        });
    },
    getBlackPeriod(_index){
        this.blackPeriodForm[_index].validateFields((err, values) => {
          if (!err) {
            let partOneArr = [];
                if (values[`${BLACKPERIOD + _index}Blackperiodbegin`] != undefined){
                    (values[`${BLACKPERIOD + _index}Blackperiodbegin`]).forEach((item, index) => {
                        const obj = {
                            blackperiodbegin: moment.utc(item).tz("America/Los_Angeles"),
                            blackperiodend: moment.utc(values[`${BLACKPERIOD + _index}Blackperiodend`][index]).tz("America/Los_Angeles"),
                        }
                        partOneArr.push(obj)
                    })
                }
                else if (values[`${BLACKPERIOD + _index}SelectInput`] == undefined && this.arrBlackPeriod[_index] != undefined && this.arrBlackPeriod[_index].length > 0){
                    partOneArr = this.arrBlackPeriod[_index];
                }
                this.$emit('blacklist', partOneArr);
          }
        });
    },
    getDiscountCalculation(_index){
        this.discountCalculationForm[_index].validateFields((err, values) => {
          if (!err) {
            let partOneArr = [];
                if (values[`${DISCOUNTCALCULATION + _index}Discount`] !== undefined){
                    (values[`${DISCOUNTCALCULATION + _index}Discount`]).forEach((item, index) => {
                        const obj = {
                            discount: item,
                            discountperiodbegin: moment.utc(values[`${DISCOUNTCALCULATION + _index}Discountperiodbegin`][index]).tz("America/Los_Angeles"),
                            discountperiodend: moment.utc(values[`${DISCOUNTCALCULATION + _index}Discountperiodend`][index]).tz("America/Los_Angeles"),
                        }
                        partOneArr.push(obj)
                    })
                }
                else if (values[`${DISCOUNTCALCULATION + _index}SelectInput`] === undefined && this.arrDiscountCalculation[_index] !== undefined && this.arrDiscountCalculation[_index].length > 0){
                    // console.log(_index,"===getDiscountCalculation==", this.arrDiscountCalculation);
                    partOneArr = this.arrDiscountCalculation[_index];
                    // console.log("===partOneArr==", partOneArr)
                    

                }
                    // console.log("===discountlist==", partOneArr)
                this.$emit('discountlist', partOneArr);
          }
        });
    },
    getKeyList(){
        this.$emit('getKeyList', this.keysListPromotion);
    },
    
    onStart(){
        // console.log("======= onStart");
        this.drag=true;
    },
    onEnd() {
        // console.log("======= onEnd", this.keysListPromotion);
       this.drag=false;
    },
  }
}
</script>
<style>
.textInput{
    height: 35px;
    border-radius: 5px;
    border: 1px #d7d7d7 solid;
}
.textInput:focus{
    height: 35px;
    border-radius: 5px;
    border: 1px #8d8d8d solid;
    outline: none;
}
.textAreaInput{
    border-radius: 5px;
    border: 1px #d7d7d7 solid;
    line-height: 20px;
}
.textAreaInput:focus{
    border-radius: 5px;
    border: 1px #8d8d8d solid;
    outline: none;
}
        .item {
            padding: 6px;
            background-color: #fdfdfd;
            border: solid 1px #eee;
            cursor: move;
        } 
        /*选中样式*/
        .chosen {
            border: solid 2px #3089dc !important;
        }
</style>