<template>
    <div style="min-height:85vh">
        <div style="margin: 16px 0;display:flex">
            <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                <a-breadcrumb-item style="font-size:30px;">Contact Customize</a-breadcrumb-item>
                <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
            </a-breadcrumb>
        </div>
        <div style="margin-left:10px;margin-top:10px;display: flex;">
            <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">Contact Main Title</div>
            <div style="display:flex;width: 100%;">
                <a-textarea v-model="contactMainTitle" placeholder="Enter Main Title" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </div>
        </div>
        <div style="margin-left:10px;margin-top:10px;display: flex;">
            <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">Contact Sub Title</div>
            <div style="display:flex;width: 100%;">
                <a-textarea v-model="contactSubTitle" placeholder="Enter Sub Title" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </div>
        </div>
        <div style="display:flex">
            <div style="margin-left: auto;margin-right: auto;">
                <div style="text-align:left;margin-top:15px;margin-left:5px;text-align:center">Contact Background Image</div>
                <a-divider/>
                <div style="text-align:left;margin-bottom:5px;margin-left:5px">
                    <div v-if="!isBackgroundSelected">
                        <div style="width:200px;height:200px;border: 1px #EEEEEE solid;border-radius: 5px;display: flex;">
                            <div style="margin:auto">No image selected</div>
                        </div>
                        <div style="display:flex;width:100%">
                            <a-button style="margin-left:auto;margin-right:auto;margin-top:10px" @click="showBackgroundImage(true)" :size="'small'">Add Background Image</a-button>
                        </div>
                    </div>
                    <div v-if="isBackgroundSelected" style="position:relative;width:200px;height:200px">
                        <img :src="`${selectedBackgroundImage}`" alt="" width="200px" height="200px">
                        <a-icon @click="cancelBackgroundImage()" style="position:absolute;top:5px;right:5px" type="close-circle" theme="filled" />
                        <a-icon @click="showBackgroundImage(true)" style="position:absolute;top:5px;right:25px" type="edit" theme="filled" />
                    </div>
                </div>
                <a-modal
                    v-model="visibleBackgroundImage"
                    title="Add Contact Backgroun Image"
                    :footer="null"
                    width="80%"
                >
                    <AwsFileManager v-if="visibleBackgroundImage" :ismulti="false" @close="showBackgroundImage(false)" @selectImages="selectBackgroundImage"/>
                </a-modal>
            </div>
        </div>
        <div style="margin-top:10px">
            <a-button class="editable-add-btn" style="margin-left:10px" @click="saveContactPageData" type="primary">
                Save Contact Page
            </a-button>
        </div>
    </div>
</template>
<script>
import { api } from '../helpers/Helpers';
import AwsFileManager from '../components/AwsFileManager.vue';
export default {
  components: { AwsFileManager },
  data() {
    return {
        contactMainTitle:'',
        contactSubTitle:'',
        isBackgroundSelected:false,
        visibleBackgroundImage:false,
        selectedBackgroundImage:''
    }
  },
  created(){
    
    this.getContactPageInfos();
  },

  methods:{
    
    getContactPageInfos:async function(){
        const res = await api.getContactPageInfo();
        // console.log(res);
        if (res != undefined && res.length > 0){
            this.contactMainTitle = res[0].contactMainTitle;
            this.contactSubTitle = res[0].contactSubTitle;
            this.selectedBackgroundImage = res[0].backgroundImage;
            this.isBackgroundSelected = true;
        }
    },

    showBackgroundImage(_isShow){
        this.visibleBackgroundImage = _isShow;
    },
    
    selectBackgroundImage(_images){
        if (_images.length > 0){
            this.selectedBackgroundImage = _images[0];
            this.isBackgroundSelected = true;
        }
        this.visibleBackgroundImage = false;
    },
    
    cancelBackgroundImage(_index){
        this.selectedBackgroundImage = '';
        this.isBackgroundSelected = false
    },
    saveContactPageData:async function(){
        const lastInfo = {
            contactMainTitle:this.contactMainTitle,
            contactSubTitle:this.contactSubTitle,
            backgroundImage: this.selectedBackgroundImage,
        }
        const res = await api.setContactPageInfo(lastInfo);
        // console.log(res)
    },
  },
    
}
</script>