
<template>
  <div style="width: 100%;height:1000px">
    <div class="file-selector">
      <button style="width: 100%;" type="button" @click="driveIconClicked();">Select Google Drive</button>
    </div>
    <AttachmentList @cancelImage="cancelImage" :tempAttachments="imageIds" />
  </div>
</template>

<script>
import AttachmentList from "./AttachmentList";
export default {
  name: "Attachment",
  data() {
    return {
      tempAttachments: [],
      imageIds:[],
      attachments: [],
      pickerApiLoaded: false,
      developerKey: "AIzaSyD7vkECFmKoIzK7ooBHw3E8LPxWhuJfbuI",
      clientId: "101176899724-7ac23fke4oa6el2htmt1mhcighkt12m5.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/drive.readonly",
      oauthToken: null
    };
  },
  
  props: {
    ismulti: {
      type: Boolean
    }
  },
  components: {
    AttachmentList: AttachmentList,
  },
  mounted() {
    this.imageIds = []
    let gDrive = document.createElement("script");
    gDrive.setAttribute("type", "text/javascript");
    gDrive.setAttribute("src", "https://apis.google.com/js/api.js");
    document.head.appendChild(gDrive);
  },
  
  created: function () {
    
      // console.log(this.ismulti);
  },
  methods: {
    // function called on click of drive icon
    async driveIconClicked() {
      /* eslint-disable */
      // console.log("Clicked");
      await gapi.load("auth2", () => {
        // console.log("Auth2 Loaded");
        gapi.auth2.authorize(
          {
            client_id: this.clientId,
            scope: this.scope,
            immediate: false
          },
          this.handleAuthResult
        );
      });
      gapi.load("picker", () => {
        // console.log("Picker Loaded");
        this.pickerApiLoaded = true;
        this.createPicker();
      });
    },

    handleAuthResult(authResult) {
      // console.log("Handle Auth result");
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
      }
    },

    // Create and render a Picker object for picking user Photos.
    createPicker() {
      // console.log("Create Picker");
      var view = new google.picker.View(google.picker.ViewId.DOCS);
      
      // var view = new google.picker.View(google.picker.ViewId.FOLDERS);
      view.setParent('root');
      // .setSelectFolderEnabled(true);
      view.setMimeTypes('image/png,image/jpeg,application/vnd.google-apps.folder');
      // view.setMimeTypes(google.picker.Document.Type.PHOTO);
      // view.setInculudeFolders();
      var feature = google.picker.Feature.MULTISELECT_ENABLED;
      if (!this.ismulti)
        feature = google.picker.Feature.MINE_ONLY;

      var uploadView = new google.picker.DocsUploadView();
      if (this.pickerApiLoaded && this.oauthToken) {
        var picker = new google.picker.PickerBuilder()
          .enableFeature(feature)//MINE_ONLY
          .addView(view)
          .addView(uploadView)
          .setOAuthToken(this.oauthToken)
          .setDeveloperKey("AIzaSyD7vkECFmKoIzK7ooBHw3E8LPxWhuJfbuI")
          .setCallback(this.pickerCallback)
          .build();
        picker.setVisible(true);
      }
    },
    async pickerCallback(data) {
      // console.log("PickerCallback Files : ", data);
      var url = "nothing";
      var name = "nothing";
      if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
        var doc = data[google.picker.Response.DOCUMENTS][0];
        url = doc[google.picker.Document.URL];
        name = doc.name;
        let docs = data.docs;
      // console.log("PickerCallback FilesCoutn : ", data.docs.length);
        var param = { fileId: doc.id, oAuthToken: this.oauthToken, name: name };
        let attachments = [];
        if (!this.ismulti)
          this.imageIds = [];
        for (let i = 0; i < docs.length; i++) {
          let attachment = {};
          attachment._id = docs[i].id;
          attachment.title = docs[i].name;
          attachment.name = docs[i].name + "." + docs[i].mimeType.split("/")[1];
          attachment.type = "gDrive";
          attachment.description = "Shared with GDrive";
          attachment.extension =
            "." +
            docs[i].mimeType.substring(docs[i].mimeType.lastIndexOf(".") + 1);
          attachment.iconURL = docs[i].iconUrl;
          attachment.size = docs[i].sizeBytes;
          attachment.user = JSON.parse(localStorage.getItem("user"));
          attachment.thumb = null;
          attachment.thumb_list = null;
          attachments.push(attachment);

          if (!this.imageIds.includes(docs[i].id))
            this.imageIds.push(docs[i].id)
        }
        this.tempAttachments = [...attachments];

        this.$emit('selectImages', this.imageIds);
      }
      this.oauthToken = null;
      this.pickerApiLoaded = false;
    },
    
    cancelImage(_imageid){
      var _index = this.imageIds.indexOf(_imageid);
      this.imageIds.splice(_index, 1);
      this.$emit('selectImages', this.imageIds);
    }
  },
  computed: {
    getTempAttachments() {
      return this.tempAttachments;
    },
    getAttachments() {
      return this.attachments;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.file-selector {
  padding: 5px;
  width: 100%;
  font-weight: 600;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #4e5b69;
  z-index: -9;
 }
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
button {
  background: #031629;
  box-shadow: 0 0 2px 0 rgba(3, 22, 41, 0.11),
    0 6px 16px 0 rgba(3, 22, 41, 0.08);
  font-family: SFProDisplay-Regular;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.4px;
  padding: 12px 30px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: all 0.25s;
}

button:hover {
  background-color: rgba(65, 184, 131, 1);
  border-color: transparent;
}
/*.separator {
  position: relative;
}
.separator:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 200px;
  background: #d8d8d8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
span {
  position: relative;
  background: #f9f9f9;
  padding: 0 4px;
  z-index: 9;
  font-size: 12px;
  color: #4e5b69;
} */
</style>
