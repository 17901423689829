<template>
    <div class="login">
        <div>
            <div style="width:100%;display:flex;top:0px;">
                <div class="loginDialog">
                    <div class="loginBtnMain" style="padding-left: 5%;padding-right: 5%;padding-top:20px">
                        <a-input placeholder="Email" v-model="loginAdmin.email" :size="'large'" @change="emailChanged"/>
                        <a-input style="margin-top:20px" placeholder="Password" v-model="loginAdmin.password" :size="'large'" @change="emailChanged"/>

                        <a-button style="margin-top:20px;width:100%" type="primary" :disabled="disabled" :size="'large'" :style="{ fontSize: '20px'}"  @click="handleLoginClick">
                            Sign in
                        </a-button>
                        
                        <div style="background-color:#F0EFEF;height:1px;widht:100%;margin-top:20px"></div>
                        <div style="width:100%;margin-top:10px;text-align:center;cursor:pointer">New here? <font color="#CEB39A" v-on:click='clickSignUp'>Sign up</font></div>
                        <div style="width:100%;margin-top:10px;text-align:center;cursor:pointer" @click="openForgotPassword">Forgot password?</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { api } from '../helpers/Helpers';

export default {
    data() {
        return {
            disabled: false,
            m_email: '',
            m_password: '',
            loginAdmin: {
                type: Object,
                required: false,
                default: () => {
                    return {
                        email: 'marcos.henrique@toptal.com',
                        password: 's3cr3tp4sswo4rd'
                    };
                }
            },
            
            isVerify: false,
        };
    },
    components: {
        
    },
    methods: {
        handleLoginClick: async function() {
            // console.log("click == ", this.loginAdmin.email + " == " + this.loginAdmin.password) 
            const res = await api.login(this.loginAdmin);
            // this.flash('task created', 'success');
            // console.log("---------accessToken-------",res);
            if (res != undefined){
                var userInfo = {
                    loginType: 'api',
                    api: {
                        auth: 'alyosha',
                        user: {
                            name: res.name,
                            email: this.loginAdmin.email,
                            profileImage: "testurl",
                            accessToken:res.accessToken,
                            refreshToken:res.refreshToken
                        }
                    },
                    id: res.userId
                }
                // console.log("---- " + userInfo)
                this.$store.commit('setLoginUser', userInfo)

                this.$emit('close');
            }
        },
        close(){
            this.$emit('close');
        },
        emailChanged(){
            if (this.loginAdmin.email == undefined || this.loginAdmin.password == undefined)
                this.disabled = true;
            else
                this.disabled = false;
        },
        closeDialog(){
            // this.$router.go(-1)
            
            window.open("/","_self");// "_blank"
        },
        clickSignUp(){
            this.$emit('openSignup');
        },
        openForgotPassword(){
            this.$emit('openForgot');
        },

        onEvent() {
            // console.log("onEvent");
        },
        verifyMethod(){
            this.isVerify = true;
            this.emailChanged();
            // console.log("verifyMethod");
        },
        expiredMethod(){
            this.isVerify = false;
            this.emailChanged();
            // console.log("expiredMethod");
        },
        renderMethod(){
            this.isVerify = false;
            // console.log("renderMethod");
        },
        errorMethod(){

            // console.log("errorMethod");
        }
    },
    mounted(){
        this.emailChanged()
    },
    created: function () {
    }
}
</script>