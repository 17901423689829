export const common = {
    commonElementsOfArray(arr1, arr2, arr3) {
        const map = new Map();
        const updateMap = arr => {
          arr.forEach(entry => {
            if (!map.has(entry)) {
              map.set(entry, 1);
            } else {
              let timesSeen = map.get(entry);
              map.set(entry, ++timesSeen);
            }
          });
        };
  
        updateMap(arr1);
        updateMap(arr2);
        updateMap(arr3);
  
        map.forEach((count, key) => {
          // remove all entries not seen at least 3 times
          if (count !== 3) {
            map.delete(key);
          }
        });
  
        return [...map.keys()];
      },

}