<template>
    <div style="width:100%">
        <div style="display:flex;">
            <div style="width:200px;margin-top:auto;margin-bottom:auto;margin-left:20px;text-align:left;">Type</div>
            <a-form-item style="margin-top:auto;margin-bottom:auto;width:100%">
                <a-select style="margin-top:auto;margin-bottom:auto;width: 100%" placeholder="Select Type" @change="onChangeType"
                    v-decorator="[
                        `${title}Type[${item}]`,
                        {
                            initialValue: arr[item] ? arr[item].type : 'Text',
                            rules: [{ required: false, message: 'Input type!' }]
                        }
                    ]"
                >
                    <a-select-option v-for="itemType in typeList" :key="itemType" :value="itemType">
                        {{ itemType }}
                    </a-select-option>
                </a-select>
            </a-form-item>
        </div>
        <a-divider />
        <div style="display:flex;" v-if="curType == 'Text'">
            <div style="width:200px;margin-left:20px;text-align:left;">Content</div>
            <a-form-item style="margin-top:auto;margin-bottom:auto;width:100%">
                <textarea class="textAreaInput" style="width:100%" placeholder="" rows="5" 
                    v-decorator="[
                        `${title}Content[${item}]`,
                        {
                            initialValue: arr[item] ? arr[item].content : undefined,
                            rules: [{ required: false, message: 'content!' }]
                        }
                    ]"
                />
            </a-form-item>
        </div>
        <div v-else-if="curType == 'Hyper Link'">
            <div style="display:flex;">
                <div style="width:200px;margin-left:20px;text-align:left;">Title</div>
                <a-form-item style="margin-top:auto;margin-bottom:auto;width:100%">
                    <a-input class="textInput" style="width:100%" placeholder="Link Title"
                        v-decorator="[
                            `${title}HyperlinkTitle[${item}]`,
                            {
                                initialValue: arr[item] ? arr[item].hyperlinkTitle : undefined,
                                rules: [{ required: false, message: 'Hyper Link Title!' }]
                            }
                        ]"
                    />
                </a-form-item>
            </div>
            <div style="display:flex;">
                <div style="width:200px;margin-left:20px;text-align:left;">Link</div>
                <a-form-item style="margin-top:auto;margin-bottom:auto;width:100%">
                    <a-input class="textInput" style="width:100%" placeholder="Link URL"
                        v-decorator="[
                            `${title}HyperlinkURL[${item}]`,
                            {
                                initialValue: arr[item] ? arr[item].hyperlinkURL : undefined,
                                rules: [{ required: false, message: 'Hyper Link URL!' }]
                            }
                        ]"
                    />
                </a-form-item>
            </div>
        </div>
        <div style="display:flex;" v-if="curType == 'Image'">
            <div style="width:200px;margin-left:20px;text-align:left;">Images</div>
            
            <div style="margin-top:auto;margin-bottom:auto;width:100%" >
                <a-button @click="showGallary(true)" :size="'small'">Add Image</a-button>
                <a-list style="margin-top:10px" :grid="{ gutter: 24, xs: 3, sm: 4, md: 6, lg: 8, xl: 8, xxl: 12 }" :data-source="selectedImages">
                    <a-list-item slot="renderItem" slot-scope="item">
                        <div style="border: 1px solid #E7E7E7;position:relative">
                            <div style="width:100%;height:8vh">
                                <img style="object-fit: cover;width:100%;height:100%" :src="`${item}`" >
                            </div>
                            <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelImage(item)" :size="'small'"></a-button>
                        </div>
                    </a-list-item>
                </a-list>
                
                <!-- <div v-if="isSelected[item]" style="position:relative">
                    <img :src="`${selectedImages[item]}`" alt="" width="100%" height="100px">
                    <a-icon @click="cancelImage(item)" style="position:absolute;top:5px;right:5px" type="close-circle" theme="filled" />
                    <a-icon @click="showGallary(true, item)" style="position:absolute;top:5px;right:25px" type="edit" theme="filled" />
                </div> -->
                <a-modal
                    v-model="visibleGallary"
                    title="Add Gallay"
                    :footer="null"
                    width="80%"
                >
                    <AwsFileManager v-if="visibleGallary" :ismulti="true" @close="showGallary(false)" @selectImages="selectImages"/>
                </a-modal>
            </div>

            <a-form-item style="margin-top:auto;margin-bottom:auto;width:100%" v-show="false">
                <a-input class="textInput" style="width:100%" placeholder="Link URL"
                    v-decorator="[
                        `${title}Images[${item}]`,
                        {
                            initialValue: getImagesStr(),
                            rules: [{ required: false, message: 'Hyper Link URL!' }]
                        }
                    ]"
                />
            </a-form-item>

        </div>
    </div>
</template>

<script>
import AwsFileManager from '../../components/AwsFileManager.vue'
const typeList = ['Text', 'Hyper Link', 'Image']
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        arr: {
            type: Array,
            default: function () {
                return []
            }
        },
        item: {
            type: Number,
            default: 0
        },
    },
    components: {
        AwsFileManager
    },
    data () {
        return {
            typeList,
            
            curType:"Text",
            
            visibleGallary: false,
            selectedImages:[],

        }
    },

    created () {
        this.init()
    },
    methods: {
        init () {
            // console.log("===arr=   === ", this.arr, this.item);
            this.selectedImages = [];
            if (this.arr.length > this.item){
                this.selectedImages = this.arr[this.item].images;
                this.curType = this.arr[this.item].type;
            }
        },
        
        onChangeType(_type){
            this.curType = _type; 
            // console.log("===onChangeType==== ", _type);
        },
        
    
        showGallary(_isShow){
            this.visibleGallary = _isShow;
        },
        selectImages(_images, _index){
            if (_images.length > 0){
                this.selectedImages = _images;
            }
            // console.log(this.selectedImages, _index)
            this.visibleGallary = false;
        },
        cancelImage(_index){
            // console.log(this.selectedImages, _index);
            this.selectedImages.splice(_index, 1);
            // console.log(this.selectedImages)
        },
        
        getImagesStr(){
            return this.selectedImages.join('-,-');
        }
    }
    
}
</script>