<template>
    <div class="adminhome" style="min-height:85vh;overflow-x: hidden;">
        <div style="display:flex">
            <div style="margin: 16px 0;display:flex">
                <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                    <a-breadcrumb-item style="font-size:30px;">Inspirations</a-breadcrumb-item>
                    <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
                </a-breadcrumb>
                <a-button style="margin-top:auto;margin-bottom:auto;margin-left:20px" type="primary">
                    <router-link to="/admin/addinspiration">
                    Add Inspiration
                    </router-link>
                </a-button>
            </div>
        </div>
        <div style="position:relative">
          <div style="position:absolute;right:0;top:-30px;display:flex;">
            <a-input-search style="width:400px;margin-right:0px;margin-left:auto" v-model="searchText" placeholder="" @search="onSearch">
              <a-button slot="enterButton">
                Search Inspiration
              </a-button>
            </a-input-search>
          </div>
          <div style="display:flex;width:100%">
              <a-tabs style="width:100%"
                  default-active-key="1"
                  :tab-position="'top'"
                  >
                  <a-tab-pane key="1" tab="All">
                      <InspirationTabelItem :key="tableCellCount" :showlist ="showlist" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listFilterCount="listFilterCount" />
                  </a-tab-pane>
              </a-tabs>
          </div>
        </div>
    </div>
</template>
<script>

import { api } from '../helpers/Helpers';
import InspirationTabelItem from './TableItems/InspirationTabelItem.vue'
const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: true,
  },
  {
    title: 'Detail',
    dataIndex: 'detail',
    key: 'detail',
    sorter: true,
  },
  {
    title: 'User Name',
    key: 'userName',
    dataIndex: 'userName',
  },
  {
    title: 'User Image',
    key: 'userImage',
    dataIndex: 'userImage',
    scopedSlots: { customRender: 'userImage' },
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    // sorter: true,
    scopedSlots: { customRender: 'tags' },
  },
  {
    title: 'createdDate',
    key: 'createdDate',
    dataIndex: 'createdDate',
    // sorter: true,
    scopedSlots: { customRender: 'createdDate' },
  },
  {
    title: 'Image',
    key: 'image',
    dataIndex: 'image',
    scopedSlots: { customRender: 'image' },
  },
  {
    title: 'Comments',
    key: 'commetCount',
    dataIndex: 'commetCount',
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];


export default {
  data() {
    return {
      alllist:[],
      showlist:[],
      columns,
      searchText:"",

      tableCellCount:10,
      
      tagList:[],
    };
  },
  
  components: {
    InspirationTabelItem
  },
  
    created: function () {
        this.initData();
    },
    mounted(){

        // this.getUserTest();
    },
  
    methods: {
        initData:async function(){
          await this.getInspirationTags();
          this.getInspirations();
        },
        getInspirationTags:async function(){
            const res = await api.getInspirationTagInfo();
            if (res != undefined && res.length > 0 && res[0].datalist != undefined){
                // console.log("getInspirationTagInfo=============", res[0].datalist);
                this.tagList = res[0].datalist;
            }
        },
        listFilterCount(_count){
          // console.log(_count);
          this.tableCellCount = _count;
        },
        getInspirations: async function() {
            const res = await api.getInspirations();
            // console.log("=============",res);
            if (res != undefined){
                this.reMakingList(res);
            }
        },
        reMakingList(res){
            var resultList = [];
            for(let i = 0; i < res.length; i++){
                var userName = '';
                if (res[i].userInfo != undefined)
                    userName = res[i].userInfo.firstName + " " + res[i].userInfo.lastName;
                var userImage = '/images/defaultuser.png';
                if (res[i].userInfo != undefined && res[i].userInfo.userImage != undefined)
                    userImage = res[i].userInfo.userImage;
                var detail = res[i].detail;                
                if (detail.length > 20)
                    detail = detail.substring(0, 20) + "...";
                var title = res[i].title;                
                if (title.length > 20)
                    title = title.substring(0, 20) + "...";
                const cellInfo = {
                    title: title,
                    detail: detail,
                    userName: userName,
                    userImage: userImage,
                    tags: res[i].tags,
                    tagNames: this.getTagNameList(res[i].tags),
                    image: res[i].mainImage.length > 0 ? res[i].mainImage[0] : '',
                    commetCount: res[i].commetCount,
                    createdDate: res[i].createdDate,
                    id: res[i]._id,
                };
                resultList.push(cellInfo);
            }
            this.alllist = resultList;
            this.showlist = resultList;
        },
        getTagNameList(_oldList){
          let tagNames = [];
          for (let i = 0; i < _oldList.length; i++) {
            let index = this.tagList.findIndex(obj => obj.key == _oldList[i]);
            if (index != -1){
              tagNames.push(this.tagList[index].name);
            }
          }
          return tagNames;
        },

        onSearch(value) {
          // console.log(value);
          var _List = []
          for (let i = 0; i < this.alllist.length; i++) {
              if (this.alllist[i].title.toLowerCase().includes(this.searchText.toLowerCase()) || this.alllist[i].userName.toLowerCase().includes(this.searchText.toLowerCase()))
                _List.push(this.alllist[i])
          }
          this.showlist = _List;
        },
        
        listUpdate(){
          this.getInspirations();
        },

    }
};
</script>
