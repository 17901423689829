<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;width:100%">
                <div style="width:25%;border:1px solid #aaaaaa">Adult number</div>
                <div style="width:25%;border:1px solid #aaaaaa">Child Number</div>
                <div style="width:25%;border:1px solid #aaaaaa">Chile Age From</div>
                <div style="width:25%;border:1px solid #aaaaaa">Child Age To</div>
                <div style="width:40px">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <div style="margin-top:5px" v-for="item in keysListAdultChildNumber" :key="item">
                <a-row :gutter="0" style="width:100%;">
                    <div style="display:flex;">
                        <a-form-item style="width:25%;margin-top:auto;margin-bottom:auto;" >
                            <a-input-number style="width:100%;padding:2px" :min="0" :max="100000" placeholder="" 
                              @change="changeAdultNumber(item)"
                                v-decorator="[
                                    `${title}AdultNumber[${item}]`,
                                    {
                                        initialValue: arrInfo[item] ? arrInfo[item].adultnumber : undefined,
                                        rules: [{ required: false, message: 'Input Adult Number!' }]
                                    }
                                ]"
                            />
                        </a-form-item>
                        <a-form-item style="width:25%;margin-top:auto;margin-bottom:auto;" >
                            <a-input-number style="width:100%;padding:2px" :min="0" :max="100000" placeholder=""
                                v-decorator="[
                                    `${title}ChildNumber[${item}]`,
                                    {
                                        initialValue: arrInfo[item] ? arrInfo[item].childnumber : undefined,
                                        rules: [{ required: false, message: 'Input Child Number!' }]
                                    }
                                ]"
                            />
                        </a-form-item>
                        <a-form-item style="width:25%;margin-top:auto;margin-bottom:auto;" >
                            <a-input-number style="width:100%;padding:2px" :min="0" :max="100000" placeholder=""
                                v-decorator="[
                                    `${title}ChildAgeFrom[${item}]`,
                                    {
                                        initialValue: arrInfo[item] ? arrInfo[item].childagefrom : undefined,
                                        rules: [{ required: false, message: 'Input Child Age From!' }]
                                    }
                                ]"
                            />
                        </a-form-item>
                        <a-form-item style="width:25%;margin-top:auto;margin-bottom:auto;" >
                            <a-input-number style="width:100%;padding:2px" :min="1" :max="100000" placeholder=""
                                v-decorator="[
                                    `${title}ChildAgeTo[${item}]`,
                                    {
                                        initialValue: arrInfo[item] ? arrInfo[item].childageto : undefined,
                                        rules: [{ required: false, message: 'Input Child Age To!' }]
                                    }
                                ]"
                            />
                        </a-form-item>
                        <div style="margin-top:auto;margin-bottom:auto;width:40px">
                            <a-button type="dashed" icon="minus" @click="removeRowAdultChildNumber(item)" class="minusRowBtn" :size="'small'"></a-button>
                        </div>
                    </div>
                </a-row>
            </div>
            
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowAdultChildNumber" type="primary">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import { Form } from 'ant-design-vue'

export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arrInfo: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  data () {
    return {
      idAdultChildNumber: 0,
      keysListAdultChildNumber: []
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    this.init()
  },
  methods: {
    init () {
      const arr = []
      // console.log("----------------")
      // console.log(this.arrInfo)
      if (this.arrInfo != undefined && this.arrInfo.length > 0) {
        this.idAdultChildNumber = 0;
        for (let i = 0; i < (this.arrInfo).length; i++) {
          arr.push(i)
          this.idAdultChildNumber = this.idAdultChildNumber + 1
        }
      }
      // console.log(arr)
      this.keysListAdultChildNumber = arr
    },
    
    removeRowAdultChildNumber (k) {
      if (this.keysListAdultChildNumber.length === 0) { 
        return
      }
      this.keysListAdultChildNumber = this.keysListAdultChildNumber.filter(item => item !== k)
    },
    addRowAdultChildNumber () {
      this.keysListAdultChildNumber = this.keysListAdultChildNumber.concat(this.idAdultChildNumber)
      this.idAdultChildNumber = this.idAdultChildNumber + 1
      // console.log(this.keysListAdultChildNumber)
    },
    changeAdultNumber(cell){
      // console.log(cell, "----------------");
      
        this.$emit('checkAdultCount', cell);
    }
  }
}
</script>