<template>
    <div style="min-height:85vh">
        <div style="display:flex" v-if="false">
            <button style="width: 100%;" type="button" @click="handleAuthClick();">Google Drive SignIn</button>
            <button style="width: 100%;" type="button" @click="handleSignoutClick();">Google Drive Sign Out</button>
           
        </div>
        <div style="display:flex;margin-top:10px;">
            <a-button v-if="true" style="margin-left:5px" @click="showCreateFile">Add File</a-button>
            
            <a-modal
              title="Create File"
              :visible="visibleCreatFile"
              :confirm-loading="confirmLoading"
              @ok="handleOkFile"
              @cancel="handleCancelFile"
            >
              <input type='file' @change="readURL" ref="file" multiple/>
              <!-- <img id="blah" src="http://placehold.it/180" alt="your image" /> -->
            </a-modal>

            <a-button style="margin-left:5px" @click="showCreateFolder">Add Folder</a-button>
            <a-modal
              title="Create Folder"
              :visible="visible"
              :confirm-loading="confirmLoading"
              @ok="handleOk"
              @cancel="handleCancel"
            >
              <a-input placeholder="Folder Name" v-model="newFolderName" />
            </a-modal>
            
            
            <a-popconfirm title="Are you sure to Delete this Folder?" ok-text="Yes" cancel-text="No" @confirm="deleteSelectedFolder">
              
              <a-button v-if="true" style="margin-left:5px">Delete Folder</a-button>
            </a-popconfirm>
            <a-popconfirm title="Are you sure to Delete this Files?" ok-text="Yes" cancel-text="No" @confirm="deleteSelectedFiles">
              
              <a-button v-if="true" style="margin-left:5px">Delete Files</a-button>
            </a-popconfirm>
            <a-button v-if="ismulti" style="margin-left:5px" @click="clickSelectAll">Select/Unselect All</a-button>
            <!-- <a-switch style="margin-left:5px;margin-top:auto;margin-bottom:auto" checked-children="Move" un-checked-children="Copy" default-checked /> 
            <a-button style="margin-left:5px">Rename</a-button>
            <a-button style="margin-left:5px">Delete</a-button>
            <a-select 
                label-in-value
                :default-value="{ key: 'name' }"
                style="width: 120px;margin-left:5px"
                @change="handleChange"
            >
                <a-select-option value="name">
                    Sort By Name
                </a-select-option>
                <a-select-option value="date">
                    Sort By Date
                </a-select-option>
            </a-select>
            <a-input-search placeholder="Search" style="width: 200px" @search="onSearch" />-->
            
            <a-button style="margin-left:auto;margin-right:5px" v-if="ismodal" @click="selectImages">Select File</a-button>
        </div>
        <div style="display:flex;margin-top:10px;min-height:85vh">
                <!-- draggable -->
            <a-directory-tree style="text-align:-webkit-left;width:25%;border:1px #eeeeee solid" 
                :tree-data="treeData" 
                multiple 
                @dragenter="onDragEnter"
                @drop="onDrop"
                @dragend="dragEnd"
                draggable
                @select="onSelect" 
                @expand="onExpand">
            </a-directory-tree>
            <div style="width:75%;border:1px #eeeeee solid;padding:10px;background-color:white;z-index: 1;">
              <drag-selector v-model="checkedList"
                @change="handleDragSelectorChange" class="drag-selector">
                <a-list :grid="{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 6 }" :data-source="itemImageList">
                    <a-list-item slot="renderItem" slot-scope="item">
                      <drag-selector-item class="drag-selector__item" :value="item" style="pointer-events: none;">
                        <div style="width:100%;border:1px #eeeeee solid;">
                          
                            <div style="display:flex;width:100%;">
                              <div style="height:150px;width:100%;display:flex;margin:auto">
                                <img style="object-fit: cover;width:100%;height:100%;" :src="`${item.id}`" draggable="true" 
                                  v-on:dragend="imageDragEnd(item.id)">
                              </div>
                            </div>
                            <div style="display:flex;height:100px">
                                <a-checkbox style="width:20px;height:20px;margin-left:2px;margin-right:2px;margin-top:2px; pointer-events: all !important;" :defaultChecked="false" :checked="item.check" @change="onSelectImage($event,item.id)">
                                </a-checkbox>
                                <div style="width:calc(100% - 20px)">
                                  <div style="margin-top:2px;word-wrap: break-word">{{item.name}}</div>
                                </div>
                            </div>
                        </div>
                      </drag-selector-item>
                    </a-list-item>
                </a-list>
              </drag-selector>
            </div>
        </div> 

        <!-- <a-tree
            v-model="checkedKeys"
            default-expand-all 
            checkable
            :expanded-keys="expandedKeys"
            :auto-expand-parent="autoExpandParent"
            :selected-keys="selectedKeys"
            :tree-data="treeData"
            @expand="onExpand"
            @select="onSelect"
        /> -->

    </div>
</template>
<script>
  const CLIENT_ID = '101176899724-7ac23fke4oa6el2htmt1mhcighkt12m5.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyD7vkECFmKoIzK7ooBHw3E8LPxWhuJfbuI';
  const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
  // const SCOPES = 'https://www.googleapis.com/auth/drive.file';// metadata.readonly';
  const SCOPES = "https://www.googleapis.com/auth/drive";
  const treeData = [];

  const ParentID = '1-UknDsTGaKel9motVDiMmFzTmcI9AoDv';
  // const ParentID = '19OdJSoUUTyLfWdgaraG95zFAhfkUJjqj';
export default {
    data(){
        return{
            CLIENT_ID,
            API_KEY,
            DISCOVERY_DOCS,
            SCOPES,
            folderList:[],
            driveParentId:'',
            selectedFolderId:'',
            folderIdForAction:'',
            expandedKeys: ['0-0-0', '0-0-1'],
            autoExpandParent: true,
            checkedKeys: ['0-0-0'],
            selectedKeys: [],
            treeData,
            itemImageList:[],

            dataList:[],
            keysObj:{},
            firstParentKey:{},

            
            newFolderName: '',
            visible: false,
            confirmLoading: false,
            

            visibleCreatFile:false,
            fileInfo:null,

            canRun:false,
            allFolderList:[],
            ParentID,

            selectedNames:[],

            dropNewPos:'',

            
            checkedList: [],
        }
    },
    props: {
      ismulti: {
        type: Boolean,
        default: true
      },
      ispdf: {
        type: Boolean,
        default: false
      },
      ismodal: {
        type: Boolean,
        default: true
      },
      rowIndex: {
        type: Number,
        default: 0
      }
    },
    created: function(){
        this.folderList = [];
        this.driveParentId = '';
        this.selectedFolderId = this.ParentID;
        this.folderIdForAction = '';
        this.itemImageList = [];
        
        this.selectedImages = [];
        this.checkedList = [];
        this.dropNewPos = ''
        gapi.load('client:auth2', this.initClient);

        
    },
    mounted: function(){
        // this.itemImageList = [];
    },
    watch: {
        checkedKeys(val) {
            // console.log('onCheck', val);
        },
    },
    methods: {
        
        showCreateFile() {  
          this.visibleCreatFile = true;
          this.fileInfo = null;
          // console.log("this.$refs.file-----  ", this.$refs.file);
          if (this.$refs.file != undefined){
            this.$refs.file.type = ''
            this.$refs.file.type = 'file'
          }
          
        },
        handleOkFile(e) {
          // // console.log(this.newFolderName);
          // this.createFile();
          if (this.fileInfo != null && this.fileInfo != undefined){
            for(let i = 0; i < this.fileInfo.length; i++)
              this.insertFile(this.fileInfo[i], this.fileCreateResult);
            this.confirmLoading = true;
            setTimeout(() => {
              this.visibleCreatFile = false;
              this.confirmLoading = false;
            }, 2000);
          }
          else{
            this.$message.error('Please select Image!');

          }
        },
        handleCancelFile(e) {
          // console.log('Clicked cancel button');
          this.visibleCreatFile = false;
        },
        
        readURL() {
          var Images = this.$refs.file.files;
          // console.log("input-----  ", Images);
          const reader = new FileReader();
          reader.addEventListener('load', (event) => {
            // console.log("event.target.result-----  ", event.target.result);
            // // console.log("event.target.result-----  ", Images[0].currentTarget.currentSrc);
            // this.createFile(Images[0].name, event.target.result, Images[0])
            this.fileInfo = Images;
            // this.insertFile(Images[0]);
          });
          reader.readAsDataURL(Images[0]);
        },
        
        // createFile(_name, _body, _file){
        //   var base64Data = btoa(_body);

        //   var fileMetadata = {
        //     'name': _name,
        //     'mimeType': 'image/jpeg',
        //     'body':base64Data,
        //     // 'file':_body,
            
        //     'parents': [this.selectedFolderId]
        //   };
        //   var media = {
        //     'mimeType': 'image/jpeg',
        //     'body': _body
        //   };
        //   gapi.client.drive.files.create({
        //     'resource': fileMetadata,
        //     // 'media': media,
        //     // 'media':_body,
        //     'fields': 'id'
        //   }).then(this.createFileResult)
        // },
        // createFileResult(response){
        //   // console.log(response.result.id);
        //   // this.listFiles();
        //   // this.allFolderList = [];
        //   // this.listFoldresByParentID(ParentID);
        // },

        insertFile(fileData, callback) {
          const boundary = '-------314159265358979323846';
          const delimiter = "\r\n--" + boundary + "\r\n";
          const close_delim = "\r\n--" + boundary + "--";

          // console.log(this.selectedFolderId);
          let _id = this.selectedFolderId;
          var reader = new FileReader();
          reader.readAsBinaryString(fileData);
          reader.onload = function(e) {
            var contentType = fileData.type || 'application/octet-stream';
          // console.log("====",_id);
            var metadata = {
              'title': fileData.name,
              'mimeType': contentType,
              'writersCanShare':false,
              "parents": [{id:_id}],
              // 'parents': [{
              //   id: this.selectedFolderId,
              //   isRoot: false,
              //   kind: "drive#parentReference",
              //   parentLink: "https://www.googleapis.com/drive/v2/files/0AB3kJFNiPDusUk9PVA",
              //   selfLink: "https://www.googleapis.com/drive/v2/files/1UsA-UqhJqrqn3Xlbl4ciIewUbZHeyUAK/parents/0AB3kJFNiPDusUk9PVA"
              // }]
              // 'parents': [`${this.selectedFolderId}`]
            };

            var base64Data = btoa(reader.result);
            var multipartRequestBody =
                delimiter +
                'Content-Type: application/json\r\n\r\n' +
                JSON.stringify(metadata) +
                delimiter +
                'Content-Type: ' + contentType + '\r\n' +
                'Content-Transfer-Encoding: base64\r\n' +
                '\r\n' +
                base64Data +
                close_delim;

            var request = gapi.client.request({
                'path': '/upload/drive/v2/files',
                'method': 'POST',
                'params': {'uploadType': 'multipart'},
                'headers': {
                  'Content-Type': 'multipart/mixed; boundary="' + boundary + '"'
                },
                'body': multipartRequestBody});
            if (!callback) {
              callback = function(file) {
                // console.log("===+", file)
                
                // this.insertFileIntoFolder(this.selectedFolderId, file.id);
              };
            }
            request.execute(callback);
          }
        },

        fileCreateResult(){
          this.getFileByFolder(this.selectedFolderId);
        },


        //////////////////////////////////////////////////////////////


        onExpand(expandedKeys) {
            // if not set autoExpandParent to false, if children expanded, parent can not collapse.
            // or, you can remove all expanded children keys.
            this.expandedKeys = expandedKeys;
            this.autoExpandParent = false;
        },
        onCheck(checkedKeys) {
            this.checkedKeys = checkedKeys;
        },
        onSelect(selectedKeys, info) {
            const result = this.folderList.filter(item => item.key == selectedKeys[0]);
            if (result.length > 0){
                this.selectedFolderId = result[0].id;
                this.folderIdForAction = result[0].id;
                this.itemImageList = [];
                this.getFileByFolder(result[0].id);
                this.selectedImages = [];
                this.checkedList = [];
            }
            this.selectedKeys = selectedKeys;
        },
        onDragEnter(info) {
          // console.log("onDragEnter",info);
          // expandedKeys 需要受控时设置
          // this.expandedKeys = info.expandedKeys
        },
        dragEnd(info) {
          // console.log("dragEnd",info);
        },

        onDrop(info) {
          // console.log("onDrop",info);
          if (info.dragNode != null){
            const dropKey = info.node.eventKey;
            const dragKey = info.dragNode.eventKey;
            const dropPos = info.node.pos.split('-');
            let oldParentKey = this.getParentKey(dragKey, this.treeData);
            // console.log("---11---",  oldParentKey)
            const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
            
            // console.log(dropPosition)
            const loop = (data, key, callback) => {
              data.forEach((item, index, arr) => {
                if (item.key === key) {
                  return callback(item, index, arr);
                }
                if (item.children) {
                  return loop(item.children, key, callback);
                }
              });
            };
            const data = [...this.treeData];

            // Find dragObject
            let dragObj;
            loop(data, dragKey, (item, index, arr) => {
              arr.splice(index, 1);
              dragObj = item;
            });
            if (!info.dropToGap) {
              // Drop on the content
              loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert 示例添加到尾部，可以是随意位置
                item.children.push(dragObj);
              });
            } else if (
              (info.node.children || []).length > 0 && // Has children
              info.node.expanded && // Is expanded
              dropPosition === 1 // On the bottom gap
            ) {
              loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert 示例添加到尾部，可以是随意位置
                item.children.unshift(dragObj);
              });
            } else {
              let ar;
              let i;
              loop(data, dropKey, (item, index, arr) => {
                ar = arr;
                i = index;
              });
              if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
              } else {
                ar.splice(i + 1, 0, dragObj);
              }
            }
            // console.log(this.treeData, data)
            this.treeData = data;
            this.dropNewPos = '';
            let newParentKey = this.getParentKey(dragKey, this.treeData);
            
            if (this.dropNewPos != ''){
              let newPid= this.folderList.filter(item => item.key == this.dropNewPos);
              let dragId = this.folderList.filter(item => item.key == dragKey);
              this.moveFolder(newPid[0].id, dragId[0].id)
            }
          }
          else{
            this.dropNewPos = this.folderList.filter(item => item.key == info.node.eventKey);
            // this.moveFolder(info.node.eventKey, dragId[0].id)
          }
        },

        imageDragEnd(_id) {
          // console.log("imageDragEnd", _id, " == ", this.dropNewPos[0].id);
          this.moveFolder(this.dropNewPos[0].id, _id, this.moveFolderResult);
        },
        handleDragSelectorChange(checkedList) {
          // console.log("handleDragSelectorChange= ",this.checkedList.length);
          // console.log("handleDragSelectorChange= ",checkedList);
          if (this.checkedList.length <= 1)
            return;
          for (let i = 0; i < this.itemImageList.length; i++){
            this.itemImageList[i].check = false;
          }
          
          if (this.ismulti){
            // if (this.checkedList.length > 1){
              this.selectedImages = [];
              this.selectedNames = [];
            // }

            for (let i = 0; i < this.checkedList.length; i++){
              let objIndex = -1;
              objIndex = this.itemImageList.findIndex((obj => obj.id == this.checkedList[i].id));
              if (objIndex == -1)
                continue;
              this.itemImageList[objIndex].check = true;
              if (this.ismulti){
                if (this.selectedImages.includes(this.checkedList[i].id)){
                  
                }
                else{
                  this.selectedImages.push(this.checkedList[i].id)
                  let tempList = this.itemImageList;
                  // console.log(this.itemImageList)
                  tempList.forEach(object => {
                    if (object.id == this.checkedList[i].id){
                      // // console.log(this.selectedNames)
                      // // console.log(object)
                      this.selectedNames.push(object.name);
                    }
                  });
                }
              }
            }
            
            let _tempList = this.itemImageList;
            this.itemImageList = []
            this.itemImageList = _tempList
            this.checkedList = [];
          }
          else{
            
          }
        },

        getParentKey(key, tree){
          let parentKey = '';
          // // console.log(tree.length)
          for (let i = 0; i < tree.length; i++){
            const node = tree[i];
            parentKey = this.intetorFun(node, key)
            if (parentKey !== '')
              return parentKey;
          }
          
          return parentKey;
        },
        intetorFun(data, key){
          let _parentKey = '';
          if (data.children && data.children.length !== 0){
            data.children.forEach(item => {
              if (item.key === key){
                _parentKey = data.key;
                this.dropNewPos = data.key;
                return _parentKey;
              } else{
                _parentKey = this.intetorFun(item, key);
                if (_parentKey !== '')
                  return _parentKey;
              }
            })
          }
          return _parentKey;
        },

        moveFolder(_pid, _id, callback){
          let fileId = _id
          let folderId = _pid
          // Retrieve the existing parents to remove
          gapi.client.drive.files.get({
            fileId: fileId,
            fields: 'parents'
          }).then(function (response){
            // console.log(fileId,folderId,response.result.parents[0])
            gapi.client.drive.files.update({
                'fileId': fileId,
                'addParents': folderId,
                'removeParents': response.result.parents.join(','),
                'fields': 'id, parents'
              }
            ).then(callback ? callback: function(response){
              // console.log("res==",response)
            })
          });
          // , function (err, file) {
          //   if (err) {
          //     // Handle error
          //     console.error(err);
          //   } else {
          //     // Move the file to the new folder
          //     var previousParents = file.parents.join(',');
          //     drive.files.update({
          //       fileId: fileId,
          //       addParents: folderId,
          //       removeParents: previousParents,
          //       fields: 'id, parents'
          //     }, function (err, file) {
          //       if (err) {
          //         // Handle error
          //       } else {
          //         // File moved.
          //       }
          //     });
          //   }
          // });
        },
        moveFolderResult(response){
          // console.log(response.result)
          
          this.getFileByFolder(this.selectedFolderId);
        },



      initClient() {
        gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES
        }).then(this.initResult, function(error) {
          // console.log("error")
          // console.log(error)
          // appendPre(JSON.stringify(error, null, 2));
        });
      },
      initResult:async function(){
        // console.log("success",gapi.auth2.getAuthInstance().isSignedIn.get())
        
        // gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
        gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
        this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());

        if (!gapi.auth2.getAuthInstance().isSignedIn.get())
          gapi.auth2.getAuthInstance().signIn();
      },
      updateSigninStatus(isSignedIn){
          if (isSignedIn) {
            // console.log("isSignedIn")
            // this.getParentId();
            this.canRun = false;
            this.allFolderList = [];
            this.folderList = [];
            this.getFileByFolder(this.selectedFolderId);
            this.listFoldresByParentID(ParentID);
          } else {
            // console.log("isSignedIn not")
          }
      },
      handleAuthClick(event) {
        // console.log("handleAuthClick")
        gapi.auth2.getAuthInstance().signIn();
      },
      handleSignoutClick(event) {
        gapi.auth2.getAuthInstance().signOut();
      },
      getParentId(){
        gapi.client.drive.files.list({
          'pageSize': 1000,
          'fields': "nextPageToken, files(id, name, parents)",
          'q': "'root' in parents and mimeType='application/vnd.google-apps.folder'",// 
          // q: "mimeType='application/vnd.google-apps.folder'",
          'spaces': 'drive',
        }).then(this.getParentIdResult);
      },
      getParentIdResult(response){
        var files = response.result.files;
        if (files.length > 0){
            this.driveParentId = files[0].parents[0];
        }
        this.selectedFolderId = this.driveParentId;
        this.listFiles()
      },
      
      listFoldresByParentID:async function(_id) {
        gapi.client.drive.files.list({
          'pageSize': 1000,
          'fields': "nextPageToken, files(id, name, parents)",
          'q': `'${_id}' in parents and trashed=false and mimeType='application/vnd.google-apps.folder'`,//'root' in parents and 
          // q: "mimeType='application/vnd.google-apps.folder'",   
          'spaces': 'drive',
        }).then(this.listFileResultTest);
      },
      listFileResultTest:async function(response){
              // // console.log(response)
        var files = response.result.files;
          if (files && files.length > 0) {
              // // console.log(files)
            for (var i = 0; i < files.length; i++) {
              var file = files[i];
              this.allFolderList.push(file)
              this.listFoldresByParentID(file.id)

              // if (file.parents.length > 0 && file.parents[0] == '1-UknDsTGaKel9motVDiMmFzTmcI9AoDv')
              //   this.canRun = true;
            }
            // if (this.canRun)
            // console.log("files:",files)
              this.treeData = this.arrayToTree(this.allFolderList, ParentID);
            // console.log("treeData", this.treeData)

          } else {
              // console.log("Empty Files!")
          }
      },



      listFiles() {
        gapi.client.drive.files.list({
          'pageSize': 1000,
          'fields': "nextPageToken, files(id, name, parents)",
          'q': "mimeType='application/vnd.google-apps.folder'",//'root' in parents and 
          // q: "mimeType='application/vnd.google-apps.folder'",
          'spaces': 'drive',
        }).then(this.listFileResult);
      },
      
      listFileResult(response){
        var files = response.result.files;
          if (files && files.length > 0) {
            // for (var i = 0; i < files.length; i++) {
            //   var file = files[i];
            //   // console.log(file)
            // }
            this.treeData = this.arrayToTree(files, this.driveParentId);

          } else {
              // console.log("Empty Files!")
          }
      },



      arrayToTree(list, pid = 0, key = '0') {
        var itemList = []
        for (var i = 0; i < list.length; i++){
          if (list[i].parents != undefined && list[i].parents.length > 0){
            if (list[i].parents[0] == pid){
              var newkey = key + '-' + itemList.length
              var keyInfo = {
                  key:newkey,
                  id:list[i].id
              }
              
              this.folderList.push(keyInfo);
              var childItem = {
                title: list[i].name,
                key: newkey,
                id:list[i].id,
                children: this.arrayToTree(list, list[i].id, newkey),
              }
              itemList.push(childItem);
            }
          }
        }
        return itemList;
      },

      getFileByFolder(_id){
        gapi.client.drive.files.list({
          'pageSize': 1000,
          'fields': "nextPageToken, files(id, name, parents)",
          'q': `'${_id}' in parents and trashed=false and mimeType!='application/vnd.google-apps.folder'`,
          // q: "mimeType='application/vnd.google-apps.folder'",
          'spaces': 'drive',
        }).then(this.getFileByFolderResult);
      },
      getFileByFolderResult(response){
        var files = response.result.files;
        // console.log(files); 
        this.itemImageList = files
        this.itemImageList.forEach(object => {
          object.check = false;
          
        });
      },

      handleChange(value) {
        // console.log(value); 
      },
      onSearch(value) {
        // console.log(value);
      },
      onSelectImage(e, id) {
        // console.log(`onSelectImage checked = ${e.target.checked}`, id);
        let objIndex = this.itemImageList.findIndex((obj => obj.id == id));
        this.itemImageList[objIndex].check = e.target.checked;
        let tempList = this.itemImageList;
        this.itemImageList = []
        this.itemImageList = tempList
        if (this.ismulti){
          if (!e.target.checked){
        // console.log(this.checkedList.length); 
              const index = this.checkedList.findIndex((obj => obj.id == id));
              if (index > -1) {
                this.checkedList.splice(index, 1); // 2nd parameter means remove one item only
              }
        // console.log(this.checkedList.length); 
            }

          if (this.selectedImages.includes(id)){
            if (!e.target.checked){
              const index = this.selectedImages.indexOf(id);
              if (index > -1) {
                this.selectedImages.splice(index, 1); // 2nd parameter means remove one item only
              }
            }
          }
          else{
            if (e.target.checked){
              this.selectedImages.push(id)
            let tempList = this.itemImageList;
            tempList.forEach(object => {
              if (object.id == id){
                this.selectedNames.push(object.name);
              }
            });
            }

          }
        }else{
          if (e.target.checked){

            let tempList = this.itemImageList;
            tempList.forEach(object => {
              if (object.id == id){
                object.check = true;
                this.selectedNames = [object.name]
              }
              else{
                object.check = false;
              }
                object.name = object.name;
                object.id = object.id;
            });
            this.itemImageList = []
            this.itemImageList = tempList
        // console.log("=====",this.itemImageList)
            this.selectedImages = [id]
          }
        }
        // console.log(this.selectedImages)
      },

      
      selectImages(){
        // console.log("------------------------")
        // console.log(this.selectedImages)

        this.itemImageList = [];
        if (this.ispdf)
          this.$emit('selectwithname', this.selectedImages, this.selectedNames);
        else
          this.$emit('selectImages', this.selectedImages, this.rowIndex);
        this.$emit('close');
      },

      
      showCreateFolder() {
        // console.log(this.selectedFolderId)
        this.visible = true;
      },
      handleOk(e) {
        // console.log(this.newFolderName);
        this.createFolder(this.newFolderName);
        this.confirmLoading = true;
        setTimeout(() => {
          this.visible = false;
          this.confirmLoading = false;
        }, 2000);
      },
      handleCancel(e) {
        // console.log('Clicked cancel button');
        this.visible = false;
      },
      createFolder(_name){
        var fileMetadata = {
          'name': _name,
          'mimeType': 'application/vnd.google-apps.folder',
          'parents': [this.selectedFolderId]
        };
        gapi.client.drive.files.create({
          'resource': fileMetadata,
          'fields': 'id'
        }).then(this.createFolderResult)
      },
      createFolderResult(response){
        // console.log(response.result.id);
        // this.listFiles();
        this.allFolderList = [];
        this.folderList = [];
        this.listFoldresByParentID(ParentID);
      },

      deleteSelectedFolder(){
        if (this.folderIdForAction != ''){
          this.deleteFile(this.folderIdForAction, this.deleteFolderResult)
          this.folderIdForAction = '';
        }
      },
      deleteFile(fileId, callback) {
        // this.insertPermission(fileId, "alyosha1990828@gmail.com", "user", "owner");
            // console.log("==fileId=+", fileId)
        var request = gapi.client.drive.files.delete({
          'supportsAllDrives':true,
          'includeItemsFromAllDrives':true,
          'fileId': fileId
        });
        if (!callback){
          callback = function(resp) {
            // console.log("===+", resp)
          };
        }
        request.execute(callback);
      },
      insertPermission(fileId, value, type, role) {
            // console.log("+====fileId===+", fileId)
        var body = {
          'emailAddress': value,
          'type': type,
          'role': role,
          'domain': "true"
        };
        var request = gapi.client.drive.permissions.create({
          'fileId': fileId,
          'resource': body
        });
        request.execute(function(resp) { 
            // console.log("+=====++===+", resp)
        });
      },
      
      deleteFolderResult(resp) {
        // console.log(resp);
        this.allFolderList = [];
        this.folderList = [];
        this.listFoldresByParentID(ParentID);
      },
      
      deleteSelectedFiles(){
        // console.log(this.selectedImages)
        for(let i = 0; i < this.selectedImages.length; i++){
          this.deleteFile(this.selectedImages[i], this.deleteFileResult)
        }
      },
      deleteFileResult(resp) {
        // console.log(resp);
        this.getFileByFolder(this.selectedFolderId);
      },

      clickSelectAll(){
        let _tempList = this.itemImageList;
        if(this.selectedImages.length > 0){
          this.selectedImages = [];
          _tempList.forEach(object => {
            object.check = false;
          });
        }
        else{
          this.selectedImages = [];
          _tempList.forEach(object => {
            object.check = true;
            this.selectedImages.push(object.id)
            
            let tempList = this.itemImageList;
            // console.log(this.itemImageList)
            tempList.forEach(obj => {
              if (obj.id == object.id){
                // console.log(this.selectedNames)
                // console.log(obj)
                this.selectedNames.push(obj.name);
              }
            });
          });

        }
        this.itemImageList = [];
        this.itemImageList = _tempList;
      },
    },
}
</script>
<style>
.drag-selector-item-wrapper{
    /* pointer-events: none; */
}
</style>