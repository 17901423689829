<template>
    <div class="addgallary">
        <div>
            <div style="width:100%;display:flex;top:0px;">
                <GDriveSelector :ismulti="ismulti" @selectImages="selectImages" />
            </div>
        </div>
    </div>
</template>

<script>
// import { api } from '../helpers/Helpers';

import GDriveSelector from "./GDriveSelector";
export default {
    data() {
        return {
            disabled: false,
        };
    },
    
  props: {
    ismulti: {
      type: Boolean
    }
  },
    components: {
        GDriveSelector
    },
    methods: {
        handleLoginClick: async function() {

            this.$emit('close');
        },
        selectImages(_images){
            this.$emit('selectImages', _images);

        }
    },
    mounted(){
        
    },
    created: function () {
    }
}
</script>