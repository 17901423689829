<template>
    <div style="min-height:85vh">
        <div style="margin: 16px 0;display:flex">
            <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                <a-breadcrumb-item style="font-size:30px;">About Customize</a-breadcrumb-item>
                <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
            </a-breadcrumb>
        </div>
        <div style="margin-left:10px;margin-top:10px;display: flex;">
            <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">About Sub Title</div>
            <div style="display:flex;width: 100%;">
                <a-input placeholder="Enter Sub Title" v-model="aboutSubTitle" />
            </div>
        </div>
        <div style="margin-left:10px;margin-top:10px;display: flex;">
            <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">About Main Title</div>
            <div style="display:flex;width: 100%;">
                <a-input placeholder="Enter Main Title" v-model="aboutMainTitle" />
            </div>
        </div>
        <a-divider/>
        <a-form style="display:flex;width:100%;" :form="dashboardAboutPageForm">
            <div style="width:100%">
                <a-table
                :columns="columnsAboutPage"
                :data-source="aboutPageInfoList"
                bordered
                size="middle"
                :pagination="false"
                :scroll="{ x: 0, y: 0 }"
            >
                    <span slot="mainname" slot-scope="text, record, index">
                        <a-form-item style="margin-bottom: 0px;">                                    
                            <a-textarea placeholder="Enter mainname" :auto-size="{ minRows: 5, maxRows: 6 }" 
                                v-decorator="[
                                        `${TABLEABOUTPAGE}mainname[${index}]`,
                                        {
                                            initialValue: text ? text : undefined,
                                            rules: [{ required: false, message: 'Input mainname !' }]
                                        }
                                    ]"
                                />
                        </a-form-item>
                    </span>
                    <span slot="subname" slot-scope="text, record, index">
                        <a-form-item style="margin-bottom: 0px;">                                                                                  
                            <a-textarea placeholder="Enter subname" :auto-size="{ minRows: 5, maxRows: 6 }" 
                                v-decorator="[
                                        `${TABLEABOUTPAGE}subname[${index}]`,
                                        {
                                            initialValue: text ? text : undefined,
                                            rules: [{ required: false, message: 'Input subname !' }]
                                        }
                                    ]"
                                />
                        </a-form-item>
                    </span>
                    <span slot="description" slot-scope="text, record, index">
                        <a-form-item style="margin-bottom: 0px;">                                        
                            <a-textarea placeholder="Enter description" :auto-size="{ minRows: 5, maxRows: 20 }" 
                                v-decorator="[
                                        `${TABLEABOUTPAGE}description[${index}]`,
                                        {
                                            initialValue: text ? text : undefined,
                                            rules: [{ required: false, message: 'Input description !' }]
                                        }
                                    ]"
                                />
                        </a-form-item>
                    </span>
                    
                    <span slot="operation" slot-scope="text, record">
                        <a @click="DeleteAboutPageOne(record.key)">Delete</a>
                    </span>
                </a-table>
                <div style="display:flex;margin-top:10px">
                    <a-button class="editable-add-btn" @click="addAboutPageData">
                        Add Item
                    </a-button> 
                </div>
            </div>
        </a-form>
        <a-divider/>
        <div style="margin-left:10px;margin-top:10px;display: flex;">
            <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">About Footer Text</div>
            <div style="display:flex;width: 100%;">
                <a-textarea v-model="aboutFooterText1" placeholder="Enter Footer Text" :auto-size="{ minRows: 6, maxRows: 20 }" />
            </div>
        </div>
        <!-- <div style="margin-left:10px;margin-top:10px;display: flex;">
            <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">About Footer Text2</div>
            <div style="display:flex;width: 100%;">
                <a-textarea v-model="aboutFooterText2" placeholder="Enter Footer Text" :auto-size="{ minRows: 6, maxRows: 20 }" />
            </div>
        </div> -->
        <div style="margin-top:10px">
            <a-button class="editable-add-btn" style="margin-left:10px" @click="saveAboutPageData" type="primary">
                Save About Page
            </a-button>
        </div>
    </div>
</template>
<script>
import { api } from '../helpers/Helpers';

const TABLEABOUTPAGE = 'tableAboutPage'
const columnsAboutPage = [
    {
        title: 'Main Name',
        dataIndex: 'mainname',
        key: 'mainname',
        width: 200,
        scopedSlots: { customRender: 'mainname' },
    },
    {
        title: 'Sub Name',
        dataIndex: 'subname',
        key: 'subname',
        width: 200,
        scopedSlots: { customRender: 'subname' },
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 200,
        scopedSlots: { customRender: 'description' },
    },
    {
        title: 'operation',
        dataIndex: 'operation',
        key: 'operation',
        width: 100,
        scopedSlots: { customRender: 'operation' },
    },
];
export default {
    
  data() {
    return {
        aboutSubTitle:'',
        aboutMainTitle:'',
        aboutFooterText1:'',
        aboutFooterText2:'',
        columnsAboutPage,
        TABLEABOUTPAGE,
        dashboardAboutPageForm: this.$form.createForm(this),
        aboutPageInfoList:[],
    }
  },
  created(){
    this.getAboutPageInfos();
  },
  methods:{
    
    getAboutPageInfos:async function(){
        const res = await api.getAboutPageInfo();
        // console.log(res);
        if (res.length > 0){
            this.aboutSubTitle = res[0].aboutSubTitle;
            this.aboutMainTitle = res[0].aboutMainTitle;
            this.aboutFooterText1 = res[0].aboutFooterText1;
            this.aboutFooterText2 = res[0].aboutFooterText2;
            this.aboutPageInfoList = res[0].datalist;
        }
    },
    
    addAboutPageData(){
        const newData = {
            key: this.aboutPageInfoList.length,
            mainname:'',
            subname:'',
            description:'',
        };
        this.aboutPageInfoList.push(newData);
    },
    DeleteAboutPageOne(_key){
        let objIndex = this.aboutPageInfoList.findIndex((obj => obj.key == _key));
        this.aboutPageInfoList.splice(objIndex, 1);
        this.updateAboutPageList();
    },
    updateAboutPageList(){
        for(let i = 0; i < this.aboutPageInfoList.length; i++){
            this.aboutPageInfoList[i].key = i;
        }
    },
    
    saveAboutPageData(){
        const { dashboardAboutPageForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${TABLEABOUTPAGE}mainname`] != undefined){
                    (values[`${TABLEABOUTPAGE}mainname`]).forEach((item, index) => {
                        // console.log(item)
                        // console.log(index)
                        const obj = {
                            mainname: item,
                            subname: values[`${TABLEABOUTPAGE}subname`][index],
                            description:values[`${TABLEABOUTPAGE}description`][index],
                            key: index,
                        }
                        partOneArr.push(obj)
                    })
                }
                // console.log(partOneArr)
                const lastInfo = {
                    aboutSubTitle:this.aboutSubTitle,
                    aboutMainTitle:this.aboutMainTitle,
                    datalist: partOneArr,
                    aboutFooterText1:this.aboutFooterText1,
                    aboutFooterText2:this.aboutFooterText2,
                }
                this.sendAboutPageData(lastInfo)
                // this.arrAdultChildNumber = partOneArr;
            }
        });
    },
    sendAboutPageData:async function(_array){
        const res = await api.setAboutPageInfo(_array);
        // console.log(res)
    },
  }
}
</script>