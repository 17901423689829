<template>
    <div class="adduser" style="min-height:85vh">
        <div>
            <div style="margin: 16px 0;display:flex">
                <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                    <a-breadcrumb-item style="font-size:30px;">Edit Hotel</a-breadcrumb-item>
                    <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
                </a-breadcrumb>
            </div>
            <a-input placeholder="Enter Hotel Name" v-model="hotelname" :size="'large'" />
            <!-- <a-textarea style="margin-top:10px"
                placeholder="Controlled autosize"
                :auto-size="{ minRows: 10, maxRows: 15 }"
            /> -->
            <!-- <vue2-tinymce-editor style="margin-top:10px" v-model="hoteltext" :options="options"></vue2-tinymce-editor> -->
            <div v-if="false">
                <vue2-tinymce-editor id="tinymce1" style="margin-top:10px" v-model="hoteltext" :options="options"></vue2-tinymce-editor>
            </div>
            
            <div style="font-size:18px;text-align:left;margin-top:20px;">AWARDS & RECOGNITION</div>
            <div>
                <vue2-tinymce-editor :key="updateTinymce2" id="tinymce2" style="margin-top:10px" v-model="hotelAwardText" :options="options"></vue2-tinymce-editor>
            </div>

            <div style="font-size:18px;text-align:left;margin-top:20px;">OVERVIEW</div>
            <div>
                <vue2-tinymce-editor :key="updateTinymce3" id="tinymce3" style="margin-top:10px" v-model="hotelOverview" :options="options"></vue2-tinymce-editor>
            </div>

            <div style="font-size:18px;text-align:left;margin-top:20px;">RESORT AMENITIES</div>
            <div>
                <vue2-tinymce-editor :key="updateTinymce4" id="tinymce4" style="margin-top:10px" v-model="hotelAmenities" :options="options"></vue2-tinymce-editor>
            </div>

            <div style="font-size:18px;text-align:left;margin-top:20px;">VUE TIP</div>
            <div>
                <vue2-tinymce-editor :key="updateTinymce5" id="tinymce5" style="margin-top:10px" v-model="hotelVueTip" :options="options1"></vue2-tinymce-editor>
            </div>

            <div style="font-size:18px;text-align:left;margin-top:20px;">CHILD POLICY</div>
            <div>
                <vue2-tinymce-editor :key="updateTinymce6" id="tinymce6" style="margin-top:10px" v-model="hotelChildPolicy" :options="options"></vue2-tinymce-editor>
            </div>


            <a-collapse style="margin-top:10px" accordion default-active-key="1">
                <a-collapse-panel key="1" header="Hotel Promotion">
                    <div>
                        <a-form style="display:flex" :form="hotelPromotionForm">
                            <hotel-promotion-list :key="reRenderKey" ref="_sublist" @travellist="getTravelList" @bookingllist="getBookingList" @blacklist="getBlackList" @getKeyList="getKeyList" @discountlist="getDiscountList"
                                :title="`${HOTELPROMOTIONPART}`"
                                :arr="arrHotelPromotion"
                                :roomList="roomList"
                            />
                        </a-form>
                    </div>
                </a-collapse-panel>
                <a-collapse-panel key="2" header="Gallery">
                    <a-button @click="showGallary(true)">Add room gallary images</a-button>
                    <a-modal
						v-model="visibleGallary"
						title="Add Gallay"
						:footer="null"
                        width="80%"
					>
                        <AwsFileManager v-if="visibleGallary" :ismulti="true" @close="showGallary(false)" @selectImages="selectImages"/>
						<!-- <AddGallary :ismulti="true" @close="showGallary(false)" @selectImages="selectImages"/> -->
					</a-modal>
                    <a-list style="margin-top:10px" 
                                                    :grid="{ gutter: 24, xs: 3, sm: 4, md: 6, lg: 8, xl: 8, xxl: 12 }" :data-source="gallaryImages">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <div style="border: 1px solid #E7E7E7;position:relative">
                                <div style="width:100%;height:8vh">
                                    <img style="object-fit: cover;width:100%;height:100%" :src="`${item}`">
                                </div>
                                <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelImage(item)" :size="'small'"></a-button>
                            </div>
                        </a-list-item>
                    </a-list>

                </a-collapse-panel>
                <a-collapse-panel key="3" header="ADD-ON'S">
                    
                    <a-tabs type="card" @change="callback">
                        <a-tab-pane key="1" tab="Transfer">
                            <div style="width:200px;margin-left:5px;margin-bottom:10px;text-align:left;font-weight:600;font-size: 15px;">Transfer Option</div>
                            <div style="width:100%;padding:5px">
                                <a-form style="display:flex" :form="hotelTransferForm">
                                    <hotel-transfer-list :key="reRenderKey" ref="_transferlist" @getimage="getTransferImageResult" @getKeyTransferList="getKeyTransferList"
                                        :title="`${HOTELTRANSFERPART}`"
                                        :arr="arrHotelTransfer"
                                    />
                                </a-form>
                                
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="Meal Plan">
                            <div style="width:200px;margin-left:5px;margin-bottom:10px;text-align:left;font-weight:600;font-size: 15px;">Meal Option</div>
                            <div style="width:100%;padding:5px">
                                <a-form style="display:flex" :form="hotelMealplanForm">
                                    <hotel-mealplan-list :key="reRenderKey" ref="_meallist" @mealplanlist="getMealPriceList" @getimage="getMealPriceImage" @getKeyMealList="getKeyMealList"
                                        :title="`${HOTELMEALPLANPART}`"
                                        :arr="arrHotelMealplan"
                                    />
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="Festive">
                            <div style="width:200px;margin-left:5px;margin-bottom:10px;text-align:left;font-weight:600;font-size: 15px;">Festive Option</div>
                            <div style="width:100%;padding:5px">
                                <a-form style="display:flex" :form="hotelFestiveForm">
                                    <hotel-festive-list :key="reRenderKey" ref="_festivelist" @festivelist="getFestiveList" @getimage="getFestiveImage" @getKeyFestiveList="getKeyFestiveList"
                                        :title="`${HOTELFESTIVEPART}`"
                                        :arr="arrHotelFestive"
                                    />
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="4" tab="Honeymoon Amenities">
                            
                            <div style="width:200px;margin-left:5px;margin-bottom:10px;text-align:left;font-weight:600;font-size: 15px;">Honey Option</div>
                            <div style="width:100%;padding:5px">
                                <a-form style="display:flex" :form="hotelHoneyForm">
                                    <hotel-honey-list :key="reRenderKey" ref="_honeylist" @getimage="getHoneyImage" @getKeyHoneyList="getKeyHoneyList"
                                        :title="`${HOTELHONEYPART}`"
                                        :arr="arrHotelHoney"
                                    />
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="5" tab="Extra Person">
                            
                            <div style="width:200px;margin-left:5px;margin-bottom:10px;text-align:left;font-weight:600;font-size: 15px;">Extra Option</div>
                            <div style="width:100%;padding:5px">
                                
                                <a-form style="display:flex" :form="hotelExtraForm">
                                    <hotel-extra-list :key="reRenderKey" ref="_extralist" @extralist="getExtraList" @getKeyExtraList="getKeyExtraList"
                                        :title="`${HOTELEXTRAPART}`"
                                        :arr="arrHotelExtra"
                                    />
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="6" tab="Quote Infomation">
                            <div style="display:flex;">
                                <div style="width:200px;margin-left:20px;text-align:left;">Quote Images</div>
                                <div style="border:1px solid #aaa;width:100%;display:flex">
                                    <div style="width:50%">
                                        <div style="text-align:left;margin-top:5px;margin-left:5px">Image1</div>
                                        <a-divider/>
                                        <div style="text-align:left;margin-bottom:5px;margin-left:5px">
                                            <div v-if="!isSelected[0]" style="display:flex">
                                                <div>No image selected</div>
                                                <a-button @click="showImage(true, 0)" :size="'small'">Add Image</a-button>
                                            </div>
                                            <div v-if="isSelected[0]" style="display:flex">
                                                <div style="position:relative;margin:auto">
                                                    <div style="width:200px;height:150px">
                                                        <img class="quotoImage" :src="`${selectedImage[0]}`" alt="" width="100%" height="100px">
                                                    </div>
                                                    <a-icon @click="cancelQuoteImage(0)" style="position:absolute;top:5px;right:5px" type="close-circle" theme="filled" />
                                                    <a-icon @click="showImage(true, 0)" style="position:absolute;top:5px;right:25px" type="edit" theme="filled" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="height:100%;width:1px;background-color:#aaa"></div>
                                    <div style="width:50%">
                                        <div style="text-align:left;margin-top:5px;margin-left:5px">Image2</div>
                                        <a-divider/>
                                        <div style="text-align:left;margin-bottom:5px;margin-left:5px">
                                            <div v-if="!isSelected[1]" style="display:flex">
                                                <div>No image selected</div>
                                                <a-button @click="showImage(true, 1)" :size="'small'">Add Image</a-button>
                                            </div>
                                            <div v-if="isSelected[1]" style="display:flex">
                                                <div style="position:relative;margin:auto">
                                                    <div style="width:200px;height:150px">
                                                        <img class="quotoImage" :src="`${selectedImage[1]}`" alt="" width="100%" height="100px">
                                                    </div>
                                                    <a-icon @click="cancelQuoteImage(1)" style="position:absolute;top:5px;right:5px" type="close-circle" theme="filled" />
                                                    <a-icon @click="showImage(true, 1)" style="position:absolute;top:5px;right:25px" type="edit" theme="filled" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <a-modal
                                        v-model="visibleImage"
                                        title="Add Image"
                                        :footer="null"
                                        width="80%"
                                    >
                                        <AwsFileManager v-if="visibleImage" :ismulti="false" :rowIndex="selitem" @close="showImage(false)" @selectImages="selectImage"/>
                                    </a-modal>

                                </div>
                            </div>
                            <a-divider/>
                            <div style="display:flex;">
                                <div style="width:200px;margin-left:20px;text-align:left;">SERVICES & AMENITIES</div>
                                <a-textarea v-model="services" placeholder="" :auto-size="{ minRows: 6, maxRows: 8 }" />
                            </div>
                            <a-divider/>
                            <div style="display:flex;">
                                <div style="width:200px;margin-left:20px;text-align:left;">IMPORTANT NOTE</div>
                                <a-textarea v-model="importantnote" placeholder="" :auto-size="{ minRows: 6, maxRows: 8 }" />
                            </div>
                            <a-divider/>
                            <div style="display:flex;">
                                <div style="width:200px;margin-left:20px;text-align:left;">Hotel Phone Number</div>
                                <a-input v-model="hotelphonenumber" style="width:100%;padding:2px" placeholder="" />
                            </div>
                            <a-divider />
                            <div style="display:flex;">
                                <div style="width:200px;margin-left:20px;text-align:left;">Hotel Email Address</div>
                                <a-input v-model="hotelemailaddress" style="width:100%;padding:2px" placeholder="" />
                            </div>
                            <a-divider/>
                            
                        </a-tab-pane>
                    </a-tabs>
                </a-collapse-panel>
                <a-collapse-panel key="6" header="Hotel Data">
                    
                    <a-tabs type="card" tabPosition="left">
                        <a-tab-pane key="1" tab="General">
                            <div>
                                
                                <div style="display:flex;margin-top:20px;">                              
                                    <a-checkbox v-model="hotelHidden" style="width:200px;margin-left:20px;text-align:left;">
                                        Hotel Hidden
                                    </a-checkbox>
                                </div>
                                <a-divider/>
                                <div style="display:flex;" v-if="false">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Regular price</div>
                                    <a-input-number  style="width:100%;padding:2px" v-model="reqularprice" placeholder="" />
                                </div>
                                <a-divider v-if="false"/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Published rate</div>
                                    <a-input-number  style="width:100%;padding:2px" v-model="publishedrate" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Green tax</div>
                                    <a-input-number  style="width:100%;padding:2px" v-model="greentax" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Service charges</div>
                                    <a-input-number  style="width:100%;padding:2px" v-model="servicecharges" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">GST</div>
                                    <a-input-number  style="width:100%;padding:2px" v-model="gst" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Commission</div>
                                    <a-input-number  style="width:100%;padding:2px" v-model="commission" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Calculation Formula *</div>
                                    <a-select v-model="calculationfomula" style="width:100%;padding:2px" placeholder="Select Fomula" >
                                        <a-select-option v-for="item in formulaList" :key="item.key" :value="item.key">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Confirmation NO</div>
                                    <a-input  style="width:100%;padding:2px" v-model="confirmationNo" placeholder="" />
                                </div>
                                <a-divider/>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="Rooms">
                            <div>
                                <div>
                                    <div style="text-align:left">Resort style?</div>
                                    <div v-for="item, iStyle in styleList" :key="iStyle" style="display:flex">
                                        <a-checkbox v-model="stypeBoolList[iStyle]">{{item}}</a-checkbox><br>
                                    </div>
                                </div>
                                <a-divider />
                                <div>
                                    <div style="text-align:left">Type of travel?</div>
                                    <div v-for="item, index in typeofList" :key="index" style="display:flex">
                                        <a-checkbox v-model="typeofBoolList[index]">{{item}}</a-checkbox><br>
                                    </div>
                                </div>
                                <a-divider />
                                <div style="text-align:left">Number of room</div>
                                <a-input-number v-model="numberofroom" style="width:100%" :min="1" :max="100000" placeholder="" :size="'small'"/>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="5" tab="Hotel Image">
                            <div style="display:flex">
                                <a-button @click="showHotelImage(true)" style="margin-top:auto;margin-bottom:auto;margin-left:20px">
                                    Add Hotel Image
                                </a-button>
                                <a-modal
                                v-model="visibleHotelImage"
                                    title="Add Hotel Image"
                                    :footer="null"
                                    width="80%"
                                >
                                    <AwsFileManager v-if="visibleHotelImage" :ismulti="false" @close="showHotelImage(false)" @selectImages="selectHotelImage"/>
                                </a-modal>
                                <a-list style="margin-top:10px;width:150px;margin-left:auto;margin-right:auto" :grid="{ gutter: 16, column: 1 }" :data-source="hotelImage">
                                    <a-list-item slot="renderItem" slot-scope="item">
                                        <div style="border: 1px solid #E7E7E7;position:relative">
                                            <img :src="`${item}`" alt="" width="150px" height="100px">
                                            <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelHotelImage(item)" :size="'small'"></a-button>
                                        </div>
                                    </a-list-item>
                                </a-list>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <a-button @click="showHotelLogo(true)" style="margin-top:auto;margin-bottom:auto;margin-left:20px">
                                    Add Hotel Logo
                                </a-button>
                                <a-modal
                                v-model="visibleHotelLogo"
                                    title="Add Hotel Logo"
                                    :footer="null"
                                    width="80%"
                                >
                                    <AwsFileManager v-if="visibleHotelLogo" :ismulti="false" @close="showHotelLogo(false)" @selectImages="selectHotelLogo"/>
                                    <!-- <AddGallary :ismulti="false" @close="showRoomImage(false)" @selectImages="selectRoomImage"/> -->
                                </a-modal>
                                <a-list style="margin-top:10px;width:150px;margin-left:auto;margin-right:auto" :grid="{ gutter: 16, column: 1 }" :data-source="hotelLogo">
                                    <a-list-item slot="renderItem" slot-scope="item">
                                        <div style="border: 1px solid #E7E7E7;position:relative">
                                            <img :src="`${item}`" alt="" width="150px" height="100px">
                                            <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelHotelLogo(item)" :size="'small'"></a-button>
                                        </div>
                                    </a-list-item>
                                </a-list>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <a-button @click="showFactsheet(true)" style="margin-top:auto;margin-bottom:auto;margin-left:20px">
                                    Add Fact sheet
                                </a-button>
                                <div style="margin-left:auto;margin-right: auto;">
                                    
                                    <div style="border: 1px solid #E7E7E7;position:relative">
                                        <img style="object-fit: cover;height:100%;" :src="facesheetPdf.id.substring(facesheetPdf.id.length - 4).toLowerCase() == '.pdf' ? '/images/pdfimage.png' : `${facesheetPdf.id}`" alt="" width="150px" height="100px">
                                        
                                        <!-- <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelHotelLogo(item)" :size="'small'"></a-button> -->
                                        <a-button v-if="facesheetPdf != undefined && facesheetPdf.id != ''" style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelFactsheet()" :size="'small'"></a-button>
                                    </div>
                                    <div style="height: 50px;">{{facesheetPdf != undefined ? facesheetPdf.name :''}}</div>
                                    <!-- <img :src="`${facesheetPdf.id}`" alt="" width="150px" height="100px"> -->
                                    
                                </div>
                                
                                <a-modal
                                    v-model="visibleHotelFactsheet"
                                    title="Add Fact sheet pdf"
                                    :footer="null"
                                    width="80%"
                                >
                                    <AwsFileManager v-if="visibleHotelFactsheet" :ismulti="false" :ispdf="true" @close="showFactsheet(false)" @selectwithname="selectFactsheet"/>
                                    <!-- <AddGallary :ismulti="false" @close="showRoomImage(false)" @selectImages="selectRoomImage"/> -->
                                </a-modal>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <a-button @click="showHotelMap(true)" style="margin-top:auto;margin-bottom:auto;margin-left:20px">
                                    Add Hotel Map
                                </a-button>
                                <a-modal
                                    v-model="visibleHotelMap"
                                    title="Add Hotel Map"
                                    :footer="null"
                                    width="80%"
                                >
                                    <AwsFileManager v-if="visibleHotelMap" :ismulti="false" @close="showHotelMap(false)" @selectImages="selectHotelMap"/>
                                    <!-- <AddGallary :ismulti="false" @close="showRoomImage(false)" @selectImages="selectRoomImage"/> -->
                                </a-modal>
                                <a-list style="margin-top:10px;width:150px;margin-left:auto;margin-right:auto" :grid="{ gutter: 16, column: 1 }" :data-source="hotelMap">
                                    <a-list-item slot="renderItem" slot-scope="item">
                                        <div style="border: 1px solid #E7E7E7;position:relative">
                                            <img :src="`${item}`" alt="" width="150px" height="100px">
                                            <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelHotelMap(item)" :size="'small'"></a-button>
                                        </div>
                                    </a-list-item>
                                </a-list>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="6" tab="Property">
                            <div>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">How to get here</div>
                                    <a-input  style="width:100%;padding:2px" v-model="getHere" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Dining and lounges</div>
                                    <a-input  style="width:100%;padding:2px" v-model="diningLounges" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Resort size</div>
                                    <a-input  style="width:100%;padding:2px" v-model="resortSize" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Best for</div>
                                    <a-input  style="width:100%;padding:2px" v-model="bestFor" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Location</div>
                                    <a-input  style="width:100%;padding:2px" v-model="location" placeholder="" />
                                </div>
                                <a-divider/>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="7" tab="API Integrat">
                            <div style="margin-top:20px">  
                                
                                <div style="display:flex;margin-top:20px;">                              
                                    <a-checkbox v-model="apiAvailable" :disabled="hotelcode == undefined || hotelcode == ''">
                                        Itwx API Available
                                    </a-checkbox>
                                </div>
                                
                                <div style="display:flex;margin-top:20px;margin-bottom:20px;">
                                    <div style="width:150px;text-align:left;margin-top:5px;">Itwx Hotel Fee</div>
                                    <a-input-number  style="width:200px;padding:2px" v-model="itwxHotelFee" :min="20" placeholder="" />
                                </div>
                            </div>
                            <div v-if="apiAvailable" style="border:1px solid #EEEEEE">
                                
                                <div style="display:flex;width:100%;background-color:transparent">
                                    <span style="width:30%;display:flex" class="dateSearchDiv">
                                        <a-icon style="margin-top:auto;margin-bottom:auto;margin-left:10px;color:#787878" type="calendar" />
                                        <a-range-picker style="width:100%;margin-top:auto;margin-bottom:auto;color:#787878;border: 1px solid transparent;" :placeholder="['Check in', 'Check out']" @change="onChangeSearchDate" >
                                        </a-range-picker>
                                    </span>
                                    <div style="background-color:#D3D3D3;width:1px;height:auto;margin-top:10px;margin-bottom:10px"></div>
                                    <span style="width:50%;display:flex;cursor:pointer">
                                    <a-popover v-model="visibleRoomList" v-if="searchRoomInfo.length > 0" title="Room List" trigger="click" placement="bottom">
                                        <template slot="content">
                                            <div style="gap: 5px;display: grid;"> 
                                            <div v-for="(item, index) in searchRoomInfo" :key="index" style="border: 1px solid #EEEEEE;padding:3px;padding-left: 10px;padding-right: 10px;display: flex;">
                                                <span @click="editSearchRoomInfo(index)" style="margin-top:auto;cursor:pointer;margin-bottom:auto;width:100%;color:#787878;margin-right: 10px;">Room {{index + 1}}: {{item.searchAdultCount}} adults, {{item.searchChildCount}} children</span> 
                                                <a-icon style="margin: auto;" type="close" @click="removeSearchRoomInfo(index)" class="minusRowBtn" :size="'small'"></a-icon>
                                            </div>
                                            </div>
                                        </template>
                                        <div style="display:flex;cursor:pointer;width:100%">
                                            <a-icon style="margin-top:auto;margin-bottom:auto;margin-left:10px;color:#787878" type="user" />
                                            <span style="margin-top:auto;margin-bottom:auto;width:100%;margin-left:10px;color:#787878">{{searchRoomInfo.length}} rooms, {{searchRoomInfo.reduce((accumulator, current) => accumulator + current.searchAdultCount, 0)}} adults, {{searchRoomInfo.reduce((accumulator, current) => accumulator + current.searchChildCount, 0)}} children</span> 
                                        </div>
                                    </a-popover>
                                    <div v-else style="display:flex;cursor:pointer;width:100%">
                                        <a-icon style="margin-top:auto;margin-bottom:auto;margin-left:10px;color:#787878" type="user" />
                                        <span style="margin-top:auto;margin-bottom:auto;width:100%;margin-left:10px;color:#787878">0 rooms, 0 adults, 0 children</span> 
                                    </div>
                                    <div style="margin-top:auto;margin-bottom:auto;margin-right: 0;width: 250px;cursor:pointer;color:#787878" @click="showSearchCountPicker(true)">
                                        + Add Another Room
                                    </div>
                                </span>
                                    <a-button class="homeSearchBtn" style="margin-top:auto;margin-bottom:auto;width:100px;height: 100%;" type="primary" @click="gotoHotelSearch">
                                        SEARCH
                                    </a-button>
                                </div>
                                
                                
                                <a-modal
                                    v-model="visibleSearchCountPicker"
                                    title=""
                                    :footer="null"
                                >
                                    <person-count-selector v-if="visibleSearchCountPicker" :dialogData="{
                                        searchRoomInfo:searchRoomInfo,
                                        searchRoomInfoID:searchRoomInfoID
                                    }" @getCountInfo="getCountInfo"></person-count-selector>
                                </a-modal>
                            </div>
                        
                            <a-list style="margin-top:10px" :data-source="itwxSearchRoomList">
                                <a-list-item slot="renderItem" slot-scope="item">
                                    <div style="border: 1px solid #E7E7E7;position:relative;;width:100%;margin-left: 10px;;margin-right: 10px;padding:10px">
                                        <div style="display:flex;width:100%">
                                            <div style="width:150px;text-align: left;">
                                                Room Number : 
                                            </div>
                                            <div>
                                                {{item.RoomNo}}
                                            </div>
                                        </div>
                                        <div style="display:flex;width:100%">
                                            <div style="width:150px;text-align: left;">
                                                Room Name : 
                                            </div>
                                            <div>
                                                {{item.RoomType}}
                                            </div>
                                        </div>
                                        <div style="display:flex;width:100%">
                                            <div style="width:150px;text-align: left;">
                                                Room Rate : 
                                            </div>
                                            <div>
                                                {{item.Rate}}$
                                            </div>
                                        </div>
                                    </div>
                                </a-list-item>
                            </a-list>

                        </a-tab-pane>
                        <a-tab-pane key="8" tab="Feedback">
                            <div style="margin-top:20px">  
                                <div style="display:flex;margin-top:20px;margin-bottom:20px;">
                                    <div style="width:150px;text-align:left;margin-top:5px;">Star</div>
                                    <a-input-number  style="width:200px;padding:2px" v-model="hotelStar" :min="0" placeholder="" />
                                </div>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </a-collapse-panel>
            </a-collapse>
            <div style="display:flex">
                <a-button style="margin-right:auto;margin-left:0;margin-top:10px" size="large" @click="publishHotel(0)">Save Draft</a-button>
                <a-button style="margin-right:0;margin-left:auto;margin-top:10px" :style="{ background: '#0071a1',border:'none'}" type="primary" size="large" @click="publishHotel(1)">Publish</a-button>
            </div>
        </div>
    </div>
</template>
<script>

import AddGallary from '../components/AddGallary.vue'
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
import { api } from '../helpers/Helpers';
import HotelPromotionList from './HotelDynamicList/HotelPromotionList.vue';
import HotelTransferList from './HotelDynamicList/HotelTransferList.vue';
import HotelMealplanList from './HotelDynamicList/HotelMealplanList.vue';
import AwsFileManager from '../components/AwsFileManager.vue';
import HotelFestiveList from './HotelDynamicList/HotelFestiveList.vue';
import HotelHoneyList from './HotelDynamicList/HotelHoneyList.vue';
import HotelExtraList from './HotelDynamicList/HotelExtraList.vue';
import HotelHighlightList from './HotelDynamicList/HotelHighlightList.vue';
import PersonCountSelector from './SearchDialogs/PersonCountSelector.vue';
import { common } from '../helpers/Common';

var moment = require('moment-timezone');
const HOTELPROMOTIONPART = 'hotelPromotionPart'
const HOTELTRANSFERPART = 'hotelTransferPart'
const HOTELMEALPLANPART = 'hotelMealplanPart'
const HOTELFESTIVEPART = 'hotelFestivePart'
const HOTELHONEYPART = 'hotelHoneyPart'
const HOTELEXTRAPART = 'hotelExtraPart'
const HOTELHIGHLIGHTPART = 'hotelHighlightPart'


var selecetedList = [];
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    selecetedList = selectedRows;
  },
  onSelect: (record, selected, selectedRows) => {
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
  },
}
export default {

  props: {
    arrPromotion: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
        reRenderKey: false,
      options:{
        menubar:true,
        plugins: 'advlist autolink charmap code codesample directionality emoticons',
        toolbar1:'fontselect | fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor',
        toolbar2:'alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code',
        content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap');",
            
            // Raleway Light=raleway light, raleway,light; 
            // Raleway Medium=raleway medium, raleway,bold; 
            // Raleway Bold=Raleway ExtraBold, raleway; 
        font_formats: `Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; 
            Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; 
            
            Raleway=raleway medium, raleway; 

            Montserrat Light=Montserrat light; Montserrat Medium=Montserrat medium; Montserrat Bold=Montserrat ExtraBold; 
            Calibri Light=Calibri light; Calibri Medium=Calibri medium; Calibri Bold=Calibri ExtraBold; 
            Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; 
            Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats`,
        },
        
      options1:{
        menubar:true,
        plugins: 'advlist autolink charmap code codesample directionality emoticons',
        toolbar1:'fontselect | fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor',
        toolbar2:'alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code',
        content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap');",
            
            // Raleway Light=raleway light, raleway,light; 
            // Raleway Medium=raleway medium, raleway,bold; 
            // Raleway Bold=Raleway ExtraBold, raleway; 
        font_formats: `Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; 
            Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; 
            
            Raleway=raleway medium, raleway; 

            Montserrat Light=Montserrat light; Montserrat Medium=Montserrat medium; Montserrat Bold=Montserrat ExtraBold; 
            Calibri Light=Calibri light; Calibri Medium=Calibri medium; Calibri Bold=Calibri ExtraBold; 
            Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; 
            Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats`,
        },
      content: "",
      text: `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`,


      hotelPromotionForm: this.$form.createForm(this),
      HOTELPROMOTIONPART,
      arrHotelPromotion: [],

      hotelTransferForm: this.$form.createForm(this),
      HOTELTRANSFERPART,
      arrHotelTransfer: [],

      hotelMealplanForm: this.$form.createForm(this),
      HOTELMEALPLANPART,
      arrHotelMealplan: [],

      hotelFestiveForm: this.$form.createForm(this),
      HOTELFESTIVEPART,
      arrHotelFestive: [],

      hotelHoneyForm: this.$form.createForm(this),
      HOTELHONEYPART,
      arrHotelHoney: [],

      hotelExtraForm: this.$form.createForm(this),
      HOTELEXTRAPART,
      arrHotelExtra: [],

      hotelHighlightForm: this.$form.createForm(this),
      HOTELHIGHLIGHTPART,
      arrHotelHighlight: [],


      visibleGallary: false,
      gallaryImages:[],

      visibleHotelFactsheet: false,
      
      createdHotelId:'',

      hotelname:'',
      hotelcode:'',
      hoteltext:'',

      hotelAwardText:'',
      hotelOverview:'',
      hotelAmenities:'',
      hotelVueTip:'',
      hotelChildPolicy:'',

      updateTinymce2:false,
      updateTinymce3:false,
      updateTinymce4:false,
      updateTinymce5:false,
      updateTinymce6:false,



      apiAvailable:false,

      hotelHidden:false,

      travelList:[],
      bookingList:[],
      blackList:[],
      discountList:[],

      mealPriceList:[],
      festivePriceList:[],
      extraPeriodList:[],

      formulaList: [
            {
                key:"Formula1",
                name:"F1: rates not included with green tax"
            },
            {
                key:"Formula2",
                name:"F2: rates included with green tax"
            }
        ],
      
      visibleImage: false,
      selitem:0,
      selectedImage:[],
      isSelected:[],

      services:'',
      transferstr:'',
      importantnote:'',

      reqularprice:'',
      publishedrate:'',
      greentax:'',
      servicecharges:'',
      gst:'',
      commission:'',
      calculationfomula:'Formula1',
      confirmationNo:'',

      stypeBoolList:[],
      styleList:[],
      typeofBoolList:[],
      typeofList:[],


      numberofroom:0,
      purchaseNoteOne:'',
      purchaseNoteTwo:'',
      romanceContent:'',
      familiesContent:'',
      boatContent:'',
      allContent:'',
      activeContent:'',
      spaContent:'',

      hotelphonenumber:'',
      hotelemailaddress:'',

      getHere:'',
      diningLounges:'',
      resortSize:'',
      bestFor:'',

      location:'',
      
      visibleHotelImage: false,
      hotelImage:[],
      visibleHotelLogo: false,
      hotelLogo:[],
      visibleHotelMap: false,
      hotelMap:[],

      selectedHotel:[],
      rowSelection,
      selecetedList,
      roomList: [],
      hotelId:'',
      hotelStar:0,



      roomlist:[],
      
        facesheetPdf:{
            id:'',
            name:''
        },

        searchStartDate:null,
        searchEndDate:null,
        searchRoomInfo:[],
        searchRoomInfoID: 0, 
        
        visibleRoomList:false,
        visibleSearchCountPicker:false,
        itwxSearchRoomList:[],
        itwxHotelFee:20,

    };
  },
  components: {
    Vue2TinymceEditor,
    AddGallary,
    HotelPromotionList,
    HotelTransferList,
    HotelMealplanList,
    AwsFileManager,
    HotelFestiveList,
    HotelHoneyList,
    HotelExtraList,
    HotelHighlightList,
    PersonCountSelector,
  },
  created:async function() {
    // this.form = this.$form.createForm(this)
    // this.styleList = common.getResortStyleList();
    await this.getResortStyleInfo();
    await this.getResortTypeTravels();
    // this.typeofList = common.getResortBestForList();
    // this.typeofBoolList = [];
    // for (let i = 0; i < this.typeofList.length; i++){
    //     this.typeofBoolList.push(false);
    // }

    this.gallaryImages = [];
    this.createdHotelId = '';

    
    var mynumber = 2;
    this.selectedImage = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.selectedImage[i] = '';
    }
    this.isSelected = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.isSelected[i] = false;
    }
    // this.getCSV();
    await this.initHotel();
  },
  methods: {
    moment,
    getResortStyleInfo:async function(){
        const res = await api.getResortStyleInfo();
        if (res.length > 0){
            this.styleList = [];
            this.stypeBoolList = [];
            for (let i = 0; i < res[0].datalist.length; i++){
                this.styleList.push(res[0].datalist[i].name);
                this.stypeBoolList.push(false);
            }
        }
    },
    
    getResortTypeTravels:async function(){
        const res = await api.getResortTypeTravelInfo();
        if (res.length > 0){
            this.typeofList = [];
            this.typeofBoolList = [];
            for (let i = 0; i < res[0].datalist.length; i++){
                this.typeofBoolList.push(false);
                this.typeofList.push(res[0].datalist[i].name);
            }
        }
    },

    initHotel:async function(){
        const _id = this.$route.query.id;
        if (_id != null){
            this.hotelId = _id;
            this.getRooms(this.hotelId);
            this.selectedHotel = await api.getHotel(_id);

            this.hotelname = this.selectedHotel.hotelname;
            this.hoteltext = this.selectedHotel.hoteltext;

            this.hotelAwardText = this.selectedHotel.hotelAwardText;
            this.hotelOverview = this.selectedHotel.hotelOverview;
            this.hotelAmenities = this.selectedHotel.hotelAmenities;
            this.hotelVueTip = this.selectedHotel.hotelVueTip;
            this.hotelChildPolicy = this.selectedHotel.hotelChildPolicy;
            this.arrHotelPromotion = this.selectedHotel.promotion;
            this.gallaryImages = this.selectedHotel.gallaryimages;

            this.apiAvailable = this.selectedHotel.apiintegrate;
            this.hotelcode = this.selectedHotel.hotelcode;
            this.hotelHidden = this.selectedHotel.hotelHidden != undefined ? this.selectedHotel.hotelHidden : false;

            this.arrHotelTransfer = this.selectedHotel.transfer;
            this.arrHotelMealplan = this.selectedHotel.mealplan;
            this.arrHotelFestive = this.selectedHotel.festive;
            this.arrHotelHoney = this.selectedHotel.honeymoon;
            this.arrHotelExtra = this.selectedHotel.extraperson;
            this.arrHotelHighlight = this.selectedHotel.hotelhighlight;
            
            this.reqularprice = this.selectedHotel.hoteldatageneral.reqularprice;
            this.publishedrate = this.selectedHotel.hoteldatageneral.publishedrate;
            this.greentax = this.selectedHotel.hoteldatageneral.greentax;
            this.servicecharges = this.selectedHotel.hoteldatageneral.servicecharges;
            this.gst = this.selectedHotel.hoteldatageneral.gst
            this.commission = this.selectedHotel.hoteldatageneral.commission;
            this.calculationfomula = this.selectedHotel.hoteldatageneral.calculationfomula;
            this.confirmationNo = this.selectedHotel.hoteldatageneral.confirmationNo;
            
            var stypeList = this.selectedHotel.hoteldatarooms.stype;
            var typeoftravel = this.selectedHotel.hoteldatarooms.typeoftravel;
            
            for (let i = 0; i < this.styleList.length; i++){
                if (stypeList.includes(this.styleList[i]))
                    this.stypeBoolList.splice(i, 1, true);
                else
                    this.stypeBoolList.splice(i, 1, false);
            }

            for (let i = 0; i < this.typeofList.length; i++){
                if (typeoftravel.includes(this.typeofList[i]))
                    this.typeofBoolList.splice(i, 1, true);
                else
                    this.typeofBoolList.splice(i, 1, false);
            }

            this.numberofroom = this.selectedHotel.hoteldatarooms.numberofroom;
            this.purchaseNoteOne = this.selectedHotel.advanced.purchasenoteone;
            this.purchaseNoteTwo = this.selectedHotel.advanced.purchasenotetwo;


            this.imageone = this.selectedHotel.quoteinfomation.imageone;
            this.imagetwo = this.selectedHotel.quoteinfomation.imagetwo;
            this.selectedImage.splice(0, 1, this.imageone);
            this.selectedImage.splice(1, 1, this.imagetwo);
            this.isSelected.splice(0, 1, this.imageone != '')
            this.isSelected.splice(1, 1, this.imagetwo != '')
            
            this.services = this.selectedHotel.quoteinfomation.services;
            this.transferstr = this.selectedHotel.quoteinfomation.transfer;
            this.importantnote = this.selectedHotel.quoteinfomation.importantnote;
            this.hotelphonenumber = this.selectedHotel.quoteinfomation.hotelphonenumber;
            this.hotelemailaddress = this.selectedHotel.quoteinfomation.hotelemailaddress;

            if (this.selectedHotel.contentoftraveltype != undefined){
                this.romanceContent = this.selectedHotel.contentoftraveltype.romancecontent;
                this.familiesContent = this.selectedHotel.contentoftraveltype.familiescontent;
                this.boatContent = this.selectedHotel.contentoftraveltype.boatride;
                this.allContent = this.selectedHotel.contentoftraveltype.allinclusive;
                this.activeContent = this.selectedHotel.contentoftraveltype.activecontent;
                this.spaContent = this.selectedHotel.contentoftraveltype.spawelleness;
            }
            
            this.hotelImage = [];
            this.hotelImage.push(this.selectedHotel.hotelimage);
            this.hotelLogo = [];
            this.hotelLogo.push(this.selectedHotel.hotellogo);
            this.facesheetPdf = this.selectedHotel.hotelfacesheet;
            this.hotelMap = [];
            this.hotelMap.push(this.selectedHotel.hotelmap);

            if (this.selectedHotel.hotelproperty != undefined){
                this.getHere = this.selectedHotel.hotelproperty.getHere;
                this.diningLounges = this.selectedHotel.hotelproperty.diningLounges;
                this.resortSize = this.selectedHotel.hotelproperty.resortSize;
                this.bestFor = this.selectedHotel.hotelproperty.bestFor;
                this.location = this.selectedHotel.hotelproperty.location;
            }
            
            this.roomlist = this.selectedHotel.roomlist;
            this.itwxHotelFee = this.selectedHotel.itwxHotelFee != undefined && this.selectedHotel.itwxHotelFee != 0 ? this.selectedHotel.itwxHotelFee : 20;

            this.selecetedList = [];

            this.reRenderKey = !this.reRenderKey;

            this.hotelStar = this.selectedHotel.star != undefined ? this.selectedHotel.star : 0;

            this.updateTinymce2 = !this.updateTinymce2;
            this.updateTinymce3 = !this.updateTinymce3;

            this.updateTinymces();
        }
    },
    updateTinymces(){
        
        setTimeout(() => {
            this.updateTinymce2 = !this.updateTinymce2;
            
            setTimeout(() => {
                this.updateTinymce3 = !this.updateTinymce3;
                setTimeout(() => {
                    this.updateTinymce4 = !this.updateTinymce4;
                    setTimeout(() => {
                        this.updateTinymce5 = !this.updateTinymce5;
                            setTimeout(() => {
                                this.updateTinymce6 = !this.updateTinymce6;
                            }, 1);
                    }, 1);
                }, 1);
            }, 1);
        }, 1);
    },
    getRooms:async function(_hotelId){
        this.roomList = await api.getHotelRooms(_hotelId);
    },
    
    showGallary(_isShow){
        this.visibleGallary = _isShow;
        // if (!_isShow)
        //     this.updateUserInfo();
    },
    selectImages(_images){
        for (let i = 0; i < _images.length; i++){
            if (!this.gallaryImages.includes(_images[i]))
                this.gallaryImages.push(_images[i]);
        }
        // this.gallaryImages = _images;
        this.visibleGallary = false;
    },
    cancelImage(_id){
      var _index = this.gallaryImages.indexOf(_id);
      this.gallaryImages.splice(_index, 1);
    },

    showImage(_isShow, _index){
        this.visibleImage = _isShow;
        if (_isShow)
            this.selitem = _index;
        else
            this.selitem = 0;
        // if (!_isShow)
        //     this.updateUserInfo();
    },
    selectImage(_images, _index){
        
        if (_images.length > 0){
            this.selectedImage[_index] = _images[0];
            this.isSelected[_index] = true;
        }
        this.visibleImage = false;
    },
    cancelQuoteImage(_index){
        this.selectedImage[_index] = '';
        this.isSelected.splice(_index, 1, false)
    },

    
    showHotelImage(_isShow){
        this.visibleHotelImage = _isShow;
    },
    selectHotelImage(_images){
        this.hotelImage = _images;
        this.visibleHotelImage = false;
    },
    cancelHotelImage(_id){
      var _index = this.hotelImage.indexOf(_id);
      this.hotelImage.splice(_index, 1);
    },

    showHotelLogo(_isShow){
        this.visibleHotelLogo = _isShow;
    },
    selectHotelLogo(_images){
        this.hotelLogo = _images;
        this.visibleHotelLogo = false;
    },
    cancelHotelLogo(_id){
      var _index = this.hotelLogo.indexOf(_id);
      this.hotelLogo.splice(_index, 1);
    },

    showHotelMap(_isShow){
        this.visibleHotelMap = _isShow;
    },
    selectHotelMap(_images){
        this.hotelMap = _images;
        this.visibleHotelMap = false;
    },
    cancelHotelMap(_id){
      var _index = this.hotelMap.indexOf(_id);
      this.hotelMap.splice(_index, 1);
    },
    
    showFactsheet(_isShow){
        this.visibleHotelFactsheet = _isShow;
    },
    selectFactsheet(_pdfids, _pdfnames){
        if (_pdfids.length > 0)
        {
            this.facesheetPdf = {
                id:_pdfids[0],
                name:_pdfnames[0]
            };
        }
        this.visibleHotelFactsheet = false;
    },
    cancelFactsheet(_id){
      this.facesheetPdf = {
        id:'',
        name:''
      };
    },
    
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },

    callback(key) {
    },

    onTravelPeriodStart(){

    },
    onChange(){

    },

    publishHotel:async function(status){
        await this.getHotelPromotion()
        
        await this.getHotelTransfer()
        await this.getHotelMealplan()
        await this.getFestiveplan()
        await this.getHotelHoney()
        await this.getExtraPerson()
        this.imageone = this.selectedImage[0];
        this.imagetwo = this.selectedImage[1];
        this.getHighlightList()
        // return
        var currentDate = new Date();
        var stypeList = []
        
        for (let i = 0; i < this.stypeBoolList.length; i++){
            if (this.stypeBoolList[i]){
                stypeList.push(this.styleList[i])//'CONTEMPORARY/MODERN')
            }
        }

        var typeoftravel = [];
        
        for (let i = 0; i < this.typeofBoolList.length; i++){
            if (this.typeofBoolList[i])
                typeoftravel.push(this.typeofList[i])//'CONTEMPORARY/MODERN')
        }
            
        let _hotelimage = '';
        if (this.hotelImage.length > 0)
            _hotelimage = this.hotelImage[0];

        let _hotellogo = '';
        if (this.hotelLogo.length > 0)
            _hotellogo = this.hotelLogo[0];
            
        let _hotelmap = '';
        if (this.hotelMap.length > 0)
            _hotelmap = this.hotelMap[0];

        let _lowerhotelname = '';
        if (this.hotelname.length > 0)
            _lowerhotelname = this.hotelname.toLowerCase().replace(/ /g, '-');
        
        var hotelInfo = {
            hotelname: this.hotelname,
            hoteltext: this.hoteltext,
            
            hotelAwardText: this.hotelAwardText,
            hotelOverview: this.hotelOverview,
            hotelAmenities: this.hotelAmenities,
            hotelVueTip: this.hotelVueTip,
            hotelChildPolicy: this.hotelChildPolicy,

            promotion: this.arrHotelPromotion,
            gallaryimages: this.gallaryImages,

            apiintegrate: this.apiAvailable,
            hotelcode: this.hotelcode,
            hotelHidden: this.hotelHidden,

            transfer: this.arrHotelTransfer,
            mealplan: this.arrHotelMealplan,
            festive: this.arrHotelFestive,
            honeymoon: this.arrHotelHoney,
            extraperson: this.arrHotelExtra,
            hotelhighlight: this.arrHotelHighlight,

            hoteldatageneral:{
                reqularprice:this.reqularprice,
                publishedrate:this.publishedrate,
                greentax:this.greentax,
                servicecharges:this.servicecharges,
                gst:this.gst,
                commission:this.commission,
                confirmationNo:this.confirmationNo,
                calculationfomula:this.calculationfomula,
                starrate:4
            },

            hoteldatarooms:{
                stype: stypeList,
                typeoftravel: typeoftravel,
                numberofroom: this.numberofroom
            },
            advanced:{
                purchasenoteone:this.purchaseNoteOne,
                purchasenotetwo:this.purchaseNoteTwo,
            },
            contentoftraveltype:{
                romancecontent:this.romanceContent,
                familiescontent:this.familiesContent,
                boatride:this.boatContent,
                allinclusive:this.allContent,
                activecontent:this.activeContent,
                spawelleness:this.spaContent
            },  

            quoteinfomation:{
                imageone:this.imageone,
                imagetwo:this.imagetwo,
                services:this.services,
                transfer:this.transferstr,
                importantnote:this.importantnote,
                hotelphonenumber:this.hotelphonenumber,
                hotelemailaddress:this.hotelemailaddress
            },
            hotelimage:_hotelimage,
            hotellogo:_hotellogo,
            hotelfacesheet:this.facesheetPdf,
            hotelmap:_hotelmap,
            
            status:status,
            username:"David",
            userid:"MineUserId",
            comments:3,
            createdate:currentDate,
            type:"Hotel",
            star:this.hotelStar,

            hotelproperty:{
                getHere:this.getHere,
                diningLounges:this.diningLounges,
                resortSize:this.resortSize,
                bestFor:this.bestFor,
                location:this.location,
            },

            roomlist:this.roomlist,
            itwxHotelFee:this.itwxHotelFee,
            lowerhotelname:_lowerhotelname,
        };
            
            console.log('====================hotelInfo.promotion================================', hotelInfo.promotion);
            const res = await api.updateHotel(this.hotelId, hotelInfo);
            if (res != undefined && res != ""){
                this.$message.success('Hotel update Success!');
                await this.sleep(1000);
                window.location.reload();
                // this.createdHotelId = res.id;

                // var tempArrHotelTransfer = this.arrHotelTransfer;
                // this.arrHotelTransfer = [];
                // var tempArrHotelMealplan = this.arrHotelMealplan;
                // this.arrHotelMealplan = [];
                // var tempArrHotelFestive = this.arrHotelFestive;
                // this.arrHotelFestive = [];
                // var tempArrHotelHoney = this.arrHotelHoney;
                // this.arrHotelHoney = [];
                // var tempArrHotelExtra = this.arrHotelExtra;
                // this.arrHotelExtra = [];
                // this.reRenderKey = !this.reRenderKey;
                // this.arrHotelTransfer = tempArrHotelTransfer;
                // this.arrHotelMealplan = tempArrHotelMealplan;
                // this.arrHotelFestive = tempArrHotelFestive;
                // this.arrHotelExtra = tempArrHotelExtra;
                // this.arrHotelHoney = tempArrHotelHoney;
                // this.reRenderKey = !this.reRenderKey;
                // //this.published = true;
            }
            else{
                this.$message.error('Hotel update Failed!');
            }
    },
    
    getSelectedBookings:async function(hotelId){
        const res = await api.getBookingsByHotelID(hotelId)
        if (res != undefined && res.length > 0){
            this.updateBookingInfo(res[0]);
        }
        else{
            this.saveBookingInfo(hotelId);
        }
    },
    
    updateBookingInfo(_info){
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        var dateList = _info.datalist;
        
        let partOneArr = [];
        var lastestInfo = {
            fromDate: new Date(),
            toDate: new Date(),
            roomPrice:'',
            hotelid:_info.hotelid
        };
        for(let i = 0; i < dateList.length; i++){
            const _cellInfo = dateList[i];
            partOneArr.push(_cellInfo);
            if (lastestInfo.toDate.getTime() < new Date(_cellInfo.toDate).getTime())
                lastestInfo = {
                    fromDate: new Date(_cellInfo.fromDate),
                    toDate: new Date(_cellInfo.toDate),
                    roomPrice:_cellInfo.roomPrice,
                    hotelid:_cellInfo.hotelid
                };
        }
        const endMouthList = [2, 5, 8, 11];
        var selSeason = 2;
        for(let i = 0; i < endMouthList.length; i++){
            const _cellInfo = endMouthList[i];
            if (_cellInfo - lastestInfo.toDate.getMonth() > 0 && _cellInfo - lastestInfo.toDate.getMonth() <= 3)
                selSeason = _cellInfo;
        }
        var lastYear = lastestInfo.toDate.getFullYear()
        const lastMonth = lastestInfo.toDate.getMonth();
        const startDate = new Date(lastYear, lastMonth, lastestInfo.toDate.getDate() + 1);
        const endDate = new Date(lastYear, selSeason, 0);
        partOneArr.push(this.getBookingCellInfo(_info.hotelid, startDate, endDate, selSeason));

        while(true){
            const startDateSpring = new Date(lastYear, selSeason, 1);
            var endMonth = selSeason + 3;
            var endYear = lastYear + 0;
            if (endMonth > 11){
                endMonth = 2;
                endYear = lastYear + 1;
            }
            const endDateSpring = new Date(endYear, endMonth, 0);
            if (lastYear > currentYear + 3)
                break;
            partOneArr.push(this.getBookingCellInfo(_info.hotelid, startDateSpring, endDateSpring, selSeason + 1));
            selSeason += 3;
            if (selSeason > 11){
                selSeason = 2;
                lastYear += 1;
            }
        };

        const lastInfo = {
            hotelid: _info.hotelid,
            datalist: partOneArr
        }
        this.sendBookingData(lastInfo);

    },
    saveBookingInfo(hotelId){
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        let partOneArr = [];
        for (let i = 0; i < 3; i++){
            const startDateWinter = new Date(currentYear + i - 1, 11, 1);
            const endDateWinter = new Date(currentYear + i, 2, 0);
            partOneArr.push(this.getBookingCellInfo(hotelId, startDateWinter, endDateWinter, 12));

            const startDateSpring = new Date(currentYear + i, 2, 1);
            const endDateSpring = new Date(currentYear + i, 5, 0);
            partOneArr.push(this.getBookingCellInfo(hotelId, startDateSpring, endDateSpring, 3));
            
            const startDateSummer = new Date(currentYear + i, 5, 1);
            const endDateSummer = new Date(currentYear + i, 8, 0);
            partOneArr.push(this.getBookingCellInfo(hotelId, startDateSummer, endDateSummer, 6));
            
            const startDateAutumn = new Date(currentYear + i, 8, 1);
            const endDateAutumn = new Date(currentYear + i, 11, 0);
            partOneArr.push(this.getBookingCellInfo(hotelId, startDateAutumn, endDateAutumn, 9));
            
        }

        const lastInfo = {
            hotelid: hotelId,
            datalist: partOneArr
        }
        this.sendBookingData(lastInfo);
    },
    getBookingCellInfo(hotelId, startDate, endDate, mouth){
        const obj = {
            fromDate: startDate,
            toDate: endDate,
            roomPrice:'',
            hotelid:hotelId
        }
        let priceObj = {}
        for (let i = 0; i < this.roomList.length; i++){// numberofroom
            var roomCount = this.roomList[i].numberofroom;
            if (this.roomList[i].numberofroom != undefined && this.roomList[i].numberofroom == 0){
                roomCount = 2;
            }
            var _rate = 0;
            if (mouth == 3)
                _rate = this.springRate;
            if (mouth == 6)
                _rate = this.summerRate;
            if (mouth == 9)
                _rate = this.autumnRate;
            if (mouth == 12)
                _rate = this.winterRate;

            var cellRoomPrice = Math.round((this.unitPriceRoom * (1 + _rate / 100) * roomCount) * 100) / 100;
            priceObj[this.roomList[i]._id] = cellRoomPrice;
        }
        obj.roomPrice = JSON.stringify(priceObj);
        return obj;

    },
    sendBookingData:async function(_array){
        const res = await api.addBookings(_array);
    },

    
    getTravelList(value){
        this.travelList = value
    },
    getBookingList(value){
        this.bookingList = value
    },
    getBlackList(value){
        this.blackList = value
    },
    getDiscountList(value){
        this.discountList = value
    },
    getKeyList(value){
        this.keyList = value
    },
    getKeyTransferList(value){
        this.keyTransferList = value
    },
    getKeyMealList(value){
        this.keyMealList = value
    },
    getKeyHoneyList(value){
        this.keyHoneyList = value
    },
    getKeyFestiveList(value){
        this.keyFestiveList = value
    },
    getKeyExtraList(value){
        this.keyExtraList = value
    },
    getHotelPromotion: async function (){
        this.$refs._sublist.getKeyList();
        const { hotelPromotionForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                var mailList = values[`${HOTELPROMOTIONPART}Title`];
                if (mailList != undefined){
                    for(let index_for = 0; index_for < this.keyList.length; index_for++){
                        var index = this.keyList[index_for];
                        this.$refs._sublist.getTravelPeriod(index);
                        this.$refs._sublist.getBookingPeriod(index);
                        this.$refs._sublist.getBlackPeriod(index);
                        this.$refs._sublist.getDiscountCalculation(index);
                        const obj = {
                            title: values[`${HOTELPROMOTIONPART}Title`][index],
                            description: values[`${HOTELPROMOTIONPART}Description`] !== undefined && values[`${HOTELPROMOTIONPART}Description`][index] !== undefined
                                ? values[`${HOTELPROMOTIONPART}Description`][index] : this.arrHotelPromotion[index] ? this.arrHotelPromotion[index].description : null,
                            termsconditions: values[`${HOTELPROMOTIONPART}Termsconditions`] !== undefined && values[`${HOTELPROMOTIONPART}Termsconditions`][index] !== undefined
                                ? values[`${HOTELPROMOTIONPART}Termsconditions`][index] : this.arrHotelPromotion[index] ? this.arrHotelPromotion[index].termsconditions : null,
                            bookingcode: values[`${HOTELPROMOTIONPART}Bookingcode`] !== undefined && values[`${HOTELPROMOTIONPART}Bookingcode`][index] !== undefined
                                ?  values[`${HOTELPROMOTIONPART}Bookingcode`][index] : this.arrHotelPromotion[index] ? this.arrHotelPromotion[index].bookingcode : null,
                            travelperiod: this.travelList,
                            bookingperiod: this.bookingList,
                            blackperiod: this.blackList,
                            maxnights: values[`${HOTELPROMOTIONPART}Maxnights`] !== undefined && values[`${HOTELPROMOTIONPART}Maxnights`][index] !== undefined
                                ? values[`${HOTELPROMOTIONPART}Maxnights`][index] : this.arrHotelPromotion[index] !== undefined ? this.arrHotelPromotion[index].maxnights : null,
                            minnights: values[`${HOTELPROMOTIONPART}Minnights`] !== undefined && values[`${HOTELPROMOTIONPART}Minnights`][index] !== undefined
                                ? values[`${HOTELPROMOTIONPART}Minnights`][index] : this.arrHotelPromotion[index] !== undefined ? this.arrHotelPromotion[index].minnights : null,
                            advance: values[`${HOTELPROMOTIONPART}Advance`] !== undefined && values[`${HOTELPROMOTIONPART}Advance`][index] !== undefined
                                ? values[`${HOTELPROMOTIONPART}Advance`][index] : this.arrHotelPromotion[index] !== undefined ? this.arrHotelPromotion[index].advance : null,
                            person:{
                                personnumber: values[`${HOTELPROMOTIONPART}Personnumber`] !== undefined && values[`${HOTELPROMOTIONPART}Personnumber`][index] !== undefined
                                    ? values[`${HOTELPROMOTIONPART}Personnumber`][index] : this.arrHotelPromotion[index] ? this.arrHotelPromotion[index].person ? this.arrHotelPromotion[index].person.personnumber : null : null,
                                adultnumber: values[`${HOTELPROMOTIONPART}Adultnumber`] !== undefined && values[`${HOTELPROMOTIONPART}Adultnumber`][index] !== undefined
                                    ? values[`${HOTELPROMOTIONPART}Adultnumber`][index] : this.arrHotelPromotion[index] ? this.arrHotelPromotion[index].person ? this.arrHotelPromotion[index].person.adultnumber : null : null,
                                childnumber: values[`${HOTELPROMOTIONPART}Childnumber`] !== undefined && values[`${HOTELPROMOTIONPART}Childnumber`][index] !== undefined
                                    ? values[`${HOTELPROMOTIONPART}Childnumber`][index] : this.arrHotelPromotion[index] ? this.arrHotelPromotion[index].person ? this.arrHotelPromotion[index].person.childnumber : null : null,
                            },
                            room: values[`${HOTELPROMOTIONPART}Room`] !== undefined && values[`${HOTELPROMOTIONPART}Room`][index] !== undefined
                                ? values[`${HOTELPROMOTIONPART}Room`][index] : this.arrHotelPromotion[index] ? this.arrHotelPromotion[index].room : null,
                            discount: values[`${HOTELPROMOTIONPART}Discount`] !== undefined && values[`${HOTELPROMOTIONPART}Discount`][index] !== undefined
                                ? values[`${HOTELPROMOTIONPART}Discount`][index] : this.arrHotelPromotion[index] ? this.arrHotelPromotion[index].discount : null,
                            discountcalculation: this.discountList,
                            stayoffer:{
                                stayday: values[`${HOTELPROMOTIONPART}Stayday`] !== undefined && values[`${HOTELPROMOTIONPART}Stayday`][index] !== undefined
                                    ? values[`${HOTELPROMOTIONPART}Stayday`][index] : this.arrHotelPromotion[index] ? this.arrHotelPromotion[index].stayoffer ? this.arrHotelPromotion[index].stayoffer.stayday : null : null,
                                offerday: values[`${HOTELPROMOTIONPART}Offerday`] !== undefined && values[`${HOTELPROMOTIONPART}Offerday`][index] !== undefined
                                    ? values[`${HOTELPROMOTIONPART}Offerday`][index] : this.arrHotelPromotion[index] ? this.arrHotelPromotion[index].stayoffer ? this.arrHotelPromotion[index].stayoffer.offerday : null : null
                            },
                        };
                        partOneArr.push(obj);
                    }
                    this.arrHotelPromotion = [];
                    // this.reRenderKey = !this.reRenderKey;
                    this.arrHotelPromotion = this.reOrderList(partOneArr, this.keyList);
                }
                else if(values[`${HOTELPROMOTIONPART}SelectInput`] !== undefined){
                    this.arrHotelPromotion= partOneArr;
                }
            }
        });
    },
    reOrderList(srcList, keyList){
        var resultList = [];
        let keyIndexList = [];

        keyIndexList = keyList.map((key, index) => {
            return { key, index };
        });
        keyIndexList.sort((a, b) => a.index - b.index);
        for(let i = 0; i < keyIndexList.length; i++){
            if (srcList.length > keyIndexList[i].index)
                resultList.push(srcList[keyIndexList[i].index]);
        }
        return resultList;
    },
    getTransferImageResult(value){
        this.transferImage = value
    },
    getHotelTransfer: async function (){
        if(this.$refs._transferlist === undefined)
            return;
        this.$refs._transferlist.getKeyTransferList();
        const { hotelTransferForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${HOTELTRANSFERPART}Transfertitle`] !== undefined){
                    for(let index_for = 0; index_for < this.keyTransferList.length; index_for++){
                        var index = this.keyTransferList[index_for];

                        let transfer = [];
                        let transferDate = [];
                        
                        ( values[`${HOTELTRANSFERPART}Minnight${index}`]).forEach((element,j)=>{
                            const transferObj = {
                                min:values[`${HOTELTRANSFERPART}Minnight${index}`][j],
                                max:values[`${HOTELTRANSFERPART}Maxnight${index}`][j],
                                peradultprice:values[`${HOTELTRANSFERPART}peradultprice${index}`][j],
                                perchildprice:values[`${HOTELTRANSFERPART}perchildprice${index}`][j],
                                perinfantprice:values[`${HOTELTRANSFERPART}perinfantprice${index}`][j],
                            }
                            transfer.push(transferObj); 
                        });

                        ( values[`${HOTELTRANSFERPART}Transferstart${index}`]).forEach((element,i)=>{
                            const transferObj = {
                                transferstart: moment.utc(values[`${HOTELTRANSFERPART}Transferstart${index}`][i]).tz("America/Los_Angeles"),
                                transferend: moment.utc(values[`${HOTELTRANSFERPART}Transferend${index}`][i]).tz("America/Los_Angeles"),
                            }
                            transferDate.push(transferObj); 
                        });
                           
                        this.$refs._transferlist.getImage(index);
                        const obj = {
                            transfertitle: values[`${HOTELTRANSFERPART}Transfertitle`][index],
                            condition:{
                                capacity: values[`${HOTELTRANSFERPART}Capacity`][index],
                                adultagerangefrom: values[`${HOTELTRANSFERPART}Adultagerangefrom`][index],
                                adultagerangeto: values[`${HOTELTRANSFERPART}Adultagerangeto`][index],
                                childagerangefrom: values[`${HOTELTRANSFERPART}Childagerangefrom`][index],
                                childagerangeto: values[`${HOTELTRANSFERPART}Childagerangeto`][index],
                                // transferstart: moment.utc(values[`${HOTELTRANSFERPART}Transferstart`][index]).tz("America/Los_Angeles"),
                                // transferend: moment.utc(values[`${HOTELTRANSFERPART}Transferend`][index]).tz("America/Los_Angeles"),
                                transferDate:transferDate,
                            },
                            price:{
                                // peradultprice: values[`${HOTELTRANSFERPART}Peradultprice`][index],
                                // perchildprice: values[`${HOTELTRANSFERPART}Perchildprice`][index],
                                // perinfantprice: values[`${HOTELTRANSFERPART}Perinfantprice`][index],
                                transfer: transfer
                                // totalprice: values[`${HOTELTRANSFERPART}Totalprice`][index],
                            },
                            description: values[`${HOTELTRANSFERPART}Description`][index],
                            image: this.transferImage
                        }
                        partOneArr.push(obj);
                    }
                    this.arrHotelTransfer = [];
                    this.arrHotelTransfer = this.reOrderList(partOneArr, this.keyTransferList);
                }
                else if(values[`${HOTELTRANSFERPART}SelectInput`] !== undefined){
                    this.arrHotelTransfer= partOneArr;
                }
            }
        });
    },
    getMealPriceList(value){
        this.mealPriceList = value
    },
    getMealPriceImage(value){
        this.mealPriceImage = value
    },
    getHotelMealplan: async function (){
        if(this.$refs._meallist === undefined)
            return;
        this.$refs._meallist.getKeyMealList();
        const { hotelMealplanForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${HOTELMEALPLANPART}Mealtitle`] !== undefined){
                    for(let index_for = 0; index_for < this.keyMealList.length; index_for++){
                        var index = this.keyMealList[index_for];
                        let mealDate = [];

                        ( values[`${HOTELMEALPLANPART}Mealstart${index}`]).forEach((element,i)=>{
                            const mealObj = {
                                mealstart: moment.utc(values[`${HOTELMEALPLANPART}Mealstart${index}`][i]).tz("America/Los_Angeles"),
                                mealend: moment.utc(values[`${HOTELMEALPLANPART}Mealend${index}`][i]).tz("America/Los_Angeles"),
                            }
                            mealDate.push(mealObj); 
                        });

                        this.$refs._meallist.getMealPrice(index);
                        this.$refs._meallist.getImage(index);
                        const obj = {
                            mealtitle: values[`${HOTELMEALPLANPART}Mealtitle`][index],
                            mealdate: mealDate,
                            price: this.mealPriceList,
                            description: values[`${HOTELMEALPLANPART}Description`][index],
                            image: this.mealPriceImage
                        }
                        partOneArr.push(obj);
                    }
                    this.arrHotelMealplan = [];
                    this.arrHotelMealplan =  this.reOrderList(partOneArr, this.keyMealList);
                }
                else if(values[`${HOTELMEALPLANPART}SelectInput`] !== undefined){
                    this.arrHotelMealplan= partOneArr;
                }
            }
        });
    },
    getFestiveList(value){
        this.festivePriceList = value
    },
    getFestiveImage(value){
        this.festiveImage = value
    },
    getFestiveplan: async function (){
        if(this.$refs._festivelist === undefined)
            return;
        this.$refs._festivelist.getKeyFestiveList();
        const { hotelFestiveForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${HOTELFESTIVEPART}Festivetitle`] !== undefined){
                    for(let index_for = 0; index_for < this.keyFestiveList.length; index_for++){
                        var index = this.keyFestiveList[index_for];
                        
                        this.$refs._festivelist.getFestivePrice(index);
                        this.$refs._festivelist.getImage(index);
                        const obj = {
                            festivetitle: values[`${HOTELFESTIVEPART}Festivetitle`][index],
                            condition: moment.utc(values[`${HOTELFESTIVEPART}Condition`][index]).tz("America/Los_Angeles"),
                            price: this.festivePriceList,
                            description: values[`${HOTELFESTIVEPART}Description`][index],
                            image: this.festiveImage
                        }
                        partOneArr.push(obj);
                    }
                    this.arrHotelFestive = [];
                    this.arrHotelFestive =  this.reOrderList(partOneArr, this.keyFestiveList);
                }
                else if(values[`${HOTELFESTIVEPART}SelectInput`] !== undefined){
                    this.arrHotelFestive= partOneArr;
                }
            }
        });
    },
    
    getHoneyImage(value){
        this.honeyImage = value
    },
    getHotelHoney: async function (){
        if(this.$refs._honeylist === undefined)
            return;
        this.$refs._honeylist.getKeyHoneyList();
        const { hotelHoneyForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${HOTELHONEYPART}Honeytitle`] !== undefined){
                    for(let index_for = 0; index_for < this.keyHoneyList.length; index_for++){
                        var index = this.keyHoneyList[index_for];
                        this.$refs._honeylist.getImage(index);
                        const obj = {
                            honeytitle: values[`${HOTELHONEYPART}Honeytitle`][index],
                            stay: values[`${HOTELHONEYPART}Stay`][index],
                            honeydate:{
                                honeystart: moment.utc(values[`${HOTELHONEYPART}Honeystart`][index]).tz("America/Los_Angeles"),
                                honeyend: moment.utc(values[`${HOTELHONEYPART}Honeyend`][index]).tz("America/Los_Angeles"),
                            },
                            description: values[`${HOTELHONEYPART}Description`][index],
                            image: this.honeyImage
                        }
                        partOneArr.push(obj);
                    }
                    this.arrHotelHoney = [];
                    this.arrHotelHoney =  this.reOrderList(partOneArr, this.keyHoneyList);
                }
                else if(values[`${HOTELHONEYPART}SelectInput`] !== undefined){
                    this.arrHotelHoney= partOneArr;
                }
            }
        });
    },
    getExtraList(value){
        this.extraPeriodList = value
    },
    getExtraPerson: async function (){
        if(this.$refs._extralist === undefined)
            return;
        this.$refs._extralist.getKeyExtraList();
        const { hotelExtraForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${HOTELEXTRAPART}From`] !== undefined){
                    for(let index_for = 0; index_for < this.keyExtraList.length; index_for++){
                        var index = this.keyExtraList[index_for];
                        this.$refs._extralist.getExtraPeriod(index);
                        const obj = {
                            agerange:{
                                from: values[`${HOTELEXTRAPART}From`][index],
                                to: values[`${HOTELEXTRAPART}To`][index],
                            },
                            periodduration: this.extraPeriodList,
                            rate:{
                                perday: values[`${HOTELEXTRAPART}Perday`][index],
                                // includegreentax: values[`${HOTELEXTRAPART}Includegreentax`][index],
                            },
                        }
                        partOneArr.push(obj);
                    }
                    this.arrHotelExtra = [];
                    this.arrHotelExtra =  this.reOrderList(partOneArr, this.keyExtraList);
                }
                else if(values[`${HOTELEXTRAPART}SelectInput`] !== undefined){
                    this.arrHotelExtra= partOneArr;
                }
            }
        });
    },
    getHighItemsList(value){
        this.highItemList = value
    },
    getHighGroupphotosList(value){
        this.highGroupphotoList = value
    },
    getHighlightList: async function (){
        const { hotelHighlightForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${HOTELHIGHLIGHTPART}Title`] !== undefined){
                    (values[`${HOTELHIGHLIGHTPART}Title`]).forEach((item, index) => {
                        
                        this.$refs._highlightlist.getItems(index);
                        this.$refs._highlightlist.getGroupphotos(index);
                        const obj = {
                            title:item,
                            items:this.highItemList,
                            groupphotos: this.highGroupphotoList,
                        }
                        partOneArr.push(obj);
                    })
                    this.arrHotelHighlight = partOneArr;
                }
                else if(values[`${HOTELHIGHLIGHTPART}SelectInput`] !== undefined){
                    this.arrHotelHighlight= partOneArr;
                }
            }
        });
    },

    onChangeSearchDate(date, dateString) {
        this.searchStartDate = dateString[0];
        this.searchEndDate = dateString[1];
    },
    
    showSearchCountPicker(isShow){
        this.visibleSearchCountPicker = isShow;
        if (isShow)
        this.searchRoomInfoID = this.searchRoomInfo.length;
    },
    
    removeSearchRoomInfo(_index){
        this.searchRoomInfo.splice(_index, 1);
    },
    editSearchRoomInfo(_index){
        this.visibleSearchCountPicker = true;
        this.searchRoomInfoID = _index;
        this.visibleRoomList = false;
    },
    gotoHotelSearch(){
      if (this.searchStartDate == null || this.searchEndDate == null || this.searchStartDate == this.searchEndDate){
        this.$message.error('Please select date range correctly!');
        return
      }
      if (this.searchRoomInfo.length <= 0){
        this.$message.error('Please enter search informations!');
        return;
      }
      if (this.searchRoomInfo.filter(obj => obj.searchAdultCount == 0).length > 0){
        this.$message.error('Please enter search informations!');
        return;
      }
      var itwxSearchRoomInfoList = [];
      for (let i = 0; i < this.searchRoomInfo.length; i++){
        var adultList = [];
        for (var aIndex = 0; aIndex < this.searchRoomInfo[i].searchAdultCount; aIndex++ ){
          adultList.push(
            {
              Age:25
            }
          )
        }
        var childList = [];
        for (var cIndex = 0; cIndex < this.searchRoomInfo[i].searchChildCount; cIndex++ ){
          childList.push(
            {
              Age:this.searchRoomInfo[i].searchChildAge[cIndex]
            }
          )
        }

        itwxSearchRoomInfoList.push(
          {
            Adult:adultList,
            Child:childList
          }
        )
      }
      
      const fromdate = new Date(this.searchStartDate);
      const todate = new Date(this.searchEndDate);
      
      const diffTime = Math.abs(todate - fromdate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      var itwxSelectedHotel = [];
      itwxSelectedHotel.push(this.selectedHotel.hotelcode)
      var itwxData = {
        fromdate: this.searchStartDate.replaceAll('-',''),
        todate: this.searchEndDate.replaceAll('-',''),
        roominfo: JSON.stringify(itwxSearchRoomInfoList),
        page:1,
        pagecount:100,
        selectedHotel: itwxSelectedHotel
      }
      this.itwxSearchRoomList = [];
      this.searchItwxHotel(itwxData, diffDays);
    },
    searchItwxHotel:async function(itwxData, diffDays){
      const res = await api.searchItwxHotel(itwxData)
      if (res!= undefined && res.hotelList!= undefined && res.hotelList.length > 0){
        this.itwxSearchRoomList = res.hotelList[0].RoomTypeDetails.Rooms.Room;
      }
    },
    
    getCountInfo(_info){
      this.searchRoomInfoID = _info.searchRoomInfoID;
      this.searchRoomInfo.splice(
        this.searchRoomInfoID, 1,
        {
          searchAdultCount : _info.searchAdultCount,
          searchChildCount : _info.searchChildCount,
          searchChildAge : _info.searchChildAge
        }
      )
      this.visibleSearchCountPicker = false;
    },


  },
};
</script>
<style>
.ant-tabs-nav-scroll {
    margin-left: 0;
    margin-right: auto;
}
.ant-tabs-nav-wrap{
    display: flex;
}
</style>

<style>
.ant-divider-horizontal {
    margin: 10px 0;
}
</style>