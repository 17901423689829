<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%;">
            <draggable v-model="keysListInspiration" chosenClass="chosen" forceFallback="false" group="people" animation="1000" filter="aaa" @start="onStart" @end="onEnd" :options="{handle :'div.item'}">
                <transition-group>
                    <div style="background-color:#f8f8f8;padding:5px;border:1px solid #dddddd;border-bottom:none" v-for="item, index in keysListInspiration" :key="item">
                        <a-row style="margin:0" :gutter="24">
                            <div style="display:flex">
                                <div v-if="true" class="item" style="width:50px;height: inherit;display:flex;color:#000000a6">
                                    <div style="margin:auto">{{index + 1}}</div>
                                </div>
                                <div style="width:100%">
                                  <inspiration-content-item
                                    :title="title"
                                    :arr="arr"
                                    :item="item"
                                  ></inspiration-content-item>
                                    
                                </div>
                                <div style="width: 25px;margin-left:0;margin-top:auto;margin-bottom:auto">
                                    <a-col :span="2" style="padding-left: 3px">
                                        <a-form-item :labelCol="{span: 0}" :wrapperCol="{span: 24}">
                                            <template v-if="keysListInspiration.length > 0">
                                                <a-button shape="circle" type="solid" icon="minus" @click="removeRowInspiration(item)" class="minusRowBtn" :size="'small'"></a-button>
                                            </template>
                                        </a-form-item>
                                    </a-col>

                                </div>
                            </div>
                        </a-row>
                    </div>
                </transition-group>
            </draggable> 
            <div v-if="keysListInspiration.length > 0" style="width:100%;height:1px;background-color:#dddddd"></div>
                
            <div style="display:flex;margin-top:10px">
                <a-button style="margin-left:auto;margin-right:0" :style="{ background: '#0071a1',border:'none'}" @click="addRowInspiration" type="primary">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
var moment = require('moment-timezone');
import draggable from 'vuedraggable'
import InspirationContentItem from './InspirationContentItem.vue';

const typeList = ['Text', 'Hyper Link', 'Image']
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  components: {
    draggable,
    InspirationContentItem
  },
  data () {
    return {
        typeList,
         
        idInspiration: 0,
        keysListInspiration: [],
      

    }
  },
  created () {
    this.init()
  },
  methods: {
    moment,
    init () {
      const arrTemp = []
      
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idInspiration = 0;
        for (let i = 0; i < (this.arr).length; i++) {
          arrTemp.push(i)
          this.idInspiration = this.idInspiration + 1
        }
      }
      this.keysListInspiration = arrTemp
    },
    
    callback(key) {
      // console.log(key);
    },
    onChange(){

    },

    removeRowInspiration (k) {
      if (this.keysListInspiration.length === 0) { 
        return
      }
      this.keysListInspiration = this.keysListInspiration.filter(item => item !== k)
    },
    addRowInspiration () {
      this.keysListInspiration = this.keysListInspiration.concat(this.idInspiration)
      this.idInspiration = this.idInspiration + 1
    },

    
    onStart(){
        // console.log("======= onStart");
        this.drag=true;
    },
    onEnd() {
        // console.log("======= onEnd", this.keysListInspiration);
        this.drag=false;
    },

    
    getKeyList(){
        this.$emit('getKeyList', this.keysListInspiration);
    },
  }
}
</script>
<style>
.textInput{
    height: 35px;
    border-radius: 5px;
    border: 1px #d7d7d7 solid;
}
.textInput:focus{
    height: 35px;
    border-radius: 5px;
    border: 1px #8d8d8d solid;
    outline: none;
}
.textAreaInput{
    border-radius: 5px;
    border: 1px #d7d7d7 solid;
    line-height: 20px;
}
.textAreaInput:focus{
    border-radius: 5px;
    border: 1px #8d8d8d solid;
    outline: none;
}
        .item {
            padding: 6px;
            background-color: #fdfdfd;
            border: solid 1px #eee;
            cursor: move;
        } 
        /*选中样式*/
        .chosen {
            border: solid 2px #3089dc !important;
        }
</style>