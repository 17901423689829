<template>
    <div class="adduser" style="min-height:85vh;overflow-x: hidden;position: relative;">
        <div style="display:flex">
            <div style="margin: 16px 0;display:flex">
                <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                    <a-breadcrumb-item style="font-size:30px;">Rooms</a-breadcrumb-item>
                    <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
                </a-breadcrumb>
                <a-button style="margin-top:auto;margin-bottom:auto;margin-left:20px" type="primary">
                    <router-link to="/admin/addroom">
                    Add Room
                    </router-link>
                </a-button>
            </div>
        </div>
        <div v-if="isLoading" style="position: absolute;left: 50%;z-index: 3;margin-left:-50px;display: inline-flex;text-align: center;position: absolute;top:190px;border-radius: 4px;">
            <!-- <a-spin size="large" style="z-index: 1;"/> -->
            <LoadingBar />
        </div>
        
        <div style="position:relative">
          <div style="position:absolute;right:0;top:-60px;display:flex;">
            <a-input-search style="width:400px;margin-right:0px;margin-left:auto" v-model="roomSearchText" placeholder="" @search="onSearch">
              <a-button slot="enterButton">
                Search Room
              </a-button>
            </a-input-search>
          </div>
          <div style="position:absolute;right:0;top:-25px;display:flex;width:400px;">
            <a-select v-model="searchHotel" style="height:35px;margin-bottom:5px;width:300px;">
              <a-select-option v-for="_hotel in hotelList" :key="_hotel._id" :value="_hotel._id">
                <div style="display:flex">
                  <div style="margin-left:5px;margin-top:auto;margin-bottom:auto;overflow-wrap: break-word;white-space: break-spaces;">{{ _hotel.hotelname }}</div>
                </div>
              </a-select-option>
            </a-select>
            <a-button @click="onSearchByHotel">
              Search Room By Hotel
            </a-button>
          </div>
          <div style="display:flex">
              <a-tabs
                  default-active-key="1"
                  :tab-position="'top'"
                  >
                  <a-tab-pane key="1" tab="All">
                      <RoomsTableItem :key="tableCellCount" :roomlist ="roomlist" :columns="columns" @listUpdate="listUpdate" :tableCellCount="tableCellCount" @listFilter="listFilter" @listOrder="listOrder" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="Mine" force-render>
                      <RoomsTableItem :key="tableCellCount" :roomlist ="minelist" :columns="columns" @listUpdate="listUpdate" :tableCellCount="tableCellCount" @listFilter="listFilter" @listOrder="listOrder" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="3" tab="Published">
                      <RoomsTableItem :key="tableCellCount" :roomlist ="publishedlist" :columns="columns" @listUpdate="listUpdate" :tableCellCount="tableCellCount" @listFilter="listFilter" @listOrder="listOrder" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="4" tab="Draft">
                      <RoomsTableItem :key="tableCellCount" :roomlist ="draftlist" :columns="columns" @listUpdate="listUpdate" :tableCellCount="tableCellCount" @listFilter="listFilter" @listOrder="listOrder" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
              </a-tabs>
          </div>
        </div>
    </div>
</template>
<script>
import RoomsTableItem from './TableItems/RoomsTableItem.vue'
import { api } from '../helpers/Helpers';
import { common } from '../utils/common';
import LoadingBar from '@/components/LoadingBar.vue';
const columns = [
  {
    title: 'Name',
    dataIndex: 'roomname',
    sorter: true,
    key: 'roomname',
    scopedSlots: { customRender: 'roomname' },
  },
  {
    title: 'Image',
    dataIndex: 'roomimage',
    key: 'roomimage',
    scopedSlots: { customRender: 'roomimage' },
  },
  // {
  //   title: 'Base Price',
  //   dataIndex: 'baseprice',
  //   key: 'baseprice',
  // },
  {
    title: 'Hotel',
    key: 'hotelname',
    sorter: true,
    dataIndex: 'hotelname',
  },
  {
    title: 'Tags',
    key: 'roomtags',
    dataIndex: 'roomtags',
  },
  {
    title: 'Date',
    key: 'createdate',
    sorter: true,
    dataIndex: 'createdate',
    scopedSlots: { customRender: 'createdate' },
  },
  {
    title: 'Api Integrate',
    key: 'apiintegrate',
    dataIndex: 'apiintegrate',
    sorter: true,
    scopedSlots: { customRender: 'apiintegrate' },
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
  },
  {
    title: 'Number',
    key: 'numberofroom',
    sorter: true,
    dataIndex: 'numberofroom',
  },
  {
    title: 'SEO Score',
    key: 'seoscore',
    dataIndex: 'seoscore',
  },
  {
    title: 'ReadScore',
    key: 'readscore',
    dataIndex: 'readscore',
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];
const roomlist = [];
export default {
    
  data() {
    return {
      allroomlist:[],
      minelist:[],
      publishedlist:[],
      draftlist:[],
      roomlist,
      hotelList:[
        {
          _id:'0',
          hotelname:'All'
        }
      ],

      columns,
      roomSearchText:"",
      tableCellCount:10,

      isLoading : false,
       
      searchHotel:'0',
    };
  },
  components: {
    LoadingBar,
    RoomsTableItem
  },
  
  mounted(){
      this.getRooms();
      this.getHotels();
  },
  methods: {
      
    
    onSearch(value) {
      // console.log(value);
      var _tempList = []
      for (let i = 0; i < this.allroomlist.length; i++) {
          if (this.allroomlist[i].roomname.toLowerCase().includes(this.roomSearchText.toLowerCase()) || this.allroomlist[i].hotelname.toLowerCase().includes(this.roomSearchText.toLowerCase()))
            _tempList.push(this.allroomlist[i])
      }
      this.roomlist = _tempList;
      this.updateRoomList();
    },
    onSearchByHotel() {
      // console.log(this.searchHotel);
      var _tempList = []
      if (this.searchHotel == "0"){
        for (let i = 0; i < this.allroomlist.length; i++) {
          _tempList.push(this.allroomlist[i])
        }
      }
      else{
        for (let i = 0; i < this.allroomlist.length; i++) {
            if (this.allroomlist[i].hotel == this.searchHotel)
              _tempList.push(this.allroomlist[i])
        }
      }
      this.roomlist = _tempList;
      this.updateRoomList();
    },
    listUpdate(){
       this.getRooms();
    },
    listFilterCount(_count){
      // console.log(_count);
      this.tableCellCount = _count;
    },
    listFilter(date, seoscore, readscore){
      var _tempList1 = [];
      var _tempList2 = [];
      var _tempList3 = [];
      if (date == 'all')
        _tempList1 = this.allroomlist;
      else{
        const selMonth = new Date(date).getMonth();
        const selYear = new Date(date).getFullYear();
        for (let i = 0; i < this.allroomlist.length; i++) {
          const tarMonth = new Date(this.allroomlist[i].createdate).getMonth();
          const tarYear = new Date(this.allroomlist[i].createdate).getFullYear();
          if (tarYear == selYear && tarMonth == selMonth)
            _tempList1.push(this.allroomlist[i])
        }
      }

      if (seoscore == 'all')
        _tempList2 = this.allroomlist;
      else{
        for (let i = 0; i < this.allroomlist.length; i++) {
          if (this.allroomlist[i].seoscore == seoscore)
            _tempList2.push(this.allroomlist[i])
        }
      }

      if (readscore == 'all')
        _tempList3 = this.allroomlist;
      else{
        for (let i = 0; i < this.allroomlist.length; i++) {
          if (this.allroomlist[i].readscore == readscore)
            _tempList3.push(this.allroomlist[i])
        }
      }
      
      this.roomlist = common.commonElementsOfArray(_tempList1, _tempList2, _tempList3);
    },
    listOrder(_field, _order){
      if (_field == 'roomname'){
        if (_order == "ascend"){
          this.roomlist.sort(function(a,b) {return (a.roomname > b.roomname) ? 1 : ((b.roomname > a.roomname) ? -1 : 0);} );
        }
        else if(_order == "descend"){
          this.roomlist.sort(function(a,b) {return (a.roomname < b.roomname) ? 1 : ((b.roomname < a.roomname) ? -1 : 0);} );
        }
      }
      this.updateRoomList();
    },
    
    getHotels:async function(){
      this.hotelList = [];
      this.hotelList.push({
        _id:'0',
        hotelname:'All'
      });
      const res = await api.getHotelsTotal();
      res.sort(function(a,b) {
        let aValue = a['hotelname'];
        let bValue = b['hotelname'];
        return (aValue > bValue) ? 1 : ((bValue > aValue) ? -1 : 0);
        } 
      );
      this.hotelList = this.hotelList.concat(res);
      // console.log("getHotels:", this.hotelList);
    },
    getRooms:async function(){
      
        this.isLoading = true;
        const res = await api.getRoomsTotal();

        // for (let i = 0; i < res.length; i++){
        //   if(res[i].roomfloorplan == undefined || res[i].roomfloorplan == '')
        //     continue;
        //   const resRoom = await api.updateRoom(res[i]._id, {roomfloorplans:[res[i].roomfloorplan]});
        //   // console.log(resRoom, "-resRoom--")

        // }
        
        // console.log("getRooms:",res);
        this.getRoomResult(res);
    },
    getRoomResult(res){
      // console.log("getRooms:",res);
      this.allroomlist = res;
      this.roomlist = res;
      this.updateRoomList();
    },
    updateRoomList(){
      var resMineList = [];
      var resPublishedList = [];
      var resDraftList = [];
      for (let i = 0; i < this.roomlist.length; i++) {
          if (this.roomlist[i].userid == "MineUserId")
              resMineList.push(this.roomlist[i])
          if (this.roomlist[i].status == 1)
              resPublishedList.push(this.roomlist[i])
          if (this.roomlist[i].status == 0)
              resDraftList.push(this.roomlist[i])
      }
      this.minelist = resMineList; 
      this.publishedlist = resPublishedList;
      this.draftlist = resDraftList;
      
      this.isLoading = false;
    },
    
  }
}
</script>