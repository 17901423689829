<template>
    <div style="min-height:85vh">
        <div style="margin: 16px 0;display:flex">
            <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                <a-breadcrumb-item style="font-size:30px;">Hotel-Rooms Price</a-breadcrumb-item>
                <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
            </a-breadcrumb>
        </div>
        <div style="display:flex">
            <a-select style="margin-top:auto;margin-bottom:auto;width:500px" placeholder="Select Hotel" v-model="selectedHotelId" @change="handleChangeHotel">
                <a-select-option v-for="item in allhotellist" :key="item._id" :value="item._id">
                    {{ item.hotelname }}
                </a-select-option>
            </a-select>
        </div>
        <div style="display:flex;margin-top:10px">
            <div v-show="selectedHotelId != ''" style="text-decoration-line: underline;cursor:pointer;" @click="gotoHotelEdit">Go to Edit Hotel page</div>
        </div>
        <!-- <div style="display:flex;width:100%;margin-top:10px"> -->
        <a-form style="display:flex;width:100%;margin-top:10px" :form="hotelRoomsPriceForm">
            <a-table
                :columns="columns"
                :data-source="bookingList"
                bordered
                size="middle"
                :pagination="false"
                :scroll="{ x: roomCellWidth, y: 0 }"
                :style="{width : '100%'}"
            >
                <span slot="action" slot-scope="text, record">
                    <a @click="DeleteOne(record.no)">Delete</a>
                </span>
                <span slot="from" slot-scope="text, record, index">
                    <a-form-item style="margin-bottom: 0px;">
                        <!-- :default-value="moment(record.from, dateFormatList[0])" -->
                        <a-date-picker 
                            :showToday="false"
                            valueFormat="YYYY-MM-DD"
                            v-decorator="[
                                    `${TABLENAME}fromDate[${index}]`,
                                    {
                                        initialValue: moment(record.from).utc().format('YYYY-MM-DD'),//record.from.toString().split('T')[0],
                                        rules: [{ required: true, message: 'Input fromDate !' }]
                                    }
                                ]"
                            >
                            <!-- <template slot="dateRender" slot-scope="current">
                                <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                    {{ moment(current).utc().date() }}
                                </div>
                            </template> -->
                        </a-date-picker>
                    </a-form-item>
                </span>
                <span
                    v-for="item in selectedRoomList"
                    :slot="'roomTitle' + item.id"
                    :key="item.key"
                >
                   <div style="text-decoration-line: underline;color:#1890ff;cursor: pointer;" @click="clickRoomHeader(item.id)" v-on:click.middle="clickRoomHeader(item.id)">{{ item.name }}</div> 
                </span>
                <span slot="to" slot-scope="text, record, index">
                    <a-form-item style="margin-bottom: 0px;">
                        <a-date-picker 
                            :showToday="false"
                            valueFormat="YYYY-MM-DD"
                            v-decorator="[
                                    `${TABLENAME}toDate[${index}]`,
                                    {
                                        initialValue:  moment(record.to).utc().format('YYYY-MM-DD'),//record.to.toString().split('T')[0],
                                        rules: [{ required: true, message: 'Input toDate !' }]
                                    }
                                ]"
                            >
                            <!-- <template slot="dateRender" slot-scope="current">
                                <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                    {{ moment(current).utc().date() }}
                                </div>
                            </template> -->
                        </a-date-picker>
                    </a-form-item>
                </span>
                
                <span
                    v-for="(item) in selectedRoomList"
                    :slot="'Room' + item.id"
                    :key="item.key"
                    slot-scope="text,recode,index"
                >
                    <a-form-item style="margin-bottom: 0px;">
                        <a-input-number style="width:180px" placeholder="" 
                            v-decorator="[
                                    `${TABLENAME}room${item.id}[${index}]`,
                                    {
                                        initialValue: text? text:0,
                                        rules: [{ required: true, message: 'Input room price !' }]
                                    }
                                ]"
                            />
                    </a-form-item>
                </span>
            </a-table>
        </a-form>
        <div style="display:flex">
            <a-button style="margin-top:10px;" @click="addBookingData" size="large">
                Add Booking Data
            </a-button>
        </div>
        <div style="display:flex">
            <div style="margin-top:20px">
                <!-- <div style="display:flex;margin-bottom:5px">
                    <div style="width:150px;text-align:left;margin-top:auto;margin-bottom:auto">Regular Price</div>
                    <a-input-number v-model="hoteldatageneralInfo.reqularprice" style="width:200px" placeholder="" />
                </div> -->
                <div style="display:flex;margin-bottom:5px">
                    <div style="width:150px;text-align:left;margin-top:auto;margin-bottom:auto">Published Rate</div>
                    <a-input-number v-model="hoteldatageneralInfo.publishedrate" style="width:200px" placeholder="" />
                </div>
                <div style="display:flex;margin-bottom:5px">
                    <div style="width:150px;text-align:left;margin-top:auto;margin-bottom:auto">Green Tax</div>
                    <a-input-number v-model="hoteldatageneralInfo.greentax" style="width:200px" placeholder="" />
                </div>
                <div style="display:flex;margin-bottom:5px">
                    <div style="width:150px;text-align:left;margin-top:auto;margin-bottom:auto">Service Charges</div>
                    <a-input-number v-model="hoteldatageneralInfo.servicecharges" style="width:200px" placeholder="" />
                </div>
                <div style="display:flex;margin-bottom:5px">
                    <div style="width:150px;text-align:left;margin-top:auto;margin-bottom:auto">GST</div>
                    <a-input-number v-model="hoteldatageneralInfo.gst" style="width:200px" placeholder="" />
                </div>
                <div style="display:flex;margin-bottom:5px">
                    <div style="width:150px;text-align:left;margin-top:auto;margin-bottom:auto">Commission</div>
                    <a-input-number v-model="hoteldatageneralInfo.commission" style="width:200px" placeholder="" />
                </div>
                <div style="display:flex;margin-bottom:5px">
                    <div style="width:150px;text-align:left;margin-top:auto;margin-bottom:auto">Rate Calculation Formula</div>
                    
                    <a-select v-model="hoteldatageneralInfo.calculationfomula" style="width:200px" placeholder="Select Fomula" >
                        <a-select-option v-for="item in formulaList" :key="item.key" :value="item.key">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </div>
                <div style="font-size:12px">You can use variables PRICE, PUBLISHED_RATE, GREEN_TAX, SERVICE_CHARGES, GST, ADULT, CHILD, NIGHTS</div>

            </div>
            <div style="display:flex;margin-right:0px;margin-left: auto;">
                <a-button style="margin:auto;margin-rignt:10px;margin-bottom:10px" @click="saveBookingData" size="large"  type="primary">
                    Save
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import { api } from '../helpers/Helpers';
var moment = require('moment-timezone');
const TABLENAME = 'tableName'
const columns = [
  {
    title: 'No',
    fixed: 'left',
    // width: 60,
    // children: [
    //     {
    //         title: '',
            dataIndex: 'no',
            key: 'no',
            width: 50,
    //     },
    // ],
  },
  {
    title: 'Booking Date',
            width: 400,
    children: [
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
            width: 200,
            scopedSlots: { customRender: 'from' },
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
            width: 200,
            scopedSlots: { customRender: 'to' },
        },
      
    ],
  },
  {
    title: 'Hotel Rooms',
    children: [],
  },
  {
    title: '',
    fixed: 'right',
    // width: 50,
    // children: [
    //     {
            title: '',
            key: 'action',
            width: 80,
            scopedSlots: { customRender: 'action' },
    //     }
    // ]
  },
];
const bookingList = [];
// for (let i = 0; i < 8; i++) {
//   bookingList.push({
//     key: i,
//     no: i + 1,
//     from: '01/01/2022',
//     to: '08/01/2022',
//     room0: 1000 + i,
//     room1: 2000,
//     room3: 1000 + i,
//     room4: 2000,
//   });
  
// }
const roomCellUnit = 250;
export default {
    
  data() {
    return {
        TABLENAME,
        
        hotelRoomsPriceForm: this.$form.createForm(this),
        // hotelList: ['hotel1', 'hotel2'],
        allhotellist: [],
        selectedHotelId:'',
        selectedHotel:{
            hoteldatageneral:{
                reqularprice:this.reqularprice,
                publishedrate:this.publishedrate,
                greentax:this.greentax,
                servicecharges:this.servicecharges,
                gst:this.gst,
                commission:this.commission,
                calculationfomula:this.calculationfomula,
                starrate:4
            },
        },
        hoteldatageneralInfo:{},
        selectedRooms:[],

        bookingList,
        columns,

        roomCellUnit,

        roomCellWidth: 'calc(1500px + 50%)',
        selectedRoomList:[],

        
        // dateFormat: 'YYYY/MM/DD',
        monthFormat: 'YYYY/MM',
        dateFormatList: ['YYYY/MM/DD', 'DD/MM/YY'],

        formulaList: [
            {
                key:"Formula1",
                name:"F1: rates not included with green tax"
            },
            {
                key:"Formula2",
                name:"F2: rates included with green tax"
            }
        ],

        
        // dateFormat : (val) => {
        //     return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
        // }
    }
  },

  created: function () {
      this.getHotelList();
    // this.getRoomList();
    this.updateUI();
  },

  methods: {
        moment,
        gotoHotelEdit(){
            this.$router.push({ path: 'edithotel', query: {id: this.selectedHotelId}})
        },
        DeleteOne(_id){
            let objIndex = this.bookingList.findIndex((obj => obj.no == _id));
            this.loadInputData();
            this.bookingList.splice(objIndex, 1);
            this.updateBookingList();
        },
        updateBookingList(){
            for(let i = 0; i < this.bookingList.length; i++){
                this.bookingList[i].no = i + 1;
                // this.bookingList[i].key = i;
            }
        },
        loadInputData(){
            const { hotelRoomsPriceForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    if (values[`${TABLENAME}fromDate`] !== undefined){
                        (values[`${TABLENAME}fromDate`]).forEach((item, index) => {
                            // // console.log(item);
                            // // console.log(index);
                            
                            for (let i = 0; i < this.selectedRooms.length; i++){
                                const cellRoomPrice = values[`${TABLENAME}room${i}`][index];
                                this.bookingList[index]["room" + i] = cellRoomPrice;
                                this.bookingList[index]["from"] = moment.utc(item).tz("America/Los_Angeles");
                                this.bookingList[index]["to"] = moment.utc(values[`${TABLENAME}toDate`][index]).tz("America/Los_Angeles");
                                
                            }
                        })
                    }
                }
            });
        },
        getLastKey(){
            let _key = 0;

            for(let i = 0; i < this.bookingList.length; i++){
                this.bookingList[i].no = i + 1;
                if (_key < this.bookingList[i].key)
                    _key = this.bookingList[i].key;
            }
            return _key;
        },
        addBookingData(){
            if (this.selectedHotelId == '')
                return;
            const currentDate = new Date();
            const newData = {
                key: this.getLastKey() + 1,
                no: 0,
                from: currentDate,
                to: currentDate,
            };
            for (let i = 0; i < this.selectedRooms.length; i++){
                newData['room' + i] = 0;//this.selectedRooms[i].baseprice;
            }
            this.bookingList.push(newData);
            this.updateBookingList();
        },
        saveBookingData(){
            if (this.selectedHotelId == '')
                return;
            // console.log("-==",this.bookingList)
            const { hotelRoomsPriceForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    let partOneArr = [];
                    if (values[`${TABLENAME}fromDate`] !== undefined){
                        (values[`${TABLENAME}fromDate`]).forEach((item, index) => {
                            // console.log(item)
                            // console.log(index)
                            const obj = {
                                fromDate: moment.utc(item).tz("America/Los_Angeles"),
                                toDate: moment.utc(values[`${TABLENAME}toDate`][index]).tz("America/Los_Angeles"),
                                roomPrice:'',
                                hotelid:''
                            }
                            let priceObj = {}
                            for (let i = 0; i < this.selectedRooms.length; i++){
                                const cellRoomPrice = values[`${TABLENAME}room${i}`][index];
                                // // console.log("+++",this.selectedRooms[i]._id)
                                // // console.log(cellRoomPrice)
                                // // console.log(this.hoteldatageneralInfo)
                                // // console.log(this.selectedHotel)
                                
                                let resCalc = {}

                                // cellRoomPrice = cellRoomPrice * this.hoteldatageneralInfo.publishedrate;
                                // // console.log(cellRoomPrice * this.hoteldatageneralInfo.publishedrate)
                                // resCalc = this.calculateData(cellRoomPrice * this.hoteldatageneralInfo.publishedrate)

                                
                                // console.log("++++++",resCalc)
                                
                                priceObj[this.selectedRooms[i]._id] = cellRoomPrice
                                // {
                                    // mainPrice:cellRoomPrice * this.hoteldatageneralInfo.publishedrate,
                                    // initPrice:cellRoomPrice,
                                    // subPrice:resCalc
                                // };
                            }
                            obj.roomPrice = JSON.stringify(priceObj);
                            obj.hotelid = this.selectedHotelId;
                            // // console.log(JSON.parse(obj.roomPrice));
                            partOneArr.push(obj)
                        })
                    }
                    // console.log("----",partOneArr)
                    const lastInfo = {
                        hotelid: this.selectedHotelId,
                        datalist: partOneArr
                    }
                    this.sendBookingData(lastInfo);
                    this.saveHotelDataGeneralInfo();
                    // this.arrAdultChildNumber = partOneArr;
                }
            });
        },
        sendBookingData:async function(_array){
            
            const res = await api.addBookings(_array);
            // console.log("==== ",res)
            if (res != undefined && res != ""){
                this.$message.success('Saving Booking success!');
                this.$emit('close');
            }
            else{
                this.$message.error('Saving Booking failed!');
            }
        },
        saveHotelDataGeneralInfo:async function(){
            
            const res = await api.saveHotelDataGeneral(this.selectedHotelId, this.hoteldatageneralInfo);
            // console.log(res)
        },
        calculateData(_value){
            // this.selectedHotel
            let resultPrice = _value;
            let mainPrice = resultPrice;
            // resultPrice = resultPrice * this.hoteldatageneralInfo.publishedrate;
            
            // console.log(resultPrice)
            
            let greenTax = this.hoteldatageneralInfo.greentax;
            
            // resultPrice = resultPrice - greenTax;

            let gstValue = resultPrice - resultPrice / this.hoteldatageneralInfo.gst;
            resultPrice = resultPrice / this.hoteldatageneralInfo.gst;
            let serviceValue = resultPrice - resultPrice / this.hoteldatageneralInfo.servicecharges;
            resultPrice = resultPrice / this.hoteldatageneralInfo.servicecharges;
            let compressionValue = resultPrice - resultPrice * this.hoteldatageneralInfo.commission;
            resultPrice = resultPrice * this.hoteldatageneralInfo.commission;

            let resultObj = {}

            const _promotion = this.selectedHotel.promotion
            
                                // console.log("+++_promotion+++",_promotion.length)
            if (_promotion.length > 0){
                
                for(let i = 0; i < _promotion.length; i++){
                    ////   Booking engin

                    // const nights = _promotion[i].minnights;
                    // const persons = _promotion[i].person.personnumber;
                    // let cellPrice = resultPrice * nights;
                    // let mainPrice = cellPrice;
                    // let greenTax = persons * nights * this.hoteldatageneralInfo.greentax;
                    // cellPrice = cellPrice - greenTax;

                    // let gstValue = cellPrice - cellPrice / this.hoteldatageneralInfo.gst;
                    // cellPrice = cellPrice / this.hoteldatageneralInfo.gst;
                    // let serviceValue = cellPrice - cellPrice / this.hoteldatageneralInfo.servicecharges;
                    // cellPrice = cellPrice / this.hoteldatageneralInfo.servicecharges;
                    // let compressionValue = cellPrice - cellPrice * this.hoteldatageneralInfo.commission;
                    // cellPrice = cellPrice * this.hoteldatageneralInfo.commission;
                    ////

                    //// Offer 
                    
                    let cellPrice = resultPrice;
                    if (_promotion[i].discount != null && _promotion[i].discount != 0)
                        cellPrice = cellPrice * _promotion[i].discount
                    //
                    let extraAdultPrice = 0;
                    let extraChildPrice = 0;
                    if (this.hoteldatageneralInfo.calculationfomula == "Formula2"){
                        extraAdultPrice = 44;
                    }
                    else if (this.hoteldatageneralInfo.calculationfomula == "Formula3"){
                        extraChildPrice = 22;
                    }
                    else {//Formula1

                    }

                    resultObj[_promotion[i]._id] = {
                        mainPrice:mainPrice,
                        price:Math.round(cellPrice * 100) / 100,
                        extraAdult:extraAdultPrice,
                        extraChild:extraChildPrice,
                        greenTax:Math.round(greenTax * 100) / 100,
                        gstService:Math.round((gstValue + serviceValue) * 100) / 100,
                        compression:Math.round(compressionValue * 100) / 100,
                    }
                }
            }
            return resultObj;
        },
        getHotelList:async function(){
    
            const res = await api.getHotelsTotal();
            // console.log(res);
            res.sort(function(a,b) {
                let aValue = a['hotelname'];
                let bValue = b['hotelname'];
                return (aValue > bValue) ? 1 : ((bValue > aValue) ? -1 : 0);
                } 
            );
            this.allhotellist = res;
            
        },
        getRoomList(){
            this.selectedRoomList = []
            for (let i = 0; i < this.selectedRooms.length - 1; i++){
                const _info = {
                    // title: this.selectedRooms[i].roomname,
                    name: this.selectedRooms[i].roomname,
                    id:i,
                    dataIndex: 'room' + i,
                    key: 'room' + i,
                    width: 200,
                    slots: { title: 'roomTitle' + i },
                    scopedSlots: { customRender: 'Room' + i },
                } 
                this.selectedRoomList.push(_info);
            }
            if (this.selectedRooms.length <= 0)
                return;
            const lastIndex = this.selectedRooms.length - 1;
            const _info = {
                // title: this.selectedRooms[lastIndex].roomname,
                name: this.selectedRooms[lastIndex].roomname,
                id: lastIndex,
                dataIndex: 'room' + lastIndex,
                key: 'room' + lastIndex,
                slots: { title: 'roomTitle' + lastIndex },
                scopedSlots: { customRender: 'Room' + lastIndex },
            } 
            this.selectedRoomList.push(_info);

        },
        clickRoomHeader(_index){
            // window.location.href = '/admin/editroom?id=' + this.selectedRooms[_index]._id;
            window.open(`/admin/edithotel?id=${this.selectedRooms[_index]._id}`,"_blank");
        //   this.$router.push({ path: 'editroom', query: {id:this.selectedRooms[_index]._id}})
        },
        updateUI(){
            let columnRooms = this.columns[2];
            columnRooms.children = this.selectedRoomList;
            // this.columns.splice(2, 1, columnRooms);
            this.roomCellWidth = 'calc(' + roomCellUnit * this.selectedRoomList.length + 'px + 50%)'
        },

        handleChangeHotel(value) {
            this.bookingList = [];
            this.selectedHotel = this.allhotellist.find((obj => obj._id == value));
            // console.log(this.selectedHotel);
            this.hoteldatageneralInfo = this.selectedHotel.hoteldatageneral;
            this.getSelectedRooms();
            // this.getSelectedBookings();
        },
        getSelectedRooms:async function(){
            this.selectedRooms = [];
            const res = await api.getHotelRoomsNonItwx(this.selectedHotel._id);
            
                // console.log("getSelectedRooms==",res);
                
            res.sort(function(a,b) {
              let aValue = a['roomname'];
              let bValue = b['roomname'];
              return (aValue > bValue) ? 1 : ((bValue > aValue) ? -1 : 0);
              } 
            );

            this.selectedRooms = res;
            // console.log(this.selectedRooms);
            this.getRoomList();
            this.getSelectedBookings();
            this.updateUI();
        },
        getSelectedBookings:async function(){
            const res = await api.getBookingsByHotelID(this.selectedHotelId)
                // console.log("getSelectedBookings==",res);
            
            this.bookingList = [];
            if (res.length > 0){
                const _info = res[0].datalist;
                for (let i = 0; i < _info.length; i++){
                    const priceList = JSON.parse(_info[i].roomPrice)
                
                    const newData = {
                        key: i,
                        no: 0,
                        from: _info[i].fromDate,
                        to: _info[i].toDate,
                    };
                    for (let i = 0; i < this.selectedRooms.length; i++){
                        newData['room' + i] = priceList[this.selectedRooms[i]._id];//.initPrice;
                    }
                    this.bookingList.push(newData);
                }
                // console.log("this.bookingList==",this.bookingList);
                
                this.updateBookingList();
            }

        }

  },
}
</script>