<template>
    <div style="min-height:85vh">
        <div style="display:flex;margin-top:10px;">
            <a-button v-if="true" style="margin-left:5px" @click="showCreateFile">Add File</a-button>
            
            <a-modal
              title="Create File"
              :visible="visibleCreatFile"
              :confirm-loading="confirmLoading"
              @ok="handleOkFile"
              @cancel="handleCancelFile"
            >
                <a-radio-group v-model="valueTypeFile" @change="onChangeAddFileType">
                    <a-radio :style="radioStyle" :value="1">
                        Add to select folder
                    </a-radio>
                    <a-radio :style="radioStyle" :value="2">
                        Add new file
                    </a-radio>
                </a-radio-group>
                <input style="margin-top:10px" type='file' @change="readURL" ref="file" multiple/>
                <!-- <img id="blah" src="http://placehold.it/180" alt="your image" /> -->
            </a-modal>
            <a-button v-if="true" style="margin-left:5px" @click="showAddFolder">Add Folder</a-button>
            
            <a-modal
              title="Add Folder"
              :visible="visibleAddFolder"
              :confirm-loading="confirmLoading"
              @ok="handleOkAddFolder"
              @cancel="handleCancelAddFolder"
            >
                <a-radio-group v-model="valueTypeAddFolder" @change="onChangeAddFolderType">
                    <a-radio :style="radioStyle" :value="1">
                        Add to select folder
                    </a-radio>
                    <a-radio :style="radioStyle" :value="2">
                        Add new file
                    </a-radio>
                </a-radio-group>
                <!-- <input style="margin-top:10px" type='folder' @change="readURL" ref="file" multiple/> -->
                <input type="file" id="ctrl" ref="folder" @change="readURLAddFolder" webkitdirectory directory multiple/>
                <!-- <img id="blah" src="http://placehold.it/180" alt="your image" /> -->
            </a-modal>
            <a-button style="margin-left:5px" @click="showCreateFolder">Create Folder</a-button>
            <a-modal
                title="Create Folder"
                :visible="visible"
                :confirm-loading="confirmLoading"
                @ok="handleOk"
                @cancel="handleCancel"
            >
                <a-radio-group v-model="valueTypeFolder" @change="onChangeCreateFolderType">
                    <a-radio :style="radioStyle" :value="1">
                        Add to select folder
                    </a-radio>
                    <a-radio :style="radioStyle" :value="2">
                        Add new folder
                    </a-radio>
                </a-radio-group>
                <a-input style="margin-top:10px" placeholder="Folder Name" v-model="newFolderName" />
            </a-modal>
            
            <a-popconfirm title="Are you sure to Delete this Folder?" ok-text="Yes" cancel-text="No" @confirm="deleteSelectedFolder">
              
              <a-button v-if="true" style="margin-left:5px">Delete Folder</a-button>
            </a-popconfirm>
            <a-popconfirm title="Are you sure to Delete this Files?" ok-text="Yes" cancel-text="No" @confirm="deleteSelectedFiles">
              
              <a-button v-if="true" style="margin-left:5px">Delete Files</a-button>
            </a-popconfirm>
            <a-button style="margin-left:5px" @click="showRename">Rename Folder/File</a-button>
            <a-modal
                title="Rename Folder/File"
                :visible="visibleRename"
                :confirm-loading="confirmLoading"
                @ok="handleOkRename"
                @cancel="handleCancelRename"
            >
                <a-radio-group v-model="valueTypeRename" @change="onChangeRename">
                    <a-radio :style="radioStyle" :value="1">
                        Rename Selected Folder
                    </a-radio>
                    <a-radio :style="radioStyle" :value="2">
                        Rename Selected File
                    </a-radio>
                </a-radio-group>
                <a-input style="margin-top:10px" placeholder="Folder Name" v-model="newName" />
            </a-modal>

            <a-button v-if="ismulti" style="margin-left:5px" @click="clickSelectAll">Select/Unselect All</a-button>
            <a-button style="margin-left:auto;margin-right:5px" v-if="ismodal" @click="selectImages">Select File</a-button>

            
        </div>
        <div style="display:flex;margin-top:10px;min-height:85vh">
                <!-- draggable multiple-->
            <a-directory-tree style="text-align:-webkit-left;width:25%;border:1px #eeeeee solid" 
                :tree-data="treeData" 
                @dragenter="onDragEnter"
                @drop="onDrop"
                @dragend="dragEnd"
                draggable
                @select="onSelect" 
                @expand="onExpand">
            </a-directory-tree>
            <div style="position: relative;width:75%;border:1px #eeeeee solid;padding:10px;background-color:white;z-index: 1;">
              
                <div v-show="isLoading" style="position: absolute;left: 50%;z-index: 1;margin-left:-50px;display: inline-flex;text-align: center;position: absolute;top:190px;border-radius: 4px;">
                    <!-- <a-spin size="large" style="z-index: 1;"/> -->
                    <LoadingBar class="dssddfsd"/>
                </div>
              <drag-selector v-model="checkedList"
                @change="handleDragSelectorChange" class="drag-selector">
                <a-list :grid="{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 6 }" :data-source="itemImageList">
                    <a-list-item slot="renderItem" slot-scope="item">
                      <drag-selector-item class="drag-selector__item" :value="item" style="pointer-events: none;">
                        <div style="width:100%;border:1px #eeeeee solid;">
                          
                            <div style="display:flex;width:100%;">
                              <div style="height:150px;width:100%;display:flex;margin:auto;">
                              <!-- draggable="true" v-on:dragend="imageDragEnd(item.key)"  -->
                                <img style="object-fit: cover;width:100%;height:100%;" :src="item.key.substring(item.key.length - 4).toLowerCase() == '.pdf' ? '/images/pdfimage.png' : `https://storage.vuecollection.com/${item.key}`">
                              </div>
                            </div>
                            <div style="display:flex;height:100px">
                                <a-checkbox style="width:100%;height:100%;margin-left:2px;margin-right:auto;margin-top:2px; pointer-events: all !important;text-align:center;word-break: break-all;padding:3px" :defaultChecked="false" :checked="item.check" @change="onSelectImage($event,item.key)">
                                    {{item.title}}
                                </a-checkbox>
                                <!-- <div style="width:calc(100% - 20px)">
                                  <div style="margin-top:2px;word-wrap: break-word">{{item.title}}</div>
                                </div> -->
                            </div>
                        </div>
                      </drag-selector-item>
                    </a-list-item>
                </a-list>
              </drag-selector>
            </div>
        </div> 


        <!-- File Manager -->
        <!-- <form @submit.prevent="sendFile" action="multipart/form-data">
            <div>
                <label for="file">Upload File</label>
                <input type="file"
                ref="file"
                @change="selectFile">
            </div>
            <div>
                <button>Send</button>
            </div>
        </form>
        <button @click="createFolder">Create Folder</button>
        <button @click="deleteFile">Delete Folder</button> -->

    </div>
</template>
<script>
import { api } from '../helpers/Helpers';
import LoadingBar from './LoadingBar.vue';
const bucketName = 'maldive-storage';
export default {
    data() {
        return {
            file: "",
            visibleCreatFile: false,
            fileInfo: null,
            confirmLoading: false,
            newFolderName: "",
            visible: false,
            visibleRename: false,
            newName: "",
            renameFolderCount:0,
            renameFolderCount2:0,
            renameFileCount:0,
            renameFileCount2:0,

            visibleAddFolder: false,
            fileInfoFolder: null,
            parentFolderKeyList:{},
            
            selectedFolderId: "",
            selectedFolderSimpleId: "",
            folderList: [],
            treeData: [],
            expandedKeys: ["0-0-0", "0-0-1"],
            checkedKeys: ["0-0-0"],
            autoExpandParent: true,
            selectedKeys: [],
            checkedList: [],
            itemImageList: [],
            selectedNames: [],
            selectedImages: [],
            bucketName,
            newFileList: [],
            originalObjectsList: [],
            isLoading: false,

            valueTypeFile:1,
            valueTypeAddFolder:1,
            valueTypeFolder:1,
            valueTypeRename:1,
            radioStyle: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
            },
        };
    },
    props: {
        ismulti: {
            type: Boolean,
            default: true
        },
        ismodal: {
            type: Boolean,
            default: true
        },
        ispdf: {
            type: Boolean,
            default: false
        },
        rowIndex: {
            type: Number,
            default: 0
        }
    },
    created: function () {
        this.selectedFolderId = "";
        this.selectedFolderSimpleId = "";
        this.checkedList = [];
        this.itemImageList = [];
        this.selectedImages = [];
        this.newFolderName = "";
        this.newName = "";
        // this.createBucket();
        // this.getListBuckets();
        // this.deleteBucket('vue-maldive-bucket-234389');
        // this.getListObjectsInBucket('maldive-storage');
        this.getListObjectsInBucket(this.bucketName);
    },
    mounted: function () {
    },
    methods: {
        init() {
            // console.log("-----init---");
            this.checkedList = [];
            // this.itemImageList = [];
            this.selectedImages = [];
            this.folderList = [];
            this.newFolderName = "";
            this.newName = "";
            // this.createBucket();
            // this.getListBuckets();
            // this.deleteBucket('vue-maldive-bucket-234389');
            // this.getListObjectsInBucket('maldive-storage');
            this.getListObjectsInBucket(this.bucketName);
        },
        imageClick(_key) {
            // console.log("--------", _key);
            // console.log(_key.substring(_key.length - 4));
        },
        showCreateFile() {
            this.visibleCreatFile = true;
            this.valueTypeFile = 1;
            this.fileInfo = null;
            if (this.$refs.file != undefined) {
                this.$refs.file.type = "";
                this.$refs.file.type = "file";
            }
        },
        handleOkFile(e) {
            // // console.log(this.newFolderName);
            // this.createFile();
            if (this.fileInfo != null && this.fileInfo != undefined) {
                for (let i = 0; i < this.fileInfo.length; i++) {
                    const formData = new FormData();
                    let _name = this.bucketName;
                    if (this.valueTypeFile == 1 && this.selectedFolderId != "")
                        _name = _name + "/" + this.selectedFolderId;
                    formData.append("file", this.fileInfo[i]);
                    formData.append("name", _name);
                    formData.append("fileType", this.fileInfo[i].type);
                    this.sendFormData(formData, (i >= this.fileInfo.length - 1));
                }
                this.confirmLoading = true;
                setTimeout(() => {
                    this.visibleCreatFile = false;
                    this.confirmLoading = false;
                }, 2000);
            }
            else {
                this.$message.error("Please select Image!");
            }
        },
        handleCancelFile(e) {
            this.visibleCreatFile = false;
        },
        readURL() {
            var Images = this.$refs.file.files;
            // console.log("sendFile==", Images);
            this.fileInfo = Images;
        },


        sendFormData: async function (formData, _isRefresh) {
            const res = await api.uploadFileToBucket(formData);
            // console.log("sendFile==", res);
            if (res != undefined && _isRefresh)
                this.init();
        },
        //  Add Folder
        showAddFolder() {
            this.visibleAddFolder = true;
            this.valueTypeAddFolder = 1;
            this.fileInfoFolder = null;
            if (this.$refs.folder != undefined) {
                this.$refs.folder.type = "";
                this.$refs.folder.type = "file";
            }
        },
        handleOkAddFolder(e) {
            // // console.log(this.newFolderName);
            // this.createFile();
            
            if (this.fileInfoFolder != null && this.fileInfoFolder != undefined) {
                this.handleOkAddFolderMain();
                this.confirmLoading = true;
                setTimeout(() => {
                    this.visibleAddFolder = false;
                    this.confirmLoading = false;
                }, 2000);
            }
            else {
                this.$message.error("Please select Image!");
            }
        },
        handleOkAddFolderMain:async function() {
            this.parentFolderKeyList = {};
            this.isLoading = true;
            for (let i = 0; i < this.fileInfoFolder.length; i++) {
                var _currentParent = await this.createParentFolders(this.fileInfoFolder[i]);
                const formData = new FormData();
                let _name = this.bucketName;
                if (this.valueTypeAddFolder == 1 && _currentParent != "")
                    _name = _name + "/" + _currentParent;
                formData.append("file", this.fileInfoFolder[i]);
                formData.append("name", _name);
                formData.append("fileType", this.fileInfoFolder[i].type);
                await this.sendFormData(formData, (i >= this.fileInfoFolder.length - 1));
            }
            this.init();
        },
        handleCancelAddFolder(e) {
            this.visibleAddFolder = false;
        },
        readURLAddFolder() {
            var Images = this.$refs.folder.files;
            // console.log("sendFile==", Images);
            // console.log("this.selectedFolderId==", this.selectedFolderId);
            this.fileInfoFolder = Images;
        },
        createParentFolders:async function(_fileInfo){
            var prevParentKeyName = '';
            if (this.valueTypeAddFolder == 1 && this.selectedFolderId != "")
                prevParentKeyName = this.selectedFolderId;

            if (_fileInfo != undefined && _fileInfo.webkitRelativePath != undefined){
                var parentFolderNames = _fileInfo.webkitRelativePath.split('/');
                if (parentFolderNames.length > 2){

                    let _name = this.bucketName;
                    // if (this.valueTypeAddFolder == 1 && this.selectedFolderId != "")
                    //     _name = _name + "/" + this.selectedFolderId;

                    for (let i = 1; i < parentFolderNames.length - 1; i++){
                        var prevParentFullKeyName = _name
                        if (prevParentKeyName != ''){
                            prevParentFullKeyName = prevParentFullKeyName + '/' + prevParentKeyName;
                        }
                        var folderFullName = parentFolderNames.slice(0, i + 1).join("/");
                        // // console.log(parentFolderNames[i], "======folderFullName======", folderFullName, this.parentFolderKeyList[folderFullName]);
                        if (this.parentFolderKeyList[folderFullName] == undefined){
                            var parentFolderKey = await this.createParentFolderOne(prevParentFullKeyName, parentFolderNames[i]);

                            this.parentFolderKeyList[folderFullName] = parentFolderKey;
                            prevParentKeyName = parentFolderKey;
                        }
                        else{
                            prevParentKeyName = this.parentFolderKeyList[folderFullName];
                        }
                        if (prevParentKeyName.charAt(prevParentKeyName.length - 1) == '/'){
                            prevParentKeyName = prevParentKeyName.slice(0, -1);
                        }
                        // // console.log(i, "======prevParentKeyName==2222====", prevParentKeyName)
                    }
                    return prevParentKeyName;
                }
            }
            return prevParentKeyName;
        },
        
        createParentFolderOne: async function (_name, _folderName) {
            // // console.log("createParentFolderOne==", _folderName);
            const res = await api.createFolderToBucket({ foldername: _folderName, bucketname: _name });
            // // console.log("createParentFolderOne =res=", res.key);
            return res.key;
            // this.init();
        },

        ////   create folder
        showCreateFolder() {
            this.visible = true;
            this.valueTypeFolder = 1;
        },
        handleOk(e) {
            this.createFolder();
            this.confirmLoading = true;
            setTimeout(() => {
                this.visible = false;
                this.confirmLoading = false;
            }, 2000);
        },
        handleCancel(e) {
            this.visible = false;
        },
        // Rename
        showRename(){
            
            const selectedArray = this.selectedFolderId.split("/");
            const selectedMainName = selectedArray[selectedArray.length - 1];
            const selectedmainArray = selectedMainName.split("-@-");
            const selectedMainNameLast = selectedmainArray[selectedmainArray.length - 1];
            this.newName = selectedMainNameLast;
            this.visibleRename = true;
        },
        handleOkRename(e) {
            this.renameFolder();
            this.confirmLoading = true;
            setTimeout(() => {
                this.visibleRename = false;
                this.confirmLoading = false;
            }, 2000);
        },
        handleCancelRename(e) {
            this.visibleRename = false;
        },
        ////  ui functions
        onExpand(expandedKeys) {
            // if not set autoExpandParent to false, if children expanded, parent can not collapse.
            // or, you can remove all expanded children keys.
            this.expandedKeys = expandedKeys;
            this.autoExpandParent = false;
        },
        onCheck(checkedKeys) {
            this.checkedKeys = checkedKeys;
        },
        onSelect(selectedKeys, info) {
            // console.log("onSelect", selectedKeys[0]);
            const result = this.folderList.filter(item => item.key == selectedKeys[0]);
            if (result.length > 0) {
                this.selectedFolderId = result[0].totalKey;
                this.selectedFolderSimpleId = result[0].id;
                // this.folderIdForAction = result[0].id;
                this.itemImageList = [];
                this.getFileByFolder(result[0].id);
                this.selectedImages = [];
                this.checkedList = [];
            }
            this.selectedKeys = selectedKeys;
        },
        getFileByFolder(_key = "") {
            const result = this.newFileList.filter(item => item.parent == _key);
            this.itemImageList = [];
            this.itemImageList = result;
            this.itemImageList.forEach(object => {
                object.check = false;
            });
        },
        onDragEnter(info) {
            // console.log("onDragEnter", info);
            // expandedKeys 需要受控时设置
            // this.expandedKeys = info.expandedKeys
        },
        dragEnd(info) {
            // console.log("dragEnd", info);
        },
        onDrop(info) {
            // console.log("onDrop", info);
            if (info.dragNode != null){
                const dropKey = info.node.eventKey;
                const dragKey = info.dragNode.eventKey;
                const dropPos = info.node.pos.split('-');
                let oldParentKey = this.getParentKey(dragKey, this.treeData);
                // console.log("---11---",  dragKey, oldParentKey)
                // console.log(this.treeData)
                // return
                const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
                
                // console.log(dropPosition)
                const loop = (data, key, callback) => {
                data.forEach((item, index, arr) => {
                    if (item.key === key) {
                    return callback(item, index, arr);
                    }
                    if (item.children) {
                    return loop(item.children, key, callback);
                    }
                });
                };
                const data = [...this.treeData];

                // Find dragObject
                let dragObj;
                loop(data, dragKey, (item, index, arr) => {
                arr.splice(index, 1);
                dragObj = item;
                });
                if (!info.dropToGap) {
                // Drop on the content
                loop(data, dropKey, item => {
                    item.children = item.children || [];
                    // where to insert 示例添加到尾部，可以是随意位置
                    item.children.push(dragObj);
                });
                } else if (
                (info.node.children || []).length > 0 && // Has children
                info.node.expanded && // Is expanded
                dropPosition === 1 // On the bottom gap
                ) {
                loop(data, dropKey, item => {
                    item.children = item.children || [];
                    // where to insert 示例添加到尾部，可以是随意位置
                    item.children.unshift(dragObj);
                });
                } else {
                let ar;
                let i;
                loop(data, dropKey, (item, index, arr) => {
                    ar = arr;
                    i = index;
                });
                if (dropPosition === -1) {
                    ar.splice(i, 0, dragObj);
                } else {
                    ar.splice(i + 1, 0, dragObj);
                }
                }
                // console.log(this.treeData, data)
                this.treeData = data;
                this.dropNewPos = '';
                let newParentKey = this.getParentKey(dragKey, this.treeData);
                // console.log("---22---",  dragKey, newParentKey)
                
                let newPkey = '';
                if (newParentKey != ''){
                    let newPid= this.folderList.filter(item => item.key == newParentKey);
                    if (newPid.length > 0)
                        newPkey = newPid[0].totalKey;
                }
                
                let oldPkey = '';
                if (oldParentKey != ''){
                    let oldPid= this.folderList.filter(item => item.key == oldParentKey);
                    if (oldPid.length > 0)
                        oldPkey = oldPid[0].totalKey;
                }
                let myId = '';
                if (dragKey != ''){
                    let myPid= this.folderList.filter(item => item.key == dragKey);
                    if (myPid.length > 0)
                        myId = myPid[0].id;
                }

                // // console.log("---oldPid---",  newPid, oldPid)
                // console.log("---oldPkey---",  newPkey, oldPkey, myId)
                this.moveFolder(newPkey, oldPkey, myId);
                // if (this.dropNewPos != ''){
                //     let newPid= this.folderList.filter(item => item.key == this.dropNewPos);
                //     let dragId = this.folderList.filter(item => item.key == dragKey);
                // // console.log("---newPid---",  newPid, dragId)
                //     this.moveFolder(newPid, dragId)
                // }
            }
            else{
                this.dropNewPos = this.folderList.filter(item => item.key == info.node.eventKey);
                // this.moveFolder(info.node.eventKey, dragId[0].id)
            }
        },
        getParentKey(key, tree){
          let parentKey = '';
          // // console.log(tree.length)
          for (let i = 0; i < tree.length; i++){
            const node = tree[i];
            parentKey = this.intetorFun(node, key)
                // console.log('--intetorFun111--', parentKey);
            if (parentKey !== '')
              return parentKey;
          }
          
          return parentKey;
        },
        intetorFun(data, key){
          let _parentKey = '';
          if (data.children && data.children.length !== 0){            
            for (let i = 0; i < data.children.length; i++){
                var item = data.children[i];
                if (item.key === key){
                    _parentKey = data.key;
                    this.dropNewPos = data.key;
                    return _parentKey;
                } else{
                    _parentKey = this.intetorFun(item, key);
                    if (_parentKey !== '')
                    return _parentKey;
                }
            }
          }
          return _parentKey;
        },
        moveFolder(newPkey, oldPkey, myId){
            var allNewKey = '';
            var allOldKey = '';
            if (newPkey != ''){
                allNewKey = newPkey + '/' + myId;
            }
            else{
                allNewKey = myId;
            }
            if (oldPkey != ''){
                allOldKey = oldPkey + '/' + myId;
            }
            else{
                allOldKey = myId;
            }
            let _name = this.bucketName;
                // console.log('--moveFolder--', allNewKey, allOldKey, myId);
            
            this.renameFolderCount = 0;
            this.renameFolderCount2 = 0;
            for(let i = 0; i < this.originalObjectsList.length; i++){
                if (this.originalObjectsList[i].Key.includes(allOldKey)){
                    const oldkey = this.originalObjectsList[i].Key;                        
                    const newkey = oldkey.replace(allOldKey, allNewKey);
                // console.log('--oldkey--', oldkey, newkey);
                    this.renameFolderCount++;
                    this.renameFolderName(_name, oldkey, newkey);
                }                        
            }

        },

        handleDragSelectorChange(checkedList) {
            //   return;
            if (this.checkedList.length <= 1)
                return;
            for (let i = 0; i < this.itemImageList.length; i++) {
                this.itemImageList[i].check = false;
            }
            if (this.ismulti) {
                // if (this.checkedList.length > 1){
                this.selectedImages = [];
                this.selectedNames = [];
                // }
                for (let i = 0; i < this.checkedList.length; i++) {
                    let objIndex = -1;
                    objIndex = this.itemImageList.findIndex((obj => obj.key == this.checkedList[i].key));
                    if (objIndex == -1)
                        continue;
                    this.itemImageList[objIndex].check = true;
                    if (this.ismulti) {
                        if (this.selectedImages.includes(this.checkedList[i].key)) {
                        }
                        else {
                            this.selectedImages.push(this.checkedList[i].key);
                            let tempList = this.itemImageList;
                            tempList.forEach(object => {
                                if (object.key == this.checkedList[i].key) {
                                    this.selectedNames.push(object.name);
                                }
                            });
                        }
                    }
                }
                let _tempList = this.itemImageList;
                this.itemImageList = [];
                this.itemImageList = _tempList;
                this.checkedList = [];
            }
            else {
            }
        },
        onSelectImage(e, id) {
            let objIndex = this.itemImageList.findIndex((obj => obj.key == id));
            this.itemImageList[objIndex].check = e.target.checked;
            let tempList = this.itemImageList;
            this.itemImageList = [];
            this.itemImageList = tempList;
            if (this.ismulti) {
                if (!e.target.checked) {
                    const index = this.checkedList.findIndex((obj => obj.key == id));
                    if (index > -1) {
                        this.checkedList.splice(index, 1); // 2nd parameter means remove one item only
                    }
                }
                if (this.selectedImages.includes(id)) {
                    if (!e.target.checked) {
                        const index = this.selectedImages.indexOf(id);
                        if (index > -1) {
                            this.selectedImages.splice(index, 1); // 2nd parameter means remove one item only
                        }
                    }
                }
                else {
                    if (e.target.checked) {
                        this.selectedImages.push(id);
                        let tempList = this.itemImageList;
                        tempList.forEach(object => {
                            if (object.key == id) {
                                this.selectedNames.push(object.title);
                            }
                        });
                    }
                }
            }
            else {
                if (e.target.checked) {
                    let tempList = this.itemImageList;
                    tempList.forEach(object => {
                        if (object.key == id) {
                            object.check = true;
                            this.selectedNames = [object.title];
                        }
                        else {
                            object.check = false;
                        }
                        object.title = object.title;
                        object.key = object.key;
                    });
                    this.itemImageList = [];
                    this.itemImageList = tempList;
                    this.selectedImages = [id];
                }
            }
            // console.log(this.selectedImages);
        },
        clickSelectAll() {
            let _tempList = this.itemImageList;
            if (this.selectedImages.length > 0) {
                this.selectedImages = [];
                _tempList.forEach(object => {
                    object.check = false;
                });
            }
            else {
                this.selectedImages = [];
                _tempList.forEach(object => {
                    object.check = true;
                    this.selectedImages.push(object.key);
                    let tempList = this.itemImageList;
                    tempList.forEach(obj => {
                        if (obj.key == object.key) {
                            this.selectedNames.push(obj.title);
                        }
                    });
                });
            }
            this.itemImageList = [];
            this.itemImageList = _tempList;
        },
        deleteSelectedFolder() {
            if (this.selectedFolderSimpleId == "")
                return;
            const _name = this.bucketName;
            const _selectedList = this.originalObjectsList.filter(obj => obj.Key.includes(this.selectedFolderSimpleId));
            
            for (let i = 0; i < _selectedList.length; i++) {
                const _filename = _selectedList[i].Key;
                this.deleteSelectedOne(i, _selectedList.length, _filename, _name);
            }
            this.selectedFolderId = "";
            this.selectedFolderSimpleId = "";
            // this.init();
        },
        deleteSelectedOne: async function (_index, _length, _filename, _bucketname) {
            const res = await api.deleteObjecToBucket(_bucketname, _filename);
            // console.log("deleteSelectedFolder==", res);
            if (_index >= _length - 1) {
                this.init();
            }
        },
        deleteSelectedFiles() {
            const _name = this.bucketName;
            // const _selectedList = this.originalObjectsList.filter(obj => obj.Key.includes(this.selectedFolderSimpleId))
            for (let i = 0; i < this.selectedImages.length; i++) {
                const _filename = this.selectedImages[i];
                this.deleteSelectedOne(i, this.selectedImages.length, _filename, _name);
                // const res = await api.deleteObjecToBucket(_name, _filename);
                // // console.log("deleteSelectedFiles==", res);
            }
            // this.init();
        },
        selectImages() {
            let resultImageList = [];
            for (let i = 0; i < this.selectedImages.length; i++) {
                resultImageList.push(`https://storage.vuecollection.com/${this.selectedImages[i]}`);
            }
            this.itemImageList = [];
            if (this.ispdf)
                this.$emit("selectwithname", resultImageList, this.selectedNames);
            else
                this.$emit("selectImages", resultImageList, this.rowIndex);
            this.$emit("close");
        },
        ////////////////////////////////////////////////////////////
        createBucket: async function () {
            const res = await api.createBucket();
            // console.log("createBucket==", res);
            this.getListBuckets();
        },
        getListBuckets: async function () {
            const res = await api.getListBuckets();
            // console.log("getListBuckets==", res);
        },
        deleteBucket: async function (_name) {
            const res = await api.deleteBucket(_name);
            // console.log("deleteBucket==", res);
            this.getListBuckets();
        },
        getListObjectsInBucket: async function (_name) {
            this.isLoading = true;
            const res = await api.getListObjectsInBucket(_name);
            this.originalObjectsList = res.data;
            // console.log("deleteBucket==", res.data[0]);
            // console.log("deleteBucket==", res.data);
            this.changeToTreedata(res.data);
        },
        changeToTreedata(_originInfo) {
            let newFolderList = [];
            let keyNameList = [];
            this.newFileList = [];
            for (let i = 0; i < _originInfo.length; i++) {
                const parentArray = _originInfo[i].Key.split("/");
                // let totalKey = '';
                for (let j = 0; j < parentArray.length; j++) {
                    const _key = parentArray[j];
                    if (_key != "" && !keyNameList.includes(_key)) {
                        // if (totalKey == '')
                        //     totalKey = _key;
                        // else
                        //     totalKey = totalKey + '/' + _key;
                        const subParentArray = parentArray.slice(0, j + 1);
                        const totalKey = subParentArray.join("/");
                        let _index = _key.indexOf("-@-");
                        if (_index != -1)
                            _index += 2;
                        const _title = _key.slice(_index + 1);
                        let _parentKey = "";
                        if (j > 0) {
                            _parentKey = parentArray[j - 1];
                        }
                        if (j == parentArray.length - 1) {
                            this.newFileList.push({
                                key: _originInfo[i].Key,
                                title: _title,
                                parent: _parentKey
                            });
                        }
                        else {
                            newFolderList.push({
                                key: _key,
                                totalKey: totalKey,
                                title: _title,
                                parent: _parentKey
                            });
                        }
                        keyNameList.push(_key);
                    }
                }
            }
            this.treeData = [];
            this.treeData = this.arrayToTree(newFolderList);
            this.getFileByFolder(this.selectedFolderSimpleId);
            this.isLoading = false;
        },
        arrayToTree(list, pkey = "", key = "0") {
            var itemList = [];
            for (var i = 0; i < list.length; i++) {
                if (list[i].parent != undefined) {
                    if (list[i].parent == pkey) {
                        var newkey = key + "-" + itemList.length;
                        var keyInfo = {
                            key: newkey,
                            id: list[i].key,
                            totalKey: list[i].totalKey
                        };
                        this.folderList.push(keyInfo);
                        var childItem = {
                            title: list[i].title,
                            key: newkey,
                            id: list[i].key,
                            children: this.arrayToTree(list, list[i].key, newkey),
                        };
                        itemList.push(childItem);
                    }
                }
            }
            return itemList;
        },
        selectFile() {
            this.file = this.$refs.file.files[0];
        },
        sendFile: async function () {
            const formData = new FormData();
            let _name = this.bucketName;
            if (this.selectedFolderId != "")
                _name = _name + "/" + this.selectedFolderId;
            formData.append("file", this.file);
            formData.append("name", _name);
            const res = await api.uploadFileToBucket(formData);
            // console.log("sendFile==", res);
            this.init();
        },
        createFolder: async function () {
            let _name = this.bucketName;
            if (this.valueTypeFolder == 1 && this.selectedFolderId != "")
                _name = _name + "/" + this.selectedFolderId;
            // console.log("this.selectedFolderId==", this.selectedFolderId);
            const res = await api.createFolderToBucket({ foldername: this.newFolderName, bucketname: _name });
            // console.log("createFolder==", res);
            this.init();
        },
        deleteFile: async function () {
            const _name = this.bucketName;
            const _filename = "ooo/";
            const res = await api.deleteObjecToBucket(_name, _filename);
            // console.log("deleteFile==", res);
        },
        
        renameFolder () {
            let _name = this.bucketName;
            // console.log(this.selectedFolderId, this.valueTypeRename, "renameFolder==",this.originalObjectsList , this.originalObjectsList.length);
            if (this.valueTypeRename == 1){
                if (this.selectedFolderId == '')
                    return;
                
                const selectedArray = this.selectedFolderId.split("/");
                const selectedMainName = selectedArray[selectedArray.length - 1];
                const selectedmainArray = selectedMainName.split("-@-");
                const selectedMainNameLast = selectedmainArray[selectedmainArray.length - 1];
                selectedmainArray.splice(selectedmainArray.length - 1, 1, this.newName);
                const newMainName = selectedmainArray.join("-@-");
                // console.log(selectedMainName, "selectedArray==2", newMainName);
                this.renameFolderCount = 0;
                this.renameFolderCount2 = 0;
                for(let i = 0; i < this.originalObjectsList.length; i++){
                    if (this.originalObjectsList[i].Key.includes(this.selectedFolderId)){
                        const oldkey = this.originalObjectsList[i].Key;                        
                        const newkey = oldkey.replace(selectedMainName, newMainName);
                        this.renameFolderCount++;
                        this.renameFolderName(_name, oldkey, newkey);
                    }                        
                }
            }
            else{
                // console.log("selectedArray==2", this.selectedImages);
                this.renameFileCount = 0;
                this.renameFileCount2 = 0;
                for(let i = 0; i < this.selectedImages.length; i++){
                    this.renameFileCount++;
                    const selectedArray = this.selectedImages[i].split("/");
                    const selectedMainName = selectedArray[selectedArray.length - 1];
                    const selectedMainExtension = selectedMainName.slice(-4);
                    const selectedmainArray = selectedMainName.split("-@-");
                    const selectedMainNameLast = selectedmainArray[selectedmainArray.length - 1].slice(0, -4);
                    var lastName = this.newName + selectedMainExtension;
                    if (i == 0){
                        lastName = this.newName + selectedMainExtension;
                    }
                    else{
                        lastName = this.newName + ' (' + i + ')' + selectedMainExtension;
                    }
                    selectedmainArray.splice(selectedmainArray.length - 1, 1, lastName);
                    const newMainName = selectedmainArray.join("-@-");
                    const oldkey = this.selectedImages[i];                        
                    const newkey = oldkey.replace(selectedMainName, newMainName);
                    this.renameFileName(_name, oldkey, newkey);
                }

            }
            // if (this.valueTypeRename == 1 && this.selectedFolderId != "")
            //     _name = _name + "/" + this.selectedFolderId;
            // // console.log("this.selectedFolderId==", this.selectedFolderId);
            // const res = await api.createFolderToBucket({ foldername: this.newFolderName, bucketname: _name });
            // // console.log("createFolder==", res);
            // this.init();
        },
        renameFolderName: async function (_name, oldkey, newkey) {
            const res = await api.renameObjecToBucket(_name, oldkey, newkey);
            // console.log("renameObjecToBucket==", res);
            this.renameFolderCount2++;
            if (this.renameFolderCount <= this.renameFolderCount2)
                this.init();
        },
        renameFileName: async function (_name, oldkey, newkey) {
            const res = await api.renameObjecToBucket(_name, oldkey, newkey);
            // console.log("renameObjecToBucket==", res);
            this.renameFileCount2++;
            if (this.renameFileCount <= this.renameFileCount2)
                this.init();
        },

        onChangeAddFolderType(e) {
            // console.log('radio checked', e.target.value);
        },
        onChangeCreateFolderType(e) {
            // console.log('radio checked', e.target.value);
        },
        onChangeAddFileType(e) {
            // console.log('radio checked', e.target.value);
        },
        onChangeRename(e) {
            // console.log('radio checked', e.target.value);
            if (e.target.value == 1){
                const selectedArray = this.selectedFolderId.split("/");
                const selectedMainName = selectedArray[selectedArray.length - 1];
                const selectedmainArray = selectedMainName.split("-@-");
                const selectedMainNameLast = selectedmainArray[selectedmainArray.length - 1];
                this.newName = selectedMainNameLast;
            }
            else{
                if (this.selectedImages.length <= 0)
                    return;
                const selectedArray = this.selectedImages[0].split("/");
                const selectedMainName = selectedArray[selectedArray.length - 1];
                const selectedMainExtension = selectedMainName.slice(-4);
                const selectedmainArray = selectedMainName.split("-@-");
                const selectedMainNameLast = selectedmainArray[selectedmainArray.length - 1].slice(0, -4);
                this.newName = selectedMainNameLast;
            }
        },
    },
    components: { 
      LoadingBar
    }
}
</script>