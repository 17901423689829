<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:50%;height:30px;border:1px solid #dddddd;font-size: 14px;line-height:28px">Black-out Period Start</div>
                <div style="width:50%;border:1px solid #dddddd;border-left:none;font-size: 14px;line-height:28px;">Black-out Period End</div>
                <div style="width:30px;border:1px solid #dddddd;border-left:none;">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <div style="margin-top:0px" v-for="item in keysListBlackPeriod" :key="item">
                <a-row :gutter="0" style="width:100%;">
                    <div style="display:flex;border:1px solid #dddddd;border-top:none;background-color:#f5f5f5;">
                        <a-form-item style="width:50%;height:100%;padding:4px;background-color:white;margin-top:0px;margin-bottom:0;
                          border:1px solid #dddddd;border-left:none;border-top:none;border-bottom:none;" >
                            <a-date-picker style="width:100%;" placeholder="" 
                              :showToday="false"
                              valueFormat="YYYY-MM-DD"
                              v-decorator="[
                                `${title}Blackperiodbegin[${item}]`,
                                {
                                    initialValue: arr[item] && arr[item].blackperiodbegin ? arr[item].blackperiodbegin.split('T')[0] : null,
                                    rules: [{ required: true, message: 'Input Blackperiodbegin!' }]
                                }
                              ]"
                            >
                                <!-- <template slot="dateRender" slot-scope="current">
                                    <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                    v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                        {{ moment(current).utc().date() }}
                                    </div>
                                </template> -->
                            </a-date-picker>
                        </a-form-item>
                        <a-form-item style="width:50%;height:100%;padding:4px;background-color:white;margin-top:0px;margin-bottom:0;
                          border:1px solid #dddddd;border-left:none;border-top:none;border-bottom:none;" >
                            <a-date-picker style="width:100%;" placeholder="" 
                              :showToday="false"
                              valueFormat="YYYY-MM-DD"
                              v-decorator="[
                                `${title}Blackperiodend[${item}]`,
                                {
                                    initialValue: arr[item] && arr[item].blackperiodend ? arr[item].blackperiodend.split('T')[0] : null,
                                    rules: [{ required: false, message: 'blackperiodend!' }]
                                }
                              ]"
                            >
                                <!-- <template slot="dateRender" slot-scope="current">
                                    <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                    v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                        {{ moment(current).utc().date() }}
                                    </div>
                                </template> -->
                            </a-date-picker>
                        </a-form-item>
                        <div style="margin-top:auto;margin-bottom:auto;width:30px">
                            <a-button shape="circle" type="dashed" icon="minus" @click="removeRowBlackPeriod(item)" class="minusRowBtn" :size="'small'"></a-button>
                        </div>
                    </div>
                </a-row>
            </div>
            
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowBlackPeriod" type="primary" :size="'small'">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      idBlackPeriod: 0,
      keysListBlackPeriod: [],
      
      dateFormat : (val) => {
        return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
      }
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    this.init()
  },
  methods: {
    moment,
    init () {
      const arr = []
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idBlackPeriod = 0;
        for (let i = 0; i < (this.arr).length; i++) {
          arr.push(i)
          this.idBlackPeriod = this.idBlackPeriod + 1
        }
      }
      this.keysListBlackPeriod = arr
    },
    removeRowBlackPeriod (k) {
      if (this.keysListBlackPeriod.length === 0) { 
        return
      }
      this.keysListBlackPeriod = this.keysListBlackPeriod.filter(item => item !== k)
    },
    addRowBlackPeriod () {
      this.keysListBlackPeriod = this.keysListBlackPeriod.concat(this.idBlackPeriod)
      this.idBlackPeriod = this.idBlackPeriod + 1
    },
  }
}
</script>