<template>
    <div style="min-height:85vh">
        <AwsFileManager :ismodal="false"></AwsFileManager>
    </div>
</template>
<script>
import AwsFileManager from '../components/AwsFileManager.vue'
export default {
  components: { AwsFileManager },
    
}
</script>