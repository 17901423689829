<template>
    <div class="adminhome" style="min-height:85vh;overflow-x: hidden;">
        <div style="display:flex;position: relative;">
            <div style="margin: 16px 0;display:flex">
                <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                    <a-breadcrumb-item style="font-size:30px;">Bookings</a-breadcrumb-item>
                    <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
                </a-breadcrumb>
                <a-button v-if="false" style="margin-top:auto;margin-bottom:auto;margin-left:20px" type="primary">
                    <router-link to="/admin/adduser">
                    Add Booking
                    </router-link>
                </a-button>
            </div>
            
            <div v-if="newBookings.length > 0" style="position: absolute;top: 0;width: 100%;text-align:center;display: flex;">
              <div style="margin-left: auto;margin-right: auto;color: white;cursor:pointer;border-radius: 5px;background-color: #1890ff;width:150px;padding:5px" @click="getBookings">
                {{newBookings.length}} new bookings
                <a-icon type="reload" />
              </div>
            </div>
            
            <!-- <a-button style="margin-left:5px;position:absolute;top: 0;right: 0;">Export Excel</a-button> -->
            <export-excel  style="margin-left:5px;position:absolute;top: 0;right: 0;"
                class   = "btn-default"
                :data   = "json_data"
                :fields = "json_fields"
                :meta = "json_meta"
                type="xls"
                worksheet = "Booking"
                name    = "Booking Data">
            
                Export Excel
            
            </export-excel>
            
        </div>
        <div style="position:relative">
          <div style="position:absolute;right:0;top:-30px;display:flex;">
            <a-input-search style="width:400px;margin-right:0px;margin-left:auto" v-model="searchText" placeholder="" @search="onSearch">
              <a-button slot="enterButton">
                Search Booking
              </a-button>
            </a-input-search>
          </div>
          <div >
              <a-tabs
                  :key="tableUpdate" 
                  default-active-key="1"
                  :tab-position="'top'"
                  >
                  <a-tab-pane key="1" tab="All">
                      <BookingTableItem style="width:100%" :key="tableCellCount" :bookingList ="bookingList" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listPeriodFilter="listPeriodFilter"  @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="Confirmed" force-render>
                      <BookingTableItem :key="tableCellCount" :bookingList ="completedList" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listPeriodFilter="listPeriodFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="3" tab="Pending">
                      <BookingTableItem :key="tableCellCount" :bookingList ="processingList" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listPeriodFilter="listPeriodFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="4" tab="Abandoned">
                      <BookingTableItem :key="tableCellCount" :bookingList ="abandonedList" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listPeriodFilter="listPeriodFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="5" tab="Canceled">
                      <BookingTableItem :key="tableCellCount" :bookingList ="canceledList" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listPeriodFilter="listPeriodFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="6" tab="Hold">
                      <BookingTableItem :key="tableCellCount" :bookingList ="holdList" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listPeriodFilter="listPeriodFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="7" tab="Client Complete">
                      <BookingTableItem :key="tableCellCount" :bookingList ="clientCompleteList" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listPeriodFilter="listPeriodFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="8" tab="Hotel Complete">
                      <BookingTableItem :key="tableCellCount" :bookingList ="hotelCompleteList" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listPeriodFilter="listPeriodFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="9" tab="Agent Commission Due">
                      <BookingTableItem :key="tableCellCount" :bookingList ="agentCommissionList" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listPeriodFilter="listPeriodFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="10" tab="Booking Complete">
                      <BookingTableItem :key="tableCellCount" :bookingList ="bookingCompleteList" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listPeriodFilter="listPeriodFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
              </a-tabs>
          </div>
        </div>
        
        <!-- <a-table :columns="columns" :data-source="data">
            <a slot="name" slot-scope="text">{{ text }}</a>
            <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
            <span slot="tags" slot-scope="tags">
            <a-tag
                v-for="tag in tags"
                :key="tag"
                :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
            >
                {{ tag.toUpperCase() }}
            </a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
            <a>Invite 一 {{ record.name }}</a>
            <a-divider type="vertical" />
            <a>Delete</a>
            <a-divider type="vertical" />
            <a class="ant-dropdown-link"> More actions <a-icon type="down" /> </a>
            </span>
        </a-table> -->
    </div>
</template>
<script>

import { api } from '../helpers/Helpers';
import BookingTableItem from './TableItems/BookingTableItem.vue';
import { bus } from '../main';
import moment from 'moment';
const columns = [
  {
    title: 'Status',
    key: 'status',
    sorter: true,
    width:160,
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Booking Code',
    dataIndex: 'bookingCode',
    width:180,
    key: 'bookingCode',
    sorter: true,
  },
  {
    title: 'Confirmation No',
    dataIndex: 'confirmationNo',
    key: 'confirmationNo',
    sorter: true,
    scopedSlots: { customRender: 'confirmationNo' },
  },
  {
    title: 'Capture Balance',
    dataIndex: 'captureBalance',
    key: 'captureBalance',
    sorter: true,
    scopedSlots: { customRender: 'captureBalance' },
  },
  {
    title: 'Remaining Balance',
    dataIndex: 'remainingBalance',
    key: 'remainingBalance',
    sorter: true,
    scopedSlots: { customRender: 'remainingBalance' },
  },
  {
    title: 'Total booking',
    dataIndex: 'cost',
    key: 'cost',
    sorter: true,
    scopedSlots: { customRender: 'cost' },
  },
  {
    title: 'Date created',
    dataIndex: 'createdDate',
    key: 'createdDate',
    sorter: true,
    scopedSlots: { customRender: 'createdDate' },
  },
  {
    title: 'Date confirmed',
    key: 'confirmDate',
    dataIndex: 'confirmDate',
    sorter: true,
    scopedSlots: { customRender: 'confirmDate' },
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: true,
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: true,
  },
  {
    title: 'Agency',
    dataIndex: 'agency',
    key: 'agency',
    sorter: true,
  },
  {
    title: 'Agent',
    dataIndex: 'agentName',
    key: 'agentName',
    sorter: true,
  },
  {
    title: 'Direct User',
    dataIndex: 'userName',
    key: 'userName',
    sorter: true,
  },
  {
    title: 'Hotel',
    dataIndex: 'hotelName',
    key: 'hotelName',
    sorter: true,
  },
  {
    title: 'Room Count',
    dataIndex: 'roomNumber',
    key: 'roomNumber',
    sorter: true,
  },
  {
    title: 'Check-in',
    key: 'checkinDate',
    dataIndex: 'checkinDate',
    sorter: true,
    scopedSlots: { customRender: 'checkinDate' },
  },
  {
    title: 'Check-out',
    key: 'checkoutDate',
    dataIndex: 'checkoutDate',
    sorter: true,
    scopedSlots: { customRender: 'checkoutDate' },
  },
  {
    title: 'Nights',
    key: 'nights',
    dataIndex: 'nights',
    sorter: true,
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    sorter: true,
  },
  // {
  //   title: 'Cost',
  //   key: 'cost',
  //   dataIndex: 'cost',
  //   sorter: true,
  //   scopedSlots: { customRender: 'cost' },
  // },
  {
    title: 'Cost: Hotel To Vue',
    key: 'costHotelToVue',
    dataIndex: 'costHotelToVue',
    sorter: true,
    scopedSlots: { customRender: 'costHotelToVue' },
  },
  // {
  //   title: 'Cost: Client Selling Rate',
  //   key: 'costClientSellingRate',
  //   dataIndex: 'costClientSellingRate',
  //   sorter: true,
  //   scopedSlots: { customRender: 'costClientSellingRate' },
  // },
  
  {
    title: 'Agent Commission',
    key: 'agentCommission',
    dataIndex: 'agentCommission',
    sorter: true,
    scopedSlots: { customRender: 'agentCommission' },
  },
  {
    title: 'Memo',
    key: 'memo',
    dataIndex: 'memo',
    sorter: true,
    scopedSlots: { customRender: 'memo' },
  },
  {
    title: '3% Credit card fee',
    key: 'creditCardFee3',
    dataIndex: 'creditCardFee3',
    sorter: true,
    scopedSlots: { customRender: 'creditCardFee3' },
  },
  {
    title: 'Vue Profit/Fees',
    key: 'vueProfitFees',
    dataIndex: 'vueProfitFees',
    sorter: true,
    scopedSlots: { customRender: 'vueProfitFees' },
  },
  {
    title: 'Form of Payment',
    key: 'formPayment',
    dataIndex: 'formPayment',
    sorter: true,
  },
  // {
  //   title: 'Vue Profit fee',
  //   key: 'vueProfitFees3',
  //   dataIndex: 'vueProfitFees3',
  //   sorter: true,
  //   scopedSlots: { customRender: 'vueProfitFees3' },
  // },
  {
    title: 'Clients Due Date',
    key: 'dueDateClients',
    dataIndex: 'dueDateClients',
    sorter: true,
    scopedSlots: { customRender: 'dueDateClients' },
  },
  {
    title: 'Hotel Due Date',
    key: 'dueDateHotel',
    dataIndex: 'dueDateHotel',
    sorter: true,
    scopedSlots: { customRender: 'dueDateHotel' },
  },
  
  {
    title: 'Client Complete',
    dataIndex: 'clientComplete',
    key: 'clientComplete',
    sorter: true,
    width:150,
    scopedSlots: { customRender: 'clientComplete' },
  },
  
  {
    title: 'Hotel Complete',
    dataIndex: 'hotelComplete',
    key: 'hotelComplete',
    sorter: true,
    width:150,
    scopedSlots: { customRender: 'hotelComplete' },
  },
  
  {
    title: 'Agent commission due',
    dataIndex: 'agentCommissionDue',
    key: 'agentCommissionDue',
    sorter: true,
    width:150,
    scopedSlots: { customRender: 'agentCommissionDue' },
  },
  
  {
    title: 'Booking Complete',
    dataIndex: 'bookingComplete',
    key: 'bookingComplete',
    sorter: true,
    width:150,
    scopedSlots: { customRender: 'bookingComplete' },
  },

  {
    title: 'Action',
    key: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
];


const statusList = {
  0:'Pending',
  1:'Approved',
  2:'Denied'
};
const roleList = {
  1:'User',
  4:'Agent',
  2048:'Admin'
};
var newBookings = [];

export default {
  data() {
    return {
      hotelList:[],
      alllist:[],
      resList:[],
      completedList:[],
      canceledList:[],
      processingList:[],
      abandonedList:[],
      holdList:[],
      clientCompleteList:[],
      hotelCompleteList:[],
      agentCommissionList:[],
      bookingCompleteList:[],

      bookingList:[],
      columns,
      searchText:"",
      tableCellCount:10,
      json_fields: {
            'Status': 'status',
            'Date created': 'createdDate',
            'Date confirmed': 'confirmDate',
            'First Name': 'firstName',
            'Last Name': 'lastName',
            'Agency': 'agency',
            'Agent Name': 'agentName',
            'Direct Name': 'userName',
            'Hotel': 'hotel',
            'Check in': 'checkin',
            'Check out': 'checkout',
            '# of nights': 'nights',
            'email': 'email',
            'Cost: Hotel \nto VUE': 'costHotelToVue',
            'COST: Client\n Selling rate': 'costClientSellingRate',
            'Extra person \n Supplement': 'extraPersonSupplement',
            'COST: ADD-ON rates \n(transfers, meal plans)': 'costAddonRate',
            // 'COST: Selling add-on \nrate to clients': 'costSellingAddonRate',
            'agent commission': 'agentCommission',
            'Memo': 'memo',
            '3% credit card fee': 'creditCardFee3',
            'VUE profit \nb/f fees': 'vueProfitFees',
            'Form of Payment': 'formPayment',
            // 'Vue Profit \n-after 3% fee': 'vueProfitFees3',
            'penalty date': 'dueDateClients',
            'Due Date Hotel': 'dueDateHotel',
            // 'Telephone 2' : {
            //     field: 'phone.landline',
            //     callback: (value) => {
            //         return `Landline Phone - ${value}`;
            //     }
            // },
        },
        json_data: [
            {
                'status': 'Completed',
                'firstName': 'firstName',
                'lastName': 'lastName',
                'agency': 'New York',
                'agentName': 'New York',
                'userName': 'New York',
                'createdDate': 'New York',
                'confirmDate': 'New York',
                'hotel': 'New York',
                'checkin': 'New York',
                'checkout': 'New York',
                'nights': 'New York',
                'email': 'New York',
                'costHotelToVue': 'New York',
                'costClientSellingRate': 'New York',
                'extraPersonSupplement': 'New York',
                'costAddonRate': 'New York',
                'costSellingAddonRate': 'New York',
                'agentCommission': 'New York',
                'memo': 'New York',
                'vueProfitFees': 'New York',
                'formPayment': 'New York',
                'creditCardFee3': 'New York',
                // 'vueProfitFees3': 'New York',
                'penaltyDate': 'New York',
                'dueDateHotel': 'New York',
            }
        ],
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        newBookings,

        tableUpdate:false
    };
  },
  
  components: {
    BookingTableItem
  },
  
    created: function () {
        bus.$on('newbooking', (data) => {
          // console.log(this.newBookings, "Bookings =22= = ", JSON.parse(data).email, JSON.parse(data).id);
          const _info = JSON.parse(data);
          const _res = this.newBookings.filter(obj => obj.id == _info.id);
          // console.log(_res, "Bookings =_res= = ");
          if (_res.length <= 0){
            this.newBookings.push(_info);

            // console.log(this.newBookings, "Bookings =33= = ", _info.email);
            this.openNotification(_info.email);
          }
        });
        this.getHotels();
    },
    mounted(){

    },
  
    methods: {
      moment,
        getHotels:async function(){
        
          const res = await api.getHotelsTotal();
          // console.log("getHotels:",res);
          this.hotelList = res;
          this.getBookings();
        },
        getBookings: async function() {
          this.newBookings = [];
            let res = await api.getBookingRequestStatus();
            
            // this.flash('task created', 'success');
            // console.log("res=============",res);
            // return;
            // // console.log(res.length);
            if (res != undefined && res.length > 0){
                res.sort(function(a,b) {return (new Date(a['createdDate']) < new Date(b['createdDate'])) ? 1 : ((new Date(b['createdDate']) < new Date(a['createdDate'])) ? -1 : 0);} );
                var resList = [];
                var resCompletedList = [];
                var resCanceledList = [];
                var resProcessingList = [];
                var resAbandonedList = [];
                var resHoldList = [];
                var resClientCompleteList = [];
                var resHotelCompleteList = [];
                var resAgentCommissionList = [];
                var resBookingCompleteList = [];

                this.json_data = [];
                for (let i = 0; i < res.length; i++) {
                    if (res[i].status == 5)
                      continue;
                    const guestNameObj = res[i].guestnames.length > 0 ? res[i].guestnames[0] : '';
                    const firstName = guestNameObj.firstname;
                    const lastName = guestNameObj.lastname;
                    
                    const fromdate = new Date(res[i].fromDate);
                    const todate = new Date(res[i].toDate);

                    const diffTime = Math.abs(todate - fromdate);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
//hotelid
                    let _hotelName = res[i].hotelname;
                    let _bookingCode = res[i].bookingCode;
                    let _confirmationNo = res[i].confirmationNo;
                    let _captureBalance = res[i].captureBalance;
                    let _remainingBalance = null;
                    if (res[i].grossTotalPrice != undefined && _captureBalance != undefined)
                      _remainingBalance = res[i].grossTotalPrice - _captureBalance;
                    let hotelInfo = this.hotelList.filter(obj => obj._id == res[i].hotelid)
                    if (hotelInfo.length > 0 && hotelInfo[0].hotelname != _hotelName){
                      _hotelName = hotelInfo[0].hotelname;
                      const resUpdate = await api.bookingRequestUpdate({
                        hotelname:hotelInfo[0].hotelname}, res[i]._id);
                    }


                    var totalPrice = 0;
                    if (res[i].datalist != undefined){
                      res[i].datalist.forEach(item => {
                        totalPrice += item.price;
                      });
                    }
                    
                    const roomNumber = res[i].datalist.length;
                    const creditCardFee = (res[i].sellingTotalPrice + res[i].enhancePrice) * 0.03;
                    
                    let vueProfitFees = res[i].sellingTotalPrice + res[i].enhancePrice - creditCardFee - res[i].hoteltovue;// - res[i].commission
                    if (res[i].commission != undefined && res[i].commission != "" && res[i].agentname != undefined && res[i].agentname != "")
                      vueProfitFees = vueProfitFees - res[i].commission;
                    let infoCell = {
                      id:res[i]._id,
                      status: res[i].status,
                      firstName: firstName,
                      lastName: lastName,
                      roomNumber: roomNumber,
                      agency: res[i].agencyname,
                      agentName: res[i].agentname,
                      userName: res[i].username,
                      confirmDate: res[i].confirmDate,
                      confirmer: res[i].confirmer,
                      bookingCode: _bookingCode,
                      confirmationNo: _confirmationNo,
                      captureBalance: _captureBalance,
                      remainingBalance: _remainingBalance,

                      clientComplete: res[i].clientComplete,
                      hotelComplete: res[i].hotelComplete,
                      agentCommissionDue: res[i].agentCommissionDue,
                      bookingComplete: res[i].bookingComplete,

                      clientCompleteDate: res[i].clientCompleteDate,
                      hotelCompleteDate: res[i].hotelCompleteDate,
                      agentCommissionDueDate: res[i].agentCommissionDueDate,
                      bookingCompleteDate: res[i].bookingCompleteDate,


                      sessionId: res[i].sessionId,
                      cardId: res[i].cardId,


                      createdDate: res[i].createdDate,
                      hotelName: _hotelName,
                      checkinDate: res[i].fromDate,
                      checkoutDate: res[i].toDate,
                      nights: diffDays,
                      email: res[i].mainemail,
                      cost: res[i].grossTotalPrice,//totalPrice,//res[i].grossTotalPrice,

                      costHotelToVue: res[i].hoteltovue != undefined ? res[i].hoteltovue : 0,
                      // costHotelToVue: res[i].contrackctedPrice != undefined ? `$${res[i].contrackctedPrice}` : '',
                      costClientSellingRate: res[i].sellingTotalPrice != undefined ? res[i].sellingTotalPrice : 0,
                      extraPersonSupplement: (res[i].extraAdult != undefined ? res[i].extraAdult : 0) + (res[i].extraChild != undefined ? res[i].extraChild : 0),
                      costAddonRate:res[i].enhancePrice != undefined ?  res[i].enhancePrice : 0,

                      costSellingAddonRate:res[i].enhancePrice != undefined ?  res[i].enhancePrice : 0,
                      agentCommission: res[i].commission != undefined && res[i].agentname != undefined && res[i].agentname != ''? res[i].commission : '',
                      
                      memo: res[i].specialrequests != undefined ? res[i].specialrequests : '',

                      vueProfitFees: vueProfitFees,
                      formPayment: res[i].formpayment,
                      creditCardFee3: creditCardFee,
                      vueProfitFees3: res[i].totalprice != undefined ? res[i].totalprice - res[i].sellingTotalPrice - res[i].taxat : 0,
                      penaltyDate: res[i].cancelDate != undefined ? res[i].cancelDate.toString().slice(0, 10) : '', 
                      dueDateClients: res[i].dueDateClients != undefined ? res[i].dueDateClients : '',
                      dueDateHotel: res[i].dueDateHotel != undefined ? res[i].dueDateHotel : '',
                      // dueDateHotel: res[i].paidDate != undefined ? res[i].paidDate.toString().slice(0, 10) : '',

                    }
                    
                    resList.push(infoCell);
                    if (res[i].status == 0)
                      resAbandonedList.push(infoCell);
                    if (res[i].status == 1 || res[i].status == 4)
                      resProcessingList.push(infoCell);
                    if (res[i].status == 2)
                      resCompletedList.push(infoCell);
                    if (res[i].status == 3)
                      resCanceledList.push(infoCell);
                    if (res[i].status == 6)
                      resHoldList.push(infoCell);
              
                    if (res[i].status == 7)
                      resClientCompleteList.push(infoCell);
                    if (res[i].status == 8)
                      resHotelCompleteList.push(infoCell);
                    if (res[i].status == 9)
                      resAgentCommissionList.push(infoCell);
                    if (res[i].status == 10)
                      resBookingCompleteList.push(infoCell);

                    ///////   Json List Update
                    let cellInfo =
                    {
                        'status': res[i].status == 2 ? 'Confirmed' : res[i].status == 3 ? 'Canceled' : res[i].status == 1 || res[i].status == 4 ? 'Pending' : res[i].status == 7 ? 'Client Complete' : res[i].status == 8 ? 'Hotel Complete' : res[i].status == 9 ? 'Agent Commission Due' : res[i].status == 10 ? 'Booking Complete' : 'Abandoned',
                        'firstName': firstName,
                        'lastName': lastName,
                        'agency': res[i].agencyname,
                        'agentName': res[i].agentname,
                        'userName': res[i].username,
                        
                        'createdDate': res[i].createdDate != undefined ? moment(Date.parse(res[i].createdDate)).utc(1412144245453).format('YYYY-MM-DD HH:mm'): '',
                        'confirmDate': res[i].confirmDate != undefined ? moment(Date.parse(res[i].confirmDate)).utc(1412144245453).format('YYYY-MM-DD HH:mm') : '',
                        'hotel': res[i].hotelname,
                        'checkin': res[i].fromDate.toString().slice(0, 10),
                        'checkout': res[i].toDate.toString().slice(0, 10),
                        'nights': diffDays,
                        'email': res[i].mainemail,


                        
                        'costHotelToVue': res[i].hoteltovue != undefined ? res[i].hoteltovue : 0,
                        // costHotelToVue: res[i].contrackctedPrice != undefined ? `$${res[i].contrackctedPrice}` : '',
                        'costClientSellingRate': res[i].sellingTotalPrice != undefined ? res[i].sellingTotalPrice : 0,
                        'extraPersonSupplement': (res[i].extraAdult != undefined ? res[i].extraAdult : 0) + (res[i].extraChild != undefined ? res[i].extraChild : 0),
                        'costAddonRate':res[i].enhancePrice != undefined ?  res[i].enhancePrice : 0,

                        'costSellingAddonRate':res[i].enhancePrice != undefined ?  res[i].enhancePrice : 0,
                        'agentCommission': res[i].commission != undefined && res[i].agentname != undefined && res[i].agentname != '' ? res[i].commission : '',
                        'memo': res[i].specialrequests != undefined ? res[i].cospecialrequestsmission : '',
                        'vueProfitFees': vueProfitFees,
                        'formPayment': res[i].formpayment,
                        'creditCardFee3': creditCardFee,
                        'vueProfitFees3': res[i].totalprice != undefined ? res[i].totalprice - res[i].sellingTotalPrice - res[i].taxat : 0,
                        'penaltyDate': res[i].cancelDate != undefined ? res[i].cancelDate.toString().slice(0, 10) : '', 
                        'dueDateClients': res[i].dueDateClients != undefined ? res[i].dueDateClients: '',
                        'dueDateHotel': res[i].dueDateHotel != undefined ? res[i].dueDateHotel: '',
                        // 'dueDateHotel': res[i].paidDate != undefined ? res[i].paidDate.toString().slice(0, 10) : '',
                    };
                    this.json_data.push(cellInfo);


                }
                this.completedList = resCompletedList;
                this.canceledList = resCanceledList;
                this.processingList = resProcessingList;
                this.abandonedList = resAbandonedList;
                this.holdList = resHoldList;
                this.clientCompleteList = resClientCompleteList;
                this.hotelCompleteList = resHotelCompleteList;
                this.agentCommissionList = resAgentCommissionList;
                this.bookingCompleteList = resBookingCompleteList;
                this.bookingList = resList;
                this.resList = resList;
                this.tableUpdate = !this.tableUpdate;
            // console.log("resList=============",resList);
            }
        },
        onSearch(value) {
          // console.log(this.resList);
          var _resList = []
          for (let i = 0; i < this.resList.length; i++) {
              if ((this.resList[i].hotelName != undefined && this.resList[i].hotelName.toLowerCase().includes(this.searchText.toLowerCase()))
               || (this.resList[i].email !== undefined && this.resList[i].email.toLowerCase().includes(this.searchText.toLowerCase()))
               || (this.resList[i].firstName !== undefined && this.resList[i].firstName.toLowerCase().includes(this.searchText.toLowerCase()))
               || (this.resList[i].lastName !== undefined && this.resList[i].lastName.toLowerCase().includes(this.searchText.toLowerCase()))
               || (this.resList[i].userName !== undefined && this.resList[i].userName.toLowerCase().includes(this.searchText.toLowerCase())))
                _resList.push(this.resList[i])
          }
          this.updateList(_resList);
        },
        updateList(allList){
          var resList = [];
          var resCompletedList = [];
          var resCanceledList = [];
          var resProcessingList = [];
          var resAbandonedList = [];
          var resHoldList = [];
          var resClientCompleteList = [];
          var resHotelCompleteList = [];
          var resAgentCommissionList = [];
          var resBookingCompleteList = [];
          for (let i = 0; i < allList.length; i++) {
            resList.push(allList[i])
            if (allList[i].status == 0)
              resAbandonedList.push(allList[i]);
            if ( allList[i].status == 1 || allList[i].status == 4)
              resProcessingList.push(allList[i]);
            if (allList[i].status == 2)
              resCompletedList.push(allList[i]);
            if (allList[i].status == 3)
              resCanceledList.push(allList[i]);
            if (allList[i].status == 6)
              resHoldList.push(allList[i]);
              
            if (allList[i].status == 7)
              resClientCompleteList.push(allList[i]);
            if (allList[i].status == 8)
              resHotelCompleteList.push(allList[i]);
            if (allList[i].status == 9)
              resAgentCommissionList.push(allList[i]);
            if (allList[i].status == 10)
              resBookingCompleteList.push(allList[i]);
          }
          this.completedList = resCompletedList;
          this.canceledList = resCanceledList;
          this.processingList = resProcessingList;
          this.abandonedList = resAbandonedList;
          this.holdList = resHoldList;
          this.clientCompleteList = resClientCompleteList;
          this.hotelCompleteList = resHotelCompleteList;
          this.agentCommissionList = resAgentCommissionList;
          this.bookingCompleteList = resBookingCompleteList;
          this.bookingList = resList;
        },
        listUpdate(){
          this.getBookings();
        },
        listFilterCount(_count){
          // console.log(_count);
          this.tableCellCount = _count;
        },
        listPeriodFilter(_startDate, _endDate, roomCount){
          let _startprice = null;
          let _endPrice = null; 
          var roomCountFilter = [];
          for(let i = 0; i < this.resList.length; i++){
          // // console.log(i,roomCount,this.resList[i].roomNumber);
            // if (this.resList[i].roomNumber >= roomCount)
              roomCountFilter.push(this.resList[i]);
          }

          var allList = this.filterPrice(roomCountFilter, _startprice, _endPrice);
          
          var resList = [];
          var resCompletedList = [];
          var resCanceledList = [];
          var resProcessingList = [];
          var resAbandonedList = [];
          var resHoldList = [];
          var resClientCompleteList = [];
          var resHotelCompleteList = [];
          var resAgentCommissionList = [];
          var resBookingCompleteList = [];
          
          // console.log(roomCount,_startprice, _endPrice, allList, roomCountFilter);

          if (_startDate != null && _endDate != null){
            for (let i = 0; i < allList.length; i++) {
              if (this.dateRangeOverlaps(new Date(_startDate).getTime(), new Date(_endDate).getTime(), new Date(allList[i].checkinDate).getTime(), new Date(allList[i].checkoutDate).getTime())){
                resList.push(allList[i])
                if (allList[i].status == 0)
                  resAbandonedList.push(allList[i]);
                if (allList[i].status == 1 || allList[i].status == 4)
                  resProcessingList.push(allList[i]);
                if (allList[i].status == 2)
                  resCompletedList.push(allList[i]);
                if (allList[i].status == 3)
                  resCanceledList.push(allList[i]);
                if (allList[i].status == 6)
                  resHoldList.push(allList[i]);
                  
              
                if (allList[i].status == 7)
                  resClientCompleteList.push(allList[i]);
                if (allList[i].status == 8)
                  resHotelCompleteList.push(allList[i]);
                if (allList[i].status == 9)
                  resAgentCommissionList.push(allList[i]);
                if (allList[i].status == 10)
                  resBookingCompleteList.push(allList[i]);
                }
            }
          }
          else if (_startDate == null && _endDate != null){
            for (let i = 0; i < allList.length; i++) {
              if (new Date(allList[i].checkinDate).getTime() <= new Date(_endDate).getTime()){
                resList.push(allList[i])
                if (allList[i].status == 0)
                  resAbandonedList.push(allList[i]);
                if (allList[i].status == 1 || allList[i].status == 4)
                  resProcessingList.push(allList[i]);
                if (allList[i].status == 2)
                  resCompletedList.push(allList[i]);
                if (allList[i].status == 3)
                  resCanceledList.push(allList[i]);
                if (allList[i].status == 6)
                  resHoldList.push(allList[i]);
              
                if (allList[i].status == 7)
                  resClientCompleteList.push(allList[i]);
                if (allList[i].status == 8)
                  resHotelCompleteList.push(allList[i]);
                if (allList[i].status == 9)
                  resAgentCommissionList.push(allList[i]);
                if (allList[i].status == 10)
                  resBookingCompleteList.push(allList[i]);
              }
            }
          }
          else if (_startDate != null && _endDate == null){
            for (let i = 0; i < allList.length; i++) {
              if (new Date(_startDate).getTime() <= new Date(allList[i].checkoutDate).getTime()){
                resList.push(allList[i])
                if (allList[i].status == 0)
                  resAbandonedList.push(allList[i]);
                if (allList[i].status == 1 || allList[i].status == 4)
                  resProcessingList.push(allList[i]);
                if (allList[i].status == 2)
                  resCompletedList.push(allList[i]);
                if (allList[i].status == 3)
                  resCanceledList.push(allList[i]);
                if (allList[i].status == 6)
                  resHoldList.push(allList[i]);
              
                if (allList[i].status == 7)
                  resClientCompleteList.push(allList[i]);
                if (allList[i].status == 8)
                  resHotelCompleteList.push(allList[i]);
                if (allList[i].status == 9)
                  resAgentCommissionList.push(allList[i]);
                if (allList[i].status == 10)
                  resBookingCompleteList.push(allList[i]);
              }
            }
          }
          else{
            for (let i = 0; i < allList.length; i++) {
              resList.push(allList[i])
              if (allList[i].status == 0)
                resAbandonedList.push(allList[i]);
              if (allList[i].status == 1 || allList[i].status == 4)
                resProcessingList.push(allList[i]);
              if (allList[i].status == 2)
                resCompletedList.push(allList[i]);
              if (allList[i].status == 3)
                resCanceledList.push(allList[i]);
              if (allList[i].status == 6)
                resHoldList.push(allList[i]);
              
              if (allList[i].status == 7)
                resClientCompleteList.push(allList[i]);
              if (allList[i].status == 8)
                resHotelCompleteList.push(allList[i]);
              if (allList[i].status == 9)
                resAgentCommissionList.push(allList[i]);
              if (allList[i].status == 10)
                resBookingCompleteList.push(allList[i]);
            }
          }
          
          this.completedList = resCompletedList;
          this.canceledList = resCanceledList;
          this.processingList = resProcessingList;
          this.abandonedList = resAbandonedList;
          this.holdList = resHoldList;
          this.clientCompleteList = resClientCompleteList;
          this.hotelCompleteList = resHotelCompleteList;
          this.agentCommissionList = resAgentCommissionList;
          this.bookingCompleteList = resBookingCompleteList;
          this.bookingList = resList;
        },
        
        filterPrice(allList, _startprice, _endPrice){
          var resList = [];
          var resCompletedList = [];
          var resCanceledList = [];
          var resProcessingList = [];
          var resAbandonedList = [];
          var resHoldList = [];
          var resClientCompleteList = [];
          var resHotelCompleteList = [];
          var resAgentCommissionList = [];
          var resBookingCompleteList = [];

          if (_startprice != null && _endPrice != null){
            for (let i = 0; i < allList.length; i++) {
              if (_startprice <= allList[i].cost && _endPrice >=  allList[i].cost){
                resList.push(allList[i])
                if (allList[i].status == 0)
                  resAbandonedList.push(allList[i]);
                if (allList[i].status == 1 || allList[i].status == 4)
                  resProcessingList.push(allList[i]);
                if (allList[i].status == 2)
                  resCompletedList.push(allList[i]);
                if (allList[i].status == 3)
                  resCanceledList.push(allList[i]);
                if (allList[i].status == 6)
                  resHoldList.push(allList[i]);
              
                if (allList[i].status == 7)
                  resClientCompleteList.push(allList[i]);
                if (allList[i].status == 8)
                  resHotelCompleteList.push(allList[i]);
                if (allList[i].status == 9)
                  resAgentCommissionList.push(allList[i]);
                if (allList[i].status == 10)
                  resBookingCompleteList.push(allList[i]);
              }
            }
          }
          else if (_startprice == null && _endPrice != null){
            for (let i = 0; i < allList.length; i++) {
              if (_endPrice >=  allList[i].cost){
                resList.push(allList[i])
                if (allList[i].status == 0)
                  resAbandonedList.push(allList[i]);
                if (allList[i].status == 1 || allList[i].status == 4)
                  resProcessingList.push(allList[i]);
                if (allList[i].status == 2)
                  resCompletedList.push(allList[i]);
                if (allList[i].status == 3)
                  resCanceledList.push(allList[i]);
                if (allList[i].status == 6)
                  resHoldList.push(allList[i]); 
              
                if (allList[i].status == 7)
                  resClientCompleteList.push(allList[i]);
                if (allList[i].status == 8)
                  resHotelCompleteList.push(allList[i]);
                if (allList[i].status == 9)
                  resAgentCommissionList.push(allList[i]);
                if (allList[i].status == 10)
                  resBookingCompleteList.push(allList[i]);
              }
            }
          }
          else if (_startprice != null && _endPrice == null){
            for (let i = 0; i < allList.length; i++) {
              if (_startprice <= allList[i].cost){
                resList.push(allList[i])
                if (allList[i].status == 0)
                  resAbandonedList.push(allList[i]);
                if (allList[i].status == 1 || allList[i].status == 4)
                  resProcessingList.push(allList[i]);
                if (allList[i].status == 2)
                  resCompletedList.push(allList[i]);
                if (allList[i].status == 3)
                  resCanceledList.push(allList[i]);
                if (allList[i].status == 6)
                  resHoldList.push(allList[i]);
              
                if (allList[i].status == 7)
                  resClientCompleteList.push(allList[i]);
                if (allList[i].status == 8)
                  resHotelCompleteList.push(allList[i]);
                if (allList[i].status == 9)
                  resAgentCommissionList.push(allList[i]);
                if (allList[i].status == 10)
                  resBookingCompleteList.push(allList[i]);
              }
            }
          }
          else{
            for (let i = 0; i < allList.length; i++) {
              resList.push(allList[i])
              if (allList[i].status == 0)
                resAbandonedList.push(allList[i]);
              if (allList[i].status == 1 || allList[i].status == 4)
                resProcessingList.push(allList[i]);
              if (allList[i].status == 2)
                resCompletedList.push(allList[i]);
              if (allList[i].status == 3)
                resCanceledList.push(allList[i]);
              if (allList[i].status == 6)
                resHoldList.push(allList[i]);
              
              if (allList[i].status == 7)
                resClientCompleteList.push(allList[i]);
              if (allList[i].status == 8)
                resHotelCompleteList.push(allList[i]);
              if (allList[i].status == 9)
                resAgentCommissionList.push(allList[i]);
              if (allList[i].status == 10)
                resBookingCompleteList.push(allList[i]);
            }
            
        // console.log("---",resList)
          }
          
          this.completedList = resCompletedList;
          this.canceledList = resCanceledList;
          this.processingList = resProcessingList;
          this.abandonedList = resAbandonedList;
          this.holdList = resHoldList;
          this.clientCompleteList = resClientCompleteList;
          this.hotelCompleteList = resHotelCompleteList;
          this.agentCommissionList = resAgentCommissionList;
          this.bookingCompleteList = resBookingCompleteList;
          this.bookingList = resList;
          allList = resList;
          return allList;
        },
        dateRangeOverlaps(a_start, a_end, b_start, b_end) {
          if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
          if (a_start <= b_end   && b_end   <= a_end) return true; // b ends in a
          if (b_start <  a_start && a_end   <  b_end) return true; // a in b
          return false;
        },
        
        openNotification(_email) {
          const key = `open${Date.now()}`;
          this.$notification.open({
            message: 'Notification New Booking',
            description:
              `${_email} requested new booking to our Maldive.`,
            btn: h => {
              return h(
                'a-button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  on: {
                    click: () => this.getBookings(),
                  },
                },
                'Update Bookings',
              );
            },
            key,
            onClose: close,
          });
        },
    }
};
</script>
<style>
.btn-default{
    line-height: 2;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border: 1px #AAAAAA solid;
}
.btn-cell-icon{
    line-height: 2;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    font-size: 14px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
}
</style>
