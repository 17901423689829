<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:20%;border:1px solid #dddddd;font-size: 14px;line-height:28px;">Meal Title</div>
                <div style="width:20%;border:1px solid #dddddd;border-right:none;border-left:none;font-size: 14px;line-height:28px;">Condition</div>
                <div style="width:20%;border:1px solid #dddddd;border-right:none;font-size: 14px;line-height:28px;">Price</div>
                <div style="width:20%;border:1px solid #dddddd;border-right:none;font-size: 14px;line-height:28px;">Description</div>
                <div style="width:20%;border:1px solid #dddddd;font-size: 14px;line-height:28px;">Image</div>
                <div style="width:50px;height:30px;border:1px solid #dddddd;border-left:none;">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <draggable v-model="keysListMeal" chosenClass="chosen" forceFallback="false" group="people" animation="1000" filter="aaa" @start="onStart" @end="onEnd" :options="{handle :'div.item'}">
                <transition-group>
                    <div style="margin-top:0px;background-color:#f8f8f8;border:1px solid #dddddd;border-top:none" v-for="item, index in keysListMeal" :key="item">
                        <a-row :gutter="0" style="width:100%;">
                            <div style="display:flex;">
                                <div v-if="true" class="item" style="width:50px;height: inherit;display:flex;color:#000000a6">
                                    <div style="margin:auto">{{index + 1}}</div>
                                </div>
                                <div style="width:20%;height:auto;margin:0;background-color:white;display:flex;border:1px solid #ddd;border-top:none;border-left:none;border-bottom:none;padding:10px" >
                                    <a-form-item style="width:100%;margin-top:0;margin-bottom:0">
                                        <a-input style="width:100%;margin-top:auto;margin-bottom:auto" placeholder=""
                                            v-decorator="[
                                                `${title}Mealtitle[${item}]`,
                                                {
                                                    initialValue: arr[item] ? arr[item].mealtitle : undefined,
                                                    rules: [{ required: true, message: 'Input mealtitle!' }]
                                                }
                                            ]"
                                        />
                                    </a-form-item>
                                </div>
                                <div style="width:20%;height:auto;margin:0;background-color:white;border:1px solid #ddd;border-top:none;border-left:none;border-right:none;border-bottom:none;padding:10px" >
                                    <div style="font-weight:600;text-align:left;margin-bottom:5px">Meal Date</div>
                                    <div style="width:100%;padding:0px">
                                        <hotel-meal-date-list :arr="arr[item]" :title="title" :id="item"/>
                                    </div>
                                </div>
                                <div style="width:20%;height:auto;margin:0;background-color:white;display:flex;border:1px solid #ddd;border-top:none;border-right:none;border-bottom:none;padding:10px" >
                                    
                                    <a-form style="display:flex;width:100%" :form="hotelMealPriceForm[item]">
                                        <hotel-meal-price-list
                                            :title="`${HOTELMEALPRICEPART + (item)}`"
                                            :arr="arrHotelMealPrice[item]"
                                        />
                                    </a-form>
                                </div>
                                <a-form-item style="width:20%;height:auto;margin:0;background-color:white;border:1px solid #ddd;border-top:none;border-right:none;border-bottom:none;padding:10px" >
                                <a-textarea style="width:100%"
                                    placeholder="Controlled autosize"
                                    :auto-size="{ minRows: 8, maxRows: 10 }"
                                    v-decorator="[
                                        `${title}Description[${item}]`,
                                        {
                                            initialValue: arr[item] ? arr[item].description : undefined,
                                            rules: [{ required: false, message: 'description!' }]
                                        }
                                    ]"
                                />
                                </a-form-item>
                                <div style="width:20%;height:auto;margin:0;background-color:white;border:1px solid #ddd;border-top:none;border-bottom:none;padding:10px" >
                                    <div v-if="!isSelected[item]">
                                        <div>No image selected</div>
                                        <a-button @click="showGallary(true, item)" :size="'small'">Add Image</a-button>
                                    </div>
                                    
                                    <div v-if="isSelected[item]" style="position:relative">
                                    <img :src="`${selectedImage[item]}`" alt="" width="100%" height="100px">
                                    <a-icon @click="cancelImage(item)" style="position:absolute;top:5px;right:5px" type="close-circle" theme="filled" />
                                    <a-icon @click="showGallary(true, item)" style="position:absolute;top:5px;right:25px" type="edit" theme="filled" />
                                    </div>
                                    <a-modal
                                        v-model="visibleGallary"
                                        title="Add Gallay"
                                        :footer="null"
                                        width="80%"
                                    >
                                        <AwsFileManager v-if="visibleGallary" :ismulti="false" :rowIndex="selitem" @close="showGallary(false)" @selectImages="selectImages"/>
                                    </a-modal>
                                </div>
                                <div style="margin-top:auto;margin-bottom:auto;width:50px">
                                    <a-button shape="circle" type="solid" icon="minus" @click="removeRowMeal(item)" class="minusRowBtn" :size="'small'"></a-button>
                                </div>
                            </div>
                        </a-row>
                    </div>
                </transition-group>
            </draggable>
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowMeal" type="primary">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import AwsFileManager from '../../components/AwsFileManager.vue'
import HotelMealPriceList from './HotelMealPriceList.vue'
import HotelMealDateList from './HotelMealDateList.vue'
import moment from 'moment';
import draggable from 'vuedraggable'

const HOTELMEALPRICEPART = 'hotelMealPricePart'
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    HotelMealPriceList,
    HotelMealDateList,
    AwsFileManager,
    draggable
      
  },
  data () {
    return {
      idMeal: 0,
      keysListMeal: [],

      hotelMealPriceForm: [],
      HOTELMEALPRICEPART,
      arrHotelMealPrice: [],

      
      visibleGallary: false,
      selitem:0,
      selectedImage:[],
      isSelected:[],
      
      dateFormat : (val) => {
        return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
      }
      
    }
  },
  created () {
    var mynumber = 50;
    this.hotelMealPriceForm = new Array(mynumber);

    for (var i = 0; i < mynumber; i++) {
        this.hotelMealPriceForm[i] = this.$form.createForm(this);
    }

    this.selectedImage = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.selectedImage[i] = '';
    }
    this.isSelected = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.isSelected[i] = false;
    }
    this.init()
  },
  methods: {
    moment,
    init () {
      const arr = []
      
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idMeal = 0;
        for (let i = 0; i < (this.arr).length; i++) {
            if (this.arr[i].image != ''){
                this.isSelected.splice(i, 1, true);
                this.selectedImage.splice(i, 1, this.arr[i].image);
            }
            else{
                this.isSelected.splice(i, 1, false);
                this.selectedImage.splice(i, 1, this.arr[i].image);
            }
            
          this.arrHotelMealPrice[i] = this.arr[i].price;
          arr.push(i)
          this.idMeal = this.idMeal + 1
        }
      }
      this.keysListMeal = arr
    },


    removeRowMeal (k) {
      if (this.keysListMeal.length === 0) { 
        return
      }
      this.keysListMeal = this.keysListMeal.filter(item => item !== k)
    },
    addRowMeal () {
      this.keysListMeal = this.keysListMeal.concat(this.idMeal)
      this.idMeal = this.idMeal + 1
    },
    
    getMealPrice(_index){
        this.hotelMealPriceForm[_index].validateFields((err, values) => {
          if (!err) {
            let partOneArr = [];
                // console.log(values,_index)
                if (values[`${HOTELMEALPRICEPART + _index}Pricetitle`] !== undefined){
                    (values[`${HOTELMEALPRICEPART + _index}Pricetitle`]).forEach((item, index) => {
                        const obj = {
                            pricetitle: item,
                            pricevalue: values[`${HOTELMEALPRICEPART + _index}Pricevalue`][index],
                            agefrom: values[`${HOTELMEALPRICEPART + _index}Childagerangefrom`][index],
                            ageto: values[`${HOTELMEALPRICEPART + _index}Childagerangeto`][index],
                        }
                        partOneArr.push(obj)
                    })
                }
                else if (values[`${HOTELMEALPRICEPART + _index}SelectInput`] === undefined && this.arrHotelMealPrice[_index] !== undefined && this.arrHotelMealPrice[_index].length > 0){
                    partOneArr = this.arrHotelMealPrice[_index]
                }
                this.$emit('mealplanlist', partOneArr);
          }
        });
    },

    showGallary(_isShow, _index){
        this.visibleGallary = _isShow;
        if (_isShow)
            this.selitem = _index;
        else
            this.selitem = 0;
    },
    selectImages(_images, _index){
        if (_images.length > 0){
            this.selectedImage[_index] = _images[0];
            this.isSelected[_index] = true;
        }
        // console.log(this.selectedImage, _index)
        this.visibleGallary = false;
    },
    cancelImage(_index){
        // console.log(this.selectedImage, _index)
        // console.log(this.selectedImage, this.isSelected)
        this.selectedImage[_index] = '';
        this.isSelected.splice(_index, 1, false)
        // console.log(this.selectedImage, this.isSelected)
    },
    getImage(_index){
        this.$emit('getimage', this.selectedImage[_index]);
    },
    getKeyMealList(){
        this.$emit('getKeyMealList', this.keysListMeal);
    },
    onStart(){
        // console.log("======= onStart");
        // this.drag=true;
    },
    onEnd() {
        // console.log("======= onEnd", this.keysListMeal);
    //    this.drag=false;
    },
  }
}
</script>