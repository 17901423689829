import Vue from 'vue'
import Vuex from 'vuex'
import { setStore, getStore } from '@/config/utils'

Vue.use(Vuex)
const user = getStore('user')
const currency = getStore('currency')
const pass = getStore('pass')

export default new Vuex.Store({
  state: {
    loginAdmin: user,
    selectedCurrency: currency,
    loginPassword: pass,
  },
  mutations: {
    setLoginUser(state, user) {
      state.loginAdmin = user
      setStore('user', user)
    },
    setCurrency(state, currency) {
      state.selectedCurrency = currency
      setStore('currency', currency)
    },
    setLoginPass(state, pass) {
      state.loginPassword = pass
      setStore('pass', pass)
    },
  },
  actions: {
  },
  modules: {
    getLoginUserInfo(state) {
      return state.loginAdmin
    },
    getCurrencyInfo(state) {
      return state.selectedCurrency
    },
    getLoginPass(state) {
      return state.loginPassword
    },
  }
})
