<template> 

  <div style="width:100%">
      <div style="width:100%;height:auto;margin:0;background-color:white;border:1px solid #ddd;border-top:none;border-right:none;border-bottom:none;padding:5px;" v-for="item in keysListTransferDate" :key="item">  
        <a-row>
          <div style="width:100%;display: flex;margin:0;border:1px solid #ddd">
            <div style="width:90%;margin:10px;margin-bottom:0;padding:10px;border:1px solid #dddddd;padding-bottom:0;margin-bottom:0">
              <div style="width:100%;">
                  <div style="border:1px solid #dddddd;margin:0;font-size: 14px;line-height:28px;">Transfer Start</div>
                  <a-form-item style="border:1px solid #dddddd;border-top:none;border-bottom:none;padding:10px;margin-bottom:0">
                      <a-date-picker style="width:100%;" placeholder=""
                          :showToday="false"
                          valueFormat="YYYY-MM-DD"
                          v-decorator="[
                              `${title}Transferstart${id}[${item}]`,
                              {
                                  initialValue: arr != undefined && arr.condition != undefined && arr.condition.transferDate != undefined && arr.condition.transferDate[item] != undefined && arr.condition.transferDate[item].transferstart != undefined  && arr.condition.transferDate[item].transferstart != null ? arr.condition.transferDate[item].transferstart.toString().split('T')[0] : null,
                                  rules: [{ required: false, message: 'transferstart !' }]
                              }
                          ]"
                          >
                      </a-date-picker>
                  </a-form-item>
              </div>
              <div style="width:100%;">
                  <div style="border:1px solid #dddddd;font-size: 14px;line-height:28px;">Transfer End</div>
                  <a-form-item style="border:1px solid #dddddd;border-top:none;padding:10px">
                      <a-date-picker style="width:100%;" placeholder=""
                          :showToday="false"
                          valueFormat="YYYY-MM-DD"
                          v-decorator="[
                              `${title}Transferend${id}[${item}]`,
                              {
                                initialValue: arr != undefined && arr.condition != undefined && arr.condition.transferDate != undefined && arr.condition.transferDate[item] != undefined && arr.condition.transferDate[item].transferend ? arr.condition.transferDate[item].transferend.toString().split('T')[0] : null,
                                  rules: [{ required: false, message: 'transferend !' }]
                              }
                          ]"
                          >
                      </a-date-picker>
                  </a-form-item>
              </div>
            </div>
            <div style="margin-top:auto;margin-bottom:auto;width:15%">
                <a-button shape="circle" type="dashed" icon="minus" @click="removeRowTransferDate(item)" class="minusRowBtn" :size="'small'"></a-button>
            </div>
          </div>
        </a-row>
      </div> 
      <div style="display:flex;margin-bottom:10px;margin-right:3px">
          <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowTransferDate" type="primary" :size="'small'">
              Add Row
          </a-button>
      </div>
  </div>   
</template>
<script>
import moment from 'moment';
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    id: {
      type: Number, 
    },
    arr: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  components: {
      
  },
  data () {
    return {
      idTransferDate: 0,
      keysListTransferDate: [],
      
      dateFormat : (val) => {
        return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
      }
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    this.init()
  },
  methods: {
    moment,
    init () {
      const arr = []
      if (this.arr.condition != undefined && this.arr.condition.transferDate != undefined && this.arr.condition.transferDate.length != 0) {
        this.idTransferDate = 0;
        for (let i = 0; i < (this.arr.condition.transferDate).length; i++) {
          arr.push(i)
          this.idTransferDate = this.idTransferDate + 1
        }
        this.keysListTransferDate = arr
      }
      else{
        this.keysListTransferDate = [0]
      }
    },

    removeRowTransferDate (k) {
      if (this.keysListTransferDate.length === 0) {
        return
      }
      this.keysListTransferDate = this.keysListTransferDate.filter(item => item !== k)
    },
    addRowTransferDate () {
      if(this.keysListTransferDate.length === 1 && this.idTransferDate === 0){
        this.keysListTransferDate = this.keysListTransferDate.concat(1)
        this.idTransferDate = 2
      }else{
        this.keysListTransferDate = this.keysListTransferDate.concat(this.idTransferDate)
        this.idTransferDate = this.idTransferDate + 1
      }
    },
  }
}
</script>