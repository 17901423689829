<template>
  <div>
    <div style="display:flex">
      <div style="display:flex;">
        <a-select style="width:150px;margin-top:auto;margin-bottom:auto" v-model="controlType" slot="addonAfter" default-value="default">
            <a-select-option value="default">
                <div style="font-size:12px">Bulk actions</div>
            </a-select-option>
            <a-select-option value="remove">
                <div style="font-size:12px">Remove</div>
            </a-select-option>
            <!-- <a-select-option value="aprove">
                <div style="font-size:12px">Approve</div>
            </a-select-option> -->
            <a-select-option value="cancel">
                <div style="font-size:12px">Cancel</div>
            </a-select-option>
            <a-select-option value="pending">
                <div style="font-size:12px">Pending</div>
            </a-select-option>
            <a-select-option value="clientComplete">
                <div style="font-size:12px">Client Complete</div>
            </a-select-option>
            <a-select-option value="hotelComplete">
                <div style="font-size:12px">Hotel Complete</div>
            </a-select-option>
            <a-select-option value="agentCommissionDue">
                <div style="font-size:12px">Agent Commission Due</div>
            </a-select-option>
            <a-select-option value="bookingComplete">
                <div style="font-size:12px">Booking Complete</div>
            </a-select-option>
        </a-select>
          <a-button style="margin-left:2px" slot="enterButton" @click="onApply" type="primary" ghost>
            Apply
          </a-button>
      </div>
      <div style="display:flex;margin-left:15px">
        <!-- <div  style="display:flex;margin-right:5px">
          <a-input-number v-model="priceStart" style="width:130px" :min="0" placeholder="First Price"/>
          <a-input-number v-model="priceEnd" style="width:130px" :min="0" placeholder="Second Price"/>
        </div> -->
        <div style="display:flex">
          <a-date-picker v-model="periodStart" style="width:150px" placeholder="Start"
                                  :showToday="false"
                                  valueFormat="YYYY-MM-DD"/>
          <a-date-picker v-model="periodEnd" style="width:150px" placeholder="End"
                                  :showToday="false"
                                  valueFormat="YYYY-MM-DD"/>
        </div>
        <a-button style="margin-left:2px" slot="enterButton" @click="onFilter" type="primary" ghost>
          Filter
        </a-button>
      </div>

    </div>
    <a-table 
    :columns="columns" 
    :data-source="bookingList" 
    :pagination="pagination" 
    :row-selection="rowSelection"
    :scroll="{ x: roomCellWidth, y: 0 }" 
    :style="{width : '100%'}"
    @change="handleTableChange" rowKey="id"
    bordered>
    
        <span slot="confirmDate" slot-scope="text, record">
          <div>{{record.confirmDate != undefined? moment(Date.parse(record.confirmDate)).utc(1412144245453).format('YYYY-MM-DD HH:mm'): ''}}</div> 
          <!-- <div>{{record.confirmDate != undefined? record.confirmDate.toString().slice(0, 10) : ''}}</div> -->
        </span>
        
        <span slot="checkinDate" slot-scope="text, record">
          <div>{{record.checkinDate != undefined? record.checkinDate.toString().slice(0, 10) : ''}}</div>
        </span>
        
        <span slot="checkoutDate" slot-scope="text, record">
          <div>{{record.checkoutDate != undefined? record.checkoutDate.toString().slice(0, 10) : ''}}</div>
        </span>
        <span slot="confirmationNo" slot-scope="text, record">
          <div style="cursor:pointer;display:flex" @click="editConfirmationNo(record)">
            <div>{{record.confirmationNo}}</div>
            <a-icon type="form" style="margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right: 10px;"/>
          </div>
        </span>


        <span slot="clientComplete" slot-scope="text, record">
          <div style="cursor:pointer;display:flex" @click="editClientComplete(record)">
            <div style="word-wrap: break-word;word-break: break-all;">{{record.clientComplete}}</div>
            <a-icon type="form" style="margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right: 10px;"/>
          </div>
          <div>{{record.clientCompleteDate != undefined ? moment(record.clientCompleteDate).utc().format('YYYY-MM-DD') : ''}}</div>
        </span>
        <span slot="hotelComplete" slot-scope="text, record">
          <div style="cursor:pointer;display:flex" @click="editHotelComplete(record)">
            <div style="word-wrap: break-word;word-break: break-all;">{{record.hotelComplete}}</div>
            <a-icon type="form" style="margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right: 10px;"/>
          </div>
          <div>{{record.hotelCompleteDate != undefined ? moment(record.hotelCompleteDate).utc().format('YYYY-MM-DD') : ''}}</div>
        </span>
        <span slot="agentCommissionDue" slot-scope="text, record">
          <div style="cursor:pointer;display:flex" @click="editAgentCommissionDue(record)">
            <div style="word-wrap: break-word;word-break: break-all;">{{record.agentCommissionDue}}</div>
            <a-icon type="form" style="margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right: 10px;"/>
          </div>
          <div>{{record.agentCommissionDueDate != undefined ? moment(record.agentCommissionDueDate).utc().format('YYYY-MM-DD') : ''}}</div>
        </span>
        <span slot="bookingComplete" slot-scope="text, record">
          <div style="cursor:pointer;display:flex" @click="editBookingComplete(record)">
            <div style="word-wrap: break-word;word-break: break-all;">{{record.bookingComplete}}</div>
            <a-icon type="form" style="margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right: 10px;"/>
          </div>
          <div>{{record.bookingCompleteDate != undefined ? moment(record.bookingCompleteDate).utc().format('YYYY-MM-DD') : ''}}</div>
        </span>


        <span slot="captureBalance" slot-scope="text, record">
          <div style="cursor:pointer;display:flex">
            <div>{{record.captureBalance != undefined ? addZeroes(Math.round(record.captureBalance * 100) / 100) : ""}}</div>
          </div>
        </span>
        <span slot="remainingBalance" slot-scope="text, record">
          <div style="cursor:pointer;display:flex">
            <div>{{record.remainingBalance != undefined ? addZeroes(Math.round(record.remainingBalance * 100) / 100) : ""}}</div>
          </div>
        </span>
        <span slot="cost" slot-scope="text, record">
          <div style="cursor:pointer;display:flex">
            <div>{{record.cost != undefined ? addZeroes(Math.round(record.cost * 100) / 100) : ""}}</div>
          </div>
        </span>
        <span slot="createdDate" slot-scope="text, record">
          <div>{{record.createdDate != undefined? moment(Date.parse(record.createdDate)).utc(1412144245453).format('YYYY-MM-DD HH:mm'): ''}}</div> 
          <!-- Pacific time zone    1412144245453-->
        </span>
        <span slot="costHotelToVue" slot-scope="text, record">
          
          <div style="cursor:pointer;display:flex" @click="editCostHotelToVue(record)">
            <div>${{Math.round(record.costHotelToVue * 100) / 100}}</div>
            <a-icon type="form" style="margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right: 10px;"/>
          </div>
        </span>
        <span slot="costClientSellingRate" slot-scope="text, record">
          <div>
            ${{Math.round(record.costClientSellingRate * 100) / 100}}
          </div>
        </span>
        <span slot="extraPersonSupplement" slot-scope="text, record">
          <div>
            ${{Math.round(record.extraPersonSupplement * 100) / 100}}
          </div>
        </span>
        <span slot="costAddonRate" slot-scope="text, record">
          <div>
            ${{Math.round(record.costAddonRate * 100) / 100}}
          </div>
        </span>
        <span slot="memo" slot-scope="text">
          
          <a-tooltip placement="top">
            <template slot="title">
              <div>
                {{text}}
              </div>
            </template>
            <div style="text-overflow: ellipsis;white-space: nowrap;width: 100%;overflow: hidden;">
              {{text}}
            </div>
          </a-tooltip>
        </span>
        <span slot="agentCommission" slot-scope="text, record">
          <div>
            {{record.agentCommission != undefined && record.agentCommission != '' ? "$" + Math.round(record.agentCommission * 100) / 100 : ''}}
          </div>
        </span>
        <span slot="vueProfitFees" slot-scope="text, record">
          <div>
            ${{Math.round(record.vueProfitFees * 100) / 100}}
          </div>
        </span>
        <span slot="creditCardFee3" slot-scope="text, record">
          <div>
            ${{Math.round(record.creditCardFee3 * 100) / 100}}
          </div>
        </span>
        <span slot="vueProfitFees3" slot-scope="text, record">
          <div>
            {{Math.round(record.vueProfitFees3 * 100) / 100}}
          </div>
        </span>
        <!-- <span slot="dueDateClients" slot-scope="text, record, index">
          <div>
            <a-date-picker v-model="clientsDate[index]" :showToday="false" @change="onChangeClientsDate(record, index)" placeholder="">
              <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                  v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                      {{ moment(current).utc().date() }}
                  </div>
              </template>
            </a-date-picker>
          </div>
        </span> -->
        
        <span slot="dueDateClients" slot-scope="text, record">
          <div style="cursor:pointer;display:flex" @click="editClientsDate(record)">
            <div>{{record.dueDateClients != undefined && record.dueDateClients != '' ? moment(record.dueDateClients).utc().format('MMMM D, YYYY') : ''}}</div>
            <a-icon type="form" style="margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right: 10px;"/>
          </div>
        </span>
<!-- 
        <span slot="dueDateHotel" slot-scope="text, record, index">
          <div>
            <a-date-picker v-model="hotelDate[index]" :showToday="false" @change="onChangeHotelDate(record, index)" placeholder="">
              <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                  v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                      {{ moment(current).utc().date() }}
                  </div>
              </template>
            </a-date-picker>
          </div>
        </span> -->
        
        <span slot="dueDateHotel" slot-scope="text, record">
          <div style="cursor:pointer;display:flex" @click="editDateHotel(record)">
            <div>{{record.dueDateHotel != undefined && record.dueDateHotel != '' ? moment(record.dueDateHotel).utc().format('MMMM D, YYYY') : ''}}</div>
            <a-icon type="form" style="margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right: 10px;"/>
          </div>
        </span>

        <span slot="status" slot-scope="text, record">
          
          <a-tag
              :color="record.status === 2 ? 'green' : record.status == 3 ? 'red' : record.status == 1 || record.status == 4 ? 'geekblue' : record.status == 6 ? 'geekblue' : record.status == 7 ? 'green' : record.status == 8 ? 'green' : record.status == 9 ? 'green' : record.status == 10 ? 'green' : 'yellow'"
          >
            {{record.status == 2 ? 'Confirmed' : record.status == 3 ? 'Canceled' : record.status == 1 || record.status == 4 ? 'Pending' : record.status == 6 ? 'Hold' : record.status == 7 ? 'Client Complete' : record.status == 8 ? 'Hotel Complete' : record.status == 9 ? 'Agent Commission Due' : record.status == 10 ? 'Booking Complete' : 'Abandoned'}}
          </a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <div>
            <div>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Client Complete</span>
                </template>
                <a-icon style="margin-left:5px;color:#8dc18d" type="check-circle" @click="ApplyClientCompleteOne(record.id)"/>
              </a-tooltip>

              <a-tooltip placement="top">
                <template slot="title">
                  <span>Hotel Complete</span>
                </template>
                <a-icon style="margin-left:5px;color:#1b971b" type="check-circle" @click="ApplyHotelCompleteOne(record.id)"/>
              </a-tooltip>

              <a-tooltip placement="top">
                <template slot="title">
                  <span>Agent Commission Due</span>
                </template>
                <a-icon style="margin-left:5px;color:#2a872a" type="check-circle" @click="ApplyAgentCommissionDueOne(record.id)"/>
              </a-tooltip>
              
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Booking Complete</span>
                </template>
                <a-icon style="margin-left:5px;color:#000000" type="check-circle" @click="ApplyBookingCompleteOne(record.id)"/>
              </a-tooltip>
            </div>
            <div>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Detail</span>
                </template>
                <a-icon style="margin-left:5px;color:#003300" type="container" @click="DetailBooking(record.id)"/>
              </a-tooltip>
              <a-tooltip v-if="(record.status != 1 && record.status != 4)" placement="top">
                <template slot="title">
                  <span>Pending</span>
                </template>
                <a-icon style="margin-left:5px;color:blue" type="interaction" @click="PendingOne(record.id)"/>
              </a-tooltip>

              <a-tooltip v-if="(record.status != 3)" placement="top">
                <template slot="title">
                  <span>Cancel</span>
                </template>
                <a-icon style="margin-left:5px;color:red" type="close-circle" @click="cancelOne(record.id)"/>
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Remove</span>
                </template>
                <a-icon style="margin-left:5px;color:red" type="delete" @click="RemoveOne(record.id)"/>
              </a-tooltip>
            </div>
          </div>
          
          <!-- <a-tooltip v-if="(record.status == 1 || record.status == 4)" placement="top">
            <template slot="title">
              <span>Approve</span>
            </template>
            <a-icon style="margin-left:5px;color:green" type="check-circle" @click="AproveOne(record.id)"/>
          </a-tooltip> -->

          



          
        </span>
    </a-table>
    <div style="display:flex;position:absolute;bottom: 10px;z-index: 1;">
      <a-select style="width:170px;margin-top:auto;margin-bottom:auto;margin-left:2px" v-model="filterRoomCount" slot="addonAfter" default-value=0>
          <a-select-option v-for="item in filterRoomList" :key="item.name" :value="item.id">
              <div style="font-size:12px">{{ item.name }}</div>
          </a-select-option>
      </a-select>
      <a-button style="margin-left:2px" slot="enterButton" @click="onFilterCount" type="primary" ghost>
        Filter Count
      </a-button>
    </div>
    
    <a-modal
        :visible="visibleEditConfirm"
        @ok="handleEditConfirmOK"
        @cancel="handleEditConfirmCancel"
    >
      <div style="margin-top:30px;font-size:16px;font-weight:bold">
        {{`Total Balance : ${totalPrice}`}}
      </div>
      <div style="margin-top:15px;font-weight:600">
        Capture Balance
      </div>
      <a-input-number style="width: 100%;" placeholder="Capture Balance" v-model="newCaptureBalance" />
      
      <div style="margin-top:10px;font-weight:600">
        Confirmation No
      </div>
      <a-input placeholder="Confirmation No" v-model="newConfirmationNo" />
    </a-modal>
    
    <a-modal
      title="Client Complete"
      :visible="visibleEditClientComplete"
      @ok="handleEditClientCompleteOK"
      @cancel="handleEditClientCompleteCancel"
    >
      <a-input placeholder="Client Complete" v-model="newClientComplete" />
    </a-modal>

    <a-modal
      title="Hotel Complete"
      :visible="visibleEditHotelComplete"
      @ok="handleEditHotelCompleteOK"
      @cancel="handleEditHotelCompleteCancel"
    >
      <a-input placeholder="Hotel Complete" v-model="newHotelComplete" />
    </a-modal>

    <a-modal
      title="Agent Commission Due"
      :visible="visibleEditAgentCommissionDue"
      @ok="handleEditAgentCommissionDueOK"
      @cancel="handleEditAgentCommissionDueCancel"
    >
      <a-input placeholder="Agent Commission Due" v-model="newAgentCommissionDue" />
    </a-modal>

    <a-modal
      title="Booking Complete"
      :visible="visibleEditBookingComplete"
      @ok="handleEditBookingCompleteOK"
      @cancel="handleEditBookingCompleteCancel"
    >
      <a-input placeholder="Booking Complete" v-model="newBookingComplete" />
    </a-modal>

    <a-modal
      title="Cost Hotel To Vue"
      :visible="visibleEditCostHotelToVue"
      @ok="handleEditCostHotelToVueOk"
      @cancel="handleEditCostHotelToVueCancel"
    >
      <a-input-number style="width: 100%;" placeholder="Cost" v-model="oldCostHotelToVue" />
      
    </a-modal>

    <a-modal
      title="Clients Due Date"
      :visible="visibleEditClientsDate"
      @ok="handleEditClientsDateOk"
      @cancel="handleEditClientsDateCancel"
    >
      <a-date-picker v-model="oldClientsDate" placeholder="" 
                                  :showToday="false"
                                  valueFormat="YYYY-MM-DD">
        <!-- <template slot="dateRender" slot-scope="current">
            <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
            v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                {{ moment(current).utc().date() }}
            </div>
        </template> -->
      </a-date-picker>
    </a-modal>
    <a-modal
      title="Hotel Due Date"
      :visible="visibleEditDateHotel"
      @ok="handleEditDateHotelOk"
      @cancel="handleEditDateHotelCancel"
    >
      <a-date-picker v-model="oldDateHotel" placeholder=""
                                  :showToday="false"
                                  valueFormat="YYYY-MM-DD">
        <!-- <template slot="dateRender" slot-scope="current">
            <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
            v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                {{ moment(current).utc().date() }}
            </div>
        </template> -->
      </a-date-picker>
    </a-modal>
  </div>
</template>
<script>
import { api } from '../../helpers/Helpers';
var moment = require('moment-timezone');
var selecetedList = [];
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    selecetedList = selectedRows;
    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selecetedList);
  },
  onSelect: (record, selected, selectedRows) => {
    // console.log("onSelect === ");
    // console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    // console.log("onSelectAll === ");
    // console.log(selectedRows);
    // console.log(selected);
    // console.log(changeRows);
  },
};
const filterRoomList = [
  {
    id:10,
    name:'10 count'
  },
  {
    id:25,
    name:'25 count'
  },
  {
    id:50,
    name:'50 count'
  },
];
export default {
  data() {
    return {
      controlType:'default',
      userType:'default',
      // priceStart:null,
      // priceEnd:null,
      periodStart:null,
      periodEnd:null,
      rowSelection,
      selecetedList,
      filterRoomCount:10,
      filterRoomList,
      pagination: {
        onChange: page => {
          // console.log(page);
        },
        pageSize: 10,
      },
      userName:'',


      visibleEditCostHotelToVue:false,
      oldCostHotelToVue:new Date,
      editCostHotelToVueid:'',

      visibleEditClientsDate:false,
      oldClientsDate:new Date,
      editClientsDateid:'',
      
      visibleEditDateHotel:false,
      oldDateHotel:new Date,
      editDateHotelid:'',
      
      visibleEditClientComplete:false,
      newClientComplete:'',
      editClientCompleteId:'',

      visibleEditHotelComplete:false,
      newHotelComplete:'',
      editClientHotelId:'',

      visibleEditAgentCommissionDue:false,
      newAgentCommissionDue:'',
      editAgentCommissionDueId:'',

      visibleEditBookingComplete:false,
      newBookingComplete:'',
      editClientBookingId:'',


      visibleEditConfirm:false,

      totalPrice:0,
      newCaptureBalance:0,
      newConfirmationNo:'',
      editConfrimationId:'',
      editConfrimationInfo:{},
      
      clientsDate: [],
      hotelDate: [],
      roomCellWidth: 'calc(3000px + 60%)',

      dateFormat : (val) => {
        return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
      }
    };
  },
  props: [
      'bookingList',
      'columns',
      'tableCellCount'
  ],
  components: {
  },
  
  created: function () {
    
    // console.log('u2====:')  
    // console.log(this.bookingList) //prints out an empty string
    for (var i = 0; i < this.bookingList.length; i++) {
      this.clientsDate[i] = null;
      if (this.bookingList[i].dueDateClients != undefined && this.bookingList[i].dueDateClients != '')
        this.clientsDate[i] = moment(this.bookingList[i].dueDateClients).utc();//this.bookingList[i].dueDateClients;
      this.hotelDate[i] = null;
      if (this.bookingList[i].dueDateHotel != undefined && this.bookingList[i].dueDateHotel != '')
        this.hotelDate[i] = moment(this.bookingList[i].dueDateHotel).utc();
    }
    // console.log(this.clientsDate)  
    // // console.log('user data from parent component:')
    // // console.log(this.bookingList) //prints out an empty string
    this.pagination.pageSize = this.tableCellCount;
    this.filterRoomCount = this.tableCellCount;
    
    if (this.$store.state.loginAdmin !== null && this.$store.state.loginAdmin != undefined){
      if (this.$store.state.loginAdmin.loginType === 'api'){
        this.userName = this.$store.state.loginAdmin.api.user.name;
      }
      else if (this.$store.state.loginAdmin.loginType === 'google'){
        this.userName = this.$store.state.loginAdmin.google.user.name;
      }
      else if (this.$store.state.loginAdmin.loginType === 'fb'){
        this.userName = this.$store.state.loginAdmin.fb.user.name;
      }
      else{
        this.userName = '';
      }
    }
    else{
      this.userName = '';
    }
  },
  mounted: function () {
  },
  methods: {
    moment,
    
      addZeroes(num){
            let textNum = num.toString();
                // // console.log("textNum  === ", Math.max(textNum.split('.')[1]?.length, 2));
            var _len = 0;
            if (textNum.split('.')[1] != undefined)
                _len = textNum.split('.')[1].length;
            let decimalResult = Number(textNum).toFixed(Math.max(_len, 2) || 2);
            let thousand = decimalResult.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return thousand;
        },
      onApply(){
          // console.log(this.controlType)
        if (this.controlType == 'default'){
          return;
        }
        else if (this.controlType == 'remove'){
          this.removeBooking();
        }
        // else if (this.controlType == 'aprove'){
        //   this.aproveBooking();
        // }
        else if (this.controlType == 'clientComplete'){
          this.clientCompleteBooking();
        }
        else if (this.controlType == 'hotelComplete'){
          this.hotelCompleteBooking();
        }
        else if (this.controlType == 'agentCommissionDue'){
          this.agentCommissionDueBooking();
        }
        else if (this.controlType == 'bookingComplete'){
          this.bookingCompleteBooking();
        }

        else if (this.controlType == 'cancel'){
          this.cancelBooking();
        }
        else if (this.controlType == 'pending'){
          this.pendingBooking();
        }
        
      },
      removeBooking:async function(){
        if (selecetedList.length <= 0)
          return;
          
        this.$confirm({
          title: 'Do you want to delete these bookings?',
          // content: h => <div style="color:red;">Some descriptions</div>,
          onOk: ()=> {
            this.removeBookingResult()
          },
          onCancel() {
            
          },
          class: 'test',
        });
      },
      
      removeBookingResult:async function(){

        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.removeBookingRequest(selecetedList[i].id);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      aproveBooking:async function(){
        if (selecetedList.length <= 0)
          return;
          
        const _info = {
          status: 2,
          confirmDate: new Date(),
          confirmer: this.userName
        }
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.bookingRequestUpdate(_info, selecetedList[i].id);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      clientCompleteBooking:async function(){
        if (selecetedList.length <= 0)
          return;
          
        const _info = {
          status: 7,
          clientCompleteDate: new Date(),
        }
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.bookingRequestUpdate(_info, selecetedList[i].id);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      hotelCompleteBooking:async function(){
        if (selecetedList.length <= 0)
          return;
          
        const _info = {
          status: 8,
          hotelCompleteDate: new Date(),
        }
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.bookingRequestUpdate(_info, selecetedList[i].id);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      agentCommissionDueBooking:async function(){
        if (selecetedList.length <= 0)
          return;
          
        const _info = {
          status: 9,
          agentCommissionDueDate: new Date(),
        }
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.bookingRequestUpdate(_info, selecetedList[i].id);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      bookingCompleteBooking:async function(){
        if (selecetedList.length <= 0)
          return;
          
        const _info = {
          status: 10,
          bookingCompleteDate: new Date(),
        }
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.bookingRequestUpdate(_info, selecetedList[i].id);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      cancelBooking:async function(){
        if (selecetedList.length <= 0)
          return;
          
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.bookingRequestCancel(selecetedList[i].id);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      pendingBooking:async function(){
        if (selecetedList.length <= 0)
          return;
        
        const _info = {
          status: 1,
        }
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.bookingRequestUpdate(_info, selecetedList[i].id);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      RemoveOne: function(_id){
        this.$confirm({
          title: 'Do you want to delete this booking?',
          // content: h => <div style="color:red;">Some descriptions</div>,
          onOk: ()=> {
            this.RemoveOneResult(_id);
          },
          onCancel() {
            
          },
          class: 'test',
        });
      },
      RemoveOneResult:async function(_id){
        // console.log("========", _id)
        const res = await api.removeBookingRequest(_id);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },
      
      AproveOne:async function(_id){
        const _info = {
          status: 2,
          confirmDate: new Date(),
          confirmer: this.userName
        }
        const res = await api.bookingRequestUpdate(_info, _id);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },
      ApplyClientCompleteOne:async function(_id){
        const _info = {
          status: 7,
          clientCompleteDate: new Date(),
        }
        const res = await api.bookingRequestUpdate(_info, _id);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },
      ApplyHotelCompleteOne:async function(_id){
        const _info = {
          status: 8,
          hotelCompleteDate: new Date(),
        }
        const res = await api.bookingRequestUpdate(_info, _id);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },
      ApplyAgentCommissionDueOne:async function(_id){
        const _info = {
          status: 9,
          agentCommissionDueDate: new Date(),
        }
        const res = await api.bookingRequestUpdate(_info, _id);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },
      ApplyBookingCompleteOne:async function(_id){
        const _info = {
          status: 10,
          bookingCompleteDate: new Date(),
        }
        const res = await api.bookingRequestUpdate(_info, _id);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },
      cancelOne:async function(_id){
        const res = await api.bookingRequestCancel(_id);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },
      
      PendingOne:async function(_id){
        const _info = {
          status: 1,
        }
        const res = await api.bookingRequestUpdate(_info, _id);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },

      DetailBooking:async function(_id){
        // console.log(_id)
        // this.$router.push({ path: 'detailbooking', query: {id:_id}})
          
        let routeData = this.$router.resolve({ path: '/admin/detailbooking/', query: {id:_id}});
        window.open(routeData.href, '_blank');
      },

      onChangeRole(){
        // console.log(this.userType)
        if (this.userType == 'default'){
          return;
        }
        else if (this.userType == 'user'){
          this.changeToUser();
        }
        else if (this.userType == 'admin'){
          this.changeToAdmin();
        }
        else if (this.userType == 'agent'){
          this.changeToAgent();
        }
      },
      changeToUser:async function(){
        if (selecetedList.length <= 0)
          return;
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.changeUserTo(selecetedList[i].id, 1);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      changeToAgent:async function(){
        if (selecetedList.length <= 0)
          return;
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.changeUserTo(selecetedList[i].id, 4);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      changeToAdmin:async function(){
        if (selecetedList.length <= 0)
          return;
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.changeUserTo(selecetedList[i].id, 2048);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },

      
      onFilter(){
        this.$emit('listPeriodFilter', moment.utc(this.periodStart).tz("America/Los_Angeles"), moment.utc(this.periodEnd).tz("America/Los_Angeles"), this.filterRoomCount);
        // this.$emit('listPeriodFilter', this.priceStart, this.priceEnd, this.periodStart, this.periodEnd, this.filterRoomCount);
        // else if (this.filterType == 'pending'){
        //   this.$emit('listFilter', 'Pending');
        // }
        // else if (this.filterType == 'aproved'){
        //   this.$emit('listFilter', 'Approved');
        // }
        // else if (this.filterType == 'denied'){
        //   this.$emit('listFilter', 'Denied');
        // }
      },
      onFilterCount(){
        this.$emit('listFilterCount', this.filterRoomCount);
      },

      handleTableChange(pagination, filters, sorter) {
        // console.log(pagination);
        // console.log(filters);
        // console.log(sorter);
        this.bookingListOrder(sorter.field, sorter.order);
      },
      bookingListOrder(_field, _order){
        // this.$emit('listOrder', _field, _order);
        // console.log("===this.bookingList========", this.bookingList);
          if (_order == "ascend"){
            this.bookingList.sort(function(a,b) {
                if (a[_field] != undefined && a[_field] != ''){
                  // console.log(a[_field], "===========", b[_field], a[_field] > b[_field], a[_field] < b[_field]);
                }
                return (a[_field] > b[_field]) ? 1 : ((b[_field] > a[_field]) ? -1 : 0);
              } 
            );
          }
          else if(_order == "descend"){
            this.bookingList.sort(function(a,b) {return (a[_field] < b[_field]) ? 1 : ((b[_field] < a[_field]) ? -1 : 0);} );
          }
          else if (_field != undefined){
            this.bookingList.sort(function(a,b) {return (a['hotelname'] > b['hotelname']) ? 1 : ((b['hotelname'] > a['hotelname']) ? -1 : 0);} );
          }
      },
      
      editCostHotelToVue(_recordInfo){
        this.editCostHotelToVueid = _recordInfo.id;
        this.oldCostHotelToVue = _recordInfo.costHotelToVue != undefined && _recordInfo.costHotelToVue != '' ? _recordInfo.costHotelToVue : null;
        // console.log("===_info==", this.oldCostHotelToVue);
        this.visibleEditCostHotelToVue = true;
      },
      handleEditCostHotelToVueOk:async function(){
        var _info = {
          hoteltovue: this.oldCostHotelToVue,//this.oldClientsDate,
        }
        const res = await api.bookingRequestUpdate(_info, this.editCostHotelToVueid);
        // console.log(res, this.editCostHotelToVueid);
        this.visibleEditCostHotelToVue = false;
        this.$emit('listUpdate');
      },
      
      handleEditCostHotelToVueCancel(){
        
        this.visibleEditCostHotelToVue= false;
      },


      editClientsDate(_recordInfo){
        // console.log(_recordInfo);
        this.editClientsDateid = _recordInfo.id;
        this.oldClientsDate = _recordInfo.dueDateClients != undefined && _recordInfo.dueDateClients != null && _recordInfo.dueDateClients != ''? _recordInfo.dueDateClients.toString().split('T')[0] : null;
        // console.log("===_info==", this.oldClientsDate);
        this.visibleEditClientsDate = true;
      },

      handleEditClientsDateOk:async function(){
        var _info = {
          dueDateClients: moment.utc(this.oldClientsDate).tz("America/Los_Angeles"),//this.oldClientsDate,
        }
        // console.log("===dueDateClients==", this.dueDateClients);
        const res = await api.bookingRequestUpdate(_info, this.editClientsDateid);
        // console.log(res, this.editClientsDateid);
        this.visibleEditClientsDate = false;
        this.$emit('listUpdate');
      },
      
      handleEditClientsDateCancel(){
        
        this.visibleEditClientsDate = false;
      },

      editDateHotel(_recordInfo){
        // console.log(_recordInfo);
        this.editDateHotelid = _recordInfo.id;
        this.oldDateHotel = _recordInfo.dueDateHotel != undefined && _recordInfo.dueDateHotel != null && _recordInfo.dueDateHotel != '' ? _recordInfo.dueDateHotel.toString().split('T')[0] : null;
        
        // console.log("===_info==", this.oldDateHotel);
        this.visibleEditDateHotel = true;
      },

      handleEditDateHotelOk:async function(){
        var _info = {
          dueDateHotel: moment.utc(this.oldDateHotel).tz("America/Los_Angeles"),
        }
        // console.log("===_info==", _info);
        const res = await api.bookingRequestUpdate(_info, this.editDateHotelid);
        // console.log(res, this.editDateHotelid);
        this.visibleEditDateHotel = false;
        this.$emit('listUpdate');
      },
      
      handleEditDateHotelCancel(){
        
        this.visibleEditClientsDate = false;
      },



      editClientComplete(_recordInfo){
        // console.log(_recordInfo);
        
        this.editClientCompleteId = _recordInfo.id;
        this.newClientComplete = _recordInfo.clientComplete;
        
        this.visibleEditClientComplete = true;
      },
      
      handleEditClientCompleteOK:async function(){
        var _info = {
          clientComplete: this.newClientComplete,
          // clientCompleteDate: new Date(),
        }
        // console.log("===_info==", _info);
        const res = await api.bookingRequestUpdate(_info, this.editClientCompleteId);
        // console.log(res, this.editClientCompleteId)
        
        this.$emit('listUpdate');
        this.visibleEditClientComplete = false;
      },
      handleEditClientCompleteCancel(){
        
        this.visibleEditClientComplete = false;
      },

      editHotelComplete(_recordInfo){
        // console.log(_recordInfo);
        
        this.editHotelCompleteId = _recordInfo.id;
        this.newHotelComplete = _recordInfo.hotelComplete;
        
        this.visibleEditHotelComplete = true;
      },
      
      handleEditHotelCompleteOK:async function(){
        var _info = {
          hotelComplete: this.newHotelComplete,
          // clientCompleteDate: new Date(),
        }
        // console.log("===_info==", _info);
        const res = await api.bookingRequestUpdate(_info, this.editHotelCompleteId);
        // console.log(res, this.editHotelCompleteId)
        
        this.$emit('listUpdate');
        this.visibleEditHotelComplete = false;
      },
      handleEditHotelCompleteCancel(){
        
        this.visibleEditHotelComplete = false;
      },

      editAgentCommissionDue(_recordInfo){
        // console.log(_recordInfo);
        
        this.editAgentCommissionDueId = _recordInfo.id;
        this.newAgentCommissionDue = _recordInfo.agentCommissionDue;
        
        this.visibleEditAgentCommissionDue = true;
      },
      
      handleEditAgentCommissionDueOK:async function(){
        var _info = {
          agentCommissionDue: this.newAgentCommissionDue,
          // clientCompleteDate: new Date(),
        }
        // console.log("===_info==", _info);
        const res = await api.bookingRequestUpdate(_info, this.editAgentCommissionDueId);
        // console.log(res, this.editAgentCommissionDueId)
        
        this.$emit('listUpdate');
        this.visibleEditAgentCommissionDue = false;
      },
      handleEditAgentCommissionDueCancel(){
        
        this.visibleEditAgentCommissionDue = false;
      },
      
      editBookingComplete(_recordInfo){
        // console.log(_recordInfo);
        
        this.editBookingCompleteId = _recordInfo.id;
        this.newBookingComplete = _recordInfo.bookingComplete;
        
        this.visibleEditBookingComplete = true;
      },
      
      handleEditBookingCompleteOK:async function(){
        var _info = {
          bookingComplete: this.newBookingComplete,
          // clientCompleteDate: new Date(),
        }
        // console.log("===_info==", _info);
        const res = await api.bookingRequestUpdate(_info, this.editBookingCompleteId);
        // console.log(res, this.editBookingCompleteId)
        
        this.$emit('listUpdate');
        this.visibleEditBookingComplete = false;
      },
      
      handleEditBookingCompleteCancel(){
        
        this.visibleEditBookingComplete = false;
      },


      editConfirmationNo(_recordInfo){
        // console.log(_recordInfo);
        this.editConfrimationId = _recordInfo.id;
        this.editConfrimationInfo = _recordInfo;
        this.newConfirmationNo = _recordInfo.confirmationNo;
        this.newCaptureBalance = _recordInfo.captureBalance;
        this.totalPrice = _recordInfo.cost;
        
        // if (this.editConfrimationInfo.status != 1){
        //   this.$message.error("This booking is not on pending.");
        //   return;
        // }
        this.visibleEditConfirm = true;
      },
      handleEditConfirmOK:async function(){
        var originalStatus = this.editConfrimationInfo.status;
        var sessionId = this.editConfrimationInfo.sessionId;
        var cardId = this.editConfrimationInfo.cardId;

        var paystripeid = this.editConfrimationInfo.paystripeid;
        // // console.log("===paystripeid==", paystripeid, originalStatus, sessionId, this.newCaptureBalance);
        if (this.newCaptureBalance == undefined || this.newCaptureBalance < 0 || this.newCaptureBalance > this.totalPrice){
          this.$message.error("Please enter capture balance correctly!");
          return;
        }
        if (this.newConfirmationNo == undefined || this.newConfirmationNo ==""){
          this.$message.error("Please enter confirmation No correctly!");
          return;
        }
        if (originalStatus == 1 && sessionId != '' && sessionId != undefined){
          const res = await this.confirmPayment(sessionId);
          if (res != undefined && res.paymentIntent != undefined && res.paymentIntent['id'] != undefined){
            paystripeid = res.paymentIntent['id'];
          }
          else{
            this.$message.error("Payment confirmation faild!");
            return;
          }
        }
        // // console.log("===paystripeid= confirmPayment =", paystripeid);
        if (originalStatus == 1 && sessionId != '' && sessionId != undefined && this.newCaptureBalance != undefined && this.newCaptureBalance >= 0){
          const res = await this.capturePayment(sessionId, this.newCaptureBalance);
          if (res != undefined && res.paymentIntent != undefined && res.paymentIntent['id'] != undefined){
            paystripeid = res.paymentIntent['id'];
          }
          else{
            this.$message.error("Payment confirmation faild!");
            return;
          }
        }
        // // console.log("===paystripeid= capturePayment =", paystripeid);

        // // console.log("===handleEditConfirmOK==", this.editConfrimationInfo);
        var newStatus = 2;
        var _info = {
          confirmationNo: this.newConfirmationNo,
          captureBalance: this.newCaptureBalance,
          status: newStatus,
          paystripeid: paystripeid,
          confirmDate: new Date(),
        }
        // console.log("===_info==", _info);
        const res = await api.bookingRequestUpdate(_info, this.editConfrimationId);
        // console.log(res, this.editConfrimationId)

        this.$emit('listUpdate');
        this.visibleEditConfirm = false;
      },
      
      confirmPayment:async function(sessionId){
          var requestInfo = {
              sessionId: sessionId,
              // cardId: cardId,
          }
          let res = await api.confirmPayment(requestInfo);
          // console.log("-----confirmPayment - -- - - - - ", res);
          return res;            
      },

      capturePayment:async function(sessionId, amountCapture){
          var requestInfo = {
              sessionId: sessionId,
              amountCapture: amountCapture,
          }
          let res = await api.capturePayment(requestInfo);
          // console.log("-----capturePayment - -- - - - - ", res);
          return res;            
      },
      handleEditConfirmCancel(){
        
        this.visibleEditConfirm = false;
      },

      onChangeClientsDate: async function(_record, _index){
        // console.log(this.clientsDate[_index], "=====onChangeClientsDate=======", _record, _index)
        if (this.clientsDate[_index] != null && this.clientsDate[_index] != undefined){
          var _info = {
            dueDateClients: this.clientsDate[_index],
          }
          const res = await api.bookingRequestUpdate(_info, _record.id);
          // console.log(res.date, _record.id);
        }
      },

      onChangeHotelDate: async function(_record, _index){
        // console.log(this.hotelDate[_index],"=====onChangeHotelDate=======", _record, _index)
        if (this.hotelDate[_index] != null && this.hotelDate[_index] != undefined){
          var _info = {
            dueDateHotel: this.hotelDate[_index],
          }
          const res = await api.bookingRequestUpdate(_info, _record.id);
          // console.log(res.date, _record.id);
        }
      }
  }
};
</script>