<template>
    <div class="login">
        <div style="width:100%;display:flex;top:0px;">
            <div style="width:100%;padding-left:20px;padding-right:20px">
                <a-input style="width:100%;" placeholder="Email" v-model="forgotEmail" :size="'large'"/>
                
                <a-button style="width:100%;margin-top:10px" type="primary" :disabled="disabled" :size="'large'" :style="{ fontSize: '20px'}"  @click="handleResetClick">
                    Send Verification
                </a-button>
                <div style="background-color:#F0EFEF;height:1px;widht:100%;margin-top:20px"></div>
                <div style="width:100%;margin-top:10px;text-align:center;cursor:pointer">Click here? <font color="#CEB39A" v-on:click='clickLogin'>Log in</font></div>
            </div>
        </div>
    </div>
</template>

<script>
import { api } from '../helpers/Helpers';

export default {
    data() {
        return {
            disabled: false,
            forgotEmail: '',
        };
    },
    components: {
        
    },
    methods: {
        handleResetClick: async function() {
            if (this.forgotEmail == '')
                return
                
            // console.log("====",window.location.host)
            const res = await api.resetForgot({
                email:this.forgotEmail,
                redirecturl:window.location.host
            });
            // console.log("---------accessToken-------" + res);
            if (res != undefined){
                this.$message.success('Send success! Please check your email!');
                this.$emit('close');
            }
            else{
                this.$message.error('Send Failed!');
            }
        },
        close(){
            this.$emit('close');
        },
        clickLogin(){
            this.$emit('openLogin');
        },

    },
    mounted(){
        
    },
    created: function () {

    }
}
</script>