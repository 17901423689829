<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:50%;height:30px;border:1px solid #dddddd;font-size: 14px;line-height:28px;">Title</div>
                <div style="width:50%;border:1px solid #dddddd;border-left:none;font-size: 14px;line-height:28px;">Text</div>
                <div style="width:30px;border:1px solid #dddddd;border-left:none;">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <div style="margin-top:0px" v-for="item in keysListHighlightItem" :key="item">
                <a-row :gutter="0" style="width:100%;">
                    <div style="display:flex;height:200px;border:1px solid #dddddd;border-top:none;background-color:#f5f5f5">

                        <a-form-item style="width:50%;height:100%;padding:10px;margin-bottom:0px;margin-top:0px;margin-bottom:auto;border:1px solid #dddddd;border-top:none;border-bottom:none;border-left:none;background-color:white" >
                            <a-input style="width:100%;" placeholder=""
                                  v-decorator="[
                                      `${title}Title[${item}]`,
                                      {
                                          initialValue: arr[item] ? arr[item].title : undefined,
                                          rules: [{ required: true, message: 'Input title !' }]
                                      }
                                  ]"
                              />
                        </a-form-item>
                        <a-form-item style="width:50%;height:100%;padding:10px;margin-top:0px;margin-bottom:auto;border:1px solid #dddddd;border-top:none;border-bottom:none;border-left:none;background-color:white" >
                            <a-textarea 
                              placeholder=""
                              :auto-size="{ minRows: 8, maxRows: 10 }"
                                  v-decorator="[
                                      `${title}Text[${item}]`,
                                      {
                                          initialValue: arr[item] ? arr[item].text : undefined,
                                          rules: [{ required: false, message: 'text !' }]
                                      }
                                  ]"
                              />
                        </a-form-item>
                        <div style="margin-top:auto;margin-bottom:auto;width:30px">
                            <a-button shape="circle" type="dashed" icon="minus" @click="removeRowHighlightItem(item)" class="minusRowBtn" :size="'small'"></a-button>
                        </div>
                    </div>
                </a-row>
            </div>
            
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowHighlightItem" type="primary" :size="'small'">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
      
  },
  data () {
    return {
      idHighlightItem: 0,
      keysListHighlightItem: [],
      
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    this.init()
  },
  methods: {
    init () {
      const arr = []
      
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idHighlightItem = 0;
        for (let i = 0; i < (this.arr).length; i++) {
          arr.push(i)
          this.idHighlightItem = this.idHighlightItem + 1
        }
      }
      this.keysListHighlightItem = arr
    },



    removeRowHighlightItem (k) {
      if (this.keysListHighlightItem.length === 0) { 
        return
      }
      this.keysListHighlightItem = this.keysListHighlightItem.filter(item => item !== k)
    },
    addRowHighlightItem () {
      this.keysListHighlightItem = this.keysListHighlightItem.concat(this.idHighlightItem)
      this.idHighlightItem = this.idHighlightItem + 1
    },
  }
}
</script>