<template>
    <div style="min-height:85vh">
        <div>
            <a-select style="margin-top:auto;margin-bottom:auto;width:500px" placeholder="Select Hotel" v-model="selectedHotelId" @change="handleChangeHotel">
                <a-select-option :key="'0'" :value="'0'">
                    All
                </a-select-option>
                <a-select-option v-for="item in hotels" :key="item._id" :value="item._id">
                    {{ item.hotelname }}
                </a-select-option>
            </a-select>
        </div>
        <div style="margin-top:20px;font-style: normal;font-weight: 700;font-size: 19px;line-height: 26px;">Reservations booked</div>
        <a-divider/>
        <apexchart type="bar" height="350" :options="reservationsOptions" :series="reservations"></apexchart>
        <div style="margin-top:20px;font-style: normal;font-weight: 700;font-size: 19px;line-height: 26px;">Room nights</div>
        <a-divider/>
        <apexchart type="bar" height="350" :options="roomnightsOptions" :series="roomnights"></apexchart>
        <div style="margin-top:20px;font-style: normal;font-weight: 700;font-size: 19px;line-height: 26px;">Room revenue</div>
        <a-divider/>
        <apexchart type="bar" height="350" :options="roomrevenueOptions" :series="roomrevenue"></apexchart>
    </div>
</template>
<script>
import { api } from '../helpers/Helpers';
export default {
    
    data() {
        return {
            currentYear:2022,
            hotels:[],
            allBookings:[],
            currentYearList:[],
            lastYearList:[],
            selectedHotelId:'0',

            reservations: [
            ],
            
            reservationsOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                colors: ['#0031be', '#fe6e00'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                },
                yaxis: {
                    title: {
                        text: ' # of reservations booked'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                        return val
                        }
                    }
                }
            },
            
            roomnights: [
            ],
            
            roomnightsOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                },
                yaxis: {
                    title: {
                        text: 'Room nights'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                        return val
                        }
                    }
                }
            },

            
            roomrevenue: [
            ],
            
            roomrevenueOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                colors: ['#546E7A', '#E91E63'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                },
                yaxis: {
                    title: {
                        text: 'Room revenue($)'
                    },
                    labels: {
                        formatter: (value) => { return this.addZeroes(value) },
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: (value) => {
                            return '$' + this.addZeroes(value)
                        }
                    }
                }
            },
        }
    },
    
    components: {

    },
    created () {
        const curDate = new Date();
        this.currentYear = curDate.getFullYear();
        // console.log("currentYear=============",this.currentYear);
        this.getBooingks();
    },
    
    methods: {
        
        addZeroes(num){
            let textNum = num.toString();
                // // console.log("textNum  === ", Math.max(textNum.split('.')[1]?.length, 2));
            var _len = 0;
            if (textNum.split('.')[1] != undefined)
                _len = textNum.split('.')[1].length;
            let decimalResult = Number(textNum).toFixed(Math.max(_len, 2) || 2);
            let thousand = decimalResult.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return thousand;
        },
        getBooingks:async function(){
            
            const res = await api.getBookingRequestStatus();
            // console.log("getBooingks=============",res);
            this.getHotels();
            if (res != undefined && res.length > 0){
                this.allBookings = res;
            }
        },
        getHotels:async function(){
            const res = await api.getHotelsTotal();
            // console.log("getHotels:",res);
            if (res != undefined)
                this.hotels = res;
            this.updateBookingList(this.selectedHotelId);
        },
        updateBookingList(_id){
            this.currentYearList = [];
            var curReservationsMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var lastReservationsMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var curRoomNightsMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var lastRoomNightsMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var curRoomRevenueMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var lastRoomRevenueMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            if (_id == '0'){
                for(let i = 0; i < this.allBookings.length; i++){
                    const fromDate = new Date(this.allBookings[i].fromDate);
                    const toDate = new Date(this.allBookings[i].toDate);

                    const diffTime = Math.abs(toDate - fromDate);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

                    const _RevenueValue = this.allBookings[i].grossTotalPrice != undefined ? this.allBookings[i].grossTotalPrice : 0;
            // console.log("_RevenueValue:",_RevenueValue);
                    if (fromDate.getFullYear() == this.currentYear){
                        this.currentYearList.push(this.allBookings[i]);
                        const index = fromDate.getMonth();
                        const _CurRoomRevenueMonth = Math.round((curRoomRevenueMonth[index] + _RevenueValue) * 100) / 100;
            // console.log("_CurRoomRevenueMonth:",_CurRoomRevenueMonth);
                        curReservationsMonth.splice(index, 1, curReservationsMonth[index] + 1);
                        curRoomNightsMonth.splice(index, 1, curRoomNightsMonth[index] + diffDays);
                        curRoomRevenueMonth.splice(index, 1, _CurRoomRevenueMonth);
                    }
                    if (fromDate.getFullYear() == this.currentYear - 1){
                        this.lastYearList.push(this.allBookings[i]);
                        const index = fromDate.getMonth();
                        const _LastRoomRevenueMonth = Math.round((lastRoomRevenueMonth[index] + _RevenueValue) * 100) / 100;
                        lastReservationsMonth.splice(index, 1, lastReservationsMonth[index] + 1);
                        lastRoomNightsMonth.splice(index, 1, lastRoomNightsMonth[index] + diffDays);
                        lastRoomRevenueMonth.splice(index, 1, _LastRoomRevenueMonth);
                    }
                }
            }
            else{
                for(let i = 0; i < this.allBookings.length; i++){
                    if(this.allBookings[i].hotelid != _id)
                        continue;
                    const fromDate = new Date(this.allBookings[i].fromDate);
                    const toDate = new Date(this.allBookings[i].toDate);

                    const diffTime = Math.abs(toDate - fromDate);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

                    const _RevenueValue = this.allBookings[i].grossTotalPrice != undefined ? this.allBookings[i].grossTotalPrice : 0;
                    if (fromDate.getFullYear() == this.currentYear){
                        this.currentYearList.push(this.allBookings[i]);
                        const index = fromDate.getMonth();
                        const _CurRoomRevenueMonth = Math.round((curRoomRevenueMonth[index] + _RevenueValue) * 100) / 100;
                        curReservationsMonth.splice(index, 1, curReservationsMonth[index] + 1);
                        curRoomNightsMonth.splice(index, 1, curRoomNightsMonth[index] + diffDays);
                        curRoomRevenueMonth.splice(index, 1, _CurRoomRevenueMonth );
                    }
                    if (fromDate.getFullYear() == this.currentYear - 1){
                        this.lastYearList.push(this.allBookings[i]);
                        const index = fromDate.getMonth();
                        const _LastRoomRevenueMonth = Math.round((lastRoomRevenueMonth[index] + _RevenueValue) * 100) / 100;
                        lastReservationsMonth.splice(index, 1, lastReservationsMonth[index] + 1);
                        lastRoomNightsMonth.splice(index, 1, lastRoomNightsMonth[index] + diffDays);
                        lastRoomRevenueMonth.splice(index, 1, _LastRoomRevenueMonth);
                    }
                }
            }
            this.reservations.splice(0, 1,
                {
                    name: this.currentYear + " Year",
                    data: curReservationsMonth
                }
            );
            this.reservations.splice(1, 1,
                {
                    name: (this.currentYear - 1) + " Year",
                    data: lastReservationsMonth
                }
            );

            this.roomnights.splice(0, 1,
                {
                    name: this.currentYear + " Year",
                    data: curRoomNightsMonth
                }
            );
            this.roomnights.splice(1, 1,
                {
                    name: (this.currentYear - 1) + " Year",
                    data: lastRoomNightsMonth
                }
            );

            this.roomrevenue.splice(0, 1,
                {
                    name: this.currentYear + " Year",
                    data: curRoomRevenueMonth
                }
            );
            this.roomrevenue.splice(1, 1,
                {
                    name: (this.currentYear - 1) + " Year",
                    data: lastRoomRevenueMonth
                }
            );
        },
        
        handleChangeHotel(value) {
            // console.log(value);
            // this.getSelectedBookings();
            this.updateBookingList(value);
        },

        getRealGraphData(){

        }
        // reservations: [
        //         {
        //             name: 2021 + " Year",
        //             data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 63, 60, 66]
        //         }, {
        //             name: 2020 + " Year",
        //             data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 91, 114, 94]
        //         }
        //     ],
    }
}
</script>