<template>
    <div class="adduser" style="min-height:85vh">
        <div>
            <div style="margin: 16px 0;display:flex">
                <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                    <a-breadcrumb-item style="font-size:30px;">Add User</a-breadcrumb-item>
                    <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
                </a-breadcrumb>
            </div>
            <div style="text-align:left">Create a brand new user and add them to this site.</div>
            <div style="display:flex;margin-top:20px">
                <div style="width:200px;text-align:left;margin-top:auto;margin-bottom:auto">Email (required)</div>
                <a-input style="width:300px;margin-top:auto;margin-bottom:auto" v-model="email" placeholder="" />
            </div>
            <div style="display:flex;margin-top:20px">
                <div style="width:200px;text-align:left;margin-top:auto;margin-bottom:auto">First Name</div>
                <a-input style="width:300px;margin-top:auto;margin-bottom:auto" v-model="firstName" placeholder="" />
            </div>
            <div style="display:flex;margin-top:20px">
                <div style="width:200px;text-align:left;margin-top:auto;margin-bottom:auto">Last Name</div>
                <a-input style="width:300px;margin-top:auto;margin-bottom:auto" v-model="lastName" placeholder="" />
            </div>
            <!-- <div style="display:flex;margin-top:20px">
                <div style="width:200px;text-align:left;margin-top:auto;margin-bottom:auto">Agency Name</div>
                <a-input style="width:300px;margin-top:auto;margin-bottom:auto" v-model="agencyName" placeholder="" />
            </div> -->

            <div v-if="roleStr == 'agent'" style="margin-top:30px;margin-bottom: 30px;">
                <div style="text-align:left;font-size:18px">BUSINESS INFORMATION:</div>
                <div style="display:flex;margin-top:5px">
                    <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">Agency Name</div>
                    <a-input style="width:400px;margin-top:auto;margin-botton:auto" placeholder="" v-model="agencyName"/>
                </div>
                <div style="display:flex;margin-top:5px">
                    <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">Member Name</div>
                    <a-input style="width:400px;margin-top:auto;margin-botton:auto" placeholder="" v-model="memberName"/>
                </div>
                <div style="display:flex;margin-top:5px">
                    <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">Business ID</div>
                    <a-input style="width:400px;margin-top:auto;margin-botton:auto" placeholder="" v-model="businessID"/>
                </div>
                <div style="display:flex;margin-top:5px">
                    <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">State/Province</div>
                    <a-input style="width:400px;margin-top:auto;margin-botton:auto" placeholder="" v-model="stateProvince"/>
                </div>
            </div>

            <div style="display:flex;margin-top:20px">
                <div style="width:200px;text-align:left;margin-top:auto;margin-bottom:auto">Country</div>
                
                <a-auto-complete
                    :data-source="countryList"
                    style="width:300px;margin-top:auto;margin-bottom:auto"
                    placeholder=""
                    v-model="country"
                    :filter-option="filterOption"
                /> 
                    <!-- @select="onSelectCountry" -->

                <!-- <a-select :default-value="countryList[0]" v-model="country" placeholder="Country" style="width:300px;margin-top:auto;margin-bottom:auto" :size="'large'">
                    <a-select-option v-for="_country in countryList" :key="_country">
                        <div style="display:flex">
                            <span style="margin-top:auto;margin-bottom:auto;">{{ _country }}</span>
                        </div>
                    </a-select-option>
                </a-select> -->
            </div>
            <div style="display:flex;margin-top:20px">
                <div style="width:200px;text-align:left;margin-top:auto;margin-bottom:auto">State</div>
                <a-input style="width:300px;margin-top:auto;margin-bottom:auto" v-model="stateProvince" placeholder="" />
            </div>
            <div style="display:flex;margin-top:20px">
                <div style="width:200px;text-align:left;margin-top:auto;margin-bottom:auto">Password</div>
                <div style="width:300px;margin-top:auto;margin-bottom:auto">
                    <!-- <a-button>Show Password</a-button> -->
                    <a-input style="width:300px;margin-top:auto;margin-bottom:auto" v-model="password" placeholder="" />
                </div>
            </div>
            <div style="display:flex;margin-top:20px">
                <div style="width:200px;text-align:left;margin-top:auto;margin-bottom:auto">Send User Notification</div>
                <a-checkbox style="margin-top:auto;margin-bottom:auto" v-model="isSendNotify" @change="onChange">
                    Send the new user an email about their account.
                </a-checkbox>
            </div>
            <div style="display:flex;margin-top:20px">
                <div style="width:200px;text-align:left;margin-top:auto;margin-bottom:auto">Role</div>
                <a-select style="width:300px;margin-top:auto;margin-bottom:auto" v-model="roleStr" slot="addonAfter" default-value="user">
                    <a-select-option value="user">
                        User
                    </a-select-option>
                    <a-select-option value="admin">
                        Administrator
                    </a-select-option>
                    <a-select-option value="agent">
                        Agent
                    </a-select-option>
                </a-select>
            </div>
            
            <div style="display:flex;margin-top:20px">
                <a-button style="margin-top:auto;margin-bottom:auto;" type="primary" v-on:click="addNewUser">
                    Add New User
                </a-button>
            </div>
        </div>
        
    </div>
</template>
<script>
import { api } from '../helpers/Helpers';
import { common } from '../helpers/Common';
const roleList = {
    user:1,
    agent:4,
    admin:2048
}
const countryList= ['US', 'RSS', 'CH'];
export default {
    data() {
    return {
      userName:'',
      email:'',
      firstName:'',
      lastName:'',
      website:'',
      agencyName:'',
      memberName:'',
      businessID:'',
      stateProvince:'',
      language:'',
      country:'',
      stateProvince:'',
      password:'',
      isSendNotify:false,
      roleStr:'user',
      status: 1,
      posts:0,
      roleList,
      
      countryList,

    };
  },
    methods: {
        
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
            );
        },
        onChange(e) {
            // console.log(`checked = ${e.target.checked}`);
        },
        addNewUser: async function(){
            if (this.email === "")
                return
            var signupUser = {
                userName:this.userName,
                email:this.email,
                firstName:this.firstName,
                lastName:this.lastName,
                website:this.website,
                agencyName:this.agencyName,
                memberName:this.memberName,
                businessID:this.businessID,
                stateProvince:this.stateProvince,
                language:this.language,
                country:this.country,
                stateProvince:this.stateProvince,
                password:this.password,
                isSendNotify:this.isSendNotify,
                role:this.roleList[this.roleStr],
                permissionLevel:this.roleList[this.roleStr],
                status: 1,
                posts:0,
            }
            const res = await api.signupAdmin(signupUser);
            // this.flash('task created', 'success');
             // console.log(this.roleStr);
             // console.log(this.roleList[this.roleStr]);
            if (res != undefined && res != "" && res.error == undefined){
                this.$message.success('Add User Success!');
                this.$emit('close');
            }
            else{
                if (res.error == undefined)
                    this.$message.error('This email is already using by other');
                else
                    this.$message.error(res.error.error);
            }
        }
    },
    created(){
        this.countryList = common.getCountryList();
    },
}
</script>