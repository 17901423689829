<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:50%;border:1px solid #dddddd;font-size: 14px;line-height:28px;">Start Date</div>
                <div style="width:50%;border:1px solid #dddddd;border-left:none;font-size: 14px;line-height:28px;">End Date</div>
                <div style="width:30px;border:1px solid #dddddd;border-left:none;">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <div style="margin-top:0px;background-color:#f8f8f8;border:1px solid #dddddd;border-top:none" v-for="item in keysListExtraPeriod" :key="item">
                <a-row :gutter="0" style="width:100%;">
                    <div style="display:flex;">
                        <a-form-item style="width:50%;background-color:white;margin-top:auto;margin-bottom:auto;border:1px solid #dddddd;border-top:none;border-bottom:none;border-left:none;padding:5px" >
                            <a-date-picker style="width:100%;margin-top:auto;margin-bottom:auto" placeholder=""
                                  :showToday="false"
                                  valueFormat="YYYY-MM-DD"
                                  v-decorator="[
                                      `${title}Startdate[${item}]`,
                                      {
                                          initialValue: arr[item] != undefined && arr[item].startdate != undefined ? arr[item].startdate.toString().split('T')[0] : undefined,
                                          rules: [{ required: true, message: 'Input startdate !' }]
                                      }
                                  ]"
                                >
                                <!-- <template slot="dateRender" slot-scope="current">
                                    <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                    v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                        {{ moment(current).utc().date() }}
                                    </div>
                                </template> -->
                            </a-date-picker>
                        </a-form-item>
                        <a-form-item style="width:50%;background-color:white;margin-top:auto;margin-bottom:auto;border:1px solid #dddddd;border-top:none;border-bottom:none;border-left:none;padding:5px" >
                            <a-date-picker style="width:100%;margin-top:auto;margin-bottom:auto" placeholder=""
                                  :showToday="false"
                                  valueFormat="YYYY-MM-DD"
                                  v-decorator="[
                                      `${title}Enddate[${item}]`,
                                      {
                                          initialValue: arr[item] != undefined && arr[item].enddate != undefined ? arr[item].enddate.toString().split('T')[0] : undefined,
                                          rules: [{ required: false, message: 'enddate !' }]
                                      }
                                  ]"
                                >
                                <!-- <template slot="dateRender" slot-scope="current">
                                    <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                    v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                        {{ moment(current).utc().date() }}
                                    </div>
                                </template> -->
                            </a-date-picker>
                        </a-form-item>
                        <div style="margin-top:auto;margin-bottom:auto;width:30px">
                            <a-button shape="circle" type="dashed" icon="minus" @click="removeRowExtraPeriod(item)" class="minusRowBtn" :size="'small'"></a-button>
                        </div>
                    </div>
                </a-row>
            </div>
            
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowExtraPeriod" type="primary" :size="'small'">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
      
  },
  data () {
    return {
      idExtraPeriod: 0,
      keysListExtraPeriod: [],
      
      dateFormat : (val) => {
        return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
      }
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    this.init()
  },
  methods: {
    moment,
    init () {
      const arr = []
      
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idExtraPeriod = 0;
        for (let i = 0; i < (this.arr).length; i++) {
          arr.push(i)
          this.idExtraPeriod = this.idExtraPeriod + 1
        }
      }
      this.keysListExtraPeriod = arr
    },



    removeRowExtraPeriod (k) {
      if (this.keysListExtraPeriod.length === 0) { 
        return
      }
      this.keysListExtraPeriod = this.keysListExtraPeriod.filter(item => item !== k)
    },
    addRowExtraPeriod () {
      // console.log(this.idExtraPeriod, '-------', this.keysListExtraPeriod)
      this.keysListExtraPeriod = this.keysListExtraPeriod.concat(this.idExtraPeriod)
      this.idExtraPeriod = this.idExtraPeriod + 1
    },
  }
}
</script>