<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:35%;border:1px solid #dddddd;font-size: 14px;line-height:28px;border-right:none;">Age Range</div>
                <div style="width:41%;border:1px solid #dddddd;font-size: 14px;line-height:28px;">Period Duration</div>
                <div style="width:24%;border:1px solid #dddddd;font-size: 14px;line-height:28px;border-left:none;">Rate</div>
                <div style="width:50px;border:1px solid #dddddd;border-left:none;">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <draggable v-model="keysListExtra" chosenClass="chosen" forceFallback="false" group="people" animation="1000" filter="aaa" @start="onStart" @end="onEnd" :options="{handle :'div.item'}">
                <transition-group>
                    <div style="margin-top:0px;background-color:#f8f8f8;border:1px solid #dddddd;border-top:none" v-for="item, index in keysListExtra" :key="item">
                        <a-row :gutter="0" style="width:100%;">
                            <div style="display:flex;">
                                <div v-if="true" class="item" style="width:50px;height: inherit;display:flex;color:#000000a6">
                                    <div style="margin:auto">{{index + 1}}</div>
                                </div>
                                <div style="width:35%;height:auto;margin:0;background-color:white;display:flex;border:1px solid #ddd;border-top:none;border-left:none;border-bottom:none;padding:10px" >
                                    <div style="width:100%;margin-top:0;margin-bottom:0;display:flex">
                                        <div style="width:50%;">
                                            <div style="border:1px solid #dddddd;font-size: 14px;line-height:28px;">From</div>
                                            <a-form-item style="width:100%;border:1px solid #dddddd;border-top:none;padding:10px">
                                                <a-input-number style="width:100%" :min="0" :max="100000" placeholder=""
                                                    v-decorator="[
                                                        `${title}From[${item}]`,
                                                        {
                                                            initialValue: arr[item] ? arr[item].agerange.from : undefined,
                                                            rules: [{ required: true, message: 'Input from!' }]
                                                        }
                                                    ]"
                                                />
                                            </a-form-item>
                                        </div>
                                        <div style="width:50%;">
                                            <div style="border:1px solid #dddddd;border-left:none;font-size: 14px;line-height:28px;">To</div>
                                            <a-form-item style="width:100%;border:1px solid #dddddd;border-left:none;border-top:none;padding:10px">
                                                <a-input-number style="width:100%" :min="0" :max="100000" placeholder=""
                                                    v-decorator="[
                                                        `${title}To[${item}]`,
                                                        {
                                                            initialValue: arr[item] ? arr[item].agerange.to : undefined,
                                                            rules: [{ required: false, message: 'to!' }]
                                                        }
                                                    ]"
                                                />
                                            </a-form-item>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:41%;height:auto;margin:0;background-color:white;border:1px solid #ddd;border-top:none;border-left:none;border-right:none;border-bottom:none;padding:10px" >
                                    <a-form style="display:flex" :form="hotelExtraPeriodForm[item]">
                                        <hotel-extra-period-list
                                            :title="`${HOTELEXTRAPERIODPART + (item)}`"
                                            :arr="arrHotelExtraPeriod[item]"
                                        />
                                    </a-form>
                                </div>
                                
                                <div style="width:24%;height:auto;margin:0;background-color:white;border:1px solid #ddd;border-top:none;border-bottom:none;padding:10px" >
                                    <div style="margin-top:0;margin-bottom:0;display:flex">
                                        <div style="width:100%;">
                                            <div style="border:1px solid #dddddd;font-size: 14px;line-height:28px;">Per Day (Must include green tax)</div>
                                            <a-form-item style="width:100%;border:1px solid #dddddd;border-top:none;padding:10px" >
                                                <a-input-number style="width:100%;padding:2px" :min="0" :max="100000" placeholder=""
                                                    v-decorator="[
                                                        `${title}Perday[${item}]`,
                                                        {
                                                            initialValue: arr[item] != undefined && arr[item].rate != undefined ? arr[item].rate.perday : undefined,
                                                            rules: [{ required: false, message: 'perday!' }]
                                                        }
                                                    ]"
                                                />
                                            </a-form-item>
                                        </div>
                                    </div>
                                </div>
                                <div style="margin-top:auto;margin-bottom:auto;width:50px">
                                    <a-button shape="circle" type="solid" icon="minus" @click="removeRowFestive(item)" class="minusRowBtn" :size="'small'"></a-button>
                                </div>
                            </div>
                        </a-row>
                    </div>
                </transition-group>
            </draggable>
            <div v-if="keysListExtra.length > 0" style="width:100%;height:1px;background-color:#dddddd"></div>
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowFestive" type="primary">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
var moment = require('moment-timezone');

import AwsFileManager from '../../components/AwsFileManager.vue'
import HotelExtraPeriodList from './HotelExtraPeriodList.vue'
import HotelFestivePriceList from './HotelFestivePriceList.vue'
import draggable from 'vuedraggable'

const HOTELEXTRAPERIODPART = 'hotelExtraPeriodPart'
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    AwsFileManager,
    HotelFestivePriceList,
    HotelExtraPeriodList,
    draggable
  },
  data () {
    return {
      idExtra: 0,
      keysListExtra: [],

      hotelExtraPeriodForm: [],
      HOTELEXTRAPERIODPART,
      arrHotelExtraPeriod: [],
    }
  },
  created () {
    var mynumber = 50;
    this.hotelExtraPeriodForm = new Array(mynumber);

    for (var i = 0; i < mynumber; i++) {
        this.hotelExtraPeriodForm[i] = this.$form.createForm(this);
    }

    this.selectedImage = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.selectedImage[i] = '';
    }
    this.isSelected = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.isSelected[i] = false;
    }
    this.init()
  },
  methods: {
    moment,
    init () {
      const arr = [];
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idExtra = 0;
        for (let i = 0; i < (this.arr).length; i++) {
          this.arrHotelExtraPeriod[i] = this.arr[i].periodduration;
          arr.push(i)
          this.idExtra = this.idExtra + 1
        }
      }
      this.keysListExtra = arr
    },


    removeRowFestive (k) {
      if (this.keysListExtra.length === 0) { 
        return
      }
      this.keysListExtra = this.keysListExtra.filter(item => item !== k)
    },
    addRowFestive () {
      this.keysListExtra = this.keysListExtra.concat(this.idExtra)
      this.idExtra = this.idExtra + 1
    },
    
    getExtraPeriod(_index){
        this.hotelExtraPeriodForm[_index].validateFields((err, values) => {
          if (!err) {
            let partOneArr = [];
                // console.log(values,_index)
                if (values[`${HOTELEXTRAPERIODPART + _index}Startdate`] !== undefined){
                    (values[`${HOTELEXTRAPERIODPART + _index}Startdate`]).forEach((item, index) => {
                        const obj = {
                            startdate: moment.utc(item).tz("America/Los_Angeles").toISOString(),
                            enddate: moment.utc(values[`${HOTELEXTRAPERIODPART + _index}Enddate`][index]).tz("America/Los_Angeles").toISOString(),
                        }
                        partOneArr.push(obj)
                    })
                }
                else if (values[`${HOTELEXTRAPERIODPART + _index}SelectInput`] == undefined && this.arrHotelExtraPeriod[_index] != undefined && this.arrHotelExtraPeriod[_index].length > 0){
                    partOneArr = this.arrHotelExtraPeriod[_index]
                }
                this.$emit('extralist', partOneArr);
          }
        });
    },
    getKeyExtraList(){
        this.$emit('getKeyExtraList', this.keysListExtra);
    },
    onStart(){
        // console.log("======= onStart");
        // this.drag=true;
    },
    onEnd() {
        // console.log("======= onEnd", this.keysListExtra);
    //    this.drag=false;
    },
  }
}
</script>