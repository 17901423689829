<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:25%;border:1px solid #dddddd;font-size: 14px;border-right:none;line-height:28px;">Honey Title</div>
                <div style="width:25%;border:1px solid #dddddd;font-size: 14px;line-height:28px;">Condition</div>
                <div style="width:25%;border:1px solid #dddddd;border-left:none;font-size: 14px;line-height:28px;">Description</div>
                <div style="width:25%;border:1px solid #dddddd;border-left:none;font-size: 14px;line-height:28px;">Image</div>
                <div style="width:50px;height:30px;border:1px solid #dddddd;border-left:none;">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <draggable v-model="keysListHoney" chosenClass="chosen" forceFallback="false" group="people" animation="1000" filter="aaa" @start="onStart" @end="onEnd" :options="{handle :'div.item'}">
                <transition-group>
                    <div style="margin-top:0px;background-color:#f8f8f8;border:1px solid #dddddd;border-top:none" v-for="item, index in keysListHoney" :key="item">
                        <a-row :gutter="0" style="width:100%;">
                            <div style="display:flex;">
                                <div v-if="true" class="item" style="width:50px;height: inherit;display:flex;color:#000000a6">
                                    <div style="margin:auto">{{index + 1}}</div>
                                </div>
                                <div style="width:25%;height:auto;margin:0;background-color:white;display:flex;border:1px solid #ddd;border-top:none;border-left:none;border-bottom:none;padding:10px" >
                                    <a-form-item style="width:100%;margin-top:0;margin-bottom:0">
                                        <a-input style="width:100%;margin-top:auto;margin-bottom:auto" placeholder=""
                                            v-decorator="[
                                                `${title}Honeytitle[${item}]`,
                                                {
                                                    initialValue: arr[item] ? arr[item].honeytitle : undefined,
                                                    rules: [{ required: true, message: 'Input honeytitle!' }]
                                                }
                                            ]"
                                        />
                                    </a-form-item>
                                </div>
                                <div style="width:25%;height:auto;margin:0;background-color:white;border:1px solid #ddd;border-top:none;border-left:none;border-bottom:none;padding:0px" >
                                    <div style="font-weight:600;text-align:left;margin-bottom:5px;padding-left:10px;padding-top:10px">Stay</div>
                                    <a-form-item style="width:100%;margin-top:auto;margin-bottom:auto;padding-left:10px;padding-right:10px">
                                        <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto;" :min="1" :max="100000" 
                                                v-decorator="[
                                                    `${title}Stay[${item}]`,
                                                    {
                                                        initialValue: arr[item] ? arr[item].stay : undefined,
                                                        rules: [{ required: false, message: 'stay !' }]
                                                    }
                                                ]"
                                            />
                                    </a-form-item>
                                    <a-divider :size="'small'" />
                                    <div v-if="false" style="font-weight:600;text-align:left;margin-bottom:5px;padding-left:10px">Honey Date</div>
                                    <div style="display:flex;padding:2px;margin-left:10px;margin-right:10px">
                                        <div style="width:50%;padding:0px;padding-bottom:0">
                                            <div style="border:1px solid #dddddd;padding:5px;border-right:none">Start Date</div>
                                            <a-form-item style="width:100%;border:1px solid #dddddd;border-right:none;border-top:none;padding:5px;">
                                                <a-date-picker style="width:100%;" placeholder=""
                                                    :showToday="false"
                                                    valueFormat="YYYY-MM-DD"
                                                    v-decorator="[
                                                        `${title}Honeystart[${item}]`,
                                                        {
                                                            initialValue: arr[item] != undefined &&  arr[item].honeydate != undefined &&  arr[item].honeydate.honeystart != undefined && arr[item].honeydate.honeystart != null ? arr[item].honeydate.honeystart.toString().split('T')[0] : null,
                                                            rules: [{ required: false, message: 'honeystart !' }]
                                                        }
                                                    ]"
                                                    >
                                                    <!-- <template slot="dateRender" slot-scope="current">
                                                        <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                                        v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                                            {{ moment(current).utc().date() }}
                                                        </div>
                                                    </template> -->
                                                </a-date-picker>
                                            </a-form-item>
                                        </div>
                                        <div style="width:50%;padding:0px;padding-bottom:0">
                                            <div style="border:1px solid #dddddd;padding:5px;">End Date</div>
                                            <a-form-item style="width:100%;border:1px solid #dddddd;border-top:none;padding:5px;">
                                                <a-date-picker style="width:100%;" placeholder=""
                                                    :showToday="false"
                                                    valueFormat="YYYY-MM-DD"
                                                    v-decorator="[
                                                        `${title}Honeyend[${item}]`,
                                                        {
                                                            initialValue: arr[item] != undefined &&  arr[item].honeydate != undefined &&  arr[item].honeydate.honeyend != undefined &&  arr[item].honeydate.honeyend != null ? arr[item].honeydate.honeyend.toString().split('T')[0] : null,
                                                            rules: [{ required: false, message: 'honeyend !' }]
                                                        }
                                                    ]"
                                                    >
                                                    <!-- <template slot="dateRender" slot-scope="current">
                                                        <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                                        v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                                            {{ moment(current).utc().date() }}
                                                        </div>
                                                    </template> -->
                                                </a-date-picker>
                                            </a-form-item>
                                        </div>
                                    </div>
                                </div>
                                <a-form-item style="width:25%;height:auto;margin:0;background-color:white;border:1px solid #ddd;border-top:none;border-left:none;border-right:none;border-bottom:none;padding:10px">
                                    <a-textarea 
                                        placeholder=""
                                        :auto-size="{ minRows: 8, maxRows: 10 }"
                                        v-decorator="[
                                            `${title}Description[${item}]`,
                                            {
                                                initialValue: arr[item] ? arr[item].description : undefined,
                                                rules: [{ required: false, message: 'description !' }]
                                            }
                                        ]"
                                    />
                                </a-form-item>
                                <div style="width:25%;height:auto;margin:0;background-color:white;border:1px solid #ddd;border-top:none;border-bottom:none;padding:10px" >
                                    <div v-if="!isSelected[item]">
                                        <div>No image selected</div>
                                        <a-button @click="showGallary(true, item)" :size="'small'">Add Image</a-button>
                                    </div>
                                    <div v-if="isSelected[item]" style="position:relative">
                                    <img :src="`${selectedImage[item]}`" alt="" width="100%" height="100px">
                                    <a-icon @click="cancelImage(item)" style="position:absolute;top:5px;right:5px" type="close-circle" theme="filled" />
                                    <a-icon @click="showGallary(true, item)" style="position:absolute;top:5px;right:25px" type="edit" theme="filled" />
                                    </div>
                                    <a-modal
                                        v-model="visibleGallary"
                                        title="Add Gallay"
                                        :footer="null"
                                        width="80%"
                                    >
                                        <AwsFileManager v-if="visibleGallary" :ismulti="false" :rowIndex="selitem" @close="showGallary(false)" @selectImages="selectImages"/>
                                    </a-modal>
                                </div>
                                <div style="margin-top:auto;margin-bottom:auto;width:50px">
                                    <a-button shape="circle" type="solid" icon="minus" @click="removeRowHoney(item)" class="minusRowBtn" :size="'small'"></a-button>
                                </div>
                            </div>
                        </a-row>
                    </div>
                </transition-group>
            </draggable>
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowHoney" type="primary">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import AwsFileManager from '../../components/AwsFileManager.vue'
import moment from 'moment';
import draggable from 'vuedraggable'

export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    AwsFileManager,
    draggable
  },
  data () {
    return {
      idHoney: 0,
      keysListHoney: [],

      visibleGallary: false,
      selitem:0,
      selectedImage:[],
      isSelected:[],
      
      dateFormat : (val) => {
        return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
      }
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    
    var mynumber = 50;
    this.selectedImage = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.selectedImage[i] = '';
    }
    this.isSelected = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.isSelected[i] = false;
    }
    this.init()
  },
  methods: {
    moment,
    init () {
      const arr = []
      
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idHoney = 0;
        for (let i = 0; i < (this.arr).length; i++) {
            if (this.arr[i].image != ''){
                this.isSelected.splice(i, 1, true);
                this.selectedImage.splice(i, 1, this.arr[i].image);
            }
            else{
                this.isSelected.splice(i, 1, false);
                this.selectedImage.splice(i, 1, this.arr[i].image);
            }
          arr.push(i)
          this.idHoney = this.idHoney + 1
        }
      }
      this.keysListHoney = arr
    },


    removeRowHoney (k) {
      if (this.keysListHoney.length === 0) { 
        return
      }
      this.keysListHoney = this.keysListHoney.filter(item => item !== k)
    },
    addRowHoney () {
      this.keysListHoney = this.keysListHoney.concat(this.idHoney)
      this.idHoney = this.idHoney + 1
    },

    
    showGallary(_isShow, _index){
        this.visibleGallary = _isShow;
        if (_isShow)
            this.selitem = _index;
        else
            this.selitem = 0;
        // if (!_isShow)
        //     this.updateUserInfo();
    },
    selectImages(_images, _index){
        if (_images.length > 0){
            this.selectedImage[_index] = _images[0];
            this.isSelected[_index] = true;
        }
        // console.log(this.selectedImage, _index)
        this.visibleGallary = false;
    },
    cancelImage(_index){
        // console.log(this.selectedImage, _index)
        // console.log(this.selectedImage, this.isSelected)
        this.selectedImage[_index] = '';
        this.isSelected.splice(_index, 1, false)
        // console.log(this.selectedImage, this.isSelected)
    },
    getImage(_index){
        // // console.log(this.selectedImage, _index)
        this.$emit('getimage', this.selectedImage[_index]);
    },
    getKeyHoneyList(){
        this.$emit('getKeyHoneyList', this.keysListHoney);
    },
    onStart(){
        // console.log("======= onStart");
        // this.drag=true;
    },
    onEnd() {
        // console.log("======= onEnd", this.keysListHoney);
    //    this.drag=false;
    },
  }
}
</script>