<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%;">
          
            <div style="margin-top:0px;border:1px solid #dddddd;border-bottom:none;background-color:#f5f5f5" v-for="item in keysListHighlight" :key="item">
                <a-row :gutter="0" style="width:100%;">
                  
                    <div style="display:flex;padding-bottom:0px;padding-top:0px">
                        <div style="width:100%;">
                            <div style="width:100%;border:1px solid #dddddd;border-left:none;border-top:none;border-bottom:none;background-color:white;height:80px">
                                <div style="width:100%;text-align:left;margin-left:10px;padding-top:5px;font-weight:600;font-size: 15px;">
                                  Title
                                  <a-form-item style="width:10px" v-show="false">
                                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                                          v-decorator="[
                                              `${title}SelectInput`,
                                              {
                                                  initialValue: 0,
                                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                                              }
                                          ]"
                                      />
                                  </a-form-item>
                                </div>
                                <a-form-item style="width:100%;padding-left:10px;padding-right:10px;" >
                                    <a-input style="width:100%;padding:2px;" placeholder="" 
                                        v-decorator="[
                                            `${title}Title[${item}]`,
                                            {
                                                initialValue: arr[item] ? arr[item].title : undefined,
                                                rules: [{ required: true, message: 'Input title!' }]
                                            }
                                        ]"
                                    />
                                </a-form-item>
                            </div>
                            
                            <div style="width:100%;border:1px solid #dddddd;border-left:none;border-bottom:none;background-color:white;padding-bottom:10px" >
                                <div style="width:100%;text-align:left;margin-left:10px;padding-top:5px;font-weight:600;font-size: 15px;">Items</div>
                                <a-form style="display:flex;padding-left:10px;padding-right:10px;" :form="hotelHighlightItemForm[item]">
                                    <hotel-highlight-item-list
                                        :title="`${HOTELHIGHLIGHTITEMPART + (item)}`"
                                        :arr="arrHotelHighlightItem[item]"
                                    />
                                </a-form>
                            </div>

                            <div style="width:100%;border:1px solid #dddddd;border-left:none;border-bottom:none;padding:2px;background-color:white;padding-bottom:10px" >
                                <div style="width:100%;text-align:left;margin-left:10px;padding-top:5px;font-weight:600;font-size: 15px;">Group photos</div>
                                <a-form style="display:flex;padding-left:10px;padding-right:10px;" :form="hotelHighlightGroupphotoForm[item]">
                                    <hotel-highlight-groupphoto-list ref="_photolist{{item}}" @photolist="getCellPhotoList"
                                        :title="`${HOTELHIGHLIGHTGROUPPHOTOPART + (item)}`"
                                        :arr="arrHotelHighlightGroupphoto[item]"
                                    />
                                </a-form>
                            </div>
                        </div>

                        <div style="margin-top:auto;margin-bottom:auto;width:50px">
                            <a-button shape="circle" type="solid" icon="minus" @click="removeRowFestive(item)" class="minusRowBtn" :size="'small'"></a-button>
                        </div>
                    </div>

                </a-row>
            </div>
            <div v-if="keysListHighlight.length > 0" style="width:100%;height:1px;background-color:#dddddd"></div>
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowFestive" type="primary">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import AwsFileManager from '../../components/AwsFileManager.vue'
import HotelExtraPeriodList from './HotelExtraPeriodList.vue'
import HotelFestivePriceList from './HotelFestivePriceList.vue'
import HotelHighlightGroupphotoList from './HotelHighlightGroupphotoList.vue'
import HotelHighlightItemList from './HotelHighlightItemList.vue'
const HOTELHIGHLIGHTITEMPART = 'hotelHighlightItemPart'
const HOTELHIGHLIGHTGROUPPHOTOPART = 'hotelHighlighGroupphotoPart'
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    AwsFileManager,
    HotelFestivePriceList,
    HotelExtraPeriodList,
    HotelHighlightItemList,
    HotelHighlightGroupphotoList      
      
  },
  data () {
    return {
      idHighlight: 0,
      keysListHighlight: [],

      hotelHighlightItemForm: [],
      HOTELHIGHLIGHTITEMPART,
      arrHotelHighlightItem: [],

      hotelHighlightGroupphotoForm: [],
      HOTELHIGHLIGHTGROUPPHOTOPART,
      arrHotelHighlightGroupphoto: [],

      
      
    }
  },
  created () {
    var mynumber = 50;
    this.hotelHighlightItemForm = new Array(mynumber);

    for (var i = 0; i < mynumber; i++) {
        this.hotelHighlightItemForm[i] = this.$form.createForm(this);
    }
    this.hotelHighlightGroupphotoForm = new Array(mynumber);

    for (var i = 0; i < mynumber; i++) {
        this.hotelHighlightGroupphotoForm[i] = this.$form.createForm(this);
    }

    this.selectedImage = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.selectedImage[i] = '';
    }
    this.isSelected = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.isSelected[i] = false;
    }
    this.init()
  },
  methods: {
    init () {
      const arr = []
      
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idHighlight = 0;
        for (let i = 0; i < (this.arr).length; i++) {
          this.arrHotelHighlightItem[i] = this.arr[i].items;
        //   this.arrHotelHighlightGroupphoto[i] = this.arr[i].groupphotos;
            this.arrHotelHighlightGroupphoto.splice(i, 1, this.arr[i].groupphotos);
          
          arr.push(i)
          this.idHighlight = this.idHighlight + 1
        }
        // console.log(this.arrHotelHighlightGroupphoto)
      }
      this.keysListHighlight = arr
    },


    removeRowFestive (k) {
      if (this.keysListHighlight.length === 0) { 
        return
      }
      this.keysListHighlight = this.keysListHighlight.filter(item => item !== k)
    },
    addRowFestive () {
      this.keysListHighlight = this.keysListHighlight.concat(this.idHighlight)
      this.idHighlight = this.idHighlight + 1
    },
    
    getItems(_index){
                // console.log("========_index========",_index)
        this.hotelHighlightItemForm[_index].validateFields((err, values) => {
          if (!err) {
            let partOneArr = [];
                // console.log(values,_index)
                if (values[`${HOTELHIGHLIGHTITEMPART + _index}Title`] !== undefined){
                    (values[`${HOTELHIGHLIGHTITEMPART + _index}Title`]).forEach((item, index) => {
                        const obj = {
                            title: item,
                            text: values[`${HOTELHIGHLIGHTITEMPART + _index}Text`][index],
                        }
                        partOneArr.push(obj)
                    })
                }
                else if (values[`${HOTELHIGHLIGHTITEMPART + _index}SelectInput`] === undefined && this.arrHotelHighlightItem[_index] !== undefined && this.arrHotelHighlightItem[_index].length > 0){
                  partOneArr = this.arrHotelHighlightItem[_index]
                }
                // console.log("========getItems========",partOneArr)
                this.$emit('highlightlist', partOneArr);
          }
        });
    },
    getCellPhotoList(value){
        this.imageList = value;
    },
    getGroupphotos(_index){
                // console.log("========getGroupphotos====sdsad====")
        this.hotelHighlightGroupphotoForm[_index].validateFields((err, values) => {
          if (!err) {
            let partOneArr = [];
                // console.log(values,_index)
                if (values[`${HOTELHIGHLIGHTGROUPPHOTOPART + _index}Title`] !== undefined){
                    (values[`${HOTELHIGHLIGHTGROUPPHOTOPART + _index}Title`]).forEach((item, index) => {
                        // this.$refs.window['_photolist' + _index].getImages(index);Groupphotos
                // console.log("========Groupphotos========")
                        this.imageList = values[`${HOTELHIGHLIGHTGROUPPHOTOPART + _index}Gallary`][index].split(',')
                        const obj = {
                            title: item,
                            description: values[`${HOTELHIGHLIGHTGROUPPHOTOPART + _index}Description`][index],
                            gallary:this.imageList
                        }
                // console.log(obj)
                        partOneArr.push(obj)
                    })
                }
                else if (values[`${HOTELHIGHLIGHTGROUPPHOTOPART + _index}SelectInput`] === undefined && this.arrHotelHighlightGroupphoto[_index] !== undefined && this.arrHotelHighlightGroupphoto[_index].length > 0){
                  partOneArr = this.arrHotelHighlightGroupphoto[_index]
                }
                // console.log("========getGroupphotos========")
                this.$emit('highlightlistphoto', partOneArr);
          }
        });
    },

  }
}
</script>