<template>
    <div class="adduser" style="min-height:85vh">
        <div>
            <div style="margin: 16px 0;display:flex">
                <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                    <a-breadcrumb-item style="font-size:30px;">Edit Room</a-breadcrumb-item>
                    <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
                </a-breadcrumb>
            </div>
            
            <a-collapse style="margin-top:10px" accordion>
                <a-collapse-panel key="3" header="Room Data">
                    <a-tabs type="card" tabPosition="left">
                        <a-tab-pane key="1">
                            <span slot="tab" style="display:flex">
                                <a-icon style="margin-top:auto;margin-bottom:auto" type="setting" theme="filled" />
                                General
                            </span>
                            <div>
                                <a-divider/>
                                <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Hotels</div>
                                    <a-select style="margin-top:auto;margin-bottom:auto;width:500px" placeholder="Select Hotel" v-model="hotel">
                                        <a-select-option v-for="item in hotels" :key="item._id" :value="item._id">
                                            {{ item.hotelname }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                                <!-- <a-divider/>
                                <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Features</div>
                                    <a-checkbox style="display:-webkit-inline-box;margin-left:0;margin-top:auto;margin-bottom:auto;" v-model="featured">Checkbox</a-checkbox>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>Is featured</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div> -->
                                <a-divider/>
                                <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Number of room</div>
                                    <a-input-number v-model="numberofroom" style="margin-top:auto;margin-bottom:auto;width:500px" id="inputNumber" :min="0" :max="100" :default-value="0"/>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>Is featured</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div>
                                <a-divider/>
                                <!-- <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Base price ($)</div>
                                    <a-input-number v-model="baseprice" style="margin-top:auto;margin-bottom:auto;width:500px" id="inputNumber" :min="0" :max="100000" :default-value="0"/>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>Is featured</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div>
                                <a-divider/> -->
                                <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Capacities</div>
                                    <a-input-number v-model="capacities" style="margin-top:auto;margin-bottom:auto;width:500px" id="inputNumber" :min="0" :max="100" :default-value="0"/>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>Is featured</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div>
                                <a-divider/>
                                <!-- <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Childrens</div>
                                    <a-input-number v-model="childrens" style="margin-top:auto;margin-bottom:auto;width:500px" id="inputNumber" :min="0" :max="100" :default-value="0"/>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>Is featured</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div>
                                <a-divider/>
                                <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Minimum night</div>
                                    <a-input-number v-model="minimumnight" style="margin-top:auto;margin-bottom:auto;width:500px" id="inputNumber" :min="0" :max="100" :default-value="0"/>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>Is featured</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div>
                                <a-divider/>
                                <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Bed</div>
                                    <a-input-number v-model="bed" style="margin-top:auto;margin-bottom:auto;width:500px" id="inputNumber" :min="0" :max="100" :default-value="0"/>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>Is featured</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div>
                                <a-divider/> -->
                                <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Api Integrate</div>
                                                                    
                                    <a-checkbox v-model="apiintegrate" :disabled="roomcode == undefined || roomcode == '' || getHotelApiStatus()">
                                        Itwx API Available
                                    </a-checkbox>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>Is featured</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div>
                                <a-divider/>
                            </div>
                        </a-tab-pane>
                        
                        <a-tab-pane key="2">
                            <span slot="tab" style="display:flex">
                                <a-icon style="margin-top:auto;margin-bottom:auto" type="setting" theme="filled" />
                                Properties
                            </span>
                            <div>
                                <a-divider/>
                                <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Room size</div>
                                    <a-input v-model="roomSize" style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>440m²</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div>
                                <a-divider/>
                                <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Standard occupancy</div>
                                    <a-input v-model="standardOccupancy" style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>2 Adults</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div>
                                <a-divider/>
                                <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Max occupancy</div>
                                    <a-input v-model="maxOccupancy" style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>3 Adults or 2 Adults+ 2 Children(below 12 years)</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div>
                                <a-divider/>
                                <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Bedding</div>
                                    <a-input v-model="bedding" style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>1 King Bed</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div>
                                <a-divider/>
                                <div style="display:flex">
                                    <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Additional beds</div>
                                    <a-input v-model="additionalBeds" style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                    <a-tooltip placement="bottom">
                                        <template slot="title">
                                        <span>Oversized Daybed</span>
                                        </template>
                                        <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                    </a-tooltip>
                                </div>
                                <a-divider/>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="3">
                            <span slot="tab" style="display:flex">
                                <a-icon style="margin-top:auto;margin-bottom:auto"  type="setting" theme="filled" />
                                Amenities
                            </span>
                            <a-divider/>
                            
                            <a-form :form="facilitiesForm">
                                <div style="margin-top:5px" v-for="item, index in keysListFacilities" :key="item">
                                    <a-row :gutter="0" style="width:100%;">
                                        <div style="display:flex;width:100%;">
                                            <div style="width:100%;display:flex">
                                                <div style="width:100px;margin-top:auto;margin-bottom:auto">Content {{index + 1}}</div>
                                                <a-form-item style="width:100%;margin-top:auto;margin-bottom:auto">
                                                    <a-input style="width:100%;padding:2px"
                                                    v-decorator="[
                                                            `facility[${index}]`,
                                                            {
                                                                initialValue: facilitiesInfo[item] ? facilitiesInfo[item] : undefined,
                                                                rules: [{ required: true, message: 'Input agefrom!' }]
                                                            }
                                                        ]"
                                                    />
                                                </a-form-item>
                                            </div>
                                            <div style="width:100px;margin-top:auto;margin-bottom:auto;">
                                                <a-button type="dashed" @click="removeRowFacilities(item)" :size="'small'">Remove</a-button>
                                            </div>
                                        </div>
                                    </a-row>
                                </div>
                                
                                <a-form-item style="width:10px" v-show="false">
                                    <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                                        v-decorator="[
                                            `facilitySelectInput`,
                                            {
                                                initialValue: 0,
                                                rules: [{ required: false, message: 'Input Adult Number!' }]
                                            }
                                        ]"
                                    />
                                </a-form-item>
                            </a-form>
                            
                            <div style="display:flex">
                                <a-button style="margin-left:auto;margin-right:auto;margin-top:5px" @click="addRowFacilities" type="primary">
                                    Add
                                </a-button>
                            </div>
                        </a-tab-pane>
                        <!-- <a-tab-pane key="4">
                            <span slot="tab" style="display:flex">
                                <a-icon style="margin-top:auto;margin-bottom:auto"  type="gift" theme="filled" />
                                Discount
                            </span>
                            <div>
                                <div>Set Discount Price</div>
                                <div style="display:flex;">
                                    <div style="width:30%;border:1px solid #aaaaaa">Type</div>
                                    <div style="width:20%;border:1px solid #aaaaaa">Unit</div>
                                    <div style="width:30%;border:1px solid #aaaaaa">Sale</div>
                                    <div style="width:20%;border:1px solid #aaaaaa">Discount</div>
                                    <div style="width:100px;border:1px solid #aaaaaa"></div>
                                </div>
                                <div style="margin-top:5px" v-for="item in keysListDiscount" :key="item">
                                    <a-row :gutter="0" style="width:100%;">
                                        <div style="display:flex;width:100%;">
                                            <div style="width:30%;">
                                                <a-select style="margin-top:auto;margin-bottom:auto;width:100%" :default-value="itemTypes[0]">
                                                    <a-select-option v-for="item in itemTypes" :key="item" :value="item">
                                                        {{ item }}
                                                    </a-select-option>
                                                </a-select>
                                            </div>
                                            <div style="width:20%;">
                                                <a-input-number style="width:100%;padding:2px" :min="0" :max="100000" placeholder="" :default-value="0"/>
                                            </div>
                                            <div style="width:30%;">
                                                <a-select style="margin-top:auto;margin-bottom:auto;width:100%" :default-value="itemSales[0]">
                                                    <a-select-option v-for="item in itemSales" :key="item" :value="item">
                                                        {{ item }}
                                                    </a-select-option>
                                                </a-select>
                                            </div>
                                            <div style="width:20%;">
                                                <a-input-number style="width:100%;padding:2px" :min="0" :max="100000" placeholder="" :default-value="0"/>
                                            </div>
                                            <div style="margin-top:auto;margin-bottom:auto;width:100px;">
                                                <a-button type="dashed" @click="removeRowDiscount(item)" :size="'small'">Remove</a-button>
                                            </div>
                                        </div>
                                    </a-row>
                                </div>
                                
                                <div style="display:flex">
                                    <a-button style="margin-left:auto;margin-right:auto;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowDiscount" type="primary">
                                        Add Row
                                    </a-button>
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="5">
                            <span slot="tab" style="display:flex">
                                <a-icon style="margin-top:auto;margin-bottom:auto"  type="api" theme="filled" />
                                Extra Price
                            </span>
                            <div>Adults</div>
                            <div style="display:flex;">
                                <div style="width:50%;border:1px solid #aaaaaa">Qty</div>
                                <div style="width:50%;border:1px solid #aaaaaa">Price ($)</div>
                                <div style="width:100px;border:1px solid #aaaaaa"></div>
                            </div>
                            <div style="margin-top:5px" v-for="item in keysListAdults" :key="item">
                                <a-row :gutter="0" style="width:100%;">
                                    <div style="display:flex;width:100%;">
                                        <div style="width:50%;">
                                            <a-input-number style="width:100%;padding:2px" :min="0" :max="100000" placeholder="" :default-value="0"/>
                                        </div>
                                        <div style="width:50%;">
                                            <a-input-number style="width:100%;padding:2px" :min="0" :max="100000" placeholder="" :default-value="0"/>
                                        </div>
                                        <div style="width:100px;margin-top:auto;margin-bottom:auto;">
                                            <a-button type="dashed" @click="removeRowAdults(item)" :size="'small'">Remove</a-button>
                                        </div>
                                    </div>
                                </a-row>
                            </div>
                            
                            <div style="display:flex">
                                <a-button style="margin-left:auto;margin-right:auto;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowAdults" type="primary">
                                    Add Row
                                </a-button>
                            </div>
                            <a-divider/>
                            <div>Childrens</div>
                            <div style="display:flex;">
                                <div style="width:50%;border:1px solid #aaaaaa">Qty</div>
                                <div style="width:50%;border:1px solid #aaaaaa">Price ($)</div>
                                <div style="width:100px;border:1px solid #aaaaaa"></div>
                            </div>
                            <div style="margin-top:5px" v-for="item in keysListChildren" :key="item">
                                <a-row :gutter="0" style="width:100%;">
                                    <div style="display:flex;width:100%;">
                                        <div style="width:50%;">
                                            <a-input-number style="width:100%;padding:2px" :min="0" :max="100000" placeholder="" :default-value="0"/>
                                        </div>
                                        <div style="width:50%;">
                                            <a-input-number style="width:100%;padding:2px" :min="0" :max="100000" placeholder="" :default-value="0"/>
                                        </div>
                                        <div style="width:100px;margin-top:auto;margin-bottom:auto;">
                                            <a-button type="dashed" @click="removeRowChildren(item)" :size="'small'">Remove</a-button>
                                        </div>
                                    </div>
                                </a-row>
                            </div>
                            
                            <div style="display:flex">
                                <a-button style="margin-left:auto;margin-right:auto;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowChildren" type="primary">
                                    Add Row
                                </a-button>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="6">
                            <span slot="tab" style="display:flex">
                                <a-icon style="margin-top:auto;margin-bottom:auto"  type="snippets" theme="filled" />
                                Packages
                            </span>
                            <a-input-search placeholder="search" style="width: 200px"/>
                            <div style="display:flex">
                                <a-button style="margin-left:auto;margin-right:auto;margin-top:5px" @click="addRowChildren" type="primary">
                                    Add Package
                                </a-button>
                            </div>
                        </a-tab-pane> -->
                        <!-- <a-tab-pane key="7">
                            <span slot="tab" style="display:flex">
                                <a-icon style="margin-top:auto;margin-bottom:auto"  type="setting" theme="filled" />
                                Amenities
                            </span>
                            
                            <a-divider/>
                            <div style="display:flex">
                                <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Room Size</div>
                                <a-input style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                <a-tooltip placement="bottom">
                                    <template slot="title">
                                    <span>Is featured</span>
                                    </template>
                                    <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                </a-tooltip>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">View</div>
                                <a-input style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                <a-tooltip placement="bottom">
                                    <template slot="title">
                                    <span>Is featured</span>
                                    </template>
                                    <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                </a-tooltip>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Wifi</div>
                                <a-input style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                <a-tooltip placement="bottom">
                                    <template slot="title">
                                    <span>Is featured</span>
                                    </template>
                                    <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                </a-tooltip>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Cable TV</div>
                                <a-input style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                <a-tooltip placement="bottom">
                                    <template slot="title">
                                    <span>Is featured</span>
                                    </template>
                                    <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                </a-tooltip>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Iron</div>
                                <a-input style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                <a-tooltip placement="bottom">
                                    <template slot="title">
                                    <span>Is featured</span>
                                    </template>
                                    <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                </a-tooltip>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Breakfast</div>
                                <a-input style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                <a-tooltip placement="bottom">
                                    <template slot="title">
                                    <span>Is featured</span>
                                    </template>
                                    <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                </a-tooltip>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Airport Pickup</div>
                                <a-input style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                <a-tooltip placement="bottom">
                                    <template slot="title">
                                    <span>Is featured</span>
                                    </template>
                                    <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                </a-tooltip>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Free Slippers</div>
                                <a-input style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                <a-tooltip placement="bottom">
                                    <template slot="title">
                                    <span>Is featured</span>
                                    </template>
                                    <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                </a-tooltip>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Pets Allowed</div>
                                <a-input style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                <a-tooltip placement="bottom">
                                    <template slot="title">
                                    <span>Is featured</span>
                                    </template>
                                    <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                </a-tooltip>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <div style="margin-top:auto;margin-bottom:auto;width:150px;text-align:left">Room Service</div>
                                <a-input style="margin-top:auto;margin-bottom:auto;width:500px"/>
                                <a-tooltip placement="bottom">
                                    <template slot="title">
                                    <span>Is featured</span>
                                    </template>
                                    <a-icon style="margin-left:5px;margin-top:auto;margin-bottom:auto;" type="question-circle" />
                                </a-tooltip>
                            </div>
                            <a-divider/>
                        </a-tab-pane> -->
                        <!-- <a-tab-pane key="8">
                            <span slot="tab" style="display:flex">
                                <a-icon style="margin-top:auto;margin-bottom:auto"  type="setting" theme="filled" />
                                Extra Amenities
                            </span>
                            <a-divider/>
                            <div style="margin-top:5px" v-for="item in keysListExtraAmenities" :key="item">
                                <a-row :gutter="0" style="width:100%;">
                                    <div style="display:flex;width:100%;">
                                        <div style="width:50%;display:flex">
                                            <div style="width:100px;margin-top:auto;margin-bottom:auto">Label</div>
                                            <a-input style="width:100%;padding:2px"/>
                                        </div>
                                        <div style="width:50%;display:flex">
                                            <div style="width:100px;margin-top:auto;margin-bottom:auto">Content</div>
                                            <a-input style="width:100%;padding:2px"/>
                                        </div>
                                        <div style="width:100px;margin-top:auto;margin-bottom:auto;">
                                            <a-button type="dashed" @click="removeRowExtraAmenities(item)" :size="'small'">Remove</a-button>
                                        </div>
                                    </div>
                                </a-row>
                            </div>
                            
                            <div style="display:flex">
                                <a-button style="margin-left:auto;margin-right:auto;margin-top:5px" @click="addRowExtraAmenities" type="primary">
                                    Add
                                </a-button>
                            </div>
                        </a-tab-pane> -->
                        <!-- <a-tab-pane key="9">
                            <span slot="tab" style="display:flex">
                                <a-icon style="margin-top:auto;margin-bottom:auto"  type="book" theme="filled" />
                                Description
                            </span>
                            
                            <a-textarea style="margin-top:10px"
                                placeholder="Controlled autosize"
                                :auto-size="{ minRows: 10, maxRows: 15 }"
                            />
                        </a-tab-pane> -->
                    </a-tabs>
                </a-collapse-panel>
            </a-collapse>
            <a-input style="margin-top:30px;margin-bottom:10px" placeholder="Enter Room Name" v-model="roomname" :size="'large'" />
            <!-- <a-textarea style="margin-top:10px"
                placeholder="Controlled autosize"
                :auto-size="{ minRows: 10, maxRows: 15 }"
            /> -->
            <vue2-tinymce-editor style="margin-top:5px" v-model="roomtext" :options="options"></vue2-tinymce-editor>
            <a-collapse style="margin-top:10px" accordion>
                <a-collapse-panel key="1" header="Room Custom Field">
                    
                    <a-tabs type="card" tabPosition="left">
                        <a-tab-pane key="1" tab="Room Condition">
                            <div style="text-align:left;font-weight: 600;">Room AC number</div>
                            <div style="display:flex;border:1px solid #bbb">
                                <div style="width:30%">
                                    <div style="border:1px solid #bbb;height:40px;line-height:40px">Standard-Max Guest</div>
                                    <div style="border:1px solid #bbb;padding:5px;height:100%">
                                        <div style="border:1px solid #bbb;padding:5px">
                                            <a-input-number style="width:100%" v-model="standardmin" :min="1" :max="100000" placeholder="Standard Guest"/>                      
                                            <a-divider/>
                                            <a-input-number style="width:100%" v-model="standardmax" :min="1" :max="100000" placeholder="Max Adult"/>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:70%">
                                    <div>Adult and Child Number</div>
                                    <div style="padding:5px">
                                        <div style="width:100%;border:1px solid #aaa;padding:2px" >
                                            
                                            <a-form style="display:flex" :form="adultChildForm">
                                                <adult-child-list :key="reRenderKey"
                                                    :title="`${ADULTCHILDPART}`"
                                                    :arrInfo="arrAdultChildNumber"
                                                    @checkAdultCount="checkAdultCount"
                                                />
                                            </a-form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="Room Availability">
                            <div style="text-align:left;font-weight: 600;">Room Show Ability</div>
                            <div style="margin-top:0;margin-bottom:0">
                                <a-form style="display:flex" :form="roomAbilityForm">
                                    <room-ability-list :key="reRenderKey"
                                        :title="`${ROOMABILITYPART}`"
                                        :arr="arrRoomAbility"
                                    />
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="Room Extra Person Price">
                            <div style="margin-top:0;margin-bottom:0">
                                
                                <a-form style="display:flex" :form="roomExtraPersonPriceForm">
                                    <room-extra-person-price-list :key="reRenderKey" ref="_info" @list="getSubList"
                                        :title="`${ROOMEXTRAPERSONPRICEPART}`"
                                        :arr="arrRoomExtraPersonPrice"
                                    />
                                </a-form>

                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </a-collapse-panel>
                <a-collapse-panel key="2" header="Discussion">
                    <div style="display:flex">
                        <a-checkbox>Allow comments</a-checkbox><br>
                    </div>
                    <div style="display:flex">
                        <a-checkbox>Allow trackbacks and pingbacks on this page</a-checkbox>
                    </div>
                </a-collapse-panel>
                <a-collapse-panel key="4" header="Gallery">
                    <a-button @click="showGallary(true)">Add room gallary images</a-button>
                    <a-modal
						v-model="visibleGallary"
						title="Add Gallay"
						:footer="null"
                        width="80%"
					>
                        <AwsFileManager v-if="visibleGallary" :ismulti="true" @close="showGallary(false)" @selectImages="selectImages"/>
						<!-- <AddGallary :ismulti="true" @close="showGallary(false)" @selectImages="selectImages"/> -->
					</a-modal>
                    <a-list style="margin-top:10px" :grid="{ gutter: 24, xs: 3, sm: 4, md: 6, lg: 8, xl: 8, xxl: 12 }" :data-source="gallaryImages">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <div style="border: 1px solid #E7E7E7;position:relative">
                                <div style="width:100%;height:8vh">
                                    <img style="object-fit: cover;width:100%;height:100%" :src="item != ''?`${item}`:''">
                                </div>
                                <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelImage(item)" :size="'small'"></a-button>
                            </div>
                        </a-list-item>
                    </a-list>

                </a-collapse-panel>
                <a-collapse-panel key="5" header="Room Image">
                    
                    <div style="display:flex;">
                        <div v-if="false" style="margin-left:auto;margin-right:auto;border:1px solid #eee;padding:20px">
                            Back-end thumbnail
                            <a-button @click="showRoomImage(true)" style="margin-top:auto;margin-bottom:auto;margin-left:20px">
                            Add Image
                            </a-button>
                            <a-modal
                            v-model="visibleRoomImage"
                                title="Add Room Image"
                                :footer="null"
                                width="80%"
                            >
                                <AwsFileManager v-if="visibleRoomImage" :ismulti="false" @close="showRoomImage(false)" @selectImages="selectRoomImage"/>
                                <!-- <AddGallary :ismulti="false" @close="showRoomImage(false)" @selectImages="selectRoomImage"/> -->
                            </a-modal>
                            <a-list style="margin-top:10px;width:150px;margin-left:auto;margin-right:auto" :grid="{ gutter: 16, column: 1 }" :data-source="roomImage">
                                <a-list-item slot="renderItem" slot-scope="item">
                                    <div style="border: 1px solid #E7E7E7;position:relative">
                                        <img style="object-fit: cover;height:100%;" :src="item != ''?`${item}`:''" alt="" width="150px" height="100px">
                                        <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelRoomImage(item)" :size="'small'"></a-button>
                                    </div>
                                </a-list-item>
                            </a-list>
                        </div>
                        <div style="margin-left:auto;margin-right:auto;border:1px solid #eee;padding:20px">
                            Room Floor Plan
                            <a-button @click="showRoomFloorPlan(true)" style="margin-top:auto;margin-bottom:auto;margin-left:20px">
                                Add Floor Plan
                            </a-button>
                            <a-modal
                            v-model="visibleRoomFloorPlan"
                                title="Add Floor Plan"
                                :footer="null"
                                width="80%"
                            >
                                <AwsFileManager v-if="visibleRoomFloorPlan" :ismulti="true" @close="showRoomFloorPlan(false)" @selectImages="selectRoomFloorPlan"/>
                                <!-- <AddGallary :ismulti="false" @close="showRoomImage(false)" @selectImages="selectRoomImage"/> -->
                            </a-modal>
                            <a-list style="margin-top:10px;width:150px;margin-left:auto;margin-right:auto" :grid="{ gutter: 16, column: 1 }" :data-source="roomFloorPlan">
                                <a-list-item slot="renderItem" slot-scope="item">
                                    <div style="border: 1px solid #E7E7E7;position:relative">
                                        <img style="object-fit: cover;height:100%;" :src="item.substring(item.length - 4).toLowerCase() == '.pdf' ? '/images/pdfimage.png' : `${item}`" alt="" width="150px" height="100px">
                                        <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelRoomFloorPlan(item)" :size="'small'"></a-button>
                                    </div>
                                </a-list-item>
                            </a-list>
                        </div>
                    </div>

                </a-collapse-panel>
                <a-collapse-panel key="7" header="Room Pricing">
                    <!-- <a-collapse accordion default-active-key="1">
                        <a-collapse-panel key="1" header="Update Pricing">
                            <div style="display:flex">
                                <a-date-picker style="width:200px;padding:2px" placeholder="Arrival"/>
                                <a-date-picker style="width:200px;padding:2px" placeholder="Departure"/>
                            </div>
                            <div style="display:flex;margin-top:10px">
                                <a-checkbox>Sun</a-checkbox>
                                <a-checkbox>Mon</a-checkbox>
                                <a-checkbox>Tue</a-checkbox>
                                <a-checkbox>Wed</a-checkbox>
                                <a-checkbox>Thu</a-checkbox>
                                <a-checkbox>Fri</a-checkbox>
                                <a-checkbox>Sat</a-checkbox>
                            </div>
                            <div style="display:flex;margin-top:10px">
                                <a-select style="margin-top:auto;margin-bottom:auto;width:200px;padding:2px" :default-value="itemPrices[0]">
                                    <a-select-option v-for="item in itemPrices" :key="item" :value="item">
                                        {{ item }}
                                    </a-select-option>
                                </a-select>
                                <a-input-number style="width:200px;padding:2px;margin-top:auto;margin-bottom:auto;  " :min="0" :max="100000" placeholder="" :default-value="0"/>
                                        
                            </div>
                        </a-collapse-panel>
                        <a-collapse-panel key="2" header="Filter" :disabled="false">
                            <div style="display:flex">
                                <a-select style="margin-top:auto;margin-bottom:auto;width:200px;padding:2px" :default-value="itemMonths[0]">
                                    <a-select-option v-for="item in itemMonths" :key="item" :value="item">
                                        {{ item }}
                                    </a-select-option>
                                </a-select>
                                <a-select style="margin-top:auto;margin-bottom:auto;width:200px;padding:2px" :default-value="itemYears[0]">
                                    <a-select-option v-for="item in itemYears" :key="item" :value="item">
                                        {{ item }}
                                    </a-select-option>
                                </a-select>
                                <div style="display:flex;margin-top:auto;margin-bottom:auto;">
                                    <a-button style="margin-left:auto;margin-right:auto;">
                                        Filter
                                    </a-button>
                                </div>
                            </div>
                        </a-collapse-panel>
                    </a-collapse> -->
                    <a-calendar>
                        <ul slot="dateCellRender" slot-scope="value" class="events">
                        <li v-for="item in getListData(value)" :key="item.content">
                            <a-badge :status="item.type" :text="item.content" />
                        </li>
                        </ul>
                        <template slot="monthCellRender" slot-scope="value">
                        <div v-if="getMonthData(value)" class="notes-month">
                            <section>{{ getMonthData(value) }}</section>
                            <span>Backlog number</span>
                        </div>
                        </template>
                    </a-calendar>
                </a-collapse-panel>
                <a-collapse-panel key="8" header="Author">
                    <div style="display:flex">
                        <a-select style="margin-top:auto;margin-bottom:auto;width:200px;padding:2px" :default-value="itemAuthors[0]">
                            <a-select-option v-for="item in itemAuthors" :key="item" :value="item">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </div>
                </a-collapse-panel>
                <a-collapse-panel key="9" header="Yoast SEO">
                    <a-tabs type="card">
                        <a-tab-pane key="1">
                            <span slot="tab" style="display:flex">
                                <a-icon style="margin-top:auto;margin-bottom:auto" type="fire" theme="filled" />
                                SEO
                            </span>
                            <div style="text-align:left">Focus Keyphrase</div>
                            <a-input style="width:100%;padding:2px"/>
                            <a-collapse accordion default-active-key="1">
                                <a-collapse-panel key="1" header="Google Preview">
                                    <a-radio-group name="radioGroup" :default-value="1">
                                        <a-radio :value="1">
                                            Mobile Result
                                        </a-radio>
                                        <a-radio :value="2">
                                            Desktop Result
                                        </a-radio>
                                    </a-radio-group>
                                </a-collapse-panel>
                                <a-collapse-panel key="2">
                                    <span slot="header" style="display:flex;">
                                        <a-icon style="margin-top:auto;margin-bottom:auto;margin-right:10px" type="fire" theme="filled" />
                                        SEO analysis
                                    </span>
                                </a-collapse-panel>
                                <a-collapse-panel key="3">
                                    <span slot="header" style="display:flex;">
                                        <a-icon style="margin-top:auto;margin-bottom:auto;margin-right:10px" type="plus"/>
                                        Add related keyphase
                                    </span>
                                </a-collapse-panel>
                                <a-collapse-panel key="4" header="Cornerstone Content">
                                </a-collapse-panel>
                                <a-collapse-panel key="5" header="Advanced">
                                </a-collapse-panel>
                            </a-collapse>
                        </a-tab-pane>
                        <a-tab-pane key="2">
                            <span slot="tab" style="display:flex">
                                <a-icon style="margin-top:auto;margin-bottom:auto" type="fire" theme="filled" />
                                Readability
                            </span>
                            sss
                        </a-tab-pane>
                        <a-tab-pane key="3">
                            <span slot="tab" style="display:flex">
                                <a-icon style="margin-top:auto;margin-bottom:auto" type="apartment" />
                                Social
                            </span>
                            sss
                        </a-tab-pane>
                    </a-tabs>
                </a-collapse-panel>
            </a-collapse>
            
            <div style="display:flex">
                <a-button v-if="!published" style="margin-right:auto;margin-left:0;margin-top:10px" size="large" @click="publishRoom(0)">Save Draft</a-button>
                <a-button style="margin-right:0;margin-left:auto;margin-top:10px" :style="{ background: '#0071a1',border:'none'}" type="primary" size="large" @click="publishRoom(1)">Publish</a-button>
            </div>
        </div>
    </div>
</template>
<script>
var moment = require('moment-timezone');
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
import { api } from '../helpers/Helpers';
import AddGallary from '../components/AddGallary.vue'
import AdultChildList from './RoomDynamicList/AdultChildList'
import RoomAbilityList from './RoomDynamicList/RoomAbilityList'
import RoomExtraPersonPriceList from './RoomDynamicList/RoomExtraPersonPriceList'
import AwsFileManager from '../components/AwsFileManager.vue';
const ADULTCHILDPART = 'adultChildPart'
const ROOMABILITYPART = 'roomAbilityPart'
const ROOMEXTRAPERSONPRICEPART = 'roomExtraPersonPricePart'


var selecetedList = [];
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    selecetedList = selectedRows;
    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selecetedList);
  },
  onSelect: (record, selected, selectedRows) => {
    // console.log("onSelect === ");
    // console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    // console.log("onSelectAll === ");
    // console.log(selectedRows);
    // console.log(selected);
    // console.log(changeRows);
  },
}

export default {
    
  data() {
    return {
        reRenderKey:false,
      options:{
        menubar:true,
        plugins: 'advlist autolink charmap code codesample directionality emoticons',
        toolbar1:'fontselect | fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor',
        toolbar2:'alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code',
        content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap');",
            
            // Raleway Light=raleway light, raleway,light; 
            // Raleway Medium=raleway medium, raleway,bold; 
            // Raleway Bold=Raleway ExtraBold, raleway; 
        font_formats: `Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; 
            Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; 
            
            Raleway=raleway medium, raleway; 

            Montserrat Light=Montserrat light; Montserrat Medium=Montserrat medium; Montserrat Bold=Montserrat ExtraBold; 
            Calibri Light=Calibri light; Calibri Medium=Calibri medium; Calibri Bold=Calibri ExtraBold; 
            Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; 
            Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats`,
        },
      hotels: [],
      itemSales: ['Subtract Price', 'Descrease % Price'],
      itemTypes: ['Before Days', 'Stay Days'],
      itemPrices: ['New Prcie','Subtract Price','Add to Price','Increase % Price','Decrease % Price'],
      itemMonths: ['January','February','March','April','May','June','July','August','September','October','November','December'],
      itemYears: [2022,2023,2024,2025],
      itemAuthors: ['David K','Amit(freezingboxer)','Dima Bakuma','Sue Rin'],

      adultChildForm: this.$form.createForm(this),
      ADULTCHILDPART,
      arrAdultChildNumber: [ ],
      
      roomAbilityForm: this.$form.createForm(this),
      ROOMABILITYPART,
      arrRoomAbility: [ ],
      
      roomExtraPersonPriceForm: this.$form.createForm(this),
      ROOMEXTRAPERSONPRICEPART,
      arrRoomExtraPersonPrice: [ ],

      subList:[],

      idDiscount: 0,
      keysListDiscount: [],
      idAdults: 0,
      keysListAdults: [],
      idChildren: 0,
      keysListChildren: [],
      idExtraAmenities: 0,
      keysListExtraAmenities: [],

      idFacilities: 0,
      keysListFacilities: [],
      facilitiesInfo:[],

      createdRoomId:'',
      published:false,

      roomname:'',
      roomtext:'',
      roomcode:'',
      apiintegrate:'',
      standardmin:'',
      standardmax:'',
      hotel:"",
      hotelcode:'',
      featured:false,
      numberofroom:0,
      baseprice:0,
      capacities:0,
      childrens:0,
      minimumnight:0,
      bed:0,

      roomSize:'',
      standardOccupancy:'',
      maxOccupancy:'',
      bedding:'',
      additionalBeds:'',

      facilitiesForm: this.$form.createForm(this),
      
      visibleGallary: false,
      gallaryImages:[],
      visibleRoomImage: false,
      roomImage:[],
      visibleRoomFloorPlan: false,
      roomFloorPlan:[],

      selectedRoom:[],

      rowSelection,
      selecetedList,
      
        roomId:'',
        roomPriceInfoByDate:[],
    };
  },
  components: {
    Vue2TinymceEditor,
    AddGallary,
    AdultChildList,
    RoomAbilityList,
    RoomExtraPersonPriceList,
    AwsFileManager,
  },
  created () {
    // this.form = this.$form.createForm(this)
    this.idDiscount = 0;
    this.keysListDiscount = [];
    this.idAdults = 0;
    this.keysListAdults = [];
    this.idChildren = 0;
    this.keysListChildren = [];
    this.idExtraAmenities = 0;
    this.keysListExtraAmenities = [];

    
    this.idFacilities = 0,
    this.keysListFacilities = [],
    this.facilitiesInfo = [],

    this.createdRoomId = '';
    this.published = false;

    
    this.getHotels();
    this.selectedRoom = this.$route.query
    // console.log("--- " , this.selectedRoom);
    this.initRoom();
    // this.hotel = this.hotels[0]
  },
  
  methods: {
    moment,
    getHotels:async function(){
    
      const res = await api.getHotelsTotal();
      // console.log(res);
      this.hotels = res;
    },
    removeRowDiscount (k) {
      if (this.keysListDiscount.length === 0) { 
        return
      }
      this.keysListDiscount = this.keysListDiscount.filter(item => item !== k)
    },
    addRowDiscount () {
      this.idExtraAmenities = this.idExtraAmenities + 1
      this.keysListDiscount = this.keysListDiscount.concat(this.idExtraAmenities)
    },

    removeRowAdults (k) {
      if (this.keysListAdults.length === 0) { 
        return
      }
      this.keysListAdults = this.keysListAdults.filter(item => item !== k)
    },
    addRowAdults () {
      this.idAdults = this.idAdults + 1
      this.keysListAdults = this.keysListAdults.concat(this.idAdults)
    },

    removeRowChildren (k) {
      if (this.keysListChildren.length === 0) { 
        return
      }
      this.keysListChildren = this.keysListChildren.filter(item => item !== k)
    },
    addRowChildren () {
      this.idChildren = this.idChildren + 1
      this.keysListChildren = this.keysListChildren.concat(this.idChildren)
    },
    removeRowExtraAmenities (k) {
      if (this.keysListExtraAmenities.length === 0) { 
        return
      }
      this.keysListExtraAmenities = this.keysListExtraAmenities.filter(item => item !== k)
    },
    addRowExtraAmenities () {
      this.idChildren = this.idChildren + 1
      this.keysListExtraAmenities = this.keysListExtraAmenities.concat(this.idChildren)
    },

    removeRowFacilities (k) {
      if (this.keysListFacilities.length === 0) { 
        return
      }
      this.keysListFacilities = this.keysListFacilities.filter(item => item !== k)
    },
    addRowFacilities () {
      this.keysListFacilities = this.keysListFacilities.concat(this.idFacilities);
      this.idFacilities += 1;
    },

    getListData(value) {
      let listData;
      const cellDate = value.toDate();
      const objValue = this.roomPriceInfoByDate.filter(obj => (new Date(obj.fromDate)).getTime() - 86400000 < cellDate.getTime() && (new Date(obj.toDate)).getTime() >= cellDate.getTime());
      if (objValue.length > 0){
          listData = [
              {
                   type: 'success', 
                   content: objValue[0].roomPrice.toString() 
              }
          ]
      }
      // roomPriceInfoByDate
    //   switch (value.date()) {
    //     case 8:
    //       listData = [
    //         { type: 'warning', content: 'This is warning event.' },
    //         { type: 'success', content: 'This is usual event.' },
    //       ];
    //       break;
    //     case 10:
    //       listData = [
    //         { type: 'warning', content: 'This is warning event.' },
    //         { type: 'success', content: 'This is usual event.' },
    //         { type: 'error', content: 'This is error event.' },
    //       ];
    //       break;
    //     case 15:
    //       listData = [
    //         { type: 'warning', content: 'This is warning event' },
    //         { type: 'success', content: 'This is very long usual event。。....' },
    //         { type: 'error', content: 'This is error event 1.' },
    //         { type: 'error', content: 'This is error event 2.' },
    //         { type: 'error', content: 'This is error event 3.' },
    //         { type: 'error', content: 'This is error event 4.' },
    //       ];
    //       break;
    //     default:
    //   }
      return listData || [];
    },

    getMonthData(value) {
      if (value.month() === 8) {
        return 1394;
      }
    },
    
    initRoom:async function(){
        const _id = this.$route.query.id;
        // console.log(_id) 
        if (_id != null){
            this.roomId = _id;
            this.selectedRoom = await api.getRoom(_id)
            // console.log(this.selectedRoom) 
            this.roomname = this.selectedRoom.roomname;
            this.roomtext = this.selectedRoom.roomtext;
            this.roomcode = this.selectedRoom.roomcode;
            this.apiintegrate = this.selectedRoom.apiintegrate;
            this.standardmin = this.selectedRoom.standardmin;
            this.standardmax = this.selectedRoom.standardmax;
            this.arrAdultChildNumber = this.selectedRoom.adultchildnummber;
            this.arrRoomAbility = this.selectedRoom.roomavaliability;
            this.arrRoomExtraPersonPrice = this.selectedRoom.roomextrapersonprice;
            this.hotel = this.selectedRoom.hotel;
            this.hotelcode = this.selectedRoom.hotelcode;
            this.featured = this.selectedRoom.featured;
            this.numberofroom = this.selectedRoom.numberofroom;
            this.baseprice = this.selectedRoom.baseprice;
            this.capacities = this.selectedRoom.capacities;
            this.childrens = this.selectedRoom.childrens;
            this.minimumnight = this.selectedRoom.minimumnight;
            this.bed = this.selectedRoom.bed;

            this.roomSize = this.selectedRoom.roomSize;
            this.standardOccupancy = this.selectedRoom.standardOccupancy;
            this.maxOccupancy = this.selectedRoom.maxOccupancy;
            this.bedding = this.selectedRoom.bedding;
            this.additionalBeds = this.selectedRoom.additionalBeds;
            
            if (this.selectedRoom.facilitiesInfo != undefined){
                this.facilitiesInfo = this.selectedRoom.facilitiesInfo;
                this.keysListFacilities = [];
                for (let i = 0; i < this.facilitiesInfo.length; i++){
                    this.keysListFacilities.push(this.idFacilities);
                    this.idFacilities += 1;
                }
            }

            this.gallaryImages = this.selectedRoom.gallaryimages;
            this.selecetedList = [];
            this.roomImage = [];
            this.roomImage.push(this.selectedRoom.roomimage);
            this.roomFloorPlan = [];
            if (this.selectedRoom.roomfloorplans != undefined)
                this.roomFloorPlan = this.selectedRoom.roomfloorplans;
            // this.roomFloorPlan.push(this.selectedRoom.roomfloorplan);
            // console.log("this.arrRoomExtraPersonPrice");
            // console.log(this.arrRoomExtraPersonPrice);
            if (this.hotel != '' && this.hotel != undefined)
                this.getBookingIfo(this.hotel)
            this.reRenderKey = !this.reRenderKey;
        }
    },
    getBookingIfo:async function(_hotelId){
        const res = await api.getBookingsByHotelID(_hotelId)
        // console.log("getBookingIfo==",res);
        if (res.length > 0){
            this.getRoomBookingPrice(res[0].datalist);
        }
    },
    getRoomBookingPrice(_info){
        this.roomPriceInfoByDate = []
        for (let i = 0; i < _info.length; i++){
            const priceList = JSON.parse(_info[i].roomPrice)
            const datePrice = priceList[this.roomId];
            this.roomPriceInfoByDate.push(
                {
                    fromDate: _info[i].fromDate,
                    toDate: _info[i].toDate,
                    roomPrice: datePrice
                }
            )
        }
        // console.log("roomPriceInfoByDate==", this.roomPriceInfoByDate);
    },
    getHotelApiStatus(){
        let _hotelinfo = this.hotels.filter(obj => obj._id == this.hotel)
        if (_hotelinfo.length <= 0)
            return true;
        if (_hotelinfo[0].apiintegrate == undefined)
            return true;
        if (_hotelinfo[0].hotelcode == undefined)
            return true;
        if (_hotelinfo[0].hotelcode == '')
            return true;
        return !_hotelinfo[0].apiintegrate;            

    },
    
    checkAdultCount(cell){
        var allAdultCount = 0;
        
        setTimeout(() => {
            const { adultChildForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    let partOneArr = [];
                    if (values[`${ADULTCHILDPART}AdultNumber`] !== undefined){
                        (values[`${ADULTCHILDPART}AdultNumber`]).forEach((item, index) => {
                            allAdultCount += item;

                            const obj = {
                                adultnumber: item,
                                childnumber: values[`${ADULTCHILDPART}ChildNumber`][index],
                                childagefrom: values[`${ADULTCHILDPART}ChildAgeFrom`][index],
                                childageto: values[`${ADULTCHILDPART}ChildAgeTo`][index]
                            }
                            partOneArr.push(obj)
                        })
                    }
                    if (this.standardmax >= 0 && partOneArr[cell].adultnumber > this.standardmax){
                        // console.log("allAdultCount ", allAdultCount);
                        this.arrAdultChildNumber = [];
                        this.reRenderKey = !this.reRenderKey;
                        
                        setTimeout(() => {
                            var tempInfo = partOneArr[cell];
                            tempInfo.adultnumber = this.standardmax;
                            partOneArr.splice(cell, 1, tempInfo);
                            this.arrAdultChildNumber = partOneArr;
                            // console.log("arrAdultChildNumber ", this.arrAdultChildNumber);
                            this.reRenderKey = !this.reRenderKey;
                        }, 1);
                    }
                }
            });
        }, 10);

    },
    publishRoom:async function(status){
        this.getFacilitiesInfo();
        this.getAdultChildNumber();
        this.getRoomAbility();
        this.getRoomExtraPersonPrice();

        let _roomimage = '';
        if (this.roomImage.length > 0)
            _roomimage = this.roomImage[0];
        // let _roomFloorPlan = '';
        // if (this.roomFloorPlan.length > 0)
        //     _roomFloorPlan = this.roomFloorPlan[0];
        if (this.selectedRoom.status == 1)
            status = 1;

        let _hotelinfo = this.hotels.filter(obj => obj._id == this.hotel)
        let _hotelname = '';
        if (_hotelinfo.length > 0)
            _hotelname = _hotelinfo[0].hotelname
        var currentDate = new Date();
        // console.log(this.facilitiesInfo)
        var roomInfo = {
            type: 'Room',
            roomname: this.roomname,
            apiintegrate: this.apiintegrate,
            roomtext: this.roomtext,
            standardmin: this.standardmin,
            standardmax: this.standardmax,
            adultchildnummber: this.arrAdultChildNumber,
            roomavaliability: this.arrRoomAbility,
            roomextrapersonprice:this.arrRoomExtraPersonPrice,
            hotel: this.hotel,
            hotelname: _hotelname,
            featured: this.featured,
            numberofroom: this.numberofroom,
            baseprice:this.baseprice,
            capacities:this.capacities,
            childrens:this.childrens,
            minimumnight:this.minimumnight,
            bed:this.bed,

            
            roomSize:this.roomSize,
            standardOccupancy:this.standardOccupancy,
            maxOccupancy:this.maxOccupancy,
            bedding:this.bedding,
            additionalBeds:this.additionalBeds,

            facilitiesInfo: this.facilitiesInfo,

            gallaryimages:this.gallaryImages,
            roomimage:_roomimage,
            // roomfloorplan:_roomFloorPlan,
            roomfloorplans:this.roomFloorPlan,
            roomtags:['tetet'],

            status:status,
            createdate:currentDate,
            number:0,

            userid:"MineUserId"
        }
            const res = await api.updateRoom(this.roomId, roomInfo);
            // console.log(res.id)
            if (res != undefined && res != ""){
                this.$message.success('Room Update Success!');
                this.createdRoomId = res.id;
                // this.published = true;
                
            }
            else{
                this.$message.error('Room Update Failed!');
            }
    },





    getAdultChildNumber(){
        const { adultChildForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${ADULTCHILDPART}AdultNumber`] !== undefined){
                    (values[`${ADULTCHILDPART}AdultNumber`]).forEach((item, index) => {
                        const obj = {
                        adultnumber: item,
                        childnumber: values[`${ADULTCHILDPART}ChildNumber`][index],
                        childagefrom: values[`${ADULTCHILDPART}ChildAgeFrom`][index],
                        childageto: values[`${ADULTCHILDPART}ChildAgeTo`][index]
                        }
                        partOneArr.push(obj)
                    })
                    // console.log(partOneArr)
                    this.arrAdultChildNumber = partOneArr;
                }
                else if(values[`${ADULTCHILDPART}SelectInput`] !== undefined){
                    this.arrAdultChildNumber = partOneArr;
                }
            }
        });
    },
    getRoomAbility(){
        const { roomAbilityForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${ROOMABILITYPART}blockStart`] !== undefined){
                    (values[`${ROOMABILITYPART}blockStart`]).forEach((item, index) => {
                        const obj = {
                            blockstart: moment.utc(item).tz("America/Los_Angeles"),
                            blockend: moment.utc(values[`${ROOMABILITYPART}blockEnd`][index]).tz("America/Los_Angeles"),
                            allotment: values[`${ROOMABILITYPART}allotment`][index],
                        }
                        partOneArr.push(obj)
                    })
                    // console.log(partOneArr)
                    this.arrRoomAbility = partOneArr;
                }
                else if(values[`${ROOMABILITYPART}SelectInput`] !== undefined){
                    this.arrRoomAbility = partOneArr;
                }
            }
        })
    },
    getSubList(value){
        this.subList = value;
                    // console.log("=getSubList==",this.subList)
    },
    getRoomExtraPersonPrice(){
        const { roomExtraPersonPriceForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${ROOMEXTRAPERSONPRICEPART}startDate`] !== undefined){
                    (values[`${ROOMEXTRAPERSONPRICEPART}startDate`]).forEach((item, index) => {
                    // // console.log(index)
                        this.$refs._info.getExtraDetailInfo(index);
                        const obj = {
                            startdate: moment.utc(item).tz("America/Los_Angeles"),
                            enddate: moment.utc(values[`${ROOMEXTRAPERSONPRICEPART}endDate`][index]).tz("America/Los_Angeles"),
                            extradetailinfo: this.subList,
                        }
                        partOneArr.push(obj)
                    })
                    this.arrRoomExtraPersonPrice= partOneArr;
                }
                else if(values[`${ROOMEXTRAPERSONPRICEPART}SelectInput`] !== undefined){
                    this.arrRoomExtraPersonPrice= partOneArr;
                }
            }
        })
    },
    getFacilitiesInfo(){
        this.facilitiesForm.validateFields((err, values) => {
          if (!err) {
            
            let partOneArr = [];
            if (values[`facility`] !== undefined){
                (values[`facility`]).forEach((item, index) => {
                    partOneArr.push(item)
                })
            }
            else if(values[`facilitySelectInput`] == undefined){
                partOneArr = this.facilitiesInfo;
            }
            this.facilitiesInfo= partOneArr;
          }
        });
    },

    
    
    showGallary(_isShow){
        this.visibleGallary = _isShow;
    },
    selectImages(_images){
        for (let i = 0; i < _images.length; i++){
            if (!this.gallaryImages.includes(_images[i]))
                this.gallaryImages.push(_images[i]);
        }
        // console.log("selectImages===", this.gallaryImages)
    },
    cancelImage(_id){
      var _index = this.gallaryImages.indexOf(_id);
      this.gallaryImages.splice(_index, 1);
    },

    showRoomImage(_isShow){
        this.visibleRoomImage = _isShow;
    },
    selectRoomImage(_images){
        this.roomImage = _images;
        // console.log(this.roomImage)
    },
    cancelRoomImage(_id){
      var _index = this.roomImage.indexOf(_id);
      this.roomImage.splice(_index, 1);
    },
    

    showRoomFloorPlan(_isShow){
        this.visibleRoomFloorPlan = _isShow;
    },
    selectRoomFloorPlan(_images){
        this.roomFloorPlan = _images;
        // console.log(this.roomFloorPlan)
        this.visibleRoomFloorPlan = false;
    },
    cancelRoomFloorPlan(_id){
      var _index = this.roomFloorPlan.indexOf(_id);
      this.roomFloorPlan.splice(_index, 1);
    },

    
    onScroll: function(e, position) {
      // console.log(position);
    },

  }


}
</script>


<style scoped>
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>
<style>

.ant-fullcalendar-fullscreen .ant-fullcalendar-content{
    text-align: center;
    margin-top: 20px;
}
</style>