<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:35%;border:1px solid #aaaaaa">Block Start</div>
                <div style="width:35%;border:1px solid #aaaaaa">Block End</div>
                <div style="width:30%;border:1px solid #aaaaaa">Allotment</div>
                <div style="width:40px">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <div style="margin-top:5px" v-for="item in keysListRoomShowAbility" :key="item">
                <a-row :gutter="0" style="width:100%;">
                    <div style="display:flex;">
                        <a-form-item style="width:35%;margin-top:auto;margin-bottom:auto;" >
                            <a-date-picker style="width:100%;padding:2px" placeholder=""
                              :showToday="false"
                              valueFormat="YYYY-MM-DD"
                              v-decorator="[
                                    `${title}blockStart[${item}]`,
                                    {
                                        initialValue: arr[item] ? arr[item].blockstart.toString().split('T')[0] : undefined,
                                        rules: [{ required: true, message: 'Input blockStart!' }]
                                    }
                                ]"
                                >
                                <!-- <template slot="dateRender" slot-scope="current">
                                    <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                    v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                        {{ moment(current).utc().date() }}
                                    </div>
                                </template> -->
                            </a-date-picker>
                        </a-form-item>
                        <a-form-item style="width:35%;margin-top:auto;margin-bottom:auto;" >
                            <a-date-picker style="width:100%;padding:2px" placeholder=""
                              :showToday="false"
                              valueFormat="YYYY-MM-DD"
                              v-decorator="[
                                    `${title}blockEnd[${item}]`,
                                    {
                                        initialValue: arr[item] ? arr[item].blockend.toString().split('T')[0] : undefined,
                                        rules: [{ required: false, message: 'blockEnd Number!' }]
                                    }
                                ]"
                                >
                                <!-- <template slot="dateRender" slot-scope="current">
                                    <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                    v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                        {{ moment(current).utc().date() }}
                                    </div>
                                </template> -->
                            </a-date-picker>
                        </a-form-item>
                        
                        <a-form-item style="width:30%;margin-top:auto;margin-bottom:auto;">
                            <a-input-number style="width:100%" :min="0" :max="100000" placeholder="" 
                                v-decorator="[
                                    `${title}allotment[${item}]`,
                                    {
                                        initialValue: arr[item] && arr[item].allotment ? arr[item].allotment : 0,
                                        rules: [{ required: false, message: 'Input Allotment!' }]
                                    }
                                ]"
                            />
                        </a-form-item>
                        <div style="margin-top:auto;margin-bottom:auto;width:40px">
                            <a-button type="dashed" icon="minus" @click="removeRowRoomShowAbility(item)" class="minusRowBtn" :size="'small'"></a-button>
                        </div>
                    </div>
                </a-row>
            </div>
            
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowRoomShowAbility" type="primary">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      idRoomShowAbility: 0,
      keysListRoomShowAbility: [],
      
      dateFormat : (val) => {
        return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
      }
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    this.init()
  },
  methods: {
    moment,
    init () {
      const arr = []
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idRoomShowAbility = 0;
        for (let i = 0; i < (this.arr).length; i++) {
          arr.push(i)
          this.idRoomShowAbility = this.idRoomShowAbility + 1
        }
      }
      this.keysListRoomShowAbility = arr
    },
    removeRowRoomShowAbility (k) {
      if (this.keysListRoomShowAbility.length === 0) { 
        return
      }
      this.keysListRoomShowAbility = this.keysListRoomShowAbility.filter(item => item !== k)
    },
    addRowRoomShowAbility () {
      this.keysListRoomShowAbility = this.keysListRoomShowAbility.concat(this.idRoomShowAbility)
      this.idRoomShowAbility = this.idRoomShowAbility + 1
    },
  }
}
</script>