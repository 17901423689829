var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-height":"85vh"}},[_c('div',{staticStyle:{"margin":"16px 0","display":"flex"}},[_c('a-breadcrumb',{staticStyle:{"margin-top":"auto","margin-bottom":"auto"}},[_c('a-breadcrumb-item',{staticStyle:{"font-size":"30px"}},[_vm._v("About Customize")])],1)],1),_c('div',{staticStyle:{"margin-left":"10px","margin-top":"10px","display":"flex"}},[_c('div',{staticStyle:{"text-align":"left","margin-top":"5px","margin-left":"5px","width":"150px"}},[_vm._v("About Sub Title")]),_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('a-input',{attrs:{"placeholder":"Enter Sub Title"},model:{value:(_vm.aboutSubTitle),callback:function ($$v) {_vm.aboutSubTitle=$$v},expression:"aboutSubTitle"}})],1)]),_c('div',{staticStyle:{"margin-left":"10px","margin-top":"10px","display":"flex"}},[_c('div',{staticStyle:{"text-align":"left","margin-top":"5px","margin-left":"5px","width":"150px"}},[_vm._v("About Main Title")]),_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('a-input',{attrs:{"placeholder":"Enter Main Title"},model:{value:(_vm.aboutMainTitle),callback:function ($$v) {_vm.aboutMainTitle=$$v},expression:"aboutMainTitle"}})],1)]),_c('a-divider'),_c('a-form',{staticStyle:{"display":"flex","width":"100%"},attrs:{"form":_vm.dashboardAboutPageForm}},[_c('div',{staticStyle:{"width":"100%"}},[_c('a-table',{attrs:{"columns":_vm.columnsAboutPage,"data-source":_vm.aboutPageInfoList,"bordered":"","size":"middle","pagination":false,"scroll":{ x: 0, y: 0 }},scopedSlots:_vm._u([{key:"mainname",fn:function(text, record, index){return _c('span',{},[_c('a-form-item',{staticStyle:{"margin-bottom":"0px"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    (_vm.TABLEABOUTPAGE + "mainname[" + index + "]"),
                                    {
                                        initialValue: text ? text : undefined,
                                        rules: [{ required: false, message: 'Input mainname !' }]
                                    }
                                ]),expression:"[\n                                    `${TABLEABOUTPAGE}mainname[${index}]`,\n                                    {\n                                        initialValue: text ? text : undefined,\n                                        rules: [{ required: false, message: 'Input mainname !' }]\n                                    }\n                                ]"}],attrs:{"placeholder":"Enter mainname","auto-size":{ minRows: 5, maxRows: 6 }}})],1)],1)}},{key:"subname",fn:function(text, record, index){return _c('span',{},[_c('a-form-item',{staticStyle:{"margin-bottom":"0px"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    (_vm.TABLEABOUTPAGE + "subname[" + index + "]"),
                                    {
                                        initialValue: text ? text : undefined,
                                        rules: [{ required: false, message: 'Input subname !' }]
                                    }
                                ]),expression:"[\n                                    `${TABLEABOUTPAGE}subname[${index}]`,\n                                    {\n                                        initialValue: text ? text : undefined,\n                                        rules: [{ required: false, message: 'Input subname !' }]\n                                    }\n                                ]"}],attrs:{"placeholder":"Enter subname","auto-size":{ minRows: 5, maxRows: 6 }}})],1)],1)}},{key:"description",fn:function(text, record, index){return _c('span',{},[_c('a-form-item',{staticStyle:{"margin-bottom":"0px"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    (_vm.TABLEABOUTPAGE + "description[" + index + "]"),
                                    {
                                        initialValue: text ? text : undefined,
                                        rules: [{ required: false, message: 'Input description !' }]
                                    }
                                ]),expression:"[\n                                    `${TABLEABOUTPAGE}description[${index}]`,\n                                    {\n                                        initialValue: text ? text : undefined,\n                                        rules: [{ required: false, message: 'Input description !' }]\n                                    }\n                                ]"}],attrs:{"placeholder":"Enter description","auto-size":{ minRows: 5, maxRows: 20 }}})],1)],1)}},{key:"operation",fn:function(text, record){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.DeleteAboutPageOne(record.key)}}},[_vm._v("Delete")])])}}])}),_c('div',{staticStyle:{"display":"flex","margin-top":"10px"}},[_c('a-button',{staticClass:"editable-add-btn",on:{"click":_vm.addAboutPageData}},[_vm._v(" Add Item ")])],1)],1)]),_c('a-divider'),_c('div',{staticStyle:{"margin-left":"10px","margin-top":"10px","display":"flex"}},[_c('div',{staticStyle:{"text-align":"left","margin-top":"5px","margin-left":"5px","width":"150px"}},[_vm._v("About Footer Text")]),_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('a-textarea',{attrs:{"placeholder":"Enter Footer Text","auto-size":{ minRows: 6, maxRows: 20 }},model:{value:(_vm.aboutFooterText1),callback:function ($$v) {_vm.aboutFooterText1=$$v},expression:"aboutFooterText1"}})],1)]),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('a-button',{staticClass:"editable-add-btn",staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":_vm.saveAboutPageData}},[_vm._v(" Save About Page ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }