<template>
    <div class="adduser" style="min-height:85vh">
        <div>
            <div style="margin: 16px 0;display:flex">
                <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                    <a-breadcrumb-item style="font-size:30px;">Add Hotel</a-breadcrumb-item>
                    <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
                </a-breadcrumb>
            </div>
            <a-input placeholder="Enter Hotel Name" v-model="hotelname" :size="'large'" />
            <!-- <a-textarea style="margin-top:10px"
                placeholder="Controlled autosize"
                :auto-size="{ minRows: 10, maxRows: 15 }"
            /> -->
            <vue2-tinymce-editor v-if="false" style="margin-top:10px" v-model="hoteltext" @editorInit="editorInit" ref="te" :options="options"></vue2-tinymce-editor>
            

            <div style="font-size:18px;text-align:left;margin-top:20px;">AWARDS & RECOGNITION</div>
            <div>
                <vue2-tinymce-editor :key="updateTinymce2" id="tinymce2" style="margin-top:10px" v-model="hotelAwardText" :options="options"></vue2-tinymce-editor>
            </div>

            <div style="font-size:18px;text-align:left;margin-top:20px;">OVERVIEW</div>
            <div>
                <vue2-tinymce-editor :key="updateTinymce3" id="tinymce3" style="margin-top:10px" v-model="hotelOverview" :options="options"></vue2-tinymce-editor>
            </div>

            <div style="font-size:18px;text-align:left;margin-top:20px;">RESORT AMENITIES</div>
            <div>
                <vue2-tinymce-editor :key="updateTinymce4" id="tinymce4" style="margin-top:10px" v-model="hotelAmenities" :options="options"></vue2-tinymce-editor>
            </div>

            <div style="font-size:18px;text-align:left;margin-top:20px;">VUE TIP</div>
            <div>
                <vue2-tinymce-editor :key="updateTinymce5" id="tinymce5" style="margin-top:10px" v-model="hotelVueTip" :options="options1"></vue2-tinymce-editor>
            </div>

            <div style="font-size:18px;text-align:left;margin-top:20px;">CHILD POLICY</div>
            <div>
                <vue2-tinymce-editor :key="updateTinymce6" id="tinymce6" style="margin-top:10px" v-model="hotelChildPolicy" :options="options"></vue2-tinymce-editor>
            </div>


            <a-collapse style="margin-top:10px" accordion>
                <a-collapse-panel key="1" header="Hotel Promotion">
                    <div>
                        <a-form style="display:flex" :form="hotelPromotionForm">
                            <hotel-promotion-list ref="_sublist" @travellist="getTravelList" @bookingllist="getBookingList" @blacklist="getBlackList" @getKeyList="getKeyList" @discountlist="getDiscountList"
                                :title="`${HOTELPROMOTIONPART}`"
                                :arr="arrHotelPromotion"
                                :roomList="roomList"
                            />
                        </a-form>
                    </div>
                </a-collapse-panel>
                <a-collapse-panel key="2" header="Gallery">
                    <a-button @click="showGallary(true)">Add room gallary images</a-button>
                    <a-modal
						v-model="visibleGallary"
						title="Add Gallay"
						:footer="null"
                        width="80%"
					>
                        <AwsFileManager v-if="visibleGallary" :ismulti="true" @close="showGallary(false)" @selectImages="selectImages"/>
						<!-- <AddGallary :ismulti="true" @close="showGallary(false)" @selectImages="selectImages"/> -->
					</a-modal>
                    <a-list style="margin-top:10px" :grid="{ gutter: 24, xs: 3, sm: 4, md: 6, lg: 8, xl: 8, xxl: 12 }" :data-source="gallaryImages">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <div style="border: 1px solid #E7E7E7;position:relative">
                                <div style="width:100%;height:8vh">
                                    <img style="object-fit: cover;width:100%;height:100%" :src="`${item}`" alt="">
                                </div>
                                <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelImage(item)" :size="'small'"></a-button>
                            </div>
                        </a-list-item>
                    </a-list>

                </a-collapse-panel>
                <a-collapse-panel key="3" header="ADD-ON'S">
                    
                    <a-tabs type="card" @change="callback">
                        <a-tab-pane key="1" tab="Transfer">
                            <div style="width:200px;margin-left:5px;margin-bottom:10px;text-align:left;font-weight:600;font-size: 15px;">Transfer Option</div>
                            <div style="width:100%;padding:5px">
                                <a-form style="display:flex" :form="hotelTransferForm">
                                    <hotel-transfer-list ref="_transferlist" @getimage="getTransferImageResult" @getKeyTransferList="getKeyTransferList"
                                        :title="`${HOTELTRANSFERPART}`"
                                        :arr="arrHotelTransfer"
                                    />
                                </a-form>
                                
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="Meal Plan">
                            <div style="width:200px;margin-left:5px;margin-bottom:10px;text-align:left;font-weight:600;font-size: 15px;">Meal Option</div>
                            <div style="width:100%;padding:5px">
                                <a-form style="display:flex" :form="hotelMealplanForm">
                                    <hotel-mealplan-list ref="_meallist" @mealplanlist="getMealPriceList" @getimage="getMealPriceImage" @getKeyMealList="getKeyMealList"
                                        :title="`${HOTELMEALPLANPART}`"
                                        :arr="arrHotelMealplan"
                                    />
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="Festive">
                            <div style="width:200px;margin-left:5px;margin-bottom:10px;text-align:left;font-weight:600;font-size: 15px;">Festive Option</div>
                            <div style="width:100%;padding:5px">
                                <a-form style="display:flex" :form="hotelFestiveForm">
                                    <hotel-festive-list ref="_festivelist" @festivelist="getFestiveList" @getimage="getFestiveImage" @getKeyFestiveList="getKeyFestiveList"
                                        :title="`${HOTELFESTIVEPART}`"
                                        :arr="arrHotelFestive"
                                    />
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="4" tab="Honeymoon Amenities">
                            
                            <div style="width:200px;margin-left:5px;margin-bottom:10px;text-align:left;font-weight:600;font-size: 15px;">Honey Option</div>
                            <div style="width:100%;padding:5px">
                                <a-form style="display:flex" :form="hotelHoneyForm">
                                    <hotel-honey-list ref="_honeylist" @getimage="getHoneyImage" @getKeyHoneyList="getKeyHoneyList"
                                        :title="`${HOTELHONEYPART}`"
                                        :arr="arrHotelHoney"
                                    />
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="5" tab="Extra Person">
                            
                            <div style="width:200px;margin-left:5px;margin-bottom:10px;text-align:left;font-weight:600;font-size: 15px;">Extra Option</div>
                            <div style="width:100%;padding:5px">
                                
                                <a-form style="display:flex" :form="hotelExtraForm">
                                    <hotel-extra-list ref="_extralist" @extralist="getExtraList" @getKeyExtraList="getKeyExtraList"
                                        :title="`${HOTELEXTRAPART}`"
                                        :arr="arrHotelExtra"
                                    />
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="6" tab="Quote Infomation">
                            <div style="display:flex;">
                                <div style="width:200px;margin-left:20px;text-align:left;">Quote Images</div>
                                <div style="border:1px solid #aaa;width:100%;display:flex">
                                    <div style="width:50%">
                                        <div style="text-align:left;margin-top:5px;margin-left:5px">Image1</div>
                                        <a-divider/>
                                        <div style="text-align:left;margin-bottom:5px;margin-left:5px">
                                            <div v-if="!isSelected[0]" style="display:flex">
                                                <div>No image selected</div>
                                                <a-button @click="showImage(true, 0)" :size="'small'">Add Image</a-button>
                                            </div>
                                            <div v-if="isSelected[0]" style="display:flex">
                                                <div style="position:relative;margin:auto">
                                                    <div style="width:200px;height:150px">
                                                        <img class="quotoImage" :src="`${selectedImage[0]}`" alt="" width="100%" height="100px">
                                                    </div>
                                                    <a-icon @click="cancelQuoteImage(0)" style="position:absolute;top:5px;right:5px" type="close-circle" theme="filled" />
                                                    <a-icon @click="showImage(true, 0)" style="position:absolute;top:5px;right:25px" type="edit" theme="filled" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="height:100%;width:1px;background-color:#aaa"></div>
                                    <div style="width:50%">
                                        <div style="text-align:left;margin-top:5px;margin-left:5px">Image2</div>
                                        <a-divider/>
                                        <div style="text-align:left;margin-bottom:5px;margin-left:5px">
                                            <div v-if="!isSelected[1]" style="display:flex">
                                                <div>No image selected</div>
                                                <a-button @click="showImage(true, 1)" :size="'small'">Add Image</a-button>
                                            </div>
                                            <div v-if="isSelected[1]" style="display:flex">
                                                <div style="position:relative;margin:auto">
                                                    <div style="width:200px;height:150px">
                                                        <img class="quotoImage" :src="`${selectedImage[1]}`" alt="" width="100%" height="100px">
                                                    </div>
                                                    <a-icon @click="cancelQuoteImage(1)" style="position:absolute;top:5px;right:5px" type="close-circle" theme="filled" />
                                                    <a-icon @click="showImage(true, 1)" style="position:absolute;top:5px;right:25px" type="edit" theme="filled" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <a-modal
                                        v-model="visibleImage"
                                        title="Add Image"
                                        :footer="null"
                                        width="80%"
                                    >
                                        <AwsFileManager v-if="visibleImage" :ismulti="false" :rowIndex="selitem" @close="showImage(false)" @selectImages="selectImage"/>
                                    </a-modal>

                                </div>
                            </div>
                            <a-divider/>
                            <div style="display:flex;">
                                <div style="width:200px;margin-left:20px;text-align:left;">SERVICES & AMENITIES</div>
                                <a-textarea v-model="services" placeholder="" :auto-size="{ minRows: 6, maxRows: 8 }" />
                            </div>
                            <!-- <a-divider/>
                            <div style="display:flex;">
                                <div style="width:200px;margin-left:20px;text-align:left;">TRANSFER</div>
                                <a-textarea v-model="transferstr" placeholder="" :auto-size="{ minRows: 6, maxRows: 8 }" />
                            </div> -->
                            <a-divider/>
                            <div style="display:flex;">
                                <div style="width:200px;margin-left:20px;text-align:left;">IMPORTANT NOTE</div>
                                <a-textarea v-model="importantnote" placeholder="" :auto-size="{ minRows: 6, maxRows: 8 }" />
                            </div>
                            <a-divider/>
                            <div style="display:flex;">
                                <div style="width:200px;margin-left:20px;text-align:left;">Hotel Phone Number</div>
                                <a-input v-model="hotelphonenumber" style="width:100%;padding:2px" placeholder="" />
                            </div>
                            <a-divider />
                            <div style="display:flex;">
                                <div style="width:200px;margin-left:20px;text-align:left;">Hotel Email Address</div>
                                <a-input v-model="hotelemailaddress" style="width:100%;padding:2px" placeholder="" />
                            </div>
                            <a-divider/>
                            
                            
                        </a-tab-pane>
                    </a-tabs>
                </a-collapse-panel>
                <!-- <a-collapse-panel key="4" header="Hotel Page Customization">
                    <p>{{ text }}</p>
                </a-collapse-panel> -->
                <!-- <a-collapse-panel key="5" header="Hotel Highlight">
                    
                    <div style="width:100%;padding:5px">
                        
                        <a-form style="display:flex" :form="hotelHighlightForm">
                            <hotel-highlight-list ref="_highlightlist" @highlightlist="getHighItemsList" @highlightlistphoto="getHighGroupphotosList"
                                :title="`${HOTELHIGHLIGHTPART}`"
                                :arr="arrHotelHighlight"
                            />
                        </a-form>
                    </div>
                </a-collapse-panel> -->
                <a-collapse-panel key="6" header="Hotel Data">
                    
                    <a-tabs type="card" tabPosition="left">
                        <a-tab-pane key="1" tab="General">
                            <div>
                                <div style="display:flex;margin-top:20px;">                              
                                    <a-checkbox v-model="hotelHidden" style="width:200px;margin-left:20px;text-align:left;">
                                        Hotel Hidden
                                    </a-checkbox>
                                </div>
                                <a-divider/>
                                <div style="display:flex;" v-if="false">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Regular price</div>
                                    <a-input-number style="width:100%;padding:2px" v-model="reqularprice" placeholder="" />
                                </div>
                                <a-divider v-if="false"/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Published rate</div>
                                    <a-input-number style="width:100%;padding:2px" v-model="publishedrate" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Green tax</div>
                                    <a-input-number style="width:100%;padding:2px" v-model="greentax" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Service charges</div>
                                    <a-input-number style="width:100%;padding:2px" v-model="servicecharges" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">GST</div>
                                    <a-input-number style="width:100%;padding:2px" v-model="gst" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Commission</div>
                                    <a-input-number style="width:100%;padding:2px" v-model="commission" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Calculation Formula *</div>
                                    <!-- <a-input style="width:100%;padding:2px" v-model="calculationfomula" placeholder="" /> -->
                                    
                                    <a-select v-model="calculationfomula" style="width:100%;padding:2px" placeholder="Select Fomula" >
                                        <a-select-option v-for="item in formulaList" :key="item.key" :value="item.key">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Confirmation NO</div>
                                    <a-input  style="width:100%;padding:2px" v-model="confirmationNo" placeholder="" />
                                </div>
                                <a-divider/>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="Rooms">
                            <div>
                                <div>
                                    <div style="text-align:left">Resort style?</div>
                                    <div v-for="item, iStyle in styleList" :key="iStyle" style="display:flex">
                                        <a-checkbox v-model="stypeBoolList[iStyle]">{{item}}</a-checkbox><br>
                                    </div>
                                </div>
                                <a-divider />
                                <div>
                                    <div style="text-align:left">Type of travel?</div>
                                    <div v-for="item, index in typeofList" :key="index" style="display:flex">
                                        <a-checkbox v-model="typeofBoolList[index]">{{item}}</a-checkbox><br>
                                    </div>
                                </div>
                                <a-divider />
                                <div style="text-align:left">Number of room</div>
                                <a-input-number v-model="numberofroom" style="width:100%" :min="1" :max="100000" placeholder="" :size="'small'"/>
                            </div>
                        </a-tab-pane>
                        <!-- <a-tab-pane key="3" tab="Advanced">
                            <div style="text-align:left">Purchase note</div>
                            <a-textarea v-model="purchaseNoteOne" placeholder="" :auto-size="{ minRows: 6, maxRows: 8 }" />
                            <a-divider />
                            <a-input v-model="purchaseNoteTwo" style="width:100%;padding:2px" placeholder="" />
                        </a-tab-pane> -->
                        <!-- <a-tab-pane key="4" tab="Content of Travel type">
                            <div style="text-align:left">Content of Travel type</div>
                            <div style="margin-top:10px;border:1px solid #aaa;padding:10px">
                                <div style="text-align:left">Romance Content</div>
                                <a-textarea v-model="romanceContent" placeholder="Romance Content" :auto-size="{ minRows: 6, maxRows: 8 }" />
                                <a-divider />
                                
                                <div style="text-align:left">Families Content</div>
                                <a-textarea v-model="familiesContent" placeholder="Families" :auto-size="{ minRows: 6, maxRows: 8 }" />
                                <a-divider />

                                <div style="text-align:left">Boat Ride</div>
                                <a-textarea v-model="boatContent" placeholder="Boat Ride" :auto-size="{ minRows: 6, maxRows: 8 }" />
                                <a-divider />
                                
                                <div style="text-align:left">All-inclusive</div>
                                <a-textarea v-model="allContent" placeholder="All-inclusive" :auto-size="{ minRows: 6, maxRows: 8 }" />
                                <a-divider />
                                
                                <div style="text-align:left">Active Content</div>
                                <a-textarea v-model="activeContent" placeholder="Active Content" :auto-size="{ minRows: 6, maxRows: 8 }" />
                                <a-divider />
                                
                                <div style="text-align:left">Spa&Welleness</div>
                                <a-textarea v-model="spaContent" placeholder="Spa&Welleness" :auto-size="{ minRows: 6, maxRows: 8 }" />
                                <a-divider />
                            </div>
                        </a-tab-pane> -->
                        <a-tab-pane key="5" tab="Hotel Image">
                            <div style="display:flex">
                                <a-button @click="showHotelImage(true)" style="margin-top:auto;margin-bottom:auto;margin-left:20px">
                                    Add Image
                                </a-button>
                                <a-modal
                                v-model="visibleHotelImage"
                                    title="Add Hotel Image"
                                    :footer="null"
                                    width="80%"
                                >
                                    <AwsFileManager v-if="visibleHotelImage" :ismulti="false" @close="showHotelImage(false)" @selectImages="selectHotelImage"/>
                                    <!-- <AddGallary :ismulti="false" @close="showRoomImage(false)" @selectImages="selectRoomImage"/> -->
                                </a-modal>
                                <a-list style="margin-top:10px;width:150px;margin-left:auto;margin-right:auto" :grid="{ gutter: 16, column: 1 }" :data-source="hotelImage">
                                    <a-list-item slot="renderItem" slot-scope="item">
                                        <div style="border: 1px solid #E7E7E7;position:relative">
                                            <img :src="`${item}`" alt="" width="150px" height="100px">
                                            <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelHotelImage(item)" :size="'small'"></a-button>
                                        </div>
                                    </a-list-item>
                                </a-list>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <a-button @click="showHotelLogo(true)" style="margin-top:auto;margin-bottom:auto;margin-left:20px">
                                    Add Hotel Logo
                                </a-button>
                                <a-modal
                                v-model="visibleHotelLogo"
                                    title="Add Hotel Logo"
                                    :footer="null"
                                    width="80%"
                                >
                                    <AwsFileManager v-if="visibleHotelLogo" :ismulti="false" @close="showHotelLogo(false)" @selectImages="selectHotelLogo"/>
                                    <!-- <AddGallary :ismulti="false" @close="showRoomImage(false)" @selectImages="selectRoomImage"/> -->
                                </a-modal>
                                <a-list style="margin-top:10px;width:150px;margin-left:auto;margin-right:auto" :grid="{ gutter: 16, column: 1 }" :data-source="hotelLogo">
                                    <a-list-item slot="renderItem" slot-scope="item">
                                        <div style="border: 1px solid #E7E7E7;position:relative">
                                            <img :src="`${item}`" alt="" width="150px" height="100px">
                                            <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelHotelLogo(item)" :size="'small'"></a-button>
                                        </div>
                                    </a-list-item>
                                </a-list>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <a-button @click="showFactsheet(true)" style="margin-top:auto;margin-bottom:auto;margin-left:20px">
                                    Add Fact sheet
                                </a-button>
                                <div style="margin-left:auto;margin-right: auto;">
                                    
                                    <div style="border: 1px solid #E7E7E7;position:relative">
                                        <img style="object-fit: cover;height:100%;" :src="facesheetPdf.id.substring(facesheetPdf.id.length - 4).toLowerCase() == '.pdf' ? '/images/pdfimage.png' : `${facesheetPdf.id}`" alt="" width="150px" height="100px">
                                        
                                        <!-- <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelHotelLogo(item)" :size="'small'"></a-button> -->
                                        <a-button v-if="facesheetPdf != undefined && facesheetPdf.id != ''" style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelFactsheet()" :size="'small'"></a-button>
                                    </div>
                                    <div style="height: 50px;">{{facesheetPdf != undefined ? facesheetPdf.name :''}}</div>
                                </div>
                                
                                <a-modal
                                    v-model="visibleHotelFactsheet"
                                    title="Add Fact sheet pdf"
                                    :footer="null"
                                    width="80%"
                                >
                                    <AwsFileManager v-if="visibleHotelFactsheet" :ismulti="false" :ispdf="true" @close="showFactsheet(false)" @selectwithname="selectFactsheet"/>
                                    <!-- <AddGallary :ismulti="false" @close="showRoomImage(false)" @selectImages="selectRoomImage"/> -->
                                </a-modal>
                            </div>
                            <a-divider/>
                            <div style="display:flex">
                                <a-button @click="showHotelMap(true)" style="margin-top:auto;margin-bottom:auto;margin-left:20px">
                                    Add Hotel Map
                                </a-button>
                                <a-modal
                                    v-model="visibleHotelMap"
                                    title="Add Hotel Map"
                                    :footer="null"
                                    width="80%"
                                >
                                    <AwsFileManager v-if="visibleHotelMap" :ismulti="false" @close="showHotelMap(false)" @selectImages="selectHotelMap"/>
                                    <!-- <AddGallary :ismulti="false" @close="showRoomImage(false)" @selectImages="selectRoomImage"/> -->
                                </a-modal>
                                <a-list style="margin-top:10px;width:150px;margin-left:auto;margin-right:auto" :grid="{ gutter: 16, column: 1 }" :data-source="hotelMap">
                                    <a-list-item slot="renderItem" slot-scope="item">
                                        <div style="border: 1px solid #E7E7E7;position:relative">
                                            <img :src="`${item}`" alt="" width="150px" height="100px">
                                            <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelHotelMap(item)" :size="'small'"></a-button>
                                        </div>
                                    </a-list-item>
                                </a-list>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="6" tab="Property">
                            <div>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">How to get here</div>
                                    <a-input  style="width:100%;padding:2px" v-model="getHere" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Dining and lounges</div>
                                    <a-input  style="width:100%;padding:2px" v-model="diningLounges" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Resort size</div>
                                    <a-input  style="width:100%;padding:2px" v-model="resortSize" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Best for</div>
                                    <a-input  style="width:100%;padding:2px" v-model="bestFor" placeholder="" />
                                </div>
                                <a-divider/>
                                <div style="display:flex;">
                                    <div style="width:200px;margin-left:20px;text-align:left;">Location</div>
                                    <a-input  style="width:100%;padding:2px" v-model="location" placeholder="" />
                                </div>
                                <a-divider/>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="8" tab="Feedback">
                            <div style="margin-top:20px">  
                                <div style="display:flex;margin-top:20px;margin-bottom:20px;">
                                    <div style="width:150px;text-align:left;margin-top:5px;">Star</div>
                                    <a-input-number  style="width:200px;padding:2px" v-model="hotelStar" :min="0" placeholder="" />
                                </div>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </a-collapse-panel>
            </a-collapse>
            <div style="display:flex">
                <a-button style="margin-right:auto;margin-left:0;margin-top:10px" size="large" @click="publishHotel(0)">Save Draft</a-button>
                <a-button style="margin-right:0;margin-left:auto;margin-top:10px" :style="{ background: '#0071a1',border:'none'}" type="primary" size="large" @click="publishHotel(1)">Publish</a-button>
            </div>
        </div>
    </div>
</template>
<script>

import AddGallary from '../components/AddGallary.vue'
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
import { api } from '../helpers/Helpers';
import { common } from '../helpers/Common';
import HotelPromotionList from './HotelDynamicList/HotelPromotionList.vue';
import HotelTransferList from './HotelDynamicList/HotelTransferList.vue';
import HotelMealplanList from './HotelDynamicList/HotelMealplanList.vue';
import AwsFileManager from '../components/AwsFileManager.vue';
import HotelFestiveList from './HotelDynamicList/HotelFestiveList.vue';
import HotelHoneyList from './HotelDynamicList/HotelHoneyList.vue';
import HotelExtraList from './HotelDynamicList/HotelExtraList.vue';
import HotelHighlightList from './HotelDynamicList/HotelHighlightList.vue';

var moment = require('moment-timezone');


const HOTELPROMOTIONPART = 'hotelPromotionPart'
const HOTELTRANSFERPART = 'hotelTransferPart'
const HOTELMEALPLANPART = 'hotelMealplanPart'
const HOTELFESTIVEPART = 'hotelFestivePart'
const HOTELHONEYPART = 'hotelHoneyPart'
const HOTELEXTRAPART = 'hotelExtraPart'
const HOTELHIGHLIGHTPART = 'hotelHighlightPart'


var selecetedList = [];
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    selecetedList = selectedRows;
  },
  onSelect: (record, selected, selectedRows) => {
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
  },
}
export default {

  props: {
    arrPromotion: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      content: "",
      options:{
        menubar:true,
        plugins: 'advlist autolink charmap code codesample directionality emoticons',
        toolbar1:'fontselect | fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor',
        toolbar2:'alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code',
        content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap');",
            
            // Raleway Light=raleway light, raleway,light; 
            // Raleway Medium=raleway medium, raleway,bold; 
            // Raleway Bold=Raleway ExtraBold, raleway; 
        font_formats: `Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; 
            Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; 
            
            Raleway=raleway medium, raleway; 

            Montserrat Light=Montserrat light; Montserrat Medium=Montserrat medium; Montserrat Bold=Montserrat ExtraBold; 
            Calibri Light=Calibri light; Calibri Medium=Calibri medium; Calibri Bold=Calibri ExtraBold; 
            Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; 
            Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats`,
        },
        
      options1:{
        menubar:true,
        plugins: 'advlist autolink charmap code codesample directionality emoticons',
        toolbar1:'fontselect | fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor',
        toolbar2:'alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code',
        content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap');",
            
            // Raleway Light=raleway light, raleway,light; 
            // Raleway Medium=raleway medium, raleway,bold; 
            // Raleway Bold=Raleway ExtraBold, raleway; 
        font_formats: `Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; 
            Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; 
            
            Raleway=raleway medium, raleway; 

            Montserrat Light=Montserrat light; Montserrat Medium=Montserrat medium; Montserrat Bold=Montserrat ExtraBold; 
            Calibri Light=Calibri light; Calibri Medium=Calibri medium; Calibri Bold=Calibri ExtraBold; 
            Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; 
            Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats`,
        },

      text: `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`,


      hotelPromotionForm: this.$form.createForm(this),
      HOTELPROMOTIONPART,
      arrHotelPromotion: [ ],

      hotelTransferForm: this.$form.createForm(this),
      HOTELTRANSFERPART,
      arrHotelTransfer: [ ],

      hotelMealplanForm: this.$form.createForm(this),
      HOTELMEALPLANPART,
      arrHotelMealplan: [ ],

      hotelFestiveForm: this.$form.createForm(this),
      HOTELFESTIVEPART,
      arrHotelFestive: [ ],

      hotelHoneyForm: this.$form.createForm(this),
      HOTELHONEYPART,
      arrHotelHoney: [ ],

      hotelExtraForm: this.$form.createForm(this),
      HOTELEXTRAPART,
      arrHotelExtra: [ ],

      hotelHighlightForm: this.$form.createForm(this),
      HOTELHIGHLIGHTPART,
      arrHotelHighlight: [ ],


      visibleGallary: false,
      gallaryImages:[],

      
      createdHotelId:'',

      hotelname:'',
      hoteltext:'',

      
      hotelAwardText:'',
      hotelOverview:'',
      hotelAmenities:'',
      hotelVueTip:'',
      hotelChildPolicy:'',

      updateTinymce2:false,
      updateTinymce3:false,
      updateTinymce4:false,
      updateTinymce5:false,
      updateTinymce6:false,


      hotelStar:0,
      
      hotelHidden:false,

      travelList:[],
      bookingList:[],
      blackList:[],
      discountList:[],

      mealPriceList:[],
      festivePriceList:[],
      extraPeriodList:[],

      
      formulaList: [
            {
                key:"Formula1",
                name:"F1: rates not included with green tax"
            },
            {
                key:"Formula2",
                name:"F2: rates included with green tax"
            }
        ],

      
      visibleImage: false,
      selitem:0,
      selectedImage:[],
      isSelected:[],

      services:'',
      transferstr:'',
      importantnote:'',

      reqularprice:'',
      publishedrate:'',
      greentax:'',
      servicecharges:'',
      gst:'',
      commission:'',
      calculationfomula:'Formula1',
      confirmationNo:'',

      stypeone:false,
      stypetwo:false,
      stypethree:false,

      typeoftravelRomance:false,
      typeoftravelFamily:false,
      typeoftravelFriends:false,
      typeoftravelSolo:false,
      typeoftravelFoodies:false,
      typeoftravelDiving:false,
      typeoftravelSpa:false,
      typeoftravelAll:false,
      typeoftravelSnorkeling:false,
      typeoftravelQuick:false,
      typeoftravelUnderwater:false,
      typeoftravelAdults:false,

      stypeBoolList:[],
      styleList:[],
      typeofBoolList:[],
      typeofList:[],

      numberofroom:0,
      purchaseNoteOne:'',
      purchaseNoteTwo:'',
      romanceContent:'',
      familiesContent:'',
      boatContent:'',
      allContent:'',
      activeContent:'',
      spaContent:'',

      hotelphonenumber:'',
      hotelemailaddress:'',

      
      visibleHotelImage: false,
      hotelImage:[],
      visibleHotelLogo: false,
      hotelLogo:[],
      visibleHotelMap: false,
      hotelMap:[],
      

      getHere:'',
      diningLounges:'',
      resortSize:'',
      bestFor:'',

      location:'',

      visibleHotelFactsheet: false,

      rowSelection,
      selecetedList,
      roomList: [],

      facesheetPdf:{
        id:'',
        name:''
      },
      

    };
  },
  components: {
    Vue2TinymceEditor,
    AddGallary,
    HotelPromotionList,
    HotelTransferList,
    HotelMealplanList,
    AwsFileManager,
    HotelFestiveList,
    HotelHoneyList,
    HotelExtraList,
    HotelHighlightList
  },
  created () {
    // this.form = this.$form.createForm(this)
    // this.styleList = common.getResortStyleList();
    // this.stypeBoolList = [];
    // for (let i = 0; i < this.styleList.length; i++){
    //     this.stypeBoolList.push(false);
    // }
    // this.typeofList = common.getResortBestForList();
    // this.typeofBoolList = [];
    // for (let i = 0; i < this.typeofList.length; i++){
    //     this.typeofBoolList.push(false);
    // }
    
    this.getResortStyleInfo();
    this.getResortTypeTravels();

    this.init()
    
    this.gallaryImages = [];
    this.createdHotelId = '';

    
    var mynumber = 2;
    this.selectedImage = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.selectedImage[i] = '';
    }
    this.isSelected = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.isSelected[i] = false;
    }
  },
  methods: {
    moment,
    getResortStyleInfo:async function(){
        const res = await api.getResortStyleInfo();
        if (res.length > 0){
            this.styleList = [];
            this.stypeBoolList = [];
            for (let i = 0; i < res[0].datalist.length; i++){
                this.styleList.push(res[0].datalist[i].name);
                this.stypeBoolList.push(false);
            }
        }
    },
    
    getResortTypeTravels:async function(){
        const res = await api.getResortTypeTravelInfo();
        if (res.length > 0){
            this.typeofList = [];
            this.typeofBoolList = [];
            for (let i = 0; i < res[0].datalist.length; i++){
                this.typeofBoolList.push(false);
                this.typeofList.push(res[0].datalist[i].name);
            }
        }
    },

    init(){
        this.updateTinymces();
    },
    
    updateTinymces(){
        
        setTimeout(() => {
            this.updateTinymce2 = !this.updateTinymce2;
            
            setTimeout(() => {
                this.updateTinymce3 = !this.updateTinymce3;
                setTimeout(() => {
                    this.updateTinymce4 = !this.updateTinymce4;
                    setTimeout(() => {
                        this.updateTinymce5 = !this.updateTinymce5;
                            setTimeout(() => {
                                this.updateTinymce6 = !this.updateTinymce6;
                            }, 1);
                    }, 1);
                }, 1);
            }, 1);
        }, 1);
    },
    editorInit(_vvv){
        // _vvv.setContentStyles("@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap');");
        // _vvv.init({
        //     selector: 'textarea',  // change this value according to your HTML
        //     toolbar: 'fontselect',
        //     font_formats: 'Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n'
        // });
    },
    
    showGallary(_isShow){
        this.visibleGallary = _isShow;
        // if (!_isShow)
        //     this.updateUserInfo();
    },
    selectImages(_images){
        for (let i = 0; i < _images.length; i++){
            if (!this.gallaryImages.includes(_images[i]))
                this.gallaryImages.push(_images[i]);
        }
        this.visibleGallary = false;
    },
    cancelImage(_id){
      var _index = this.gallaryImages.indexOf(_id);
      this.gallaryImages.splice(_index, 1);
    },

    showImage(_isShow, _index){
        this.visibleImage = _isShow;
        if (_isShow)
            this.selitem = _index;
        else
            this.selitem = 0;
        // if (!_isShow)
        //     this.updateUserInfo();
    },
    selectImage(_images, _index){
        if (_images.length > 0){
            this.selectedImage[_index] = _images[0];
            this.isSelected[_index] = true;
        }
        this.visibleImage = false;
    },
    cancelQuoteImage(_index){
        this.selectedImage[_index] = '';
        this.isSelected.splice(_index, 1, false)
    },

    
    showHotelImage(_isShow){
        this.visibleHotelImage = _isShow;
    },
    selectHotelImage(_images){
        this.hotelImage = _images;
        this.visibleHotelImage = false;
    },
    cancelHotelImage(_id){
      var _index = this.hotelImage.indexOf(_id);
      this.hotelImage.splice(_index, 1);
    },

    
    showHotelLogo(_isShow){
        this.visibleHotelLogo = _isShow;
    },
    selectHotelLogo(_images){
        this.hotelLogo = _images;
        this.visibleHotelLogo = false;
    },
    cancelHotelLogo(_id){
      var _index = this.hotelLogo.indexOf(_id);
      this.hotelLogo.splice(_index, 1);
    },


    showHotelMap(_isShow){
        this.visibleHotelMap = _isShow;
    },
    selectHotelMap(_images){
        this.hotelMap = _images;
        this.visibleHotelMap = false;
    },
    cancelHotelMap(_id){
      var _index = this.hotelMap.indexOf(_id);
      this.hotelMap.splice(_index, 1);
    },
    
    showFactsheet(_isShow){
        this.visibleHotelFactsheet = _isShow;
    },
    selectFactsheet(_pdfids, _pdfnames){
        
        if (_pdfids.length > 0)
        {
            this.facesheetPdf = {
                id:_pdfids[0],
                name:_pdfnames[0]
            };
        }
        this.visibleHotelFactsheet = false;
    },
    cancelFactsheet(_id){
      this.facesheetPdf = {
        id:'',
        name:''
      };
    },

    callback(key) {
    },

    onTravelPeriodStart(){

    },
    onChange(){

    },

    publishHotel:async function(status){
        this.getHotelPromotion()
        this.getHotelTransfer()
        this.getHotelMealplan()
        this.getFestiveplan()
        this.getHotelHoney()
        this.getExtraPerson()
        this.imageone = this.selectedImage[0];
        this.imagetwo = this.selectedImage[1];
        this.getHighlightList()
        
        // return
        var currentDate = new Date();
        var stypeList = []
        
        for (let i = 0; i < this.stypeBoolList.length; i++){
            if (this.stypeBoolList[i])
                stypeList.push(i)//'CONTEMPORARY/MODERN')
        }

        var typeoftravel = [];
        
        for (let i = 0; i < this.typeofBoolList.length; i++){
            if (this.typeofBoolList[i])
                typeoftravel.push(i)//'CONTEMPORARY/MODERN')
        }
            
        var _hotelimage = '';
        if (this.hotelImage.length > 0)
            _hotelimage = this.hotelImage[0];
        let _hotellogo = '';
        if (this.hotelLogo.length > 0)
            _hotellogo = this.hotelLogo[0];
        // return
        let _hotelmap = '';
        if (this.hotelMap.length > 0)
            _hotelmap = this.hotelMap[0];
        
        let _lowerhotelname = '';
        if (this.hotelname.length > 0)
            _lowerhotelname = this.hotelname.toLowerCase().replace(/ /g, '-');

        var hotelInfo = {
            hotelname: this.hotelname,
            hoteltext: this.hoteltext,

            hotelAwardText: this.hotelAwardText,
            hotelOverview: this.hotelOverview,
            hotelAmenities: this.hotelAmenities,
            hotelVueTip: this.hotelVueTip,
            hotelChildPolicy: this.hotelChildPolicy,

            promotion: this.arrHotelPromotion,
            gallaryimages: this.gallaryImages,

            hotelHidden: this.hotelHidden,

            transfer: this.arrHotelTransfer,
            mealplan: this.arrHotelMealplan,
            festive: this.arrHotelFestive,
            honeymoon: this.arrHotelHoney,
            extraperson: this.arrHotelExtra,
            hotelhighlight: this.arrHotelHighlight,

            hoteldatageneral:{
                reqularprice:this.reqularprice,
                publishedrate:this.publishedrate,
                greentax:this.greentax,
                servicecharges:this.servicecharges,
                gst:this.gst,
                commission:this.commission,
                calculationfomula:this.calculationfomula,
                confirmationNo:this.commission,
                starrate:4
            },

            hoteldatarooms:{
                stype: stypeList,
                typeoftravel: typeoftravel,
                numberofroom: this.numberofroom
            },
            advanced:{
                purchasenoteone:this.purchaseNoteOne,
                purchasenotetwo:this.purchaseNoteTwo,
                
            },
            contentoftraveltype:{
                romancecontent:this.romanceContent,
                familiescontent:this.familiesContent,
                boatride:this.boatContent,
                allinclusive:this.allContent,
                activecontent:this.activeContent,
                spawelleness:this.spaContent
            }, 

            quoteinfomation:{
                imageone:this.imageone,
                imagetwo:this.imagetwo,
                services:this.services,
                transfer:this.transferstr,
                importantnote:this.importantnote,
                hotelphonenumber:this.hotelphonenumber,
                hotelemailaddress:this.hotelemailaddress
            },
            hotelimage:_hotelimage,
            hotellogo:_hotellogo,
            hotelfacesheet:this.facesheetPdf,
            hotelmap:_hotelmap,
            
            status:status,
            username:"David",
            userid:"MineUserId",
            comments:3,
            createdate:currentDate,
            type:"Hotel",
            star:this.hotelStar,
            lowerhotelname:_lowerhotelname,

            hotelproperty:{
                getHere:this.getHere,
                diningLounges:this.diningLounges,
                resortSize:this.resortSize,
                bestFor:this.bestFor,
                location:this.location,
            },

            roomlist:[],
            
        }
        if (this.createdHotelId == ''){
            const res = await api.addHotel(hotelInfo);
            if (res != undefined && res != ""){
                this.$message.success('Hotel Creating Success!');
                this.createdHotelId = res.id;
                
                this.reRenderKey = !this.reRenderKey;
                // this.published = true;
            }
            else{
                this.$message.error('Hotel Creating Failed!');
            }
        }
        else{
            const res = await api.updateHotel(this.createdHotelId, hotelInfo);
            if (res != undefined && res != ""){
                this.$message.success('Hotel Creating Success!');
                this.createdHotelId = res.id;
                
                this.reRenderKey = !this.reRenderKey;
                // this.published = true;
            }
            else{
                this.$message.error('Hotel Creating Failed!');
            }
        }
    },


        
    getTravelList(value){
        this.travelList = value
    },
    getBookingList(value){
        this.bookingList = value
    },
    getBlackList(value){
        this.blackList = value
    },
    getDiscountList(value){
        this.discountList = value
    },
    getKeyList(value){
        this.keyList = value
    },
    getKeyTransferList(value){
        this.keyTransferList = value
    },
    getKeyMealList(value){
        this.keyMealList = value
    },
    getKeyHoneyList(value){
        this.keyHoneyList = value
    },
    getKeyFestiveList(value){
        this.keyFestiveList = value
    },
    getKeyExtraList(value){
        this.keyExtraList = value
    },
    getHotelPromotion(){
        if (this.$refs._sublist != undefined)
            this.$refs._sublist.getKeyList();
        const { hotelPromotionForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                var mailList = values[`${HOTELPROMOTIONPART}Title`];
                if (mailList != undefined){
                    for(let index_for = 0; index_for < this.keyList.length; index_for++){
                        var index = this.keyList[index_for];
                        
                        this.$refs._sublist.getTravelPeriod(index);
                        this.$refs._sublist.getBookingPeriod(index);
                        this.$refs._sublist.getBlackPeriod(index);
                        this.$refs._sublist.getDiscountCalculation(index);
                        const obj = {
                            title: values[`${HOTELPROMOTIONPART}Title`][index],
                            description: values[`${HOTELPROMOTIONPART}Description`][index],
                            termsconditions: values[`${HOTELPROMOTIONPART}Termsconditions`][index],
                            bookingcode: values[`${HOTELPROMOTIONPART}Bookingcode`][index],
                            travelperiod: this.travelList,
                            bookingperiod: this.bookingList,
                            blackperiod: this.blackList,
                            maxnights: values[`${HOTELPROMOTIONPART}Maxnights`] !== undefined? values[`${HOTELPROMOTIONPART}Maxnights`][index] : null,
                            minnights: values[`${HOTELPROMOTIONPART}Minnights`] !== undefined? values[`${HOTELPROMOTIONPART}Minnights`][index] : null,
                            advance: values[`${HOTELPROMOTIONPART}Advance`] !== undefined? values[`${HOTELPROMOTIONPART}Advance`][index] : '',
                            person:{
                                personnumber: values[`${HOTELPROMOTIONPART}Personnumber`] !== undefined? values[`${HOTELPROMOTIONPART}Personnumber`][index] : null,
                                adultnumber: values[`${HOTELPROMOTIONPART}Adultnumber`] !== undefined? values[`${HOTELPROMOTIONPART}Adultnumber`][index] : null,
                                childnumber: values[`${HOTELPROMOTIONPART}Childnumber`] !== undefined? values[`${HOTELPROMOTIONPART}Childnumber`][index] : null,
                            },
                            room: values[`${HOTELPROMOTIONPART}Room`] !== undefined? values[`${HOTELPROMOTIONPART}Room`][index] : '',
                            discount: values[`${HOTELPROMOTIONPART}Discount`] !== undefined? values[`${HOTELPROMOTIONPART}Discount`][index] : null,
                            discountcalculation: this.discountList,
                            stayoffer:{
                                stayday: values[`${HOTELPROMOTIONPART}Stayday`] !== undefined? values[`${HOTELPROMOTIONPART}Stayday`][index] : null,
                                offerday: values[`${HOTELPROMOTIONPART}Offerday`] !== undefined? values[`${HOTELPROMOTIONPART}Offerday`][index] : null
                            },
                        }
                        partOneArr.push(obj)
                    }
                }
                this.arrHotelPromotion = this.reOrderList(partOneArr, this.keyList);
            }
        });
    },
    reOrderList(srcList, keyList){
        var resultList = [];
        for(let i = 0; i < keyList.length; i++){
            if (srcList.length > keyList[i])
                resultList.push(srcList[keyList[i]]);
        }
        return resultList;

    },
    getTransferImageResult(value){
        this.transferImage = value
    },
    getHotelTransfer(){
        if(this.$refs._transferlist === undefined)
            return;
        this.$refs._transferlist.getKeyTransferList();
        const { hotelTransferForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${HOTELTRANSFERPART}Transfertitle`] !== undefined){
                    for(let index_for = 0; index_for < this.keyTransferList.length; index_for++){
                        var index = this.keyTransferList[index_for];
                        let transfer = [];
                        let transferDate = [];

                        ( values[`${HOTELTRANSFERPART}Minnight${index}`]).forEach((element,j)=>{
                            const transferObj = {
                                min:values[`${HOTELTRANSFERPART}Minnight${index}`][j],
                                max:values[`${HOTELTRANSFERPART}Maxnight${index}`][j],
                                peradultprice:values[`${HOTELTRANSFERPART}peradultprice${index}`][j],
                                perchildprice:values[`${HOTELTRANSFERPART}perchildprice${index}`][j],
                                perinfantprice:values[`${HOTELTRANSFERPART}perinfantprice${index}`][j],
                            }
                            transfer.push(transferObj); 
                        });

                        ( values[`${HOTELTRANSFERPART}Transferstart${index}`]).forEach((element,i)=>{
                            const transferObj = {
                                transferstart: moment.utc(values[`${HOTELTRANSFERPART}Transferstart${index}`][i]).tz("America/Los_Angeles"),
                                transferend: moment.utc(values[`${HOTELTRANSFERPART}Transferend${index}`][i]).tz("America/Los_Angeles"),
                            }
                            transferDate.push(transferObj); 
                        });
                        this.$refs._transferlist.getImage(index);
                        const obj = {
                            transfertitle: values[`${HOTELTRANSFERPART}Transfertitle`][index],
                            condition:{
                                capacity: values[`${HOTELTRANSFERPART}Capacity`][index],
                                adultagerangefrom: values[`${HOTELTRANSFERPART}Adultagerangefrom`][index],
                                adultagerangeto: values[`${HOTELTRANSFERPART}Adultagerangeto`][index],
                                childagerangefrom: values[`${HOTELTRANSFERPART}Childagerangefrom`][index],
                                childagerangeto: values[`${HOTELTRANSFERPART}Childagerangeto`][index],
                                // transferstart: moment.utc(values[`${HOTELTRANSFERPART}Transferstart`][index]).tz("America/Los_Angeles"),
                                // transferend: moment.utc(values[`${HOTELTRANSFERPART}Transferend`][index]).tz("America/Los_Angeles"),
                                transferDate: transferDate,
                            },
                            price:{
                                // peradultprice: values[`${HOTELTRANSFERPART}Peradultprice`][index],
                                // perchildprice: values[`${HOTELTRANSFERPART}Perchildprice`][index],
                                // perinfantprice: values[`${HOTELTRANSFERPART}Perinfantprice`][index],
                                transfer: transfer
                                // totalprice: values[`${HOTELTRANSFERPART}Totalprice`][index],
                            },
                            description: values[`${HOTELTRANSFERPART}Description`][index],
                            image: this.transferImage
                        }
                        partOneArr.push(obj)
                    }
                }
                this.arrHotelTransfer = [];
                this.arrHotelTransfer = this.reOrderList(partOneArr, this.keyTransferList);
            }
        });
    },
    getMealPriceList(value){
        this.mealPriceList = value
    },
    getMealPriceImage(value){
        this.mealPriceImage = value
    },
    getHotelMealplan(){
        if(this.$refs._meallist === undefined)
            return;
        this.$refs._meallist.getKeyMealList();
        const { hotelMealplanForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${HOTELMEALPLANPART}Mealtitle`] !== undefined){
                    for(let index_for = 0; index_for < this.keyMealList.length; index_for++){
                        var index = this.keyMealList[index_for];
                        let mealDate = [];

                        ( values[`${HOTELMEALPLANPART}Mealstart${index}`]).forEach((element,i)=>{
                            const mealObj = {
                                mealstart: moment.utc(values[`${HOTELMEALPLANPART}Mealstart${index}`][i]).tz("America/Los_Angeles"),
                                mealend: moment.utc(values[`${HOTELMEALPLANPART}Mealend${index}`][i]).tz("America/Los_Angeles"),
                            }
                            mealDate.push(mealObj); 
                        });
                        
                        this.$refs._meallist.getMealPrice(index);
                        this.$refs._meallist.getImage(index);
                        const obj = {
                            mealtitle: values[`${HOTELMEALPLANPART}Mealtitle`][index],
                            mealdate: mealDate,
                            price: this.mealPriceList,
                            description: values[`${HOTELMEALPLANPART}Description`][index],
                            image: this.mealPriceImage
                        }
                        partOneArr.push(obj)
                    }
                }
                this.arrHotelMealplan = [];
                this.arrHotelMealplan =  this.reOrderList(partOneArr, this.keyMealList);
            }
        });
    },
    getFestiveList(value){
        this.festivePriceList = value
    },
    getFestiveImage(value){
        this.festiveImage = value
    },
    getFestiveplan(){
        if(this.$refs._festivelist === undefined)
            return;
        this.$refs._festivelist.getKeyFestiveList();
        const { hotelFestiveForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${HOTELFESTIVEPART}Festivetitle`] !== undefined){
                    for(let index_for = 0; index_for < this.keyFestiveList.length; index_for++){
                        var index = this.keyFestiveList[index_for];
                        
                        this.$refs._festivelist.getFestivePrice(index);
                        this.$refs._festivelist.getImage(index);
                        const obj = {
                            festivetitle: values[`${HOTELFESTIVEPART}Festivetitle`][index],
                            condition: moment.utc(values[`${HOTELFESTIVEPART}Condition`][index]).tz("America/Los_Angeles"),
                            price: this.festivePriceList,
                            description: values[`${HOTELFESTIVEPART}Description`][index],
                            image: this.festiveImage
                        }
                        partOneArr.push(obj)
                    }
                }
                this.arrHotelFestive = [];
                this.arrHotelFestive =  this.reOrderList(partOneArr, this.keyFestiveList);
            }
        });
    },
    
    getHoneyImage(value){
        this.honeyImage = value
    },
    getHotelHoney(){
        if(this.$refs._honeylist === undefined)
            return;
        this.$refs._honeylist.getKeyHoneyList();
        const { hotelHoneyForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${HOTELHONEYPART}Honeytitle`] !== undefined){
                    for(let index_for = 0; index_for < this.keyHoneyList.length; index_for++){
                        var index = this.keyHoneyList[index_for];
                        
                        this.$refs._honeylist.getImage(index);
                        const obj = {
                            honeytitle: values[`${HOTELHONEYPART}Honeytitle`][index],
                            stay: values[`${HOTELHONEYPART}Stay`][index],
                            honeydate:{
                                honeystart: moment.utc(values[`${HOTELHONEYPART}Honeystart`][index]).tz("America/Los_Angeles"),
                                honeyend: moment.utc(values[`${HOTELHONEYPART}Honeyend`][index]).tz("America/Los_Angeles"),
                            },
                            description: values[`${HOTELHONEYPART}Description`][index],
                            image: this.honeyImage
                        }
                        partOneArr.push(obj)
                    }
                }
                this.arrHotelHoney = [];
                this.arrHotelHoney =  this.reOrderList(partOneArr, this.keyHoneyList);
            }
        });
    },
    getExtraList(value){
        this.extraPeriodList = value
    },
    getExtraPerson(){
        if(this.$refs._extralist === undefined)
            return;
        this.$refs._extralist.getKeyExtraList();
        const { hotelExtraForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${HOTELEXTRAPART}From`] !== undefined){
                    for(let index_for = 0; index_for < this.keyExtraList.length; index_for++){
                        var index = this.keyExtraList[index_for];
                        this.$refs._extralist.getExtraPeriod(index);
                        const obj = {
                            agerange:{
                                from: values[`${HOTELEXTRAPART}From`][index],
                                to: values[`${HOTELEXTRAPART}To`][index],
                            },
                            periodduration: this.extraPeriodList,
                            rate:{
                                perday: values[`${HOTELEXTRAPART}Perday`][index],
                                // includegreentax: values[`${HOTELEXTRAPART}Includegreentax`][index],
                            },
                        }
                        partOneArr.push(obj)
                    }
                }
                this.arrHotelExtra = [];
                this.arrHotelExtra =  this.reOrderList(partOneArr, this.keyExtraList);
            }
        });
    },
    getHighItemsList(value){
        this.highItemList = value
    },
    getHighGroupphotosList(value){
        this.highGroupphotoList = value
    },
    getHighlightList(){
        const { hotelHighlightForm: { validateFields } } = this
        validateFields((errors, values) => {
            if (!errors) {
                let partOneArr = [];
                if (values[`${HOTELHIGHLIGHTPART}Title`] !== undefined){
                    (values[`${HOTELHIGHLIGHTPART}Title`]).forEach((item, index) => {
                        
                        this.$refs._highlightlist.getItems(index);
                        this.$refs._highlightlist.getGroupphotos(index);
                        const obj = {
                            title:item,
                            items:this.highItemList,
                            groupphotos: this.highGroupphotoList,
                        }
                        partOneArr.push(obj)
                    })
                }
                this.arrHotelHighlight = partOneArr;
            }
        });
    },


  },
};
</script>
<style>
.ant-tabs-nav-scroll {
    margin-left: 0;
    margin-right: auto;
}
.ant-tabs-nav-wrap{
    display: flex;
}
</style>

<style>
.ant-divider-horizontal {
    margin: 10px 0;
}
.quotoImage{
    display: block;
    width:100%;
    height:100%;
    object-fit: cover;
}
</style>