import axios from 'axios';
import Vue from 'vue';
import VueFlashMessage from 'vue-flash-message';
import 'vue-flash-message/dist/vue-flash-message.min.css';

Vue.use(VueFlashMessage, {
  messageOptions: {
    timeout: 3000,
    pauseOnInteract: true
  }
});

// axios.interceptors.request.use(function (config) {
//   // if (store.state.token) {
//     config.headers.common['Authorization'] = "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MjRlOTAyZjMwYmJmMjJlMzE4Mjk1MGQiLCJlbWFpbCI6ImFhYWFhQGFhYWFhLmNvbSIsInBlcm1pc3Npb25MZXZlbCI6MjA0OCwicHJvdmlkZXIiOiJlbWFpbCIsIm5hbWUiOiJUcmV1IFdzcyIsInJlZnJlc2hLZXkiOiJQREdWT2N5bVZRZTNlZkZ5bzI3bSt3PT0iLCJpYXQiOjE2NDkzMjIwNzl9.7UEBgKIUA42T_HPpbYAgAOprixVwGYQxwV-J3csF1zY"
//     //store.state.token
//   // }
//   return config
// }, function (error) {
//   // console.log("[[error]]");
//   // console.log(error);
//   // router.push('/login')
//   return Promise.reject(error)
// })
// axios.interceptors.response.use(function (response) {
//   return response
// }, function (error) {
//   if (error.response) {
//     switch (error.response.status) {
//       case 401:
//         // store.commit('del_token')
//         // router.push('/login')
//     }
//   }
//   return Promise.reject(error)
// })

const vm = new Vue();

var baseURL = 'https://api.vuecollection.com/';;
if (process.env.NODE_ENV == "development")
  baseURL = 'http://localhost:3000/';
// console.log("process.env.NODE_ENV= : ", process.env.NODE_ENV, baseURL)

const handleError = fn => (...params) =>
  fn(...params).catch(error => {
    // console.log("---",error.response.status);
    if (error.response.status == 403){
				this.$store.commit('setLoginUser', null)
        window.location.href = '/admin/loginview';
    }
    vm.flash(`${error.response.status}: ${error.response.statusText}`, 'error');
  });

export const api = {
  innitAxis(_token){
    // console.log(_token);
    axios.interceptors.request.use(function (config) {
      // if (store.state.token) {
        config.headers.common['Authorization'] = "Bearer " + _token
        //store.state.token
      // }
      return config
    }, function (error) {
      // console.log("[[error]]");
      // console.log(error);
      // router.push('/login')
      return Promise.reject(error)
    })
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            // store.commit('del_token')
            // router.push('/login')
        }
      }
      return Promise.reject(error)
    })
  },


  gettask: handleError(async id => {
    const res = await axios.get(baseURL + 'tasks/' + id);
    return res.data;
  }),
  gettasks: handleError(async () => {
    const res = await axios.get(baseURL + 'tasks/');
    return res.data;
  }),
  deletetask: handleError(async id => {
    const res = await axios.delete(baseURL + 'tasks/' + id);
    return res.data;
  }),
  createtask: handleError(async payload => {
    const res = await axios.post(baseURL + 'tasks/', payload);
    return res.data;
  }),
  updatetask: handleError(async payload => {
    const res = await axios.put(baseURL + 'tasks/' + payload._id, payload);
    return res.data;
  }),

  
  signup: handleError(async payload => {
    const res = await axios.post(baseURL + 'users/', payload);
    return res.data;
  }),
  login: handleError(async payload => {
    const res = await axios.post(baseURL + 'auth/', payload);
    // console.log("res=" + res.status);
    return res.data;
  }),
  resetForgot: handleError(async payload => {
    const res = await axios.post(baseURL + 'userreset/', payload);
    return res.data;
  }),

  resetPassword: handleError(async payload => {
    const res = await axios.post(baseURL + 'reset-password/', payload);
    return res.data;
  }),
//////
  getUsersTest: async function(payload){
    // // console.log("---getUsers");
    const res = await axios.get(baseURL + 'users/', payload);
    // console.log("---res");
    // console.log(res);
    return res;
  },

/////admin

  signupAdmin: handleError(async payload => {
    const res = await axios.post(baseURL + 'usersadmin/', payload);
    return res.data;
  }),
  getUsersByAdmin: handleError(async payload => {
    const res = await axios.get(baseURL + 'usersadmin/', payload);
    return res.data;
  }),

  getUserByAdmin: handleError(async id => {
    // console.log("res=" + id);
    const res = await axios.get(baseURL + 'usersadmin/' + id);
    return res.data;
  }),

  deleteUser: handleError(async id => {
    const res = await axios.delete(baseURL + 'usersadmin/' + id);
    return res.data;
  }),


  updateUserStatus: handleError(async (id, status)=> {
    const res = await axios.patch(baseURL + 'usersadmin/changestatus/' + id, {'status':status});
    return res.data;
  }),
  changeUserTo: handleError(async (id, role)=> {
    const res = await axios.patch(baseURL + 'usersadmin/changerole/' + id, {'role':role,'permissionLevel':role});
    return res.data;
  }),

  
  updateUserByAdmin: handleError(async (id, payload) => {
    const res = await axios.put(baseURL + 'usersadmin/' + id, payload);
    // console.log("------------");
    // console.log(res);
    return res;
  }),




  getInstagramInfo: handleError(async token => {
    const res = await axios.get('https://graph.instagram.com/me/media?fields=caption,comments_count,like_count,media_type,permalink,media_url&access_token=' + token);
    // console.log("res=");
    // console.log(res);
    return res.data; 
  }),

  
  // login: handleError(async payload => {
  //   axios.post(baseURL + 'auth/', payload)
  //   .then(function (response) {
      
  //     // console.log("response=" + JSON.stringify(response));
  //   })
  //   .catch(function (error) {
  //     // console.log("error=" + error);
  //   });
  // }),
  
  ////////   Room
  addRoomCategory: handleError(async payload => {
    const res = await axios.post(baseURL + 'roomcategory/', payload);
    return res.data;
  }),
  updateRoomCategory: handleError(async (id,payload) => {
    const res = await axios.put(baseURL + 'roomcategoryupdate/' + id, payload);
    return res.data;
  }),

  getRoomCategories: handleError(async payload => {
    const res = await axios.get(baseURL + 'roomcategory/', payload);
    return res.data;
  }),
  deleteRoomCategory: handleError(async id => {
    const res = await axios.delete(baseURL + 'roomcategory/' + id);
    return res.data;
  }),


  addRoom: handleError(async payload => {
    const res = await axios.post(baseURL + 'rooms/', payload);
    return res.data;
  }),
  updateRoom: handleError(async (id, payload) => {
    const res = await axios.put(baseURL + 'rooms/' + id, payload);
    return res.data;
  }),

  getRooms: handleError(async payload => {
    const res = await axios.get(baseURL + 'rooms/', payload);
    return res.data;
  }),
  getRoomsTotal: handleError(async payload => {
    const res = await axios.get(baseURL + 'roomstotal/', payload);
    return res.data;
  }),
  deleteRoom: handleError(async id => {
    const res = await axios.delete(baseURL + 'rooms/' + id);
    return res.data;
  }),

  
  getRoom: handleError(async id => {
    // console.log("res=" + id);
    const res = await axios.get(baseURL + 'rooms/' + id);
    return res.data;
  }),

  ////////  Hotel
  
  addHotelCategory: handleError(async payload => {
    const res = await axios.post(baseURL + 'hotelcategory/', payload);
    return res.data;
  }),
  updateHotelCategory: handleError(async (id,payload) => {
    const res = await axios.put(baseURL + 'hotelcategoryupdate/' + id, payload);
    return res.data;
  }),

  getHotelCategories: handleError(async payload => {
    const res = await axios.get(baseURL + 'hotelcategory/', payload);
    return res.data;
  }),
  deleteHotelCategory: handleError(async id => {
    const res = await axios.delete(baseURL + 'hotelcategory/' + id);
    return res.data;
  }),

  
  addHotel: handleError(async payload => {
    const res = await axios.post(baseURL + 'hotels/', payload);
    return res.data;
  }),
  updateHotel: handleError(async (id, payload) => {
    // console.log(payload,'==--==--payload')
    const res = await axios.put(baseURL + 'hotels/' + id, payload);
    return res.data;
  }),

  getHotels: handleError(async payload => {
    const res = await axios.get(baseURL + 'hotels/', payload);
    return res.data;
  }),
  getHotelsTotal: handleError(async payload => {
    const res = await axios.get(baseURL + 'hotelstotal/', payload);
    return res.data;
  }),
  deleteHotel: handleError(async id => {
    const res = await axios.delete(baseURL + 'hotels/' + id);
    return res.data;
  }),
  
  getHotel: handleError(async id => {
    const res = await axios.get(baseURL + 'admin-hotels/' + id);
    return res.data;
  }),

  
  searchItwxHotel: handleError(async payload => {
    const res = await axios.post(baseURL + 'searchitwxhotel/', payload);
    return res.data;
  }),

  //// Booking
  
  addBookings: handleError(async payload => {
    // console.log(payload)
    const res = await axios.post(baseURL + 'booking/', payload);
    return res.data;
  }),
  getBookingsByHotelID: handleError(async hotelid => {
    const res = await axios.get(baseURL + 'booking/' + hotelid);
    return res.data;
  }),

  saveHotelDataGeneral: handleError(async (hotelid, genaralInfo) => {
    const res = await axios.put(baseURL + 'bookinggenaral/' + hotelid, genaralInfo);
    return res.data;
  }),

  //// Admin DashBoard

  setLogoInfo: handleError(async payload => {
    const res = await axios.post(baseURL + 'headerinfo/', payload);
    return res.data;
  }),
  getLogoInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'headerinfo/', payload);
    return res.data;
  }),
  
  getHotelRooms: handleError(async id => {
    const res = await axios.get(baseURL + 'hotelrooms/' + id);
    return res.data;
  }),
  getHotelRoomsNonItwx: handleError(async id => {
    const res = await axios.get(baseURL + 'hotelroomsnonitwx/' + id);
    return res.data;
  }),

  /////  Social setting
  setBackgroundImages: handleError(async payload => {
    const res = await axios.post(baseURL + 'homebackgroundimages/', payload);
    return res.data;
  }),
  getBackgroundImages: handleError(async payload => {
    const res = await axios.get(baseURL + 'homebackgroundimages/', payload);
    return res.data;
  }),
  /////  Social setting
  setSocialInfo: handleError(async payload => {
    const res = await axios.post(baseURL + 'socialIcon/', payload);
    return res.data;
  }),
  getSocialInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'socialIcon/', payload);
    return res.data;
  }),
  /////  ResortStyle setting
  setResortStyleInfo: handleError(async payload => {
    const res = await axios.post(baseURL + 'resortStyleSetting/', payload);
    return res.data;
  }),
  getResortStyleInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'resortStyleSetting/', payload);
    return res.data;
  }),
  /////  ResortTypeTravel setting
  setResortTypeTravelInfo: handleError(async payload => {
    const res = await axios.post(baseURL + 'resortTypeTravelSetting/', payload);
    return res.data;
  }),
  getResortTypeTravelInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'resortTypeTravelSetting/', payload);
    return res.data;
  }),
  /////  About setting
  setAboutInfo: handleError(async payload => {
    const res = await axios.post(baseURL + 'aboutinfo/', payload);
    return res.data;
  }),
  getAboutInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'aboutinfo/', payload);
    return res.data;
  }),

  /////  Best Hotel setting
  setBestHotelInfo: handleError(async payload => {
    const res = await axios.post(baseURL + 'bestHotel/', payload);
    return res.data;
  }),
  getBestHotelInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'bestHotel/', payload);
    return res.data;
  }),
  
  ////  About Page info setting
  setAboutPageInfo: handleError(async payload => {
    const res = await axios.post(baseURL + 'aboutpageinfo/', payload);
    return res.data;
  }),
  getAboutPageInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'aboutpageinfo/', payload);
    return res.data;
  }),
  
  ////  Contact Page info setting
  setContactPageInfo: handleError(async payload => {
    const res = await axios.post(baseURL + 'contactpageinfo/', payload);
    return res.data;
  }),
  getContactPageInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'contactpageinfo/', payload);
    return res.data;
  }),

  ////  Inspiration 
  setInspiration: handleError(async payload => {
    const res = await axios.post(baseURL + 'inspirations/', payload);
    return res.data;
  }),
  getInspirations: handleError(async payload => {
    const res = await axios.get(baseURL + 'inspirations/', payload);
    return res.data;
  }),
  getInspirationById: handleError(async id => {
    const res = await axios.get(baseURL + 'inspirations/' + id);
    return res.data;
  }),
  
  updateInspiration: handleError(async (id,payload) => {
    const res = await axios.put(baseURL + 'inspirations/' + id, payload);
    return res.data;
  }),
  deleteInspiration: handleError(async (id) => {
    const res = await axios.delete(baseURL + 'inspirations/' + id);
    return res.data;
  }),
  
  /////  Inspiration Tag
  setInspirationTagInfo: handleError(async payload => {
    const res = await axios.post(baseURL + 'inspirationtags/', payload);
    return res.data;
  }),
  getInspirationTagInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'inspirationtags/', payload);
    return res.data;
  }),


  ///////   AWS File Manager  //////

  createBucket: handleError(async payload => {
    const res = await axios.post(baseURL + 'awsfilebuckets/', payload);
    return res.data;
  }),
  getListBuckets: handleError(async payload => {
    const res = await axios.get(baseURL + 'awsfilebuckets/', payload);
    return res.data;
  }),

  deleteBucket: handleError(async bucketName => {
    // console.log("---", bucketName);
    const res = await axios.delete(baseURL + 'awsfilebuckets/' + bucketName);
    return res.data;
  }),

  getListObjectsInBucket: handleError(async bucketName => {
    const res = await axios.get(baseURL + 'awsobjectsinbucket/' + bucketName);
    return res.data;
  }),

  uploadFileToBucket: handleError(async formData => {
    const res = await axios.post(baseURL + 'awsobjectsinbucket/', formData);
    return res.data;
  }),
  createFolderToBucket: handleError(async payload => {
    // console.log("payload==", payload);
    const res = await axios.post(baseURL + 'awsfolderinbucket/', payload);
    return res.data;
  }),
  deleteObjecToBucket: handleError(async (bucketName, filename) => {
    // console.log("payload==", filename);
    const res = await axios.post(baseURL + 'awsobjectsinbucketdelete/' + bucketName, {filename:filename});
    return res.data;
  }),
  renameObjecToBucket: handleError(async (bucketName, oldname, newname) => {
    // console.log("renameObjecToBucket=oldname=", oldname);
    // console.log("renameObjecToBucket=newname=", newname);
    const res = await axios.post(baseURL + 'awsobjectsinbucketrename/' + bucketName, {oldname:oldname, newname:newname});
    return res.data;
  }),


  /// Booking Request
  
  getBookingRequest: handleError(async () => {
    const res = await axios.get(baseURL + 'bookingRequest/');
    return res.data;
  }),
  getBookingRequestStatus: handleError(async () => {
    const res = await axios.get(baseURL + 'bookingRequeststatus/');
    return res.data;
  }),
  
  bookingRequestUpdate: handleError(async (payload, id) => {
    const res = await axios.put(baseURL + 'bookingRequest/' + id, payload);
    return res;
  }),
  
  removeBookingRequest: handleError(async id => {
    const res = await axios.delete(baseURL + 'bookingRequest/' + id);
    return res.data;
  }),
  
  bookingRequestCancel: handleError(async id => {
    const res = await axios.post(baseURL + 'bookingRequestCancel/' + id);
    return res.data;
  }),
  
  getBookingRequestInfo: handleError(async id => {
    const res = await axios.get(baseURL + 'bookingRequest/' + id);
    return res.data;
  }),
  
  createBookingPdf: handleError(async id => {
    const res = await axios.get(baseURL + 'createBookingPdf/' + id, { responseType: 'blob' });
    var blob = new Blob([res.data], {type: res.headers['content-type']})
    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob);
    link.download = 'Booking';// name the downloaded file
    link.click()

    return res;
  }),

  
  confirmPayment: handleError(async payload => {
    const res = await axios.post(baseURL + 'confirm-payment/', payload);
    return res.data;
  }),
  
  capturePayment: handleError(async payload => {
    const res = await axios.post(baseURL + 'capture-payment/', payload);
    return res.data;
  }),
};