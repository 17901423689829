<template>
    <div style="display:flex;min-height:85vh">
        <div style="width:400px;margin:auto">
            <a-input v-model="signupUser.userName" placeholder="Username" :size="'large'" @change="inputChange"/>
            <a-input style="width:100%;margin-top:10px" placeholder="Email" v-model="signupUser.email" :size="'large'" @change="inputChange"/>
            <a-input style="width:100%;margin-top:10px" placeholder="Password" v-model="signupUser.password" :size="'large'" @change="inputChange"/>
            
            <a-button style="width:100%;margin-top:10px" type="primary" :disabled="disabled" :size="'large'" :style="{ fontSize: '20px'}" @click="handleSignupClick">
                Create account
            </a-button>
            
            <div style="background-color:#F0EFEF;height:1px;widht:100%;margin-top:20px"></div>
            <div style="width:100%;margin-top:10px;text-align:center;cursor:pointer">Already a member? <font color="#CEB39A" v-on:click='clickLogin'>Log in</font></div>
            <div style="width:100%;margin-top:10px;text-align:center;cursor:pointer" @click="openForgotPassword">Forgot password?</div>
        </div>
    </div>
</template>
<script>
import { api } from '../helpers/Helpers';
import { common } from '../helpers/Common';
const countryList= ['US', 'RSS', 'CH'];
export default {
    
    data() {
        return {
            checked: false,
            disabled: false,
            countryList,
            signupUser: {
                type: Object,
                required: false,
                default: () => {
                    return {
                        firstName: '',
                        lastName: '',
                        userName: '',
                        email:'',
                        password:'',
                        permissionLevel:1,
                        country:'',
                        title:'',
                        phoneNumber:'',
                        agencyName:'',
                        menberName:'',
                        businessID:'',
                        stateProvince:'',
                        status: 1,
                    };
                }
            },
            
            isVerify: false,
        }
    },
    components: {
        
        
    },
    methods: {
        handleSignupClick: async function() {
            // console.log("click == ", this.signupUser.email + " == " + this.signupUser.password) 
            this.signupUser.permissionLevel = 2048;
            this.signupUser.status = 1;
            // console.log("click == ", this.signupUser) 
            const res = await api.signup(this.signupUser);
            // this.flash('task created', 'success');
             // console.log("---------accessToken-------" + res);
            if (res != undefined && res != "" && res.error == undefined){
                this.$message.success('Sign up success!');
                this.$emit('close');
            }
            else{
                if (res.error == undefined)
                    this.$message.error('This email is already using by other');
                else
                    this.$message.error(res.error.error);
            }
        },
        inputChange(){
            this.updateButton();
        },
        updateButton(){
            if (this.signupUser.userName == undefined ||
                this.signupUser.email == undefined || 
                this.signupUser.password == undefined)
                this.disabled = true;
            else
                this.disabled = false;
        },
        clickLogin(){
            window.location.href = '/admin/loginview';
        },
        
        openForgotPassword(){
            window.location.href = '/admin/forgotview';
        },

    },
    created(){
        
    },
    mounted(){
        this.updateButton();
    }
}
</script>