<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:25%;height:30px;border:1px solid #dddddd;border-right:none;font-size: 14px;line-height:28px;">Transfer Title</div>
                <div style="width:25%;height:30px;border:1px solid #dddddd;border-right:none;font-size: 14px;line-height:28px;">Condition</div>
                <div style="width:25%;height:30px;border:1px solid #dddddd;font-size: 14px;line-height:28px;">Price</div>
                <div style="width:25%;height:30px;border:1px solid #dddddd;border-left:none;font-size: 14px;line-height:28px;">Description</div>
                <div style="width:50px;height:30px;border:1px solid #dddddd;border-left:none;">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <draggable v-model="keysListTransfer" chosenClass="chosen" forceFallback="false" group="people" animation="1000" filter="aaa" @start="onStart" @end="onEnd" :options="{handle :'div.item'}">
                <transition-group>
                    <div style="margin-top:0px;background-color:#f8f8f8;border:1px solid #dddddd;border-top:none" v-for="item, index in keysListTransfer" :key="item">
                        <a-row :gutter="0" style="width:100%;">
                            <div style="display:flex;">
                                <div v-if="true" class="item" style="width:50px;height: inherit;display:flex;color:#000000a6">
                                    <div style="margin:auto">{{index + 1}}</div>
                                </div>
                                <div style="width:25%;height:auto;margin:0;background-color:white;display:flex;border:1px solid #ddd;border-top:none;border-left:none;border-bottom:none;padding:10px" >
                                    <a-form-item style="width:100%;margin-top:0;margin-bottom:0">
                                        <a-input style="width:100%;margin-top:auto;margin-bottom:auto" placeholder="" 
                                            v-decorator="[
                                                `${title}Transfertitle[${item}]`,
                                                {
                                                    initialValue: arr[item] ? arr[item].transfertitle : undefined,
                                                    rules: [{ required: true, message: 'Input transfertitle !' }]
                                                }
                                            ]"
                                        />
                                    </a-form-item>
                                </div>
                                <div style="width:25%;height:auto;margin:0;background-color:white;border:1px solid #ddd;border-top:none;border-left:none;border-right:none;border-bottom:none;padding:0px;padding-bottom:10px" >
                                    <div style="padding-top:10px;font-weight:600;text-align:left;padding-left:10px">Capacity</div>
                                    <a-form-item style="padding:10px;padding-top:0px;padding-bottom:0px">
                                        <a-input-number style="width:100%" :min="1" :max="100000" placeholder=""    
                                                v-decorator="[
                                                    `${title}Capacity[${item}]`,
                                                    {
                                                        initialValue: arr[item] ? arr[item].condition.capacity : undefined,
                                                        rules: [{ required: false, message: 'capacity !' }]
                                                    }
                                                ]"
                                            />
                                    </a-form-item>
                                    <a-divider :size="'small'"/>
                                    <div style="font-weight:600;text-align:left;padding-left:10px">Adult Age Range</div>
                                    <div style="display:flex;padding:10px;padding-top:0px">
                                        <a-form-item style="width:50%;margin-top:auto;margin-bottom:auto;padding-right:5px">
                                            <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto" :min="1" :max="100000"  
                                                    v-decorator="[
                                                        `${title}Adultagerangefrom[${item}]`,
                                                        {
                                                            initialValue: arr[item] ? arr[item].condition.adultagerangefrom : undefined,
                                                            rules: [{ required: false, message: 'adultagerangefrom !' }]
                                                        }
                                                    ]"
                                                />
                                        </a-form-item>
                                        <a-form-item style="width:50%;margin-top:auto;margin-bottom:auto;padding-left:5px">
                                            <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto" :min="1" :max="100000"  
                                                    v-decorator="[
                                                        `${title}Adultagerangeto[${item}]`,
                                                        {
                                                            initialValue: arr[item] ? arr[item].condition.adultagerangeto : undefined,
                                                            rules: [{ required: false, message: 'adultagerangeto !' }]
                                                        }
                                                    ]"
                                                />
                                        </a-form-item>
                                    </div>
                                    <a-divider :size="'small'"/>
                                    <div style="font-weight:600;text-align:left;padding-left:10px">Child Age Range</div>
                                    <div style="display:flex;padding:10px;padding-top:0px">
                                        <a-form-item style="width:50%;margin-top:auto;margin-bottom:auto;padding-right:5px">
                                            <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto" :min="1" :max="100000"  
                                                    v-decorator="[
                                                        `${title}Childagerangefrom[${item}]`,
                                                        {
                                                            initialValue: arr[item] ? arr[item].condition.childagerangefrom : undefined,
                                                            rules: [{ required: false, message: 'childagerangefrom !' }]
                                                        }
                                                    ]"
                                                />
                                        </a-form-item>
                                        <a-form-item style="width:50%;margin-top:auto;margin-bottom:auto;padding-left:5px">
                                            <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto" :min="1" :max="100000"  
                                                    v-decorator="[
                                                        `${title}Childagerangeto[${item}]`,
                                                        {
                                                            initialValue: arr[item] ? arr[item].condition.childagerangeto : undefined,
                                                            rules: [{ required: false, message: 'childagerangeto !' }]
                                                        }
                                                    ]"
                                                />
                                        </a-form-item>
                                    </div>
                                    <a-divider :size="'small'" />
                                    <div style="font-weight:600;text-align:left;padding-left:10px">Transfer Date</div>
                                    <hotel-transfer-date-list :arr="arr[item]" :title="title" :id="item"/>
                                </div>
                                <hotel-transfer-price-list :arr="arr[item]" :title="title" :id="item"/>
                                <a-form-item style="width:25%;height:auto;margin:0;background-color:white;border:1px solid #ddd;border-top:none;border-right:none;border-bottom:none;padding:10px">
                                    <a-textarea 
                                        placeholder=""
                                        :auto-size="{ minRows: 8, maxRows: 10 }"
                                        v-decorator="[
                                            `${title}Description[${item}]`,
                                            {
                                                initialValue: arr[item] ? arr[item].description : undefined,
                                                rules: [{ required: false, message: 'description !' }]
                                            }
                                        ]"
                                    />
                                </a-form-item>
                                <div style="margin-top:auto;margin-bottom:auto;width:50px">
                                    <a-button shape="circle" type="solid" icon="minus" @click="removeRowTransfer(item)" class="minusRowBtn" :size="'small'"></a-button>
                                </div>
                            </div>
                        </a-row>
                    </div>
                </transition-group>
            </draggable> 
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowTransfer" type="primary">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import AwsFileManager from '../../components/AwsFileManager.vue'
import HotelTransferDateList from './HotelTransferDateList.vue'
import HotelTransferPriceList from './HotelTransferPriceList.vue'
import moment from 'moment';
import draggable from 'vuedraggable'
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    AwsFileManager,
    HotelTransferPriceList,
    HotelTransferDateList,
    draggable
  },
  data () {
    return {
      idTransfer: 0,
      keysListTransfer: [],

      visibleGallary: false,
      selitem:0,
      selectedImage:[],
      isSelected:[],
      
      dateFormat : (val) => {
        return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
      }

    }
  },
  created () {
    var mynumber = 50;
    this.selectedImage = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.selectedImage[i] = '';
    }
    this.isSelected = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.isSelected[i] = false;
    }
    this.init()
  },
  methods: {
    moment,
    init () {
      const arr = []
      
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idTransfer = 0;
        for (let i = 0; i < (this.arr).length; i++) {
            if (this.arr[i].image != ''){
                this.isSelected.splice(i, 1, true);
                this.selectedImage.splice(i, 1, this.arr[i].image);
            }
            else{
                this.isSelected.splice(i, 1, false);
                this.selectedImage.splice(i, 1, this.arr[i].image);
            }
          arr.push(i)
          this.idTransfer = this.idTransfer + 1
        }
      }
      this.keysListTransfer = arr
    },

    removeRowTransfer (k) {
      if (this.keysListTransfer.length === 0) { 
        return
      }
      this.keysListTransfer = this.keysListTransfer.filter(item => item !== k)
    },
    addRowTransfer () {
      this.keysListTransfer = this.keysListTransfer.concat(this.idTransfer)
      this.idTransfer = this.idTransfer + 1
    },

    
    showGallary(_isShow, _index){
        this.visibleGallary = _isShow;
        if (_isShow)
            this.selitem = _index;
        else
            this.selitem = 0;
    },
    selectImages(_images, _index){
        if (_images.length > 0){
            this.selectedImage[_index] = _images[0];
            this.isSelected[_index] = true;
        }
        this.visibleGallary = false;
    },
    cancelImage(_index){
        this.selectedImage[_index] = '';
        this.isSelected.splice(_index, 1, false)
    },
    getImage(_index){
        this.$emit('getimage', this.selectedImage[_index]);
    },
    getKeyTransferList(){
        this.$emit('getKeyTransferList', this.keysListTransfer);
    },
    onStart(){
        // console.log("======= onStart");
        // this.drag=true;
    },
    onEnd() {
        // console.log("======= onEnd", this.keysListTransfer);
    //    this.drag=false;
    },
  }
}
</script>
