<template>
    <div class="signup">
        <div>
            <div style="width:100%;display:flex;">
                <div>
                    <div style="padding-left: 5%;padding-right: 5%;padding-top:0px">
                        <div style="display:flex">
                            <a-input style="margin-right:10px" v-model="signupUser.firstName" placeholder="First Name" :size="'large'" @change="inputChange"/>
                            <a-input v-model="signupUser.lastName" placeholder="Last Name" :size="'large'" @change="inputChange"/>
                        </div>
                        <a-select :default-value="countryList[0]" v-model="signupUser.country" placeholder="Country" style="width:100%;margin-top:10px" @change="handlePersonType" :size="'large'">
                            <a-select-option v-for="_country in countryList" :key="_country">
                                <div style="display:flex">
                                    <span style="margin-top:auto;margin-bottom:auto;">{{ _country }}</span>
                                </div>
                            </a-select-option>
                        </a-select>
                        <a-input style="width:100%;margin-top:10px" placeholder="Email" v-model="signupUser.email" :size="'large'" @change="inputChange"/>
                        <a-input style="width:100%;margin-top:10px" placeholder="Password" v-model="signupUser.password" :size="'large'" @change="inputChange"/>
                        <a-checkbox style="margin-top:20px" class="signupAgreeText" :checked="checked" @change="onChange">
                            I agree to <a style="color:#00c2cb" href="http://google.com">Terms & Conditions</a>, 
                            <a style="color:#00c2cb" href="http://google.com">Privacy Policy</a> and subscribe to emails for offers and updates.
                        </a-checkbox>
                        
                        <a-button style="width:100%;margin-top:10px" type="primary" :disabled="disabled" :size="'large'" :style="{ fontSize: '20px'}" @click="handleSignupClick">
                            Create account
                        </a-button>
                        
                        <div style="background-color:#F0EFEF;height:1px;widht:100%;margin-top:20px"></div>
                        <div style="width:100%;margin-top:10px;text-align:center;cursor:pointer">Already a member? <font color="#CEB39A" v-on:click='clickLogin'>Log in</font></div>
                        <div style="width:100%;margin-top:10px;text-align:center;cursor:pointer" @click="openForgotPassword">Forgot password?</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { api } from '../helpers/Helpers';
import { common } from '../helpers/Common';
const countryList= ['US', 'RSS', 'CH'];
export default {
    
    data() {
        return {
            checked: false,
            disabled: false,
            countryList,
            signupUser: {
                type: Object,
                required: false,
                default: () => {
                    return {
                        firstName: '',
                        lastName: '',
                        email:'',
                        password:'',
                        permissionLevel:1,
                        country:'',
                        title:'',
                        phoneNumber:'',
                        agencyName:'',
                        menberName:'',
                        businessID:'',
                        stateProvince:''
                    };
                }
            },
            
            isVerify: false,
        }
    },
    components: {
        
        
    },
    methods: {
        close(){
            this.$emit('close');
        },
        handlePersonType(value) {
            // console.log(value)
            this.updateButton();
        //	this.cities = cityData[value];
        //	this.secondCity = cityData[value][0];
        },
        onChange(e) {
            // console.log(e)
            this.checked = e.target.checked;
            this.updateButton();
        },
        handleSignupClick: async function() {
            // console.log("click == ", this.signupUser.email + " == " + this.signupUser.password) 
            this.signupUser.permissionLevel = 2048;
            // console.log("click == ", this.signupUser) 
            const res = await api.signup(this.signupUser);
            // this.flash('task created', 'success');
             // console.log("---------accessToken-------" + res);
            if (res != undefined && res != "" && res.error == undefined){
                this.$message.success('Sign up success!');
                this.$emit('close');
            }
            else{
                if (res.error == undefined)
                    this.$message.error('This email is already using by other');
                else
                    this.$message.error(res.error.error);
            }
        },
        inputChange(){
            this.updateButton();
        },
        updateButton(){
            if (this.signupUser.firstName == undefined || this.signupUser.lastName == undefined ||
                this.signupUser.country == undefined || this.signupUser.email == undefined || 
                this.signupUser.password == undefined || !this.checked)
                this.disabled = true;
            else
                this.disabled = false;
        },
        clickLogin(){
            this.$emit('openLogin');
        },
        signupAgent(){
            
            this.$emit('openSignupAgent');
        },
        
        openForgotPassword(){
            this.$emit('openForgot');
        },
        // closeDialog(){
        //     // this.$router.go(-1)
        //      // console.log("---------accessToken-------" + false);
        //     this.$parent.$emit('visibleSignup', 'false');
        // }

        
        onEvent() {
            // console.log("onEvent");
            
        },
        verifyMethod(){
            this.isVerify = true;
            this.updateButton();
            // console.log("verifyMethod");
        },
        expiredMethod(){
            this.isVerify = false;
            this.updateButton();
            // console.log("expiredMethod");
        },
        renderMethod(){
            this.isVerify = false;
            // console.log("renderMethod");
        },
        errorMethod(){

            // console.log("errorMethod");
        }
    },
    created(){
        this.countryList = common.getCountryList();
    },
    mounted(){
        this.updateButton();
    }
}
</script>