<template>
    <div class="adduser" style="min-height:85vh;overflow-x: hidden;">
        <div style="display:flex">
            <div style="margin: 16px 0;display:flex">
                <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                    <a-breadcrumb-item style="font-size:30px;">Hotels</a-breadcrumb-item>
                    <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
                </a-breadcrumb>
                <a-button style="margin-top:auto;margin-bottom:auto;margin-left:20px" type="primary">
                    <router-link to="/admin/addhotel">
                    Add Hotel
                    </router-link>
                </a-button>
            </div>
        </div>
        
        <div style="position:relative">
          <div style="position:absolute;right:0;top:-30px;display:flex;">
            <a-input-search style="width:400px;margin-right:0px;margin-left:auto" v-model="hotelSearchText" placeholder="" @search="onSearch">
              <a-button slot="enterButton">
                Search Hotel
              </a-button>
            </a-input-search>
          </div>
          <div style="display:flex">
              <a-tabs
                  default-active-key="1"
                  :tab-position="'top'"
                  >
                  <a-tab-pane key="1" tab="All">
                      <HotelsTableItem :key="tableCellCount" :hotellist ="hotellist" :hotellist2 ="hotellist" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listFilter="listFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="Mine" force-render>
                      <HotelsTableItem :key="tableCellCount" :hotellist ="minelist" :hotellist2 ="minelist" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listFilter="listFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="3" tab="Published">
                      <HotelsTableItem :key="tableCellCount" :hotellist ="publishedlist" :hotellist2 ="publishedlist" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listFilter="listFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="4" tab="Draft">
                      <HotelsTableItem :key="tableCellCount" :hotellist ="draftlist" :hotellist2 ="draftlist" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listFilter="listFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
              </a-tabs>
          </div>
        </div>
    </div>
</template>
<script>
import HotelsTableItem from './TableItems/HotelsTableItem.vue'
import { api } from '../helpers/Helpers';
import { common } from '../utils/common';
const columns = [
  {
    title: 'Title',
    dataIndex: 'hotelname',
    key: 'hotelname',
    sorter: true,
    scopedSlots: { customRender: 'hotelname' },
  },
  {
    title: 'Auther',
    dataIndex: 'username',
    sorter: true,
    key: 'username',
  },
  {
    title: 'Comments',
    dataIndex: 'comments',
    key: 'comments',
  },
  {
    title: 'Date',
    key: 'createdate',
    dataIndex: 'createdate',
    sorter: true,
    scopedSlots: { customRender: 'createdate' },
  },
  {
    title: 'Hidden',
    key: 'hotelHidden',
    dataIndex: 'hotelHidden',
    sorter: true,
    scopedSlots: { customRender: 'hotelHidden' },
  },
  {
    title: 'Api Integrate',
    key: 'apiintegrate',
    dataIndex: 'apiintegrate',
    sorter: true,
    scopedSlots: { customRender: 'apiintegrate' },
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
  },
  {
    title: 'Number',
    sorter: true,
    key: 'number',
    dataIndex: 'number',
  },
  {
    title: 'SEO Score',
    key: 'seoscore',
    dataIndex: 'seoscore',
  },
  {
    title: 'ReadScore',
    key: 'readscore',
    dataIndex: 'readscore',
  },
  {
    title: 'Image',
    key: 'hotelimage',
    dataIndex: 'hotelimage',
    scopedSlots: { customRender: 'hotelimage' },
  },
  {
    title: 'Star',
    key: 'star',
    dataIndex: 'star',
  },
  {
    title: 'Checkin',
    key: 'checkin',
    dataIndex: 'checkin',
  },
  {
    title: 'Check Out',
    key: 'checkout',
    dataIndex: 'checkout',
  },
  {
    title: 'Rooms',
    key: 'rooms',
    dataIndex: 'rooms',
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];
const hotellist = [];
export default {
    
  data() {
    return {
      allhotellist:[],
      minelist:[],
      publishedlist:[],
      draftlist:[],
      hotellist,
    //   adminlist,
    //   agentlist,
      columns,
      hotelSearchText:"",
      tableCellCount:10
    };
  },
  components: {
    HotelsTableItem
  },
  
  mounted(){
      this.getHotels();
  },
  methods: {
      
    onSearch(value) {
      // console.log(value);
      var _tempList = []
      for (let i = 0; i < this.allhotellist.length; i++) {
          if (this.allhotellist[i].hotelname.toLowerCase().includes(this.hotelSearchText.toLowerCase()) || (this.allhotellist[i].username != undefined && this.allhotellist[i].username.toLowerCase().includes(this.hotelSearchText.toLowerCase())))
            _tempList.push(this.allhotellist[i])
      }
      this.hotellist = _tempList;
      this.updateHotelList();
    },
    listUpdate(){
      this.getHotels();
    },
    listFilterCount(_count){
      // console.log(_count);
      this.tableCellCount = _count;
    },
    listFilter(date, seoscore, readscore){
      var _tempList1 = [];
      var _tempList2 = [];
      var _tempList3 = [];
      if (date == 'all')
        _tempList1 = this.allhotellist;
      else{
        const selMonth = new Date(date).getMonth();
        const selYear = new Date(date).getFullYear();
        for (let i = 0; i < this.allhotellist.length; i++) {
          const tarMonth = new Date(this.allhotellist[i].createdate).getMonth();
          const tarYear = new Date(this.allhotellist[i].createdate).getFullYear();
          if (tarYear == selYear && tarMonth == selMonth)
            _tempList1.push(this.allhotellist[i])
        }
      }

      if (seoscore == 'all')
        _tempList2 = this.allhotellist;
      else{
        for (let i = 0; i < this.allhotellist.length; i++) {
          if (this.allhotellist[i].seoscore == seoscore)
            _tempList2.push(this.allhotellist[i])
        }
      }

      if (readscore == 'all')
        _tempList3 = this.allhotellist;
      else{
        for (let i = 0; i < this.allhotellist.length; i++) {
          if (this.allhotellist[i].readscore == readscore)
            _tempList3.push(this.allhotellist[i])
        }
      }
      this.hotellist = common.commonElementsOfArray(_tempList1, _tempList2, _tempList3);
      this.updateHotelList();
    },
    getHotels:async function(){
    
      const res = await api.getHotelsTotal();
      // console.log("getHotels:",res);
      
      res.sort(function(a,b) {
        let aValue = a['hotelname'];
        let bValue = b['hotelname'];
        return (aValue > bValue) ? 1 : ((bValue > aValue) ? -1 : 0);
        } 
      );
      this.getHotelResult(res);
    },
    getHotelResult(res){
      for (let i = 0; i < res.length; i++){
        if (res[i].hoteldatarooms != undefined && res[i].hoteldatarooms.numberofroom != undefined)
          res[i]['number'] = res[i].hoteldatarooms.numberofroom;
        else  
          res[i]['number'] = '';
      }
      this.allhotellist = res;
      this.hotellist = res;
      this.updateHotelList();
    },
    
    updateHotelList(){
      var resMineList = [];
      var resPublishedList = [];
      var resDraftList = [];
      for (let i = 0; i < this.hotellist.length; i++) {
          if (this.hotellist[i].userid == "MineUserId")
              resMineList.push(this.hotellist[i])
          if (this.hotellist[i].status == 1)
              resPublishedList.push(this.hotellist[i])
          if (this.hotellist[i].status == 0)
              resDraftList.push(this.hotellist[i])
      }
      this.minelist = resMineList; 
      this.publishedlist = resPublishedList;
      this.draftlist = resDraftList;
    }
  }
}
</script>