<template>
    <div style="display:flex;min-height:85vh">
        <div style="width:100%;display:flex;top:0px;margin:auto;">
            
            <div style="margin:auto;">
                <div style="width:300px;padding-top:20px">
                    <a-input placeholder="Email" v-model="loginAdmin.email" :size="'large'" @change="emailChanged"/>
                    <!-- <a-input style="margin-top:20px" placeholder="Password" v-model="loginAdmin.password" :size="'large'" @change="emailChanged"/> -->
                    <a-input-password style="margin-top:20px" placeholder="Password" v-model="loginAdmin.password" :size="'large'" @change="emailChanged"/>
                    
                    <a-checkbox style="margin-top:20px;" v-model="isRemenber">Remember me</a-checkbox>
                    <a-button style="margin-top:10px;width:100%" type="primary" :disabled="disabled" :size="'large'" :style="{ fontSize: '20px'}"  @click="handleLoginClick">
                        Log in
                    </a-button>
                    
                    <div style="background-color:#F0EFEF;height:1px;widht:100%;margin-top:20px"></div>
                    <div style="width:100%;margin-top:10px;text-align:center;cursor:pointer">New here? <font color="#CEB39A" v-on:click='clickSignUp'>Sign up</font></div>
                    <div style="width:100%;margin-top:10px;text-align:center;cursor:pointer" @click="openForgotPassword">Forgot password?</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { api } from '../helpers/Helpers';

export default {
    data() {
        return {
            disabled: false,
            loginAdmin: {
                type: Object,
                required: false,
                default: () => {
                    return {
                        email: 'marcos.henrique@toptal.com',
                        password: 's3cr3tp4sswo4rd'
                    };
                }
            },

            isRemenber: false
        };
    },
    components: {
        
    },
    methods: {
        getUserPass(){
            var passInfo = this.$store.state.loginPassword;
            if (passInfo != undefined && passInfo.userEmail != undefined && passInfo.password != undefined){
                var _temp = {
                    email:passInfo.userEmail,
                    password:passInfo.password,
                }
                this.loginAdmin = _temp;
            }
            // else{
            //     this.loginAdmin.email = '';
            //     this.loginAdmin.password = '';
            // }
            // console.log("click == ", this.loginAdmin) 
        },
        handleLoginClick: async function() {
            // console.log("click == ", this.loginAdmin.email + " == " + this.loginAdmin.password) 
            const res = await api.login(this.loginAdmin);
            // this.flash('task created', 'success');
            // console.log("---------accessToken-------",res);
            if (res != undefined){
                var userInfo = {
                    loginType: 'api',
                    api: {
                        auth: 'alyosha',
                        user: {
                            name: res.name,
                            email: this.loginAdmin.email,
                            profileImage: "testurl",
                            accessToken:res.accessToken,
                            refreshToken:res.refreshToken
                        }
                    },
                    id: res.userId
                }
                // console.log("---- " + userInfo)
                this.$store.commit('setLoginUser', userInfo)
                if (this.isRemenber){
                    var userPass = {
                        userEmail: this.loginAdmin.email,
                        password: this.loginAdmin.password
                    }
                    this.$store.commit('setLoginPass', userPass)
                }
                else{
                    var userPass = {
                        userEmail: '',
                        password: ''
                    }
                    this.$store.commit('setLoginPass', userPass)
                }
                window.location.href = '/admin/users';
            }
        },
        emailChanged(){
            if (this.loginAdmin.email == undefined || this.loginAdmin.password == undefined)
                this.disabled = true;
            else
                this.disabled = false;
        },
        clickSignUp(){
            window.location.href = '/admin/signupview';
        },
        openForgotPassword(){
            window.location.href = '/admin/forgotview';
        },
    },
    mounted(){
        this.emailChanged();
       // this.getUserPass();
    },
    created: function () {
        this.getUserPass();
    }
}
</script>