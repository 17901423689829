<template>
    <div style="min-height:85vh">>
        
        <div style="margin: 16px 0;display:flex">
            <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                <a-breadcrumb-item style="font-size:30px;">Edit User</a-breadcrumb-item>
                <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
            </a-breadcrumb>
            
            <a-button style="margin-top:auto;margin-bottom:auto;margin-left:20px" type="primary">
                <router-link to="/admin/adduser">
                Add User
                </router-link>
            </a-button>
        </div>
        <div style="display:flex;margin-top:5px">
            <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">Role</div>
            <a-select style="width:400px;margin-top:auto;margin-botton:auto;" v-model="userrole" slot="addonAfter" default-value="1">
                <a-select-option value="1">
                    User
                </a-select-option>
                <a-select-option value="2048">
                    Administrator
                </a-select-option>
                <a-select-option value="4">
                    Agent
                </a-select-option>
            </a-select>
        </div>
        <div style="display:flex;margin-top:5px">
            <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">First Name</div>
            <a-input style="width:400px;margin-top:auto;margin-botton:auto" placeholder="" v-model="firstname"/>
        </div>
        <div style="display:flex;margin-top:5px">
            <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">Last Name</div>
            <a-input style="width:400px;margin-top:auto;margin-botton:auto" placeholder="" v-model="lastname"/>
        </div>
        <div style="display:flex;margin-top:5px">
            <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">Email</div>
            <a-input style="width:400px;margin-top:auto;margin-botton:auto" placeholder="" v-model="useremail"/>
        </div>
        <div v-if="userrole == '4'" style="margin-top:30px;margin-bottom: 30px;">
            <div style="text-align:left;font-size:18px">BUSINESS INFORMATION:</div>
            <div style="display:flex;margin-top:5px">
                <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">Agency Name</div>
                <a-input style="width:400px;margin-top:auto;margin-botton:auto" placeholder="" v-model="agencyName"/>
            </div>
            <div style="display:flex;margin-top:5px">
                <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">Member Name</div>
                <a-input style="width:400px;margin-top:auto;margin-botton:auto" placeholder="" v-model="memberName"/>
            </div>
            <div style="display:flex;margin-top:5px">
                <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">Business ID</div>
                <a-input style="width:400px;margin-top:auto;margin-botton:auto" placeholder="" v-model="businessID"/>
            </div>
            <div style="display:flex;margin-top:5px">
                <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">State/Province</div>
                <a-input style="width:400px;margin-top:auto;margin-botton:auto" placeholder="" v-model="stateProvince"/>
            </div>
        </div>

        
        <div style="display:flex;margin-top:5px">
            <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">Password</div>
            <a-input style="width:400px;margin-top:auto;margin-botton:auto" placeholder="" v-model="userpassword"/>
        </div>
        
        <div style="display:flex;margin-top:5px">
            <div style="width:200px;text-align:left;margin-top:auto;margin-bottom:auto">Country</div>
            
            <a-auto-complete
                :data-source="countryList"
                style="width:400px;margin-top:auto;margin-bottom:auto"
                placeholder=""
                v-model="country"
                :filter-option="filterOption"
            /> 
            <!-- <a-select :default-value="countryList[0]" v-model="country" placeholder="Country" style="width:400px;margin-top:auto;margin-bottom:auto" >
                <a-select-option v-for="_country in countryList" :key="_country">
                    <div style="display:flex">
                        <span style="margin-top:auto;margin-bottom:auto;">{{ _country }}</span>
                    </div>
                </a-select-option>
            </a-select> -->
        </div>
        <div style="display:flex;margin-top:5px">
            <div style="width:200px;text-align:left;margin-top:auto;margin-bottom:auto">State</div>
            <a-input style="width:400px;margin-top:auto;margin-bottom:auto" v-model="stateProvince" placeholder="" />
        </div>
        
        <div style="display:flex;margin-top:20px">
            <div style="width:200px;text-align:left;margin-top:auto;margin-bottom:auto">Send User Notification</div>
            <a-checkbox style="margin-top:auto;margin-bottom:auto" v-model="isSendNotify">
                Send the new user an email about their account.
            </a-checkbox>
        </div>
        
        <div style="display:flex;margin-top:5px">
            <div style="width:200px;margin-top:auto;margin-botton:auto;text-align:left">Status</div>
            <a-select style="width:400px;margin-top:auto;margin-botton:auto;" v-model="userstatus" slot="addonAfter" default-value="1">
                <a-select-option value="0">
                    Pending
                </a-select-option>
                <a-select-option value="1">
                    Approved
                </a-select-option>
                <a-select-option value="2">
                    Denied
                </a-select-option>
            </a-select>
        </div>
        
        <div style="display:flex;margin-top:20px">
            <a-button style="margin-top:auto;margin-bottom:auto;" type="primary" v-on:click="saveUser">
                Save User
            </a-button>
        </div>
    </div>
</template>
<script>

import { api } from '../helpers/Helpers';
import { common } from '../helpers/Common';

const countryList= ['US', 'RSS', 'CH'];
// const roleToInt = {
//     user:1,
//     agent:4,
//     admin:2048
// }
// const roleToStr = {
//     1:user,
//     4:agent,
//     2048:admin
// }
export default {
  data() {
    return {
        username:'',
        userrole:'',
        firstname:'',
        lastname:'',
        useremail:'',
        userwebsite:'',
        agencyName:'',
        memberName:'',
        businessID:'',
        stateProvince:'',
        userpassword:'',
        userlanguage:'',
        country:'',
        stateProvince:'',
        isSendNotify:false,
        userstatus:'',

        userid:'',

        countryList,
    };
  },
  
  created: function () {
        this.getUserInfo();
        this.countryList = common.getCountryList();
  },
  methods: {
    filterOption(input, option) {
        return (
            option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
        );
    },
      getUserInfo:async function(){
          
        const queryString = window.location.search;
        const parameters = new URLSearchParams(queryString);
        const _id = parameters.get('id');
        // console.log(_id) 
        if (_id != null){
            this.userid = _id;
            const res = await api.getUserByAdmin(_id)
            // console.log(res) 
            this.username = res.userName;
            this.firstname = res.firstName;
            this.lastname = res.lastName;
            this.useremail = res.email;
            this.userwebsite = res.website;
            this.agencyName = res.agencyName;
            this.memberName = res.menberName;
            this.businessID = res.businessID;
            this.stateProvince = res.stateProvince;
            this.userlanguage = res.language;
            this.country = res.country;
            this.stateProvince = res.stateProvince;
            this.isSendNotify = res.isSendNotify;
            this.userstatus = res.status;
            let _role = 1;
            if (res.permissionLevel != undefined)
                _role = res.permissionLevel;
            this.userrole = _role.toString()
        }
      },
      saveUser:async function(){
            let signupUser = {};
            if (this.userpassword === ""){
                signupUser = {
                    userName:this.username,
                    email:this.useremail,
                    firstName:this.firstname,
                    lastName:this.lastname,
                    website:this.userwebsite,
                    agencyName:this.agencyName,
                    menberName:this.menberName,
                    businessID:this.businessID,
                    stateProvince:this.stateProvince,
                    language:this.userlanguage,
                    country:this.country,
                    stateProvince:this.stateProvince,
                    isSendNotify:this.isSendNotify,
                    role:this.userrole,
                    permissionLevel:this.userrole,
                    status: this.userstatus,
                    posts:0,
                }
            }
            else{
                signupUser = {
                    userName:this.username,
                    email:this.useremail,
                    firstName:this.firstname,
                    lastName:this.lastname,
                    website:this.userwebsite,
                    agencyName:this.agencyName,
                    menberName:this.menberName,
                    businessID:this.businessID,
                    stateProvince:this.stateProvince,
                    language:this.userlanguage,
                    country:this.country,
                    stateProvince:this.stateProvince,
                    isSendNotify:this.isSendNotify,
                    role:this.userrole,
                    permissionLevel:this.userrole,
                    status: this.userstatus,
                    posts:0,
                    password:this.userpassword,
                }
            }
            const res = await api.updateUserByAdmin(this.userid, signupUser);
            // this.flash('task created', 'success');
             // console.log(res);
            if (res != undefined && res != ""){
                this.$message.success('update success!');
                this.$emit('close');
                // window.location.href = '/admin/users';
                this.$router.push({ path: 'users'})
            }
            else{
                this.$message.error('update failed!');
            }
      }
  }
}
</script>