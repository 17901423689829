<template>
    <div style="min-height:85vh">
        <a-collapse style="margin-top:10px" accordion>
            <a-collapse-panel key="1" header="Logo Setting">
                <div style="display:flex">
                    <div style="width:200px">
                        <div style="text-align:left;margin-top:5px;margin-left:5px">Logo Image</div>
                        <a-divider />
                        <div style="text-align:left;margin-bottom:5px;margin-left:5px">
                            <div v-if="!isSelected" style="display:flex">
                                <div>No image selected</div>
                                <a-button @click="showImage(true)" :size="'small'">Add Image</a-button>
                            </div>
                            <div v-if="isSelected" style="position:relative;width:200px;height:200px">
                                <img :src="`${selectedImage}`" alt="" width="200px" height="200px">
                                <a-icon @click="cancelImage()" style="position:absolute;top:5px;right:5px"
                                    type="close-circle" theme="filled" />
                                <a-icon @click="showImage(true)" style="position:absolute;top:5px;right:25px" type="edit"
                                    theme="filled" />
                            </div>
                        </div>
                        <a-modal v-model="visibleImage" title="Add Image" :footer="null" width="80%">
                            <AwsFileManager v-if="visibleImage" :ismulti="false" @close="showImage(false)"
                                @selectImages="selectImage" />
                        </a-modal>
                    </div>
                    <div style="width:200px;margin-left:10px">
                        <div style="text-align:left;margin-top:5px;margin-left:5px">Logo Title</div>
                        <a-divider />
                        <div style="display:flex">
                            <a-input placeholder="Enter Logo Title" v-model="logoname" />
                        </div>
                    </div>
                    <div style="width:100%:display:flex">
                        <a-button style="margin-left:50px;margin-top:50px" @click="saveLogo" type="primary">Save Logo
                            Info</a-button>
                    </div>

                </div>
            </a-collapse-panel>
            <a-collapse-panel key="2" header="Social Icons Setting">
                <a-form style="display:flex;width:100%;" :form="dashboardSocialForm">
                    <div>
                        <a-table :columns="columnsSocial" :data-source="socialList" bordered size="middle"
                            :pagination="false" :scroll="{ x: 0, y: 0 }">
                            <span slot="name" slot-scope="text, record, index">
                                <a-form-item style="margin-bottom: 0px;">
                                    <a-input v-decorator="[
                                        `${TABLESOCIAL}name[${index}]`,
                                        {
                                            initialValue: text ? text : undefined,
                                            rules: [{ required: false, message: 'Input Name !' }]
                                        }
                                    ]" />
                                </a-form-item>
                            </span>
                            <span slot="link" slot-scope="text, record, index">
                                <a-form-item style="margin-bottom: 0px;">
                                    <a-input v-decorator="[
                                        `${TABLESOCIAL}link[${index}]`,
                                        {
                                            initialValue: text ? text : undefined,
                                            rules: [{ required: false, message: 'Input Link !' }]
                                        }
                                    ]" />
                                </a-form-item>
                            </span>
                            <span slot="operation" slot-scope="text, record">
                                <a @click="DeleteOne(record.key)">Delete</a>
                            </span>
                            <span slot="icon" slot-scope="text, record, index">
                                <div style="text-align:left;margin-bottom:5px;margin-left:5px;width:150px">
                                    <div v-if="!isSocialSelected[index]" style="display:flex">
                                        <div>No image selected</div>
                                        <a-button @click="showSocialImage(true, index)" :size="'small'">Add Image</a-button>
                                    </div>
                                    <div v-if="isSocialSelected[index]" style="position:relative">
                                        <img :src="`${selectedSocialImage[index]}`" alt="" width="100%px" height="150px">
                                        <a-icon @click="cancelSocialImage(index)"
                                            style="position:absolute;top:5px;right:5px" type="close-circle"
                                            theme="filled" />
                                        <a-icon @click="showSocialImage(true, index)"
                                            style="position:absolute;top:5px;right:25px" type="edit" theme="filled" />
                                    </div>
                                </div>

                            </span>
                        </a-table>

                        <a-modal v-model="visibleSocialImage" title="Add Image" :footer="null" width="80%">
                            <AwsFileManager v-if="visibleSocialImage" :ismulti="false" :rowIndex="selitemSocial"
                                @close="showSocialImage(false)" @selectImages="selectSocialImage" />
                        </a-modal>
                        <div style="display:flex;margin-top:10px">

                            <a-button class="editable-add-btn" @click="addData">
                                Add SocialIcon
                            </a-button>
                            <a-button class="editable-add-btn" style="margin-left:10px" @click="saveSocialData"
                                type="primary">
                                Save Social Icons
                            </a-button>
                        </div>
                    </div>
                </a-form>
            </a-collapse-panel>
            <a-collapse-panel key="3" header="About Maldives Setting">
                <div style="display:flex">
                    <div style="width:150px;text-align:left">Banner Text : </div>
                    <a-textarea :auto-size="{ minRows: 6, maxRows: 8 }" style="width:400px" placeholder="Enter Banner Text"
                        v-model="bannerText" />
                </div>
                <a-divider />
                <a-form style="display:flex;width:100%;" :form="dashboardAboutForm">
                    <div>
                        <a-table :columns="columnsAbout" :data-source="aboutList" bordered size="middle" :pagination="false"
                            :scroll="{ x: 0, y: 0 }">
                            <span slot="name" slot-scope="text, record, index">
                                <a-form-item style="margin-bottom: 0px;">
                                    <a-input v-decorator="[
                                        `${TABLEABOUT}name[${index}]`,
                                        {
                                            initialValue: text ? text : undefined,
                                            rules: [{ required: false, message: 'Input Name !' }]
                                        }
                                    ]" />
                                </a-form-item>
                            </span>
                            <span slot="link" slot-scope="text, record, index">
                                <a-form-item style="margin-bottom: 0px;">
                                    <a-textarea placeholder="" :auto-size="{ minRows: 8, maxRows: 10 }" v-decorator="[
                                        `${TABLEABOUT}link[${index}]`,
                                        {
                                            initialValue: text ? text : undefined,
                                            rules: [{ required: false, message: 'Input Link !' }]
                                        }
                                    ]" />
                                </a-form-item>
                            </span>
                            <span slot="detail" slot-scope="text, record, index">
                                <a-form-item style="margin-bottom: 0px;">
                                    <a-textarea placeholder="" :auto-size="{ minRows: 8, maxRows: 10 }" v-decorator="[
                                        `${TABLEABOUT}detail[${index}]`,
                                        {
                                            initialValue: text ? text : undefined,
                                            rules: [{ required: false, message: 'Detail!' }]
                                        }
                                    ]" />
                                </a-form-item>
                            </span>
                            <span slot="operation" slot-scope="text, record">
                                <a @click="DeleteAboutOne(record.key)">Delete</a>
                            </span>
                            <span slot="icon" slot-scope="text, record, index">
                                <div style="text-align:left;margin-bottom:5px;margin-left:5px;width:150px">
                                    <div v-if="!isAboutSelected[index]" style="display:flex">
                                        <div>No image selected</div>
                                        <a-button @click="showAboutImage(true, index)" :size="'small'">Add Image</a-button>
                                    </div>
                                    <div v-if="isAboutSelected[index]" style="position:relative">
                                        <img :src="`${selectedAboutImage[index]}`" alt="" width="100%px" height="150px">
                                        <a-icon @click="cancelAboutImage(index)" style="position:absolute;top:5px;right:5px"
                                            type="close-circle" theme="filled" />
                                        <a-icon @click="showAboutImage(true, index)"
                                            style="position:absolute;top:5px;right:25px" type="edit" theme="filled" />
                                    </div>
                                </div>

                            </span>
                        </a-table>

                        <a-modal v-model="visibleAboutImage" title="Add Image" :footer="null" width="80%">
                            <AwsFileManager v-if="visibleAboutImage" :ismulti="false" :rowIndex="selitemAbout"
                                @close="showAboutImage(false)" @selectImages="selectAboutImage" />
                        </a-modal>
                        <div style="display:flex;margin-top:10px">
                            <a-button class="editable-add-btn" @click="addAboutData">
                                Add Item
                            </a-button>
                            <a-button class="editable-add-btn" style="margin-left:10px" @click="saveAboutData"
                                type="primary">
                                Save About Setting
                            </a-button>
                        </div>
                    </div>
                </a-form>
            </a-collapse-panel>
            <a-collapse-panel key="4" header="Best Offers Setting">
                <a-form style="display:flex;width:100%;" :form="dashboardBestHotelForm">
                    <a-modal v-model="visibleEditModal" title="Edit Feature" :footer="null" width="20%">

                        <span v-for="item, index in this.EditModalArray" :key="index">
                            <a-form-item style="margin-bottom: 0px;">
                                <a-input style="width:100%" :value="item" i @change="(evt) => changeValue(index, evt)" />
                            </a-form-item>
                        </span>
                        <div style="padding-bottom: 40px;">
                            <a-button class="editable-add-btn" style="margin-top:15px;float:right;" type="primary"
                                @click="editBestHotelFeature">
                                Edit Features
                            </a-button>
                        </div>
                    </a-modal>
                    <div style="width:100%">
                        <a-table :columns="columnsBestHotel" :data-source="bestHotelList" bordered size="middle"
                            :pagination="false" :scroll="{ x: roomCellWidth, y: 0 }">
                            <span slot="hotelid" slot-scope="text, record, index">

                                <a-form-item style="margin-top:auto;margin-bottom:auto;width: 100%">
                                    <a-select style="margin-top:auto;margin-bottom:auto;width: 100%"
                                        placeholder="Select Hotel" v-decorator="[
                                            `${TABLEBESTHOTEL}hotelid[${index}]`,
                                            {
                                                initialValue: text ? text : undefined,
                                                rules: [{ required: false, message: 'hotelid!' }]
                                            }
                                        ]">
                                        <a-select-option v-for="item in hotelList" :key="item._id" :value="item._id">
                                            {{ item.hotelname }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </span>
                            <span slot="startdate" slot-scope="text, record, index">

                                <!-- <date-picker v-model="bestStartDate[index]" style="width:100%;" valueType="format"></date-picker> -->
                                <a-form-item style="margin-bottom: 0px;">
                                    <a-date-picker style="width:100%;" placeholder="" :showToday="false"
                                        valueFormat="YYYY-MM-DD" v-decorator="[
                                            `${TABLEBESTHOTEL}startdate[${index}]`,
                                            {
                                                initialValue: (record.startdate != undefined && record.startdate != null) ? record.startdate.split('T')[0] : null,
                                                rules: [{ required: false, message: 'Input startdate!' }]
                                            }
                                        ]">
                                        <!-- <template slot="dateRender" slot-scope="current">
                                            <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                            v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                                {{ moment(current).utc().date() }}
                                            </div>
                                        </template> -->
                                    </a-date-picker>
                                </a-form-item>
                            </span>
                            <span slot="enddate" slot-scope="text, record, index">
                                <!-- <date-picker v-model="bestEndDate[index]" style="width:100%;" valueType="format"></date-picker> -->
                                <a-form-item style="margin-bottom: 0px;">
                                    <a-date-picker style="width:100%;" placeholder="" :showToday="false"
                                        valueFormat="YYYY-MM-DD" v-decorator="[
                                            `${TABLEBESTHOTEL}enddate[${index}]`,
                                            {
                                                initialValue: (record.enddate !== undefined && record.enddate !== null) ? record.enddate.split('T')[0] : null,
                                                rules: [{ required: false, message: 'Input enddate!' }]
                                            }
                                        ]">
                                        <!-- <template slot="dateRender" slot-scope="current">
                                            <div class="ant-calendar-date" :title="moment(current).utc().format('MMMM D, YYYY')"
                                            v-bind:style="[moment(current).utc().format('MMMM D, YYYY') == moment(new Date()).utc().format('MMMM D, YYYY') ? {'font-weight': 'bold'} : {}]">
                                                {{ moment(current).utc().date() }}
                                            </div>
                                        </template> -->
                                    </a-date-picker>
                                </a-form-item>
                            </span>

                            <span slot="features" slot-scope="text, record, index">
                                <a-form-item style="margin-top:auto;margin-bottom:auto;width: 100%">
                                    <a-select style="margin-top:auto;margin-bottom:auto;width: 100%" placeholder="Select"
                                        mode="tags" v-decorator="[
                                            `${TABLEBESTHOTEL}features[${index}]`,
                                            {
                                                initialValue: text ? text : undefined,
                                                rules: [{ required: false, message: 'features!' }]
                                            }
                                        ]">
                                        <a-select-option v-for="item in featuresList" :key="item">
                                            {{ item }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </span>

                            <span slot="shownewprice" slot-scope="text, record, index">
                                <a-form-item style="margin-bottom: 0px;">
                                    <a-input-number style="width:100%" v-decorator="[
                                        `${TABLEBESTHOTEL}shownewprice[${index}]`,
                                        {
                                            initialValue: text ? text : 0,
                                            rules: [{ required: false, message: 'Input shownewprice !' }]
                                        }
                                    ]" />
                                </a-form-item>
                            </span>
                            <span slot="showoldprice" slot-scope="text, record, index">
                                <a-form-item style="margin-bottom: 0px;">
                                    <a-input-number style="width:100%" v-decorator="[
                                        `${TABLEBESTHOTEL}showoldprice[${index}]`,
                                        {
                                            initialValue: text ? text : 0,
                                            rules: [{ required: false, message: 'Input showoldprice !' }]
                                        }
                                    ]" />
                                </a-form-item>
                            </span>

                            <span slot="url" slot-scope="text, record, index">
                                <a-form-item style="margin-bottom: 0px;">
                                    <a-textarea placeholder="Enter Custom Url" :auto-size="{ minRows: 5, maxRows: 6 }"
                                        v-decorator="[
                                            `${TABLEBESTHOTEL}url[${index}]`,
                                            {
                                                initialValue: text ? text : undefined,
                                                rules: [{ required: false, message: 'Input url !' }]
                                            }
                                        ]" />
                                </a-form-item>
                            </span>
                            <span slot="priceper" slot-scope="text, record, index">
                                <a-form-item style="margin-top:auto;margin-bottom:auto;width: 100%">
                                    <a-select style="margin-top:auto;margin-bottom:auto;width: 100%" placeholder="Select"
                                        v-decorator="[
                                            `${TABLEBESTHOTEL}priceper[${index}]`,
                                            {
                                                initialValue: text ? text : undefined,
                                                rules: [{ required: false, message: 'priceper!' }]
                                            }
                                        ]">
                                        <a-select-option v-for="item, iItem in pricePerList" :key="iItem" :value="iItem">
                                            {{ item }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </span>
                            <span slot="operation" slot-scope="text, record">
                                <a @click="EditBestHotelOne(record.key)">Edit</a>
                                <a style="cursor:default;color:red;">&nbsp;|&nbsp;</a>
                                <a @click="DeleteBestHotelOne(record.key)">Delete</a>
                            </span>

                        </a-table>
                        <div style="display:flex;margin-top:10px">
                            <a-button class="editable-add-btn" @click="addBestHotelData">
                                Add Item
                            </a-button>
                            <a-button class="editable-add-btn" style="margin-left:10px" @click="saveBestHotelData"
                                type="primary">
                                Save Best Hotels
                            </a-button>
                        </div>
                    </div>
                </a-form>
            </a-collapse-panel>

            <a-collapse-panel key="5" header="Home Background Images">
                <a-modal v-model="visibleBackgrounImages" title="Add Gallay" :footer="null" width="80%">
                    <AwsFileManager v-if="visibleBackgrounImages" :ismulti="true" @close="showBackgrounImages(false)"
                        @selectImages="selectBackgrounImages" />
                    <!-- <AddGallary :ismulti="true" @close="showGallary(false)" @selectImages="selectImages"/> -->
                </a-modal>
                <a-list style="margin-top:10px" :grid="{ gutter: 24, xs: 3, sm: 4, md: 6, lg: 8, xl: 8, xxl: 12 }"
                    :data-source="backgroundImages">
                    <a-list-item slot="renderItem" slot-scope="item">
                        <div style="border: 1px solid #E7E7E7;position:relative">
                            <div style="width:100%;height:8vh">
                                <img style="object-fit: cover;width:100%;height:100%" :src="`${item}`">
                            </div>
                            <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white"
                                type="solid" shape="circle" icon="close" @click="cancelBackgroundImage(item)"
                                :size="'small'"></a-button>
                        </div>
                    </a-list-item>
                </a-list>

                <div style="display:flex;margin-top:10px">
                    <a-button @click="showBackgrounImages(true)">Add home background images</a-button>
                    <a-button class="editable-add-btn" style="margin-left:10px" @click="saveBackgroundImages"
                        type="primary">
                        Save Images
                    </a-button>
                </div>

            </a-collapse-panel>

            <a-collapse-panel key="6" header="Hotel Style Setting">
                <a-form style="display:flex;width:100%;" :form="dashboardResortStyleForm">
                    <div>
                        <a-table :columns="columnsResortStyle" :data-source="resortStyleList" bordered size="middle"
                            :pagination="false" :scroll="{ x: 0, y: 0 }">
                            <span slot="name" slot-scope="text, record, index">
                                <a-form-item style="margin-bottom: 0px;">
                                    <a-input v-decorator="[
                                        `${TABLERESORTSTYLE}name[${index}]`,
                                        {
                                            initialValue: text ? text : undefined,
                                            rules: [{ required: false, message: 'Input Name !' }]
                                        }
                                    ]" />
                                </a-form-item>
                            </span>
                            <span slot="operation" slot-scope="text, record">
                                <a @click="DeleteOneResortStyle(record.key)">Delete</a>
                            </span>
                        </a-table>

                        <div style="display:flex;margin-top:10px">
                            <a-button class="editable-add-btn" @click="addResortStyleData">
                                Add Resort Style
                            </a-button>
                            <a-button class="editable-add-btn" style="margin-left:10px" @click="saveResortStyleData"
                                type="primary">
                                Save Resort Styles
                            </a-button>
                        </div>
                    </div>
                </a-form>
            </a-collapse-panel>
            <a-collapse-panel key="7" header="Hotel Type of Travel Setting">
                <a-form style="display:flex;width:100%;" :form="dashboardResortTypeTravelForm">
                    <div>
                        <a-table :columns="columnsResortTypeTravel" :data-source="resortTypeTravelList" bordered
                            size="middle" :pagination="false" :scroll="{ x: 0, y: 0 }">
                            <span slot="name" slot-scope="text, record, index">
                                <a-form-item style="margin-bottom: 0px;">
                                    <a-input v-decorator="[
                                        `${TABLERESORTTYPETRAVEL}name[${index}]`,
                                        {
                                            initialValue: text ? text : undefined,
                                            rules: [{ required: false, message: 'Input Name !' }]
                                        }
                                    ]" />
                                </a-form-item>
                            </span>
                            <span slot="operation" slot-scope="text, record">
                                <a @click="DeleteOneResortTypeTravel(record.key)">Delete</a>
                            </span>
                        </a-table>

                        <div style="display:flex;margin-top:10px">
                            <a-button class="editable-add-btn" @click="addResortTypeTravelData">
                                Add Resort Type of Travel
                            </a-button>
                            <a-button class="editable-add-btn" style="margin-left:10px" @click="saveResortTypeTravelData"
                                type="primary">
                                Save Resort Types of Travel
                            </a-button>
                        </div>
                    </div>
                </a-form>
            </a-collapse-panel>


            <!-- <a-collapse-panel key="5" header="About Page Customize">
                <div>
                    <div style="margin-left:10px;margin-top:10px;display: flex;">
                        <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">About Sub Title</div>
                        <div style="display:flex;width: 100%;">
                            <a-input placeholder="Enter Sub Title" v-model="aboutSubTitle" />
                        </div>
                    </div>
                    <div style="margin-left:10px;margin-top:10px;display: flex;">
                        <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">About Main Title</div>
                        <div style="display:flex;width: 100%;">
                            <a-input placeholder="Enter Main Title" v-model="aboutMainTitle" />
                        </div>
                    </div>
                    <a-divider/>
                    <a-form style="display:flex;width:100%;" :form="dashboardAboutPageForm">
                        <div style="width:100%">
                            <a-table
                            :columns="columnsAboutPage"
                            :data-source="aboutPageInfoList"
                            bordered
                            size="middle"
                            :pagination="false"
                            :scroll="{ x: 0, y: 0 }"
                        >
                                <span slot="mainname" slot-scope="text, record, index">
                                    <a-form-item style="margin-bottom: 0px;">                                    
                                        <a-textarea placeholder="Enter mainname" :auto-size="{ minRows: 5, maxRows: 6 }" 
                                            v-decorator="[
                                                    `${TABLEABOUTPAGE}mainname[${index}]`,
                                                    {
                                                        initialValue: text ? text : undefined,
                                                        rules: [{ required: false, message: 'Input mainname !' }]
                                                    }
                                                ]"
                                            />
                                    </a-form-item>
                                </span>
                                <span slot="subname" slot-scope="text, record, index">
                                    <a-form-item style="margin-bottom: 0px;">                                                                                  
                                        <a-textarea placeholder="Enter subname" :auto-size="{ minRows: 5, maxRows: 6 }" 
                                            v-decorator="[
                                                    `${TABLEABOUTPAGE}subname[${index}]`,
                                                    {
                                                        initialValue: text ? text : undefined,
                                                        rules: [{ required: false, message: 'Input subname !' }]
                                                    }
                                                ]"
                                            />
                                    </a-form-item>
                                </span>
                                <span slot="description" slot-scope="text, record, index">
                                    <a-form-item style="margin-bottom: 0px;">                                        
                                        <a-textarea placeholder="Enter description" :auto-size="{ minRows: 5, maxRows: 20 }" 
                                            v-decorator="[
                                                    `${TABLEABOUTPAGE}description[${index}]`,
                                                    {
                                                        initialValue: text ? text : undefined,
                                                        rules: [{ required: false, message: 'Input description !' }]
                                                    }
                                                ]"
                                            />
                                    </a-form-item>
                                </span>
                                
                                <span slot="operation" slot-scope="text, record">
                                    <a @click="DeleteAboutPageOne(record.key)">Delete</a>
                                </span>
                            </a-table>
                            <div style="display:flex;margin-top:10px">
                                <a-button class="editable-add-btn" @click="addAboutPageData">
                                    Add Item
                                </a-button> 
                            </div>
                        </div>
                    </a-form>
                    <a-divider/>
                    <div style="margin-left:10px;margin-top:10px;display: flex;">
                        <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">About Footer Text1</div>
                        <div style="display:flex;width: 100%;">
                            <a-textarea v-model="aboutFooterText1" placeholder="Enter Footer Text" :auto-size="{ minRows: 6, maxRows: 20 }" />
                        </div>
                    </div>
                    <div style="margin-left:10px;margin-top:10px;display: flex;">
                        <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">About Footer Text2</div>
                        <div style="display:flex;width: 100%;">
                            <a-textarea v-model="aboutFooterText2" placeholder="Enter Footer Text" :auto-size="{ minRows: 6, maxRows: 20 }" />
                        </div>
                    </div>
                    <div style="margin-top:10px">
                        <a-button class="editable-add-btn" style="margin-left:10px" @click="saveAboutPageData" type="primary">
                            Save About Page
                        </a-button>
                    </div>
                </div>
            </a-collapse-panel>
            <a-collapse-panel key="6" header="Contact Page Customize">
                <div>
                    <div style="margin-left:10px;margin-top:10px;display: flex;">
                        <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">Contact Main Title</div>
                        <div style="display:flex;width: 100%;">
                            <a-textarea v-model="contactMainTitle" placeholder="Enter Main Title" :auto-size="{ minRows: 3, maxRows: 5 }" />
                        </div>
                    </div>
                    <div style="margin-left:10px;margin-top:10px;display: flex;">
                        <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">Contact Sub Title</div>
                        <div style="display:flex;width: 100%;">
                            <a-textarea v-model="contactSubTitle" placeholder="Enter Sub Title" :auto-size="{ minRows: 3, maxRows: 5 }" />
                        </div>
                    </div>
                    <div style="display:flex">
                        <div style="margin-left: auto;margin-right: auto;">
                            <div style="text-align:left;margin-top:15px;margin-left:5px;text-align:center">Contact Background Image</div>
                            <a-divider/>
                            <div style="text-align:left;margin-bottom:5px;margin-left:5px">
                                <div v-if="!isBackgroundSelected">
                                    <div style="width:200px;height:200px;border: 1px #EEEEEE solid;border-radius: 5px;display: flex;">
                                        <div style="margin:auto">No image selected</div>
                                    </div>
                                    <div style="display:flex;width:100%">
                                     <a-button style="margin-left:auto;margin-right:auto;margin-top:10px" @click="showBackgroundImage(true)" :size="'small'">Add Background Image</a-button>
                                    </div>
                                </div>
                                <div v-if="isBackgroundSelected" style="position:relative;width:200px;height:200px">
                                    <img :src="`${selectedBackgroundImage}`" alt="" width="200px" height="200px">
                                    <a-icon @click="cancelBackgroundImage()" style="position:absolute;top:5px;right:5px" type="close-circle" theme="filled" />
                                    <a-icon @click="showBackgroundImage(true)" style="position:absolute;top:5px;right:25px" type="edit" theme="filled" />
                                </div>
                            </div>
                            <a-modal
                                v-model="visibleBackgroundImage"
                                title="Add Contact Backgroun Image"
                                :footer="null"
                                width="80%"
                            >
                                <AwsFileManager v-if="visibleBackgroundImage" :ismulti="false" @close="showBackgroundImage(false)" @selectImages="selectBackgroundImage"/>
                            </a-modal>
                        </div>
                    </div>
                    <div style="margin-top:10px">
                        <a-button class="editable-add-btn" style="margin-left:10px" @click="saveContactPageData" type="primary">
                            Save Contact Page
                        </a-button>
                    </div>
                </div>
            </a-collapse-panel> -->

        </a-collapse>
    </div>
</template>
<script>
import { api } from '../helpers/Helpers';
import AwsFileManager from '../components/AwsFileManager.vue';

var moment = require('moment-timezone');

const TABLESOCIAL = 'tableSocial'
const TABLEABOUT = 'tableAbout'
const TABLEBESTHOTEL = 'tableBestHotel'
const TABLEABOUTPAGE = 'tableAboutPage'
const TABLERESORTSTYLE = 'tableResortStyle'
const TABLERESORTTYPETRAVEL = 'tableResortTypeTravel'

const socialList = [];
const aboutList = [];
const bestHotelList = [];
const resortStyleList = [];
const resortTypeTravelList = [];
const columnsSocial = [
    {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        scopedSlots: { customRender: 'name' },
    },
    {
        title: 'Icon',
        dataIndex: 'icon',
        key: 'icon',
        width: 200,
        scopedSlots: { customRender: 'icon' },
    },
    {
        title: 'Link',
        dataIndex: 'link',
        key: 'link',
        width: 200,
        scopedSlots: { customRender: 'link' },
    },
    {
        title: 'operation',
        dataIndex: 'operation',
        key: 'operation',
        width: 200,
        scopedSlots: { customRender: 'operation' },
    },
];
const columnsResortStyle = [
    {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        scopedSlots: { customRender: 'name' },
    },
    {
        title: 'operation',
        dataIndex: 'operation',
        key: 'operation',
        width: 200,
        scopedSlots: { customRender: 'operation' },
    },
];
const columnsResortTypeTravel = [
    {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        scopedSlots: { customRender: 'name' },
    },
    {
        title: 'operation',
        dataIndex: 'operation',
        key: 'operation',
        width: 200,
        scopedSlots: { customRender: 'operation' },
    },
];
const columnsAbout = [
    {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        scopedSlots: { customRender: 'name' },
    },
    {
        title: 'Icon',
        dataIndex: 'icon',
        key: 'icon',
        width: 200,
        scopedSlots: { customRender: 'icon' },
    },
    {
        title: 'Link',
        dataIndex: 'link',
        key: 'link',
        width: 200,
        scopedSlots: { customRender: 'link' },
    },
    {
        title: 'Detail',
        dataIndex: 'detail',
        key: 'detail',
        width: 200,
        scopedSlots: { customRender: 'detail' },
    },
    {
        title: 'operation',
        dataIndex: 'operation',
        key: 'operation',
        width: 200,
        scopedSlots: { customRender: 'operation' },
    },
];

const columnsBestHotel = [
    {
        title: 'Hotel',
        dataIndex: 'hotelid',
        key: 'hotelid',
        width: 150,
        scopedSlots: { customRender: 'hotelid' },
    },
    {
        title: 'Start Date',
        dataIndex: 'startdate',
        key: 'startdate',
        width: 150,
        scopedSlots: { customRender: 'startdate' },
    },
    {
        title: 'End Date',
        dataIndex: 'enddate',
        key: 'enddate',
        width: 150,
        scopedSlots: { customRender: 'enddate' },
    },
    {
        title: 'New Price',
        dataIndex: 'shownewprice',
        key: 'shownewprice',
        width: 150,
        scopedSlots: { customRender: 'shownewprice' },
    },
    {
        title: 'Old Price',
        dataIndex: 'showoldprice',
        key: 'showoldprice',
        width: 150,
        scopedSlots: { customRender: 'showoldprice' },
    },
    {
        title: 'Custom url',
        dataIndex: 'url',
        key: 'url',
        width: 200,
        scopedSlots: { customRender: 'url' },
    },
    {
        title: 'Per Nights',
        dataIndex: 'priceper',
        key: 'priceper',
        width: 150,
        scopedSlots: { customRender: 'priceper' },
    },
    {
        title: 'Features',
        dataIndex: 'features',
        key: 'features',
        width: 150,
        scopedSlots: { customRender: 'features' },
    },
    {
        title: 'operation',
        dataIndex: 'operation',
        key: 'operation',
        width: 100,
        scopedSlots: { customRender: 'operation' },
    },
];

const featuresList = [
    'Ocean view',
    'Free wifi',
    'Unlimited dining & unlimited beverages',
    'Romance',
    'Family',
    'Friends getaway',
    'Solo traveler',
    'Foodies',
    'Spa & wellness',
    'Wellness retreat',
    'All-inclusive',
    'Best for diving',
    'Great house reef',
    'Snorkeling',
    'Quick airport transfer',
    'Underwater dining',
    'Overwater villas only',
    'Adults-only resort',
    'Best for surfing',
    'Remote and secluded',
];
const pricePerList = [
    '1 night from',
    '2 nights from',
    '3 nights from',
    '4 nights from',
    '5 nights from',
    '6 nights from',
    '7 nights from'
];
const hotelList = '';

const columnsAboutPage = [
    {
        title: 'Main Name',
        dataIndex: 'mainname',
        key: 'mainname',
        width: 200,
        scopedSlots: { customRender: 'mainname' },
    },
    {
        title: 'Sub Name',
        dataIndex: 'subname',
        key: 'subname',
        width: 200,
        scopedSlots: { customRender: 'subname' },
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 200,
        scopedSlots: { customRender: 'description' },
    },
    {
        title: 'operation',
        dataIndex: 'operation',
        key: 'operation',
        width: 100,
        scopedSlots: { customRender: 'operation' },
    },
];

export default {
    components: { AwsFileManager },

    data() {
        return {

            TABLESOCIAL,

            dashboardSocialForm: this.$form.createForm(this),
            visibleImage: false,
            visibleEditModal: false,
            editFeatureIndex: 0,
            EditModalArray: [],
            selectedImage: '',
            isSelected: false,

            logoname: '',

            socialList,
            columnsSocial,
            visibleSocialImage: false,
            selitemSocial: 0,
            selectedSocialImage: [],
            isSocialSelected: [],

            TABLEABOUT,
            dashboardAboutForm: this.$form.createForm(this),
            aboutList,
            columnsAbout,
            visibleAboutImage: false,
            selitemAbout: 0,
            selectedAboutImage: [],
            isAboutSelected: [],

            bannerText: '',

            TABLEBESTHOTEL,
            dashboardBestHotelForm: this.$form.createForm(this),
            bestHotelList,
            columnsBestHotel,

            pricePerList,
            hotelList,

            featuresList,

            aboutSubTitle: '',
            aboutMainTitle: '',
            aboutFooterText1: '',
            aboutFooterText2: '',
            columnsAboutPage,
            TABLEABOUTPAGE,
            dashboardAboutPageForm: this.$form.createForm(this),
            aboutPageInfoList: [],


            dashboardResortStyleForm: this.$form.createForm(this),
            columnsResortStyle,
            resortStyleList,
            TABLERESORTSTYLE,



            dashboardResortTypeTravelForm: this.$form.createForm(this),
            columnsResortTypeTravel,
            resortTypeTravelList,
            TABLERESORTTYPETRAVEL,


            contactMainTitle: '',
            contactSubTitle: '',
            isBackgroundSelected: false,
            visibleBackgroundImage: false,
            selectedBackgroundImage: '',

            roomCellWidth: 'calc(900px + 50%)',
            // dateFormat : (val) => {
            //     return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
            // },

            visibleBackgrounImages: false,
            backgroundImages: [],

            bestStartDate: [],
            bestEndDate: [],
        }
    },
    created() {

        var mynumber = 20;
        this.selectedSocialImage = new Array(mynumber);
        for (var i = 0; i < mynumber; i++) {
            this.selectedSocialImage[i] = '';
        }
        this.isSocialSelected = new Array(mynumber);
        for (var i = 0; i < mynumber; i++) {
            this.isSocialSelected[i] = false;
        }
        this.selectedAboutImage = new Array(mynumber);
        for (var i = 0; i < mynumber; i++) {
            this.selectedAboutImage[i] = '';
        }
        this.isAboutSelected = new Array(mynumber);
        for (var i = 0; i < mynumber; i++) {
            this.isAboutSelected[i] = false;
        }
        this.getLogo();
        this.getBackgroundImages();
        this.getSocialIcons();
        this.getAboutInfos();
        this.getHotels();
        this.getBestHotelInfos();
        this.getAboutPageInfos();
        this.getContactPageInfos();
        this.getResortStyles();
        this.getResortTypeTravels();

    },
    methods: {
        moment,
        getHotels: async function () {
            const res = await api.getHotels();
            // console.log(res);
            this.hotelList = res;
        },

        changeValue(index, evt) {
            // console.log('evt===', evt.target.value)
            // console.log('index===', this.EditModalArray[index])
            this.EditModalArray[index] = evt.target.value
        },
        showBackgrounImages(_isShow) {
            this.visibleBackgrounImages = _isShow;
        },

        selectBackgrounImages(_images) {
            // console.log(_images);

            for (let i = 0; i < _images.length; i++) {
                if (!this.backgroundImages.includes(_images[i]))
                    this.backgroundImages.push(_images[i]);
            }

            this.visibleBackgrounImages = false;
        },

        cancelBackgroundImage(_id) {
            // console.log(_id);

            var _index = this.backgroundImages.indexOf(_id);
            this.backgroundImages.splice(_index, 1);
        },

        saveBackgroundImages: async function () {
            if (this.backgroundImages.length > 0) {
                const _array = {
                    datalist: this.backgroundImages
                }
                const res = await api.setBackgroundImages(_array);
                // console.log(res);

                if (res != undefined)
                    this.$message.success('Saving Success!');
            }

        },


        showImage(_isShow) {
            this.visibleImage = _isShow;
        },

        selectImage(_images) {
            if (_images.length > 0) {
                this.selectedImage = _images[0];
                this.isSelected = true;
            }
            this.visibleImage = false;
        },

        cancelImage(_index) {
            this.selectedImage = '';
            this.isSelected = false
        },

        saveLogo: async function () {
            const _info = {
                logoimage: this.selectedImage,
                logoname: this.logoname
            }
            const res = await api.setLogoInfo(_info);
            // console.log(res);
            if (res != undefined && res != "") {
                this.$message.success('Logo Creating Success!');
            }
            else {
                this.$message.error('Logo Creating Failed!');
            }
        },
        getLogo: async function () {
            const res = await api.getLogoInfo();
            // console.log(res);
            if (res.length > 0) {
                this.selectedImage = res[0].logoimage;
                this.logoname = res[0].logoname;
                this.isSelected = true;
            }
        },
        getSocialIcons: async function () {
            const res = await api.getSocialInfo();
            // console.log(res);
            if (res.length > 0) {
                this.socialList = res[0].datalist;
                for (let i = 0; i < this.socialList.length; i++) {
                    this.selectedSocialImage.splice(i, 1, this.socialList[i].icon);
                    if (this.socialList[i].icon != '' && this.socialList[i].icon != undefined)
                        this.isSocialSelected.splice(i, 1, true);
                }
                // console.log(this.socialList);
            }
        },
        getBackgroundImages: async function () {
            const res = await api.getBackgroundImages();
            // console.log("getBackgroundImages == ", res);
            if (res != undefined && res.length > 0) {
                this.backgroundImages = res[0].datalist;
            }
        },
        getAboutInfos: async function () {
            const res = await api.getAboutInfo();
            // console.log(res);
            if (res.length > 0) {
                this.aboutList = res[0].datalist;
                for (let i = 0; i < this.aboutList.length; i++) {
                    this.selectedAboutImage.splice(i, 1, this.aboutList[i].icon);
                    if (this.aboutList[i].icon != '' && this.aboutList[i].icon != undefined)
                        this.isAboutSelected.splice(i, 1, true);
                }
                this.bannerText = res[0].banner;
            }
        },
        getBestHotelInfos: async function () {
            const res = await api.getBestHotelInfo();
            // console.log("getBestHotelInfo--", res);
            if (res.length > 0) {
                this.bestHotelList = res[0].datalist;
                for (let i = 0; i < this.bestHotelList.length; i++) {
                    this.bestStartDate.push(this.bestHotelList[i].startdate);
                    this.bestEndDate.push(this.bestHotelList[i].enddate);
                }
            }
            // console.log("this.bestStartDate--", this.bestStartDate);
            // this.updateBestOffers = !this.updateBestOffers;
        },
        getAboutPageInfos: async function () {
            const res = await api.getAboutPageInfo();
            // console.log(res);
            if (res.length > 0) {
                this.aboutSubTitle = res[0].aboutSubTitle;
                this.aboutMainTitle = res[0].aboutMainTitle;
                this.aboutFooterText1 = res[0].aboutFooterText1;
                this.aboutFooterText2 = res[0].aboutFooterText2;
                this.aboutPageInfoList = res[0].datalist;
            }
        },
        getContactPageInfos: async function () {
            const res = await api.getContactPageInfo();
            // console.log(res);
            if (res != undefined && res.length > 0) {
                this.contactMainTitle = res[0].contactMainTitle;
                this.contactSubTitle = res[0].contactSubTitle;
                this.selectedBackgroundImage = res[0].backgroundImage;
                this.isBackgroundSelected = true;
            }
        },
        getResortStyles: async function () {
            const res = await api.getResortStyleInfo();
            // console.log(res);
            if (res.length > 0) {
                this.resortStyleList = res[0].datalist;
                // console.log(this.resortStyleList);
            }
        },
        getResortTypeTravels: async function () {
            const res = await api.getResortTypeTravelInfo();
            // console.log(res);
            if (res.length > 0) {
                this.resortTypeTravelList = res[0].datalist;
                // console.log(this.resortTypeTravelList);
            }
        },

        addData() {
            const newData = {
                key: this.socialList.length,
                name: '',
                link: '',
                icon: ''
            };
            this.socialList.push(newData);
        },

        addAboutData() {
            const newData = {
                key: this.aboutList.length,
                name: '',
                link: '',
                icon: '',
                detail: ''
            };
            this.aboutList.push(newData);
        },

        addBestHotelData() {
            const newData = {
                key: this.bestHotelList.length,
                hotelid: '',
                startdate: null,
                enddate: null,
                features: [],
                shownewprice: 0,
                showoldprice: 0,
                priceper: '',
                url: '',
            };
            this.bestHotelList.push(newData);
            // // console.log("bestHotelList: ",this.bestHotelList)
        },
        addResortStyleData() {
            const newData = {
                key: this.resortStyleList.length,
                name: '',
            };
            this.resortStyleList.push(newData);
        },
        addResortTypeTravelData() {
            const newData = {
                key: this.resortTypeTravelList.length,
                name: '',
            };
            this.resortTypeTravelList.push(newData);
        },

        DeleteOne(_key) {
            let objIndex = this.socialList.findIndex((obj => obj.key == _key));
            this.socialList.splice(objIndex, 1);
            this.updateSocialList();
        },
        DeleteAboutOne(_key) {
            let objIndex = this.aboutList.findIndex((obj => obj.key == _key));
            this.aboutList.splice(objIndex, 1);
            this.updateAboutList();
        },
        DeleteBestHotelOne(_key) {
            let objIndex = this.bestHotelList.findIndex((obj => obj.key == _key));
            this.bestHotelList.splice(objIndex, 1);
            this.updateBestHotelList();
        },
        EditBestHotelOne(_key) {
            let objIndex = this.bestHotelList.findIndex((obj => obj.key == _key));
            const { dashboardBestHotelForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    if (values[`${TABLEBESTHOTEL}hotelid`] != undefined) {
                        this.EditModalArray = values[`${TABLEBESTHOTEL}features`][objIndex];
                        // console.log(values[`${TABLEBESTHOTEL}edithotelid`])
                    }
                }
            });

            this.editFeatureIndex = objIndex;
            this.visibleEditModal = true
        },
        editBestHotelFeature() {
            const { dashboardBestHotelForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    if (values[`${TABLEBESTHOTEL}hotelid`] != undefined) {
                        // values[`${TABLEBESTHOTEL}features`][this.editFeatureIndex][0] = "test";
                    }
                }
            });
            this.saveBestHotelData()
            this.visibleEditModal = false;
        },

        DeleteOneResortStyle(_key) {
            let objIndex = this.resortStyleList.findIndex((obj => obj.key == _key));
            this.resortStyleList.splice(objIndex, 1);
            this.updateResortStyleList();
        },
        DeleteOneResortTypeTravel(_key) {
            let objIndex = this.resortTypeTravelList.findIndex((obj => obj.key == _key));
            this.resortTypeTravelList.splice(objIndex, 1);
            this.updateResortTypeTravelList();
        },
        updateResortTypeTravelList() {
            for (let i = 0; i < this.resortTypeTravelList.length; i++) {
                this.resortTypeTravelList[i].key = i;
            }
        },

        updateSocialList() {
            for (let i = 0; i < this.socialList.length; i++) {
                this.socialList[i].key = i;
            }
        },
        updateAboutList() {
            for (let i = 0; i < this.aboutList.length; i++) {
                this.aboutList[i].key = i;
            }
        },
        updateBestHotelList() {
            for (let i = 0; i < this.bestHotelList.length; i++) {
                this.bestHotelList[i].key = i;
            }
        },
        updateResortStyleList() {
            for (let i = 0; i < this.resortStyleList.length; i++) {
                this.resortStyleList[i].key = i;
            }
        },


        showSocialImage(_isShow, _index) {
            this.visibleSocialImage = _isShow;
            if (_isShow)
                this.selitemSocial = _index;
            else
                this.selitemSocial = 0;
        },
        showAboutImage(_isShow, _index) {
            this.visibleAboutImage = _isShow;
            if (_isShow)
                this.selitemAbout = _index;
            else
                this.selitemAbout = 0;
        },
        selectSocialImage(_images, _index) {
            if (_images.length > 0) {
                this.selectedSocialImage[_index] = _images[0];
                this.isSocialSelected[_index] = true;
            }
            // console.log(this.selectedSocialImage, _index)
            this.visibleSocialImage = false;
        },
        selectAboutImage(_images, _index) {
            if (_images.length > 0) {
                this.selectedAboutImage[_index] = _images[0];
                this.isAboutSelected[_index] = true;
            }
            // console.log(this.selectedAboutImage, _index)
            this.visibleAboutImage = false;
        },
        cancelSocialImage(_index) {
            this.selectedSocialImage[_index] = '';
            this.isSocialSelected.splice(_index, 1, false)
        },
        cancelAboutImage(_index) {
            this.selectedAboutImage[_index] = '';
            this.isAboutSelected.splice(_index, 1, false)
        },
        saveSocialData() {
            const { dashboardSocialForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    let partOneArr = [];
                    if (values[`${TABLESOCIAL}name`] != undefined) {
                        (values[`${TABLESOCIAL}name`]).forEach((item, index) => {
                            // console.log(item)
                            // console.log(index)
                            const obj = {
                                name: item,
                                link: values[`${TABLESOCIAL}link`][index],
                                key: index,
                                icon: this.selectedSocialImage[index]
                            }
                            partOneArr.push(obj)
                        })
                    }
                    // console.log(partOneArr)
                    const lastInfo = {
                        datalist: partOneArr
                    }
                    this.sendSocialData(lastInfo)
                    // this.arrAdultChildNumber = partOneArr;
                }
            });
        },
        sendSocialData: async function (_array) {

            const res = await api.setSocialInfo(_array);
            // console.log(res);
            if (res != undefined)
                this.$message.success('Saving Success!');
        },


        saveResortStyleData() {
            const { dashboardResortStyleForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    let partOneArr = [];
                    if (values[`${TABLERESORTSTYLE}name`] != undefined) {
                        (values[`${TABLERESORTSTYLE}name`]).forEach((item, index) => {
                            // console.log(item)
                            // console.log(index)
                            const obj = {
                                name: item,
                                key: index
                            }
                            partOneArr.push(obj)
                        })
                    }
                    // console.log(partOneArr)
                    const lastInfo = {
                        datalist: partOneArr
                    }
                    this.sendResortStyleData(lastInfo)
                    // this.arrAdultChildNumber = partOneArr;
                }
            });
        },
        sendResortStyleData: async function (_array) {

            const res = await api.setResortStyleInfo(_array);
            // console.log(res);
            if (res != undefined)
                this.$message.success('Saving Success!');
        },

        saveResortTypeTravelData() {
            const { dashboardResortTypeTravelForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    let partOneArr = [];
                    if (values[`${TABLERESORTTYPETRAVEL}name`] != undefined) {
                        (values[`${TABLERESORTTYPETRAVEL}name`]).forEach((item, index) => {
                            // console.log(item)
                            // console.log(index)
                            const obj = {
                                name: item,
                                key: index
                            }
                            partOneArr.push(obj)
                        })
                    }
                    // console.log(partOneArr)
                    const lastInfo = {
                        datalist: partOneArr
                    }
                    this.sendResortTypeTravelData(lastInfo)
                    // this.arrAdultChildNumber = partOneArr;
                }
            });
        },
        sendResortTypeTravelData: async function (_array) {

            const res = await api.setResortTypeTravelInfo(_array);
            // console.log(res);
            if (res != undefined)
                this.$message.success('Saving Success!');
        },

        saveAboutData() {
            const { dashboardAboutForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    let partOneArr = [];
                    if (values[`${TABLEABOUT}name`] != undefined) {
                        (values[`${TABLEABOUT}name`]).forEach((item, index) => {
                            // console.log(item)
                            // console.log(index)
                            const obj = {
                                name: item,
                                link: values[`${TABLEABOUT}link`][index],
                                detail: values[`${TABLEABOUT}detail`][index],
                                key: index,
                                icon: this.selectedAboutImage[index],
                            }
                            partOneArr.push(obj)
                        })
                    }
                    // console.log(partOneArr)
                    const lastInfo = {
                        banner: this.bannerText,
                        datalist: partOneArr
                    }
                    this.sendAboutData(lastInfo);
                    // this.arrAdultChildNumber = partOneArr;
                }
            });
        },
        sendAboutData: async function (_array) {
            const res = await api.setAboutInfo(_array);
            // console.log(res);
            if (res != undefined)
                this.$message.success('Saving Success!');
        },
        saveBestHotelData() {
            const { dashboardBestHotelForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    let partOneArr = [];
                    if (values[`${TABLEBESTHOTEL}hotelid`] != undefined) {
                        (values[`${TABLEBESTHOTEL}hotelid`]).forEach((item, index) => {
                            const obj = {
                                hotelid: item,
                                startdate: moment.utc(values[`${TABLEBESTHOTEL}startdate`][index]).tz("America/Los_Angeles"),//moment.utc(this.bestStartDate[index]).tz("America/Los_Angeles"),//values[`${TABLEBESTHOTEL}startdate`][index],
                                enddate: moment.utc(values[`${TABLEBESTHOTEL}enddate`][index]).tz("America/Los_Angeles"),//moment.utc(this.bestEndDate[index]).tz("America/Los_Angeles"),//values[`${TABLEBESTHOTEL}enddate`][index],
                                shownewprice: values[`${TABLEBESTHOTEL}shownewprice`][index],
                                showoldprice: values[`${TABLEBESTHOTEL}showoldprice`][index],
                                features: values[`${TABLEBESTHOTEL}features`][index],
                                priceper: values[`${TABLEBESTHOTEL}priceper`][index],
                                url: values[`${TABLEBESTHOTEL}url`][index],
                                key: index,
                            }
                            partOneArr.push(obj)
                        })
                    }
                    // console.log(partOneArr)
                    const lastInfo = {
                        datalist: partOneArr
                    }
                    this.sendBestHotelData(lastInfo)
                    // this.arrAdultChildNumber = partOneArr;
                }
            });
        },
        sendBestHotelData: async function (_array) {

            const res = await api.setBestHotelInfo(_array);
            // console.log(res)
            if (res != undefined)
                this.$message.success('Saving Success!');
        },


        addAboutPageData() {
            const newData = {
                key: this.aboutPageInfoList.length,
                mainname: '',
                subname: '',
                description: '',
            };
            this.aboutPageInfoList.push(newData);
        },
        DeleteAboutPageOne(_key) {
            let objIndex = this.aboutPageInfoList.findIndex((obj => obj.key == _key));
            this.aboutPageInfoList.splice(objIndex, 1);
            this.updateAboutPageList();
        },
        updateAboutPageList() {
            for (let i = 0; i < this.aboutList.length; i++) {
                this.aboutPageInfoList[i].key = i;
            }
        },

        saveAboutPageData() {
            const { dashboardAboutPageForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    let partOneArr = [];
                    if (values[`${TABLEABOUTPAGE}mainname`] != undefined) {
                        (values[`${TABLEABOUTPAGE}mainname`]).forEach((item, index) => {
                            // console.log(item)
                            // console.log(index)
                            const obj = {
                                mainname: item,
                                subname: values[`${TABLEABOUTPAGE}subname`][index],
                                description: values[`${TABLEABOUTPAGE}description`][index],
                                key: index,
                            }
                            partOneArr.push(obj)
                        })
                    }
                    // console.log(partOneArr)
                    const lastInfo = {
                        aboutSubTitle: this.aboutSubTitle,
                        aboutMainTitle: this.aboutMainTitle,
                        datalist: partOneArr,
                        aboutFooterText1: this.aboutFooterText1,
                        aboutFooterText2: this.aboutFooterText2,
                    }
                    this.sendAboutPageData(lastInfo)
                    // this.arrAdultChildNumber = partOneArr;
                }
            });
        },
        sendAboutPageData: async function (_array) {
            const res = await api.setAboutPageInfo(_array);
            // console.log(res)
        },


        showBackgroundImage(_isShow) {
            this.visibleBackgroundImage = _isShow;
        },

        selectBackgroundImage(_images) {
            if (_images.length > 0) {
                this.selectedBackgroundImage = _images[0];
                this.isBackgroundSelected = true;
            }
            this.visibleBackgroundImage = false;
        },

        // cancelBackgroundImage(_index){
        //     this.selectedBackgroundImage = '';
        //     this.isBackgroundSelected = false
        // },
        saveContactPageData: async function () {
            const lastInfo = {
                contactMainTitle: this.contactMainTitle,
                contactSubTitle: this.contactSubTitle,
                backgroundImage: this.selectedBackgroundImage,
            }
            const res = await api.setContactPageInfo(lastInfo);
            // console.log(res)
        },


    }
}
</script>