<template>
  <div>
    <a-list :grid="{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }" :data-source="tempAttachments">
        <a-list-item slot="renderItem" slot-scope="item">
          <div style="border: 1px solid #E7E7E7;position:relative">
            <img :src="`${item}`" alt="" width="100%" height="200px">
            <a-button style="position:absolute;top:0;right:0;background-color:transparent;color:white" type="solid" icon="close" @click="cancelImage(item)" :size="'small'"></a-button>
          </div>
        </a-list-item>
      </a-list>
  </div>
</template>

<script>
export default {
  name: "AttachmentList",
  methods: {
    checkProgress(attachment) {
      return attachment.progress === null ? false : true;
    },
    cancelImage(item){
      this.$emit('cancelImage', item);
    }
  },
  props: {
    tempAttachments: {
      type: Array
    }
  }
};
</script>
<style>
/* ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
}
li {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 16px;
  width: 100%;
  text-align: left;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(3, 22, 41, 0.11),
    0 6px 16px 0 rgba(3, 22, 41, 0.08);
  border-radius: 6px;
} */
.file-name {
  min-width: 150px;
  font-size: 16px;
}
.file-name p {
  margin: 0;
}
.uploaded-date {
  font-size: 12px;
  font-weight: 300;
}
.upload-progress {
  font-size: 12px;
  font-weight: 300;
}
.file-details {
  flex: 1;
}
.file-info {
  display: flex;
  justify-content: space-between;
  margin: 0px;
}
.close {
  position: absolute;
  margin: 0px;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  cursor: pointer;
}
.close:hover g {
  stroke: #41b883;
}
.icon {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
</style>
