<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:100%;border:1px solid #dddddd;border-right:none;font-size: 14px;line-height:28px;">Price Info</div>
                <!-- <div style="width:50%;border:1px solid #dddddd;border-right:none;font-size: 14px;line-height:28px;">Price Value</div> -->
                <div style="width:30px;border:1px solid #dddddd;">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <div style="margin-top:0px;background-color:#f8f8f8;border:1px solid #dddddd;border-top:none" v-for="item in keysListMealPrice" :key="item">
                <a-row :gutter="0" style="width:100%;">
                    <div style="display:flex;">
                    
                      <div style="width:100%;">
                        <div style="display:flex;width:100%;background-color:white;">
                          <div style="width:50%;border:1px solid #dddddd;border-left:none;border-top:none;font-size: 14px;line-height:28px;">Price Title</div>
                          <div style="width:50%;border:1px solid #dddddd;border-left:none;border-top:none;font-size: 14px;line-height:28px;">Price Value</div>
                        </div>

                        <div style="display:flex;width:100%;">
                            <a-form-item style="width:50%;background-color:white;margin-top:auto;margin-bottom:auto;border:1px solid #dddddd;border-top:none;border-left:none;padding:10px" >
                                <a-input style="width:100%;margin-top:auto;margin-bottom:auto" placeholder=""
                                    v-decorator="[
                                        `${title}Pricetitle[${item}]`,
                                        {
                                            initialValue: arr[item] ? arr[item].pricetitle : undefined,
                                            rules: [{ required: true, message: 'Input pricetitle!' }]
                                        }
                                    ]"
                                />
                            </a-form-item>
                            <a-form-item style="width:50%;background-color:white;margin-top:auto;margin-bottom:auto;border:1px solid #dddddd;border-top:none;border-left:none;padding:10px" >
                                <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto" :min="0" :max="100000" placeholder=""
                                    v-decorator="[
                                        `${title}Pricevalue[${item}]`,
                                        {
                                            initialValue: arr[item] ? arr[item].pricevalue : undefined,
                                            rules: [{ required: false, message: 'pricevalue!' }]
                                        }
                                    ]"
                                />
                            </a-form-item>
                        </div>
                        
                        <div style="display:flex;width:100%;background-color:white;">
                          <div style="width:50%;border:1px solid #dddddd;border-left:none;border-top:none;font-size: 14px;line-height:28px;">Age From</div>
                          <div style="width:50%;border:1px solid #dddddd;border-left:none;border-top:none;font-size: 14px;line-height:28px;">Age To<Tbody></Tbody></div>
                        </div>
                        <div style="display:flex;">
                            <a-form-item style="width:50%;background-color:white;margin-top:auto;margin-bottom:auto;border:1px solid #dddddd;border-top:none;border-bottom:none;border-left:none;padding:10px">
                                <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto" :min="0" :max="100000"  
                                        v-decorator="[
                                            `${title}Childagerangefrom[${item}]`,
                                            {
                                                initialValue: arr[item] ? arr[item].agefrom : undefined,
                                                rules: [{ required: false, message: 'childagerangefrom !' }]
                                            }
                                        ]"
                                    />
                            </a-form-item>
                            <a-form-item style="width:50%;background-color:white;margin-top:auto;margin-bottom:auto;border:1px solid #dddddd;border-top:none;border-bottom:none;border-left:none;padding:10px">
                                <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto" :min="0" :max="100000"  
                                        v-decorator="[
                                            `${title}Childagerangeto[${item}]`,
                                            {
                                                initialValue: arr[item] ? arr[item].ageto : undefined,
                                                rules: [{ required: false, message: 'childagerangeto !' }]
                                            }
                                        ]"
                                    />
                            </a-form-item>
                        </div>
                      </div>

                        <div style="margin-top:auto;margin-bottom:auto;width:30px">
                            <a-button shape="circle" type="dashed" icon="minus" @click="removeRowMealPrice(item)" class="minusRowBtn" :size="'small'"></a-button>
                        </div>
                    </div>
                </a-row>
            </div>
            
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowMealPrice" type="primary" :size="'small'">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
      
  },
  data () {
    return {
      idMealPrice: 0,
      keysListMealPrice: [],
      
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    this.init()
  },
  methods: {
    init () {
      const arr = []
      
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idMealPrice = 0;
        for (let i = 0; i < (this.arr).length; i++) {
          arr.push(i)
          this.idMealPrice = this.idMealPrice + 1
        }
      }
      this.keysListMealPrice = arr
    },



    removeRowMealPrice (k) {
      if (this.keysListMealPrice.length === 0) { 
        return
      }
      this.keysListMealPrice = this.keysListMealPrice.filter(item => item !== k)
    },
    addRowMealPrice () {
      this.keysListMealPrice = this.keysListMealPrice.concat(this.idMealPrice)
      this.idMealPrice = this.idMealPrice + 1
    },
  }
}
</script>