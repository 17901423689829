<template> 

  <div style="width:25%">
      <div style="width:100%;height:auto;margin:0;background-color:white;border:1px solid #ddd;border-top:none;border-right:none;border-bottom:none;padding:5px;" v-for="item in keysListTransferPrice" :key="item">  
        <a-row>
          <div style="display: flex;margin:0;border:1px solid #ddd">
            <div style="width:50%;height:auto;margin:0;background-color:white;border-right:1px solid #ddd;padding:0px;padding-top:10px" >
              <div style="font-weight:600;text-align:center;padding:0">night</div>
              <a-divider />
              <div style="font-weight:600;text-align:left;padding-top:10px;padding-left:10px">min</div>
              <a-form-item style="width:100%;margin-top:auto;margin-bottom:auto;padding:10px;padding-top:0px;padding-bottom:0px">
                  <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto" :min="0" :max="100000" placeholder="min night"
                      v-decorator="[
                          `${title}Minnight${id}[${item}]`,
                          {
                              initialValue: arr.price!=undefined && arr.price.transfer != undefined && arr.price.transfer[item] != undefined && arr.price.transfer[item].min != undefined ? arr.price.transfer[item].min: 0,
                              rules: [{ required: false, message: 'minnight !' }]
                          }
                      ]"
                  />
              </a-form-item> 
              <div style="font-weight:600;text-align:left;padding-top:10px;padding-left:10px">max</div>
              <a-form-item style="width:100%;margin-top:auto;margin-bottom:auto;padding:10px;padding-top:0px;padding-bottom:0px">
                  <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto" :min="0" :max="100000" placeholder="max night"
                      v-decorator="[
                          `${title}Maxnight${id}[${item}]`,
                          {
                              initialValue: arr.price!=undefined && arr.price.transfer != undefined && arr.price.transfer[item] != undefined && arr.price.transfer[item].max != undefined ? arr.price.transfer[item].max : 0,
                              rules: [{ required: false, message: 'maxnight !' }]
                          }
                      ]"
                  />
              </a-form-item>
            </div>    
            <div style="width:50%;height:auto;margin:0;background-color:white;border-right:1px solid #ddd;padding:0px;padding-top:10px;padding-bottom:10px" >
                <div style="font-weight:600;text-align:center;padding:0">price</div>
                <a-divider />
                <div style="font-weight:600;text-align:left;padding-top:10px;padding-left:10px">Adults</div>
                <a-form-item style="width:100%;margin-top:auto;margin-bottom:auto;padding:10px;padding-top:0px;padding-bottom:0px">
                    <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto" :min="0" :max="100000" placeholder="Per Adult Price"
                        v-decorator="[
                            `${title}peradultprice${id}[${item}]`,
                            {
                                initialValue: arr.price!=undefined && arr.price.transfer != undefined && arr.price.transfer[item] != undefined && arr.price.transfer[item].peradultprice != undefined ? arr.price.transfer[item].peradultprice : 0,
                                rules: [{ required: false, message: 'peradultprice !' }]
                            }
                        ]"
                    />
                </a-form-item> 
                <div style="font-weight:600;text-align:left;padding-top:10px;padding-left:10px">Children</div>
                <a-form-item style="width:100%;margin-top:auto;margin-bottom:auto;padding:10px;padding-top:0px;padding-bottom:0px">
                    <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto" :min="0" :max="100000" placeholder="Per Child Price"
                        v-decorator="[
                            `${title}perchildprice${id}[${item}]`,
                            {
                                initialValue: arr.price!=undefined && arr.price.transfer != undefined && arr.price.transfer[item] != undefined && arr.price.transfer[item].perchildprice != undefined ? arr.price.transfer[item].perchildprice : 0,
                                rules: [{ required: false, message: 'perchildprice !' }]
                            }
                        ]"
                    />
                </a-form-item>
                <div style="font-weight:600;text-align:left;padding-top:10px;padding-left:10px">Infant</div>
                <a-form-item style="width:100%;margin-top:auto;margin-bottom:auto;padding:10px;padding-top:0px;padding-bottom:0px">
                    <a-input-number style="width:100%;margin-top:auto;margin-bottom:auto" :min="0" :max="100000" placeholder="Per Child Price"
                        v-decorator="[
                            `${title}perinfantprice${id}[${item}]`,
                            {
                                initialValue: arr.price!=undefined && arr.price.transfer != undefined && arr.price.transfer[item] != undefined && arr.price.transfer[item].perinfantprice != undefined ? arr.price.transfer[item].perinfantprice : 0,
                                rules: [{ required: false, message: 'Perinfantprice !' }]
                            }
                        ]"
                    />
                </a-form-item>
            </div>  
            <div style="margin-top:auto;margin-bottom:auto;width:30px">
                <a-button shape="circle" type="dashed" icon="minus" @click="removeRowTransferPrice(item)" class="minusRowBtn" :size="'small'"></a-button>
            </div>
          </div>
        </a-row>
      </div> 
      <div style="display:flex;margin-bottom:10px;margin-right:3px">
          <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowTransferPrice" type="primary" :size="'small'">
              Add Row
          </a-button>
      </div>
  </div>
        <!-- <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:50%;border:1px solid #dddddd;font-size: 14px;line-height:28px;">Start Date</div>
                <div style="width:50%;border:1px solid #dddddd;border-left:none;font-size: 14px;line-height:28px;">End Date</div>
                <div style="width:30px;border:1px solid #dddddd;border-left:none;">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <div style="margin-top:0px;background-color:#f8f8f8;border:1px solid #dddddd;border-top:none" v-for="item in keysListTransferPrice" :key="item">
                <a-row :gutter="0" style="width:100%;">
                    <div style="display:flex;">
                        <a-form-item style="width:50%;background-color:white;margin-top:auto;margin-bottom:auto;border:1px solid #dddddd;border-top:none;border-bottom:none;border-left:none;padding:5px" >
                            <a-date-picker style="width:100%;margin-top:auto;margin-bottom:auto" placeholder=""
                                  :showToday="false"
                                  valueFormat="YYYY-MM-DD"
                                  v-decorator="[
                                      `${title}Startdate${id}[${item}]`,
                                      {
                                          initialValue: item != undefined && item.startdate != undefined ? item.startdate.toString().split('T')[0] : undefined,
                                          rules: [{ required: true, message: 'Input startdate !' }]
                                      }
                                  ]"
                                > 
                            </a-date-picker>
                        </a-form-item>
                        <a-form-item style="width:50%;background-color:white;margin-top:auto;margin-bottom:auto;border:1px solid #dddddd;border-top:none;border-bottom:none;border-left:none;padding:5px" >
                            <a-date-picker style="width:100%;margin-top:auto;margin-bottom:auto" placeholder=""
                                  :showToday="false"
                                  valueFormat="YYYY-MM-DD"
                                  v-decorator="[
                                      `${title}Enddate${id}[${item}]`,
                                      {
                                          initialValue: item != undefined && item.enddate != undefined ? item.enddate.toString().split('T')[0] : undefined,
                                          rules: [{ required: false, message: 'enddate !' }]
                                      }
                                  ]"
                                > 
                            </a-date-picker>
                        </a-form-item>
                        <div style="margin-top:auto;margin-bottom:auto;width:30px">
                            <a-button shape="circle" type="dashed" icon="minus" @click="removeRowExtraPeriod(item)" class="minusRowBtn" :size="'small'"></a-button>
                        </div>
                    </div>
                </a-row>
            </div>
            
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowExtraPeriod" type="primary" :size="'small'">
                    Add Row
                </a-button>
            </div>
        </div> -->
   
</template>
<script>
import moment from 'moment';
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    id: {
      type: Number, 
    },
    arr: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  components: {
      
  },
  data () {
    return {
      idTransferPrice: 0,
      keysListTransferPrice: [],
      
      dateFormat : (val) => {
        return moment(val).utc().format('YYYY-MM-DD')// HH:mm:ss')
      }
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    this.init()
  },
  methods: {
    moment,
    init () {
      const arr = []
      
      if (this.arr.price != undefined && this.arr.price.transfer != undefined && this.arr.price.transfer.length != 0) {
        this.idTransferPrice = 0;
        for (let i = 0; i < (this.arr.price.transfer).length; i++) {
          arr.push(i)
          this.idTransferPrice = this.idTransferPrice + 1
        }
        this.keysListTransferPrice = arr
      }
      else{
        this.keysListTransferPrice = [0]
      }
    },



    removeRowTransferPrice (k) {
      if (this.keysListTransferPrice.length === 0) { 
        return
      }
      this.keysListTransferPrice = this.keysListTransferPrice.filter(item => item !== k)
    },
    addRowTransferPrice () {
      if(this.keysListTransferPrice.length === 1 && this.idTransferPrice === 0){
        this.keysListTransferPrice = this.keysListTransferPrice.concat(1)
        this.idTransferPrice = 2
      }else{
        this.keysListTransferPrice = this.keysListTransferPrice.concat(this.idTransferPrice)
        this.idTransferPrice = this.idTransferPrice + 1
      }
    },
  }
}
</script>