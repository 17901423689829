import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Adminhome from '../views/Adminhome.vue'
import AddUser from '../views/AddUser.vue'
import EditUser from '../views/EditUser.vue'
import AdminHotels from '../views/AdminHotels.vue'
import AddHotel from '../views/AddHotel.vue'
import EditHotel from '../views/EditHotel.vue'
import AdminRooms from '../views/AdminRooms.vue'
import AddRoom from '../views/AddRoom.vue'
import EditRoom from '../views/EditRoom.vue'
import FileManager from '../views/FileManager.vue'
import HotelRoomsPrice from '../views/HotelRoomsPrice.vue'
import AdminDashboard from '../views/AdminDashboard.vue'
import AdminBooking from '../views/AdminBooking.vue'
import DetailBooking from '../views/DetailBooking.vue'
import BookingGraphChart from '../views/BookingGraphChart.vue'

import AwsFileManagerView from '../views/AwsFileManagerView.vue'

import LoginView from '../views/LoginView.vue'
import SignupView from '../views/SignupView.vue'
import ForgotView from '../views/ForgotView.vue'
import ResetPassword from '../views/ResetPassword.vue'


import AdminInspirations from '../views/AdminInspirations.vue'
import AddInspiration from '../views/AddInspiration.vue'
import EditInspiration from '../views/EditInspiration.vue'

import AdminInspirationTags from '../views/AdminInspirationTags.vue'

import AboutCustomize from '../views/AboutCustomize.vue'
import ContactCustomize from '../views/ContactCustomize.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/admin'
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/admin',
    redirect: '/admin/loginview'
  },
  {
    path: '/admin/admindashboard',
    name: 'AdminDashboard',
    component: AdminDashboard
  },
  {
    path: '/admin/bookings',
    name: 'AdminBooking',
    component: AdminBooking
  },
  {
    path: '/admin/detailbooking',
    name: 'DetailBooking',
    component: DetailBooking
  },
  {
    path: '/admin/bookinggraphchart',
    name: 'BookingGraphChart',
    component: BookingGraphChart
  },
  {
    path: '/admin/users',
    name: 'AdminHome',
    component: Adminhome
  },
  {
    path: '/admin/adduser',
    name: 'AddUser',
    component: AddUser
  },
  {
    path: '/admin/edituser',
    name: 'EditUser',
    component: EditUser
  },
  {
    path: '/admin/hotels',
    name: 'AdminHotels',
    component: AdminHotels
  },
  {
    path: '/admin/addhotel',
    name: 'AddHotel',
    component: AddHotel
  },
  {
    path: '/admin/edithotel',
    name: 'EditHotel',
    component: EditHotel
  },
  {
    path: '/admin/rooms',
    name: 'AdminRooms',
    component: AdminRooms
  },
  {
    path: '/admin/addroom',
    name: 'AddRoom',
    component: AddRoom
  },
  {
    path: '/admin/editroom',
    name: 'EditRoom',
    component: EditRoom
  },
  {
    path: '/admin/filemanager',
    name: 'FileManager',
    component: FileManager
  },
  {
    path: '/admin/hotelroomprice',
    name: 'HotelRoomsPrice',
    component: HotelRoomsPrice
  },

  {
    path: '/admin/loginview',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/admin/signupview',
    name: 'SignupView',
    component: SignupView
  },
  {
    path: '/admin/forgotview',
    name: 'ForgotView',
    component: ForgotView
  },
  {
    path: '/admin/reset-password',
    name: 'reset-password',
    component: ResetPassword
  },
  
  {
    path: '/admin/awsfilemanager',
    name: 'AwsFileManager',
    component: AwsFileManagerView
  },
  {
    path: '/admin/inspirations',
    name: 'AdminInspirations',
    component: AdminInspirations
  },
  {
    path: '/admin/inspirationtags',
    name: 'AdminInspirationTags',
    component: AdminInspirationTags
  },
  {
    path: '/admin/addinspiration',
    name: 'AddInspiration',
    component: AddInspiration
  },
  {
    path: '/admin/editinspiration',
    name: 'EditInspiration',
    component: EditInspiration
  },
  {
    path: '/admin/aboutcustomize',
    name: 'AboutCustomize',
    component: AboutCustomize
  },
  {
    path: '/admin/contactcustomize',
    name: 'ContactCustomize',
    component: ContactCustomize
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
