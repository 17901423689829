<template>
    <div style="width:100%">
        <div style="margin-top:0;margin-bottom:0;width:100%">
            <div style="display:flex;">
                <div style="width:33%;height:30px;border:1px solid #dddddd;font-size: 14px;line-height:28px;">Title</div>
                <div style="width:33%;border:1px solid #dddddd;border-left:none;font-size: 14px;line-height:28px;">Description</div>
                <div style="width:34%;border:1px solid #dddddd;border-left:none;font-size: 14px;line-height:28px;">Gallery</div>
                <div style="width:30px;border:1px solid #dddddd;border-left:none;">
                  <a-form-item style="width:10px" v-show="false">
                      <a-input-number style="width:10px" :min="0" :max="100000" placeholder="" 
                          v-decorator="[
                              `${title}SelectInput`,
                              {
                                  initialValue: 0,
                                  rules: [{ required: false, message: 'Input Adult Number!' }]
                              }
                          ]"
                      />
                  </a-form-item>
                </div>
            </div>
            <div style="margin-top:0px" v-for="item in keysListHighlightGroupphoto" :key="item">
                <a-row :gutter="0" style="width:100%;">
                    <div style="display:flex;border:1px solid #dddddd;border-top:none;background-color:#f5f5f5;">

                        <a-form-item style="width:33%;height:100%;padding:10px;background-color:white;margin-top:0px;margin-bottom:auto;height:auto;margin:0;;
                          border:1px solid #dddddd;border-left:none;border-top:none;border-bottom:none;">
                            <a-input style="width:100%;padding:2px" placeholder=""
                                  v-decorator="[
                                      `${title}Title[${item}]`,
                                      {
                                          initialValue: arr[item] ? arr[item].title : undefined,
                                          rules: [{ required: true, message: 'Input title !' }]
                                      }
                                  ]"
                              />
                        </a-form-item>
                        <a-form-item style="width:33%;height:100%;padding:10px;background-color:white;margin-top:0px;margin-bottom:auto;height:auto;margin:0;;
                          border:1px solid #dddddd;border-left:none;border-top:none;border-bottom:none;">
                            <a-textarea 
                              placeholder=""
                              :auto-size="{ minRows: 8, maxRows: 10 }"
                                  v-decorator="[
                                      `${title}Description[${item}]`,
                                      {
                                          initialValue: arr[item] ? arr[item].description : undefined,
                                          rules: [{ required: false, message: 'description !' }]
                                      }
                                  ]"
                              />
                        </a-form-item>
                        <div style="width:34%;height:100%;padding:10px;background-color:white;margin-top:0px;margin-bottom:auto;height:auto;margin:0;;
                          border:1px solid #dddddd;border-left:none;border-top:none;border-bottom:none;">
                          <a-form-item style="margin-top:0px">
                            <a-input style="width:100%;padding:0px;height:1px" v-show="false"  placeholder="" 
                                  v-decorator="[
                                      `${title}Gallary[${item}]`,
                                      {
                                          initialValue: gallaryDtr[item] ? gallaryDtr[item] : undefined,
                                          rules: [{ required: false, message: 'gallary !' }]
                                      }
                                  ]"
                              />
                          </a-form-item>
                          <div>
                            <a-list style="margin-top:10px" :grid="{ gutter: 16, column: 4 }" :data-source="gallaryImages[item]">
                                <a-list-item slot="renderItem" slot-scope="itemlist">
                                    <div style="border: 1px solid #E7E7E7;position:relative">
                                        <div style="width:100%;height:8vh">
                                            <img style="object-fit: cover;width:100%;height:100%" :src="`${itemlist}`" alt="" width="100%" height="80vh">
                                        </div>
                                        <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelImage(itemlist, item)" :size="'small'"></a-button>
                                    </div>
                                </a-list-item>
                            </a-list>

                          </div>
                            
                          
                          <a-button @click="showGallary(true, item)">Add to gallary</a-button>
                          
                          <a-modal
                            v-model="visibleGallary"
                            title="Add Gallay"
                            :footer="null"
                            width="80%"
                          >
                            <AwsFileManager v-if="visibleGallary" :rowIndex="selitem" :ismulti="true" @close="showGallary(false)" @selectImages="selectImages"/>
                            <!-- <AddGallary :ismulti="true" @close="showGallary(false)" @selectImages="selectImages"/> -->
                          </a-modal>
                        </div>
                        <div style="margin-top:auto;margin-bottom:auto;width:30px">
                            <a-button shape="circle" type="dashed" icon="minus" @click="removeRowHighlightGroupphoto(item)" class="minusRowBtn" :size="'small'"></a-button>
                        </div>
                    </div>
                </a-row>
            </div>
            
            <div style="display:flex">
                <a-button style="margin-left:auto;margin-right:0;margin-top:5px" :style="{ background: '#0071a1',border:'none'}" @click="addRowHighlightGroupphoto" type="primary" :size="'small'">
                    Add Row
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import AwsFileManager from '../../components/AwsFileManager.vue'
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    wrapHeight: { 
      type: Number,
      default: 120
    },
    arr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
      AwsFileManager
  },
  data () {
    return {
      idHighlightGroupphoto: 0,
      keysListHighlightGroupphoto: [],
      
      
      visibleGallary: false,
      gallaryImages:[],
      gallaryDtr:[],
      
      selitem:0,
    }
  },
  created () {
    // this.form = this.$form.createForm(this)
    var mynumber = 50;
    this.gallaryImages = new Array(mynumber);
    for (var i = 0; i < mynumber; i++) {
        this.gallaryImages[i] = [];
        this.gallaryDtr[i] = ''
    }
    this.init()
  },
  methods: {

    
    showGallary(_isShow, _index){
        this.visibleGallary = _isShow;
        if (_isShow)
            this.selitem = _index;
        else
            this.selitem = 0;
        // if (!_isShow)
        //     this.updateUserInfo();
    },
    selectImages(_images, _index){
        this.gallaryImages[_index] = _images;
        this.gallaryDtr[_index] = this.gallaryImages[_index].toString();
        // console.log(this.gallaryImages)
        
        this.visibleGallary = false;
    },
    cancelImage(_id, index){
      var _index = this.gallaryImages[index].indexOf(_id);
      let cellInfo = this.gallaryImages[index];
      cellInfo.splice(_index, 1);
      this.gallaryImages.splice(index, 1, cellInfo);
      this.gallaryDtr[_index] = this.gallaryImages[_index].toString();
    },

    init () {
      const arr = []
      
      if (this.arr != undefined && this.arr.length !== 0) {
        this.idHighlightGroupphoto = 0;
        for (let i = 0; i < (this.arr).length; i++) {
          this.gallaryImages.splice(i, 1, this.arr[i].gallary);
          arr.push(i)
          this.idHighlightGroupphoto = this.idHighlightGroupphoto + 1
        }
      }
      this.keysListHighlightGroupphoto = arr
    },
    getImages(index){
                // console.log("index",index)
                // console.log(this.gallaryImages[index])
      this.$emit('photolist', this.gallaryImages[index]);
    },





    removeRowHighlightGroupphoto (k) {
      if (this.keysListHighlightGroupphoto.length === 0) { 
        return
      }
      this.keysListHighlightGroupphoto = this.keysListHighlightGroupphoto.filter(item => item !== k)
    },
    addRowHighlightGroupphoto () {
      this.keysListHighlightGroupphoto = this.keysListHighlightGroupphoto.concat(this.idHighlightGroupphoto)
      this.idHighlightGroupphoto = this.idHighlightGroupphoto + 1
    },
  }
}
</script>