import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import router from './router'
import store from './store'
import excel from 'vue-excel-export'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.component('date-picker', DatePicker)

import "./css/admindashboard.css"
import VueDragSelector from "vue-drag-selector";
Vue.use(VueDragSelector);
Vue.use(Antd);
Vue.use(excel);
Vue.config.productionTip = false

export const bus = new Vue();

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
