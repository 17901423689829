<template>
  <div>
    <div style="display:flex">
      <div style="display:flex;">
        <a-select style="width:110px;margin-top:auto;margin-bottom:auto" v-model="controlType" slot="addonAfter" default-value="default">
            <a-select-option value="default">
                <div style="font-size:12px">Bulk actions</div>
            </a-select-option>
            <a-select-option value="delete">
                <div style="font-size:12px">Delete</div>
            </a-select-option>
        </a-select>
          <a-button style="margin-left:2px" slot="enterButton" @click="onApply" type="primary" ghost>
            Apply
          </a-button>
      </div>
    </div>
    <a-table :columns="columns" :data-source="showlist" :pagination="pagination" :row-selection="rowSelection" @change="handleTableChange" rowKey="id">
        
        <span slot="userImage" slot-scope="text, record">
          <div style="display:flex;width:100%">
            <img style="border-radius:15px;margin:auto" :src="record.userImage" alt="" width="30px" height="30px">
          </div>
        </span>
        <span slot="image" slot-scope="text, record">
          <img :src="record.image" alt="" width="50px" height="30px">
        </span>
        
        <span slot="createdDate" slot-scope="text, record">
          <div>{{record.createdDate != undefined ? record.createdDate.toString().slice(0, 10) : ''}}</div>
        </span>
        <span slot="tags" slot-scope="tags, record">
          <a-tag
              v-for="tag in record.tagNames"
              :key="tag"
              :color="'green'"
          >
              {{ tag }}
          </a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
        <!-- <a>Invite 一 {{ record.name }}</a> -->
          <a @click="DeleteInspirationOne(record.id)">Delete</a>
          <a-divider type="vertical" />
          <a @click="EditInspiration(record.id)">Edit</a>
        </span>
    </a-table>
    <div style="display:flex;position:absolute;bottom: 10px;z-index: 1;">
      <a-select style="width:170px;margin-top:auto;margin-bottom:auto;margin-left:2px" v-model="filterRoomCount" slot="addonAfter" default-value=0>
          <a-select-option v-for="item in filterRoomList" :key="item.name" :value="item.id">
              <div style="font-size:12px">{{ item.name }}</div>
          </a-select-option>
      </a-select>
      <a-button style="margin-left:2px" slot="enterButton" @click="onFilterCount" type="primary" ghost>
        Filter Room
      </a-button>
    </div>
  </div>
</template>
<script>
import { api } from '../../helpers/Helpers';
var selecetedList = [];
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    selecetedList = selectedRows;
    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selecetedList);
  },
  onSelect: (record, selected, selectedRows) => {
    // console.log("onSelect === ");
    // console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    // console.log("onSelectAll === ");
    // console.log(selectedRows);
    // console.log(selected);
    // console.log(changeRows);
  },
};
const filterRoomList = [
  {
    id:10,
    name:'10 count'
  },
  {
    id:25,
    name:'25 count'
  },
  {
    id:50,
    name:'50 count'
  },
];
export default {
  data() {
    return {
      controlType:'default',
      userType:'default',
      filterType:'default',
      rowSelection,
      selecetedList,
      filterRoomCount:10,
      filterRoomList,
      pagination: {
        onChange: page => {
          // console.log(page);
        },
        pageSize: 10,
      },
    };
  },
  props: [
      'showlist',
      'columns',
      'tableCellCount'
  ],
  components: {
  },
  
  created: function () {
    this.pagination.pageSize = this.tableCellCount;
    this.filterRoomCount = this.tableCellCount;
  },
  methods: {
      getUserList(){
          
      },
      onApply(){
        if (this.controlType == 'default'){
          return;
        }
        else if (this.controlType == 'delete'){
          this.deleteInspiration();
        }
        
      },
      deleteInspiration:async function(){
        if (selecetedList.length <= 0)
          return;
          
        this.$confirm({
          title: 'You sure you want to delete?',
          // content: h => <div style="color:red;">Some descriptions</div>,
          onOk: ()=> {
            this.DeleteInspirationResult()
          },
          onCancel() {
            
          },
          class: 'test',
        });
      },
      
      DeleteInspirationResult:async function(){

        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.deleteInspiration(selecetedList[i].id);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },

      DeleteInspirationOne: function(_id){

        this.$confirm({
          title: 'You sure you want to delete?',
          // content: h => <div style="color:red;">Some descriptions</div>,
          onOk: ()=> {
            this.DeleteInspirationOneResult(_id);
          },
          onCancel() {
            
          },
          class: 'test',
        });
      },
      DeleteInspirationOneResult:async function(_id){
        // console.log("========", _id)
        const res = await api.deleteInspiration(_id);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },
      
      EditInspiration:async function(_id){
        // console.log(_id)
        this.$router.push({ path: 'editinspiration', query: {id:_id}})
      },

      handleTableChange(pagination, filters, sorter) {
        // console.log(pagination);
        // console.log(filters);
        // console.log(sorter);
        this.listOrder(sorter.field, sorter.order);
      },
      listOrder(_field, _order){
        // this.$emit('listOrder', _field, _order);
          if (_order == "ascend"){
            this.showlist.sort(function(a,b) {return (a[_field] > b[_field]) ? 1 : ((b[_field] > a[_field]) ? -1 : 0);} );
          }
          else if(_order == "descend"){
            this.showlist.sort(function(a,b) {return (a[_field] < b[_field]) ? 1 : ((b[_field] < a[_field]) ? -1 : 0);} );
          }
          else if (_field != undefined){
            this.showlist.sort(function(a,b) {return (a['title'] > b['title']) ? 1 : ((b['title'] > a['title']) ? -1 : 0);} );
          }
      },
      onFilterCount(){
        this.$emit('listFilterCount', this.filterRoomCount);
      },
  }
};
</script>