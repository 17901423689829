<template>
    <div style="min-height:85vh">
        <FileManagerView :ismodal="false"></FileManagerView>
    </div>
</template>
<script>
import FileManagerView from '../components/FileManagerView.vue'
export default {
  components: { FileManagerView },
    
}
</script>