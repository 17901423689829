<template>
    <div style="display:flex;min-height:85vh">
        <div style="width:100%;display:flex;top:0px;margin:auto;">
            
            <div style="margin:auto;">
                <div style="width:300px;padding-top:20px">
                    <div style="font-size:18px;margin-bottom:5px;">Reset Password</div>
                    <a-input style="width:100%;" placeholder="Password" v-model="resetpassword" :size="'large'"/>
                
                    <a-button style="width:100%;margin-top:10px" type="primary" :disabled="disabled" :size="'large'" :style="{ fontSize: '20px'}"  @click="handleResetClick">
                        Reset
                    </a-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { api } from '../helpers/Helpers';
export default {
    
    data() {
        return {
            disabled:false,
            resetpassword:''
        }
    },
    methods: {
        handleResetClick: async function(){
            const _info = {
                token: this.resetToken,
                password: this.resetpassword
            }
            const res = await api.resetPassword(_info)
            if (res!= null && res._id != undefined){
                this.$message.success('Reset success. Please login.');
                // window.open("/","_self");// "_blank"
            }
            else{
                this.$message.error('Reset Failed!');
            }
        }
    },
    
    mounted(){
        this.resetToken = this.$route.query.token;
    }
}
</script>