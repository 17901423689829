<template>
    <div style="min-height:85vh">
        <div style="margin: 16px 0;display:flex">
            <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                <a-breadcrumb-item style="font-size:30px;">Add Inspiration</a-breadcrumb-item>
                <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
            </a-breadcrumb>
        </div>
        <!-- <div style="display:flex;margin-top:20px;margin-left:10px;">
            <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">User</div>
            
            <div style="display:flex;width: 100%;">
                <a-select style="width:300px;margin-top:auto;margin-bottom:auto" v-model="selectedUser" slot="addonAfter">
                    <a-select-option v-for="item in allUserList" :key="item._id" :value="item._id">
                        <div style="display:flex">
                            <span style="margin-top:auto;margin-bottom:auto;">{{item.firstName + " " + item.lastName}}</span>
                        </div>
                    </a-select-option>
                </a-select>
            </div>

        </div> -->
        
        <div style="margin-left:10px;margin-top:10px;display: flex;">
            <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">Title</div>
            <div style="display:flex;width: 100%;">
                <a-input v-model="inspirationTitle" placeholder="Enter Title"/>
            </div>
        </div>
        <div style="display:flex;margin-top:20px;margin-left:10px;">
            <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">Tags</div>
            <div style="display:flex;width: 100%;">
                <a-select style="width:500px;margin-top:auto;margin-bottom:auto" v-model="selectedTag" mode="multiple" slot="addonAfter">
                    <a-select-option v-for="item in tagList" :key="item.key" :value="item.key">
                        <div style="display:flex">
                            <span style="margin-top:auto;margin-bottom:auto;">{{item.name}}</span>
                        </div>
                    </a-select-option>
                </a-select>
            </div>

        </div>
        <div style="margin-left:10px;margin-top:10px;display: flex;">
            <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">Detail</div>
            <!-- <div style="display:flex;width: 100%;">
                <a-textarea v-model="inspirationDetail" placeholder="Enter Detail" :auto-size="{ minRows: 10, maxRows: 100 }" />
            </div> -->
            
            <div style="display:flex;width: 100%;border: 1px solid #eeeeee;border-radius: 5px;padding: 10px;min-height: 80px;">
                <a-form style="display:flex;width: 100%;" :form="inspirationForm">
                    <inspiration-content-list ref="_sublist" @getKeyList="getKeyList"
                        :title="`${INSPIRATIONPART}`"
                        :arr="arrInspiration"
                    />
                </a-form>
            </div>
        </div>
        <!-- <div style="display:flex;width: 100%;padding-top: 20px;">
            <div style="text-align:left;margin-top:5px;margin-left:5px;width:150px">More Images</div>
            <div style="display:flex;width: 100%;border: 1px solid #eeeeee;border-radius: 5px;padding: 10px;">
                <div style="margin-left: auto;margin-right: auto;width: 100%;">
                    <a-button @click="showGallary(true)">Add Inspiration Images</a-button>
                    <a-modal
                        v-model="visibleGallary"
                        title="Add Gallay"
                        :footer="null"
                        width="80%"
                    >
                        <AwsFileManager v-if="visibleGallary" :ismulti="true" @close="showGallary(false)" @selectImages="selectImages"/>
                    </a-modal>
                    <a-list style="margin-top:10px" :grid="{ gutter: 24, xs: 3, sm: 4, md: 6, lg: 8, xl: 8, xxl: 12 }" :data-source="gallaryImages">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <div style="border: 1px solid #E7E7E7;position:relative">
                                <div style="width:100%;height:8vh">
                                    <img style="object-fit: cover;width:100%;height:100%" :src="`${item}`" >
                                </div>
                                <a-button style="position:absolute;top:-5px;right:-5px;background-color:#272838;color:white" type="solid" shape="circle" icon="close" @click="cancelImage(item)" :size="'small'"></a-button>
                            </div>
                        </a-list-item>
                    </a-list>
                </div>
            </div>
        </div> -->

        
        
        <div style="margin-top:10px">
            <a-button class="editable-add-btn" style="margin-left:10px" @click="addInspiration" type="primary">
                Add Inspiration
            </a-button>
        </div>
    </div>
</template>
<script>
import { api } from '../helpers/Helpers';
import AwsFileManager from '../components/AwsFileManager.vue';
import InspirationContentList from './HotelDynamicList/InspirationContentList.vue';

const INSPIRATIONPART = 'inspirationPart'
export default {
    components: { 
        AwsFileManager,
        InspirationContentList,
    },
        
    data() {
        return {
            allUserList:[],
            selectedUser:'',
            inspirationTitle:'',
            inspirationDetail:'',
            
            visibleGallary: false,
            gallaryImages:[],

            selectedTag:[],
            tagList:[],

            
            inspirationForm: this.$form.createForm(this),
            INSPIRATIONPART,
            arrInspiration: [],
        }
    },
    
    created () {
        if (this.$store.state.loginAdmin.id != undefined)
            this.selectedUser = this.$store.state.loginAdmin.id;
        // console.log("this.selectedUser=============", this.selectedUser);

        this.getInspirationTags();
        // this.getUsers();

    },
    methods:{
        getInspirationTags:async function(){
            const res = await api.getInspirationTagInfo();
            if (res != undefined && res.length > 0 && res[0].datalist != undefined){
                // console.log("getInspirationTagInfo=============", res[0].datalist);
                this.tagList = res[0].datalist;
            }
        },
        // getUsers:async function(){
        //     const res = await api.getUsersByAdmin();
        //     // console.log("res=============", res);
        //     this.allUserList = res;
        // },
        
        showGallary(_isShow){
            this.visibleGallary = _isShow;
        },
        selectImages(_images){
            
            for (let i = 0; i < _images.length; i++){
                if (!this.gallaryImages.includes(_images[i]))
                    this.gallaryImages.push(_images[i]);
            }
            // console.log(this.gallaryImages)
            this.visibleGallary = false;
        },
        cancelImage(_id){
            var _index = this.gallaryImages.indexOf(_id);
            this.gallaryImages.splice(_index, 1);
        },
        getKeyList(value){
            this.keyList = value
            // console.log("======= getKeyList", this.keyList);
        },
        addInspiration:async function(){

            // if (this.selectedUser == undefined || this.selectedUser == ''){
            //     this.$message.error('Please select user!');
            //     return;
            // }
            if (this.inspirationTitle == undefined || this.inspirationTitle == ''){
                this.$message.error('Please input inspiration title!');
                return;
            }
            // if (this.inspirationDetail == undefined || this.inspirationDetail == ''){
            //     this.$message.error('Please input inspiration in detail!');
            //     return;
            // }
            // if (this.gallaryImages == undefined || this.gallaryImages.length <= 0){
            //     this.$message.error('Please set images!');
            //     return;
            // }


            if (this.$refs._sublist != undefined)
                this.$refs._sublist.getKeyList();
            const { inspirationForm: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    let partOneArr = [];
                    var TypeList = values[`${INSPIRATIONPART}Type`];
                    // console.log("TypeList==", TypeList);
                    if (TypeList != undefined){
                        for(let index = 0; index < TypeList.length; index++){
                            var _type = TypeList[index];
                            
                            const obj = {
                                type: _type,
                                content: values[`${INSPIRATIONPART}Content`] != undefined && values[`${INSPIRATIONPART}Content`][index] != undefined ? values[`${INSPIRATIONPART}Content`][index] : '',
                                hyperlinkTitle: values[`${INSPIRATIONPART}HyperlinkTitle`] != undefined && values[`${INSPIRATIONPART}HyperlinkTitle`][index] != undefined ? values[`${INSPIRATIONPART}HyperlinkTitle`][index] : '',
                                hyperlinkURL: values[`${INSPIRATIONPART}HyperlinkURL`] && values[`${INSPIRATIONPART}HyperlinkURL`][index] != undefined ? values[`${INSPIRATIONPART}HyperlinkURL`][index] : '',
                                images: values[`${INSPIRATIONPART}Images`] != undefined && values[`${INSPIRATIONPART}Images`][index] != undefined ? this.getImageList(values[`${INSPIRATIONPART}Images`][index]) : [],
                            }
                            partOneArr.push(obj)
                        }
                    }
                    // console.log(partOneArr);
                    
                    this.arrInspiration = this.reOrderList(partOneArr, this.keyList);
                    // this.arrInspiration = partOneArr;

                    this.addInspirationMain();
                }
            });
        },
        addInspirationMain:async function(){


            const lastInfo = {
                userId: this.selectedUser,
                createdDate: new Date(),
                title: this.inspirationTitle,
                detail:this.inspirationDetail,
                mainImage:this.gallaryImages,
                commetCount:0,
                tags:this.selectedTag,
                detailInfo:this.arrInspiration
            }
            const res = await api.setInspiration(lastInfo);
            // console.log(lastInfo, "res=============", res);
            if (res != undefined && res != ""){
                this.$message.success('Inspiration Creating Success!');
            }
            else{
                this.$message.error('Inspiration Creating Failed!');
            }
        },

        getImageList(_str){    
            if (_str == undefined)
                return [];        
            const resArray = _str.split("-,-");
            // console.log(resArray, "resArray=============", _str);
            return resArray;
        },
        reOrderList(srcList, keyList){
            var resultList = [];
            for(let i = 0; i < keyList.length; i++){
                if (srcList.length > keyList[i])
                    resultList.push(srcList[keyList[i]]);
            }
            return resultList;
        },
    },
    
}
</script>