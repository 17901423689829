var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"margin-top":"0","margin-bottom":"0","width":"100%"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"width":"33%","border":"1px solid #aaaaaa"}},[_vm._v("Age From")]),_c('div',{staticStyle:{"width":"33%","border":"1px solid #aaaaaa"}},[_vm._v("Age To")]),_c('div',{staticStyle:{"width":"34%","border":"1px solid #aaaaaa"}},[_vm._v("Price")]),_c('div',{staticStyle:{"width":"30px"}},[_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticStyle:{"width":"10px"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          (_vm.title + "SelectInput"),
                          {
                              initialValue: 0,
                              rules: [{ required: false, message: 'Input Adult Number!' }]
                          }
                      ]),expression:"[\n                          `${title}SelectInput`,\n                          {\n                              initialValue: 0,\n                              rules: [{ required: false, message: 'Input Adult Number!' }]\n                          }\n                      ]"}],staticStyle:{"width":"10px"},attrs:{"min":0,"max":100000,"placeholder":""}})],1)],1)]),_vm._l((_vm.keysListRoomExtraInfo),function(item){return _c('div',{key:item,staticStyle:{"margin-top":"5px"}},[_c('a-row',{staticStyle:{"width":"100%"},attrs:{"gutter":0}},[_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('a-form-item',{staticStyle:{"width":"33%","margin-bottom":"0"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                (_vm.title + "ageFrom[" + item + "]"),
                                {
                                    initialValue: _vm.arr[item] ? _vm.arr[item].agefrom : undefined,
                                    rules: [{ required: true, message: 'Input agefrom!' }]
                                }
                            ]),expression:"[\n                                `${title}ageFrom[${item}]`,\n                                {\n                                    initialValue: arr[item] ? arr[item].agefrom : undefined,\n                                    rules: [{ required: true, message: 'Input agefrom!' }]\n                                }\n                            ]"}],staticStyle:{"width":"100%","padding":"2px"},attrs:{"min":1,"max":100000,"placeholder":""}})],1),_c('a-form-item',{staticStyle:{"width":"33%","margin-bottom":"0"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                (_vm.title + "ageTo[" + item + "]"),
                                {
                                    initialValue: _vm.arr[item] ? _vm.arr[item].ageto : undefined,
                                    rules: [{ required: false, message: 'ageto Number!' }]
                                }
                            ]),expression:"[\n                                `${title}ageTo[${item}]`,\n                                {\n                                    initialValue: arr[item] ? arr[item].ageto : undefined,\n                                    rules: [{ required: false, message: 'ageto Number!' }]\n                                }\n                            ]"}],staticStyle:{"width":"100%","padding":"2px"},attrs:{"min":1,"max":100000,"placeholder":""}})],1),_c('a-form-item',{staticStyle:{"width":"34%","margin-bottom":"0"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                (_vm.title + "price[" + item + "]"),
                                {
                                    initialValue: _vm.arr[item] ? _vm.arr[item].price : undefined,
                                    rules: [{ required: false, message: 'price Number!' }]
                                }
                            ]),expression:"[\n                                `${title}price[${item}]`,\n                                {\n                                    initialValue: arr[item] ? arr[item].price : undefined,\n                                    rules: [{ required: false, message: 'price Number!' }]\n                                }\n                            ]"}],staticStyle:{"width":"100%","padding":"2px"},attrs:{"min":1,"max":100000,"placeholder":""}})],1),_c('div',{staticStyle:{"width":"30px","padding":"5px"}},[_c('a-button',{staticClass:"minusRowBtn",attrs:{"type":"dashed","icon":"minus","size":'small'},on:{"click":function($event){return _vm.removeRowRoomExtraInfo(item)}}})],1)],1)])],1)}),_c('div',{staticStyle:{"display":"flex"}},[_c('a-button',{staticStyle:{"margin-left":"auto","margin-right":"0","margin-top":"5px"},style:({ background: '#0071a1',border:'none'}),attrs:{"type":"primary"},on:{"click":_vm.addRowRoomExtraInfo}},[_vm._v(" Add Row ")])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }