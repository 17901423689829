<template>
    <div class="adminhome" style="min-height:85vh;overflow-x: hidden;position: relative;">
        <div style="display:flex">
            <div style="margin: 16px 0;display:flex">
                <a-breadcrumb style="margin-top:auto;margin-bottom:auto">
                    <a-breadcrumb-item style="font-size:30px;">Users</a-breadcrumb-item>
                    <!-- <a-breadcrumb-item>Bill</a-breadcrumb-item> -->
                </a-breadcrumb>
                <a-button style="margin-top:auto;margin-bottom:auto;margin-left:20px" type="primary">
                    <router-link to="/admin/adduser">
                    Add User
                    </router-link>
                </a-button>
            </div>
        </div>
        <div v-if="newUsers.length > 0" style="position: absolute;top: 0;width: 100%;text-align:center;display: flex;">
          <div style="margin-left: auto;margin-right: auto;color: white;cursor:pointer;border-radius: 5px;background-color: #1890ff;width:150px;padding:5px" @click="getUsers">
            {{newUsers.length}} new accounts
            <a-icon type="reload" />
          </div>
        </div>
        <export-excel  style="margin-left:5px;position:absolute;top: 0;right: 0;"
            class   = "btn-default"
            :data   = "json_data"
            :fields = "json_fields"
            :meta = "json_meta"
            type="xls"
            worksheet = "Users"
            name    = "Users Data.xls">
        
            Export Excel
        </export-excel>

        <div style="position:relative">
          <div style="position:absolute;right:0;top:-30px;display:flex;">
            <a-input-search style="width:400px;margin-right:0px;margin-left:auto" v-model="userSearchText" placeholder="" @search="onSearch">
              <a-button slot="enterButton">
                Search User
              </a-button>
            </a-input-search>
          </div>
          <div style="display:flex">
              <a-tabs style="width:100%"
                  default-active-key="1"
                  :tab-position="'top'"
                  >
                  <a-tab-pane key="1" tab="All Users">
                      <UsersTableItem :key="tableCellCount" :userlist ="userlist" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listFilter="listFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="Admin" force-render>
                      <UsersTableItem :key="tableCellCount" :userlist ="adminlist" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listFilter="listFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
                  <a-tab-pane key="3" tab="Agent">
                      <UsersTableItem :key="tableCellCount" :userlist ="agentlist" :columns="columns" :tableCellCount="tableCellCount" @listUpdate="listUpdate" @listFilter="listFilter" @listFilterCount="listFilterCount"/>
                  </a-tab-pane>
              </a-tabs>
          </div>
        </div>
        
        <!-- <a-table :columns="columns" :data-source="data">
            <a slot="name" slot-scope="text">{{ text }}</a>
            <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
            <span slot="tags" slot-scope="tags">
            <a-tag
                v-for="tag in tags"
                :key="tag"
                :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
            >
                {{ tag.toUpperCase() }}
            </a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
            <a>Invite 一 {{ record.name }}</a>
            <a-divider type="vertical" />
            <a>Delete</a>
            <a-divider type="vertical" />
            <a class="ant-dropdown-link"> More actions <a-icon type="down" /> </a>
            </span>
        </a-table> -->
    </div>
</template>
<script>

import { api } from '../helpers/Helpers';
import UsersTableItem from './TableItems/UsersTableItem.vue'
import { bus } from '../main';
const columns = [
  // {
  //   title: 'Username',
  //   dataIndex: 'username',
  //   key: 'username',
  // },
  // {
  //   title: 'Name',
  //   dataIndex: 'name',
  //   key: 'name',
  //   sorter: true,
  // },
  {
    title: 'First Name',
    dataIndex: 'firstname',
    key: 'firstname',
    sorter: true,
  },
  {
    title: 'LastName',
    dataIndex: 'lastname',
    key: 'lastname',
    sorter: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
  },
  {
    title: 'Role',
    key: 'role',
    dataIndex: 'role',
    // sorter: true,
    scopedSlots: { customRender: 'tags' },
  },
  {
    title: 'State/Province',
    dataIndex: 'stateProvince',
    key: 'stateProvince',
    sorter: true,
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    sorter: true,
  },
  {
    title: 'Agency Name',
    dataIndex: 'agencyName',
    key: 'agencyName',
    sorter: true,
  },
  {
    title: 'Member Name',
    dataIndex: 'menberName',
    key: 'menberName',
    sorter: true,
  },
  {
    title: 'Business ID',
    dataIndex: 'businessID',
    key: 'businessID',
    sorter: true,
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    sorter: true,
  },
  {
    title: 'Status',
    key: 'status',
    sorter: true,
    dataIndex: 'status',
  },
  {
    title: 'Posts',
    key: 'posts',
    dataIndex: 'posts',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    sorter: true,
    scopedSlots: { customRender: 'createdDate' },
  },
  {
    title: 'Action',
    key: 'action',
    width: 200,
    scopedSlots: { customRender: 'action' },
  },
  // {
  //   title: 'Export',
  //   key: 'export',
  //   scopedSlots: { customRender: 'export' },
  // },
];

const userlist = [
  // {
  //   key: '1',
  //   username: 'John Brown',
  //   name: 32,
  //   email: 'New York No. 1 Lake Park',
  //   role: ['developer'],
  //   status: ['approved'],
  //   posts:0
  // }
];
const adminlist = [];
const agentlist = [];

const statusList = {
  0:'Pending',
  1:'Approved',
  2:'Denied'
}
const roleList = {
  1:'User',
  4:'Agent',
  2048:'Admin'
}
var newUsers = [];

export default {
  data() {
    return {
      alluserlist:[],
      userlist,
      adminlist,
      agentlist,
      columns,
      userSearchText:"",
      
      tableCellCount:10,
      newUsers,

      json_fields: {
          'First Name': 'First Name',
          'Last Name': 'Last Name',
          'Email': 'Email',
          'Role': 'Role',
          'Country': 'Country',
          'Agency Name': 'Agency Name',
          'Member Name': 'Member Name',
          'Business ID': 'Business ID',
          'Phone Number': 'Phone Number',
          'State/Province': 'State/Province',
          'Status': 'Status',
          'Posts': 'Posts',
      },
      json_data: [
          {
            'First Name': 'First Name',
            'Last Name': 'Last Name',
            'Email': 'Email',
            'Role': 'Role',
            'Country': 'Country',
            'Agency Name': 'Agency Name',
            'Member Name': 'Member Name',
            'Business ID': 'Business ID',
            'Phone Number': 'Phone Number',
            'State/Province': 'State/Province',
            'Status': 'Status',
            'Posts': 'Posts',
          }
      ],
      json_meta: [
          [
              {
                  'key': 'charset',
                  'value': 'utf-8'
              }
          ]
      ],
    };
  },
  
  components: {
    UsersTableItem
  },
  
    created: function () {
      this.newUsers = [];
			bus.$on('newagent', (data) => {
				// console.log(this.newUsers, "Users =22= = ", JSON.parse(data).email);
        const email = JSON.parse(data).email;
        const _res = this.newUsers.filter(obj => obj == email);
				// console.log(_res, "Users =_res= = ");
        if (_res.length <= 0){
          this.newUsers.push(email);

          // console.log(this.newUsers, "Users =33= = ", JSON.parse(data).email);
          this.openNotification(email);
        }
			});

      this.getUsers();
        
    },
    mounted(){

        // this.getUserTest();
    },
  
    methods: {
      // getUserTest:async function(){
      //   const res = await api.getUsersTest();
      //   // console.log("res=============");
      //   // console.log(res);
      //   // console.log("res=============");
      // },
        getUsers: async function() {
            this.newUsers = [];
            let res = await api.getUsersByAdmin();
            
            // this.flash('task created', 'success');
            // console.log("res=============");
            // console.log(res);
            
            if (res != undefined && res.length > 0){
              
            //     res.sort(function(a,b) {return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0);} );
            // // console.log(res);
                var resUserList = [];
                var resAdminList = [];
                var resAgentList = [];
                this.json_data = [];
                for (let i = 0; i < res.length; i++) {
                    var _role = res[i].permissionLevel
                    if (res[i].permissionLevel == undefined){
                      _role = 1;
                      
                    }
                    var _status = 0;
                    if (res[i].status != undefined)
                      _status = res[i].status;
                    let _username = res[i].userName;
                    if (res[i].userName == undefined)
                      _username = res[i].firstName
                    if (res[i].firstName == undefined)
                      res[i].firstName = '';
                    if (res[i].lastName == undefined)
                      res[i].lastName = '';
                    var cellInfo = {
                        id:res[i]._id,
                        key: i + 1,
                        username: _username,
                        // name: res[i].firstName + " " + res[i].lastName,
                        firstname: res[i].firstName,
                        lastname: res[i].lastName,
                        email: res[i].email,
                        role: [roleList[_role]],
                        status: statusList[_status],
                        posts: res[i].posts,
                        country: res[i].country,
                        agencyName: res[i].agencyName,
                        menberName: res[i].menberName,
                        businessID: res[i].businessID,
                        phoneNumber: res[i].phoneNumber,
                        stateProvince: res[i].stateProvince,
                        
                        createdDate: res[i].createdDate,
                    };
                    resUserList.push(cellInfo);
                    if (res[i].permissionLevel == 2048)
                        resAdminList.push(cellInfo);
                    if (res[i].permissionLevel == 4)
                        resAgentList.push(cellInfo);

                    
                    ///////   Json List Update
                    let cellInfoJson =
                    {
                      // 'Name': cellInfo.name,
                      'First Name': cellInfo.firstname,
                      'Last Name': cellInfo.lastname,
                      'Email': cellInfo.email,
                      'Role': cellInfo.role,
                      'Country': cellInfo.country,
                      'Agency Name': cellInfo.agencyName,
                      'Member Name': cellInfo.menberName,
                      'Business ID': cellInfo.businessID,
                      'Phone Number': cellInfo.phoneNumber,
                      'State/Province': cellInfo.stateProvince,
                      'Status': cellInfo.status,
                      'Posts': cellInfo.posts,
                    };
                    this.json_data.push(cellInfoJson);
                }
                this.adminlist = resAdminList;
                this.agentlist = resAgentList;
                this.userlist = resUserList;
                this.alluserlist = resUserList;

                
                // var userInfo = {
                //     loginType: 'api',
                //     api: {
                //         auth: 'alyosha',
                //         user: {
                //             name: res.name,
                //             email: this.loginAdmin.email,
                //             profileImage: "testurl"
                //         }
                //     }
                // }
                // // console.log("---- " + userInfo)
                // this.$store.commit('setLoginUser', userInfo)

                // this.$emit('close');
            }
        },
        onSearch(value) {
          // console.log(value);
          var _userList = []
          for (let i = 0; i < this.alluserlist.length; i++) {
              if (this.alluserlist[i].username.toLowerCase().includes(this.userSearchText.toLowerCase())
               || this.alluserlist[i].firstname.toLowerCase().includes(this.userSearchText.toLowerCase())
               || this.alluserlist[i].lastname.toLowerCase().includes(this.userSearchText.toLowerCase())
               || this.alluserlist[i].email.toLowerCase().includes(this.userSearchText.toLowerCase()))
                _userList.push(this.alluserlist[i])
          }
          this.userlist = _userList;
          this.updateUserList();
        },
        updateUserList(){
          var resAdminList = [];
          var resAgentList = [];
          for (let i = 0; i < this.userlist.length; i++) {
              if (this.userlist[i].role == 2048)
                  resAdminList.push(this.userlist[i])
              if (this.userlist[i].role == 4)
                  resAgentList.push(this.userlist[i])
          }
          this.adminlist = resAdminList; 
          this.agentlist = resAgentList;
        },
        listUpdate(){
          this.getUsers();
        },
        listFilterCount(_count){
          // console.log(_count);
          this.tableCellCount = _count;
        },
        listFilter(status){
          var resUserList = [];
          var resAdminList = [];
          var resAgentList = [];
          for (let i = 0; i < this.alluserlist.length; i++) {
        // console.log(status,this.alluserlist[i].status)
            if (status == 'Default' || this.alluserlist[i].status == status){
              resUserList.push(this.alluserlist[i])
              if (this.alluserlist[i].role == 2048)  
                  resAdminList.push(this.alluserlist[i])
              if (this.alluserlist[i].role == 4)
                  resAgentList.push(this.alluserlist[i])
            }
          }
          this.userlist = resUserList;
          this.adminlist = resAdminList; 
          this.agentlist = resAgentList;
        },
        openNotification(_email) {
          const key = `open${Date.now()}`;
          this.$notification.open({
            message: 'Notification New Agent',
            description:
              `${_email} registered to our Maldive as agent.`,
            btn: h => {
              return h(
                'a-button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  on: {
                    click: () => this.getUsers(),
                  },
                },
                'Update Users',
              );
            },
            key,
            onClose: close,
          });
        },
    }
};
</script>
