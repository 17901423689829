<template>
  <div>
    <div style="display:flex">
      <div style="display:flex;">
        <a-select style="width:110px;margin-top:auto;margin-bottom:auto" v-model="controlType" slot="addonAfter" default-value="default">
            <a-select-option value="default">
                <div style="font-size:12px">Bulk actions</div>
            </a-select-option>
            <a-select-option value="delete">
                <div style="font-size:12px">Delete</div>
            </a-select-option>
            <a-select-option value="aprove">
                <div style="font-size:12px">Approve</div>
            </a-select-option>
            <a-select-option value="deny">
                <div style="font-size:12px">Deny</div>
            </a-select-option>
        </a-select>
          <a-button style="margin-left:2px" slot="enterButton" @click="onApply" type="primary" ghost>
            Apply
          </a-button>
      </div>
      <div style="display:flex;margin-left:15px">
        <a-select style="width:120px;margin-top:auto;margin-bottom:auto" v-model="userType" slot="addonAfter" default-value="default">
            <a-select-option value="default">
                <div style="font-size:12px">Change role to</div>
            </a-select-option>
            <a-select-option value="user">
                <div style="font-size:12px">User</div>
            </a-select-option>
            <a-select-option value="admin">
                <div style="font-size:12px">Administrator</div>
            </a-select-option>
            <a-select-option value="agent">
                <div style="font-size:12px">Agent</div>
            </a-select-option>
        </a-select>
          <a-button style="margin-left:2px" slot="enterButton" @click="onChangeRole" type="primary" ghost>
            Change
          </a-button>
      </div>
      <div style="display:flex;margin-left:15px">
        <a-select style="width:100px;margin-top:auto;margin-bottom:auto" v-model="filterType" slot="addonAfter" default-value="default">
            <a-select-option value="default">
                <div style="font-size:12px">View all users</div>
            </a-select-option>
            <a-select-option value="pending">
                <div style="font-size:12px">pending</div>
            </a-select-option>
            <a-select-option value="aproved">
                <div style="font-size:12px">aproved</div>
            </a-select-option>
            <a-select-option value="denied">
                <div style="font-size:12px">denied</div>
            </a-select-option>
        </a-select>
          <a-button style="margin-left:2px" slot="enterButton" @click="onFilter" type="primary" ghost>
            Filter
          </a-button>
      </div>

    </div>
    <a-table :columns="columns" :data-source="userlist" :pagination="pagination" 
      :row-selection="rowSelection" 
      @change="handleTableChange"
      
      :scroll="{ x: roomCellWidth, y: 0 }" 
      :style="{width : '100%'}"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
        <span slot="tags" slot-scope="tags">
        <a-tag
            v-for="tag in tags"
            :key="tag"
            :color="'green'"
        >
            {{ tag }}
        </a-tag>
        </span>
        
        <span slot="createdDate" slot-scope="text, record">
          <div>{{record.createdDate != undefined? moment(Date.parse(record.createdDate)).utc(1412144245453).format('YYYY-MM-DD HH:mm'): ''}}</div> 
          <!-- Pacific time zone    1412144245453-->
        </span>
        <span slot="action" slot-scope="text, record">
        <!-- <a>Invite 一 {{ record.name }}</a> -->

          <export-excel
            class   = "btn-cell-icon"
            :data   = "getJsonData(record)"
            :fields = "json_fields"
            :meta = "json_meta"
            type="xls"
            worksheet = "User"
            name    = "User Data.xls">
        
            <a-icon style="margin-left:5px;color:#003300" type="download"/>
          </export-excel>
          <a-divider type="vertical" />
          <a @click="DeleteUserOne(record.id)">Delete</a>
          <a-divider type="vertical" />
          <a @click="EditUser(record.id)">Edit</a>
          <a-divider type="vertical" />
          <a v-if="(record.status === 'Pending' || record.status === 'Denied' || record.status === undefined)" @click="AproveUserOne(record.id)">Approve</a>
          <a v-if="(record.status == 'Approved')" @click="DenyUserOne(record.id)">Deny</a>
        </span>
    </a-table>
    <div style="display:flex;position:absolute;bottom: 10px;z-index: 1;">
      <a-select style="width:170px;margin-top:auto;margin-bottom:auto;margin-left:2px" v-model="filterRoomCount" slot="addonAfter" default-value=0>
          <a-select-option v-for="item in filterRoomList" :key="item.name" :value="item.id">
              <div style="font-size:12px">{{ item.name }}</div>
          </a-select-option>
      </a-select>
      <a-button style="margin-left:2px" slot="enterButton" @click="onFilterCount" type="primary" ghost>
        Filter Count
      </a-button>
    </div>
  </div>
</template>
<script>
import { api } from '../../helpers/Helpers';
var moment = require('moment-timezone');
var selecetedList = [];
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    selecetedList = selectedRows;
    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selecetedList);
  },
  onSelect: (record, selected, selectedRows) => {
    // console.log("onSelect === ");
    // console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    // console.log("onSelectAll === ");
    // console.log(selectedRows);
    // console.log(selected);
    // console.log(changeRows);
  },
};
const filterRoomList = [
  {
    id:10,
    name:'10 count'
  },
  {
    id:25,
    name:'25 count'
  },
  {
    id:50,
    name:'50 count'
  },
];
export default {
  data() {
    return {
      controlType:'default',
      userType:'default',
      filterType:'default',
      rowSelection,
      selecetedList,
      
      filterRoomCount:10,
      filterRoomList,
      pagination: {
        onChange: page => {
          // console.log(page);
        },
        pageSize: 10,
      },

      json_fields: {
          'First Name': 'First Name',
          'Last Name': 'Last Name',
          'Email': 'Email',
          'Role': 'Role',
          'Country': 'Country',
          'Agency Name': 'Agency Name',
          'Member Name': 'Member Name',
          'Business ID': 'Business ID',
          'Phone Number': 'Phone Number',
          'State/Province': 'State/Province',
          'Status': 'Status',
          'Posts': 'Posts',
      },
      json_data: [
          {
            'First Name': 'First Name',
            'Last Name': 'Last Name',
            'Email': 'Email',
            'Role': 'Role',
            'Country': 'Country',
            'Agency Name': 'Agency Name',
            'Member Name': 'Member Name',
            'Business ID': 'Business ID',
            'Phone Number': 'Phone Number',
            'State/Province': 'State/Province',
            'Status': 'Status',
            'Posts': 'Posts',
          }
      ],
      json_meta: [
          [
              {
                  'key': 'charset',
                  'value': 'utf-8'
              }
          ]
      ],
      roomCellWidth: 'calc(900px + 50%)',
    };
  },
  props: [
      'userlist',
      'columns',
      'tableCellCount'
  ],
  components: {
  },
  
  created: function () {
    // console.log('user data from parent component:')
    // console.log(this.userlist) //prints out an empty string
    this.pagination.pageSize = this.tableCellCount;
    this.filterRoomCount = this.tableCellCount;

    
  },
  methods: {
      moment,
      getUserList(){
          
      },
      onApply(){
          // console.log(this.controlType)
        if (this.controlType == 'default'){
          return;
        }
        else if (this.controlType == 'delete'){
          this.deleteUser();
        }
        else if (this.controlType == 'aprove'){
          this.aproveUser();
        }
        else if (this.controlType == 'deny'){
          this.denyUser();
        }
        
      },
      deleteUser:async function(){
        if (selecetedList.length <= 0)
          return;
          
        this.$confirm({
          title: 'Do you want to delete these users?',
          // content: h => <div style="color:red;">Some descriptions</div>,
          onOk: ()=> {
            this.DeleteUserResult()
          },
          onCancel() {
            
          },
          class: 'test',
        });
      },
      
      DeleteUserResult:async function(){

        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.deleteUser(selecetedList[i].id);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      aproveUser:async function(){
        if (selecetedList.length <= 0)
          return;
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.updateUserStatus(selecetedList[i].id, 1);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      denyUser:async function(){
        if (selecetedList.length <= 0)
          return;
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.updateUserStatus(selecetedList[i].id, 2);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },

      getJsonData(record){
          // // console.log("record==",record)
        var json_data = [
          {
            'First Name': record.firstname,
            'Last Name': record.lastname,
            'Email': record.email,
            'Role': record.role,
            'Country': record.country,
            'Agency Name': record.agencyName,
            'Member Name': record.menberName,
            'Business ID': record.businessID,
            'Phone Number': record.phoneNumber,
            'State/Province': record.stateProvince,
            'Status': record.status,
            'Posts': record.posts,
          }
        ];
        return json_data;
      },
      // DownloadUserOne: function(_id){

      //   this.$emit('downloadOne', _id);
      // },

      DeleteUserOne: function(_id){

        this.$confirm({
          title: 'Do you want to delete this user?',
          // content: h => <div style="color:red;">Some descriptions</div>,
          onOk: ()=> {
            this.DeleteUserOneResult(_id);
          },
          onCancel() {
            
          },
          class: 'test',
        });
      },
      DeleteUserOneResult:async function(_id){
        // console.log("========", _id)
        const res = await api.deleteUser(_id);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },
      
      AproveUserOne:async function(_id){
        const res = await api.updateUserStatus(_id, 1);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },
      DenyUserOne:async function(_id){
        const res = await api.updateUserStatus(_id, 2);
        // console.log(res, _id)
        this.$emit('listUpdate');
      },

      EditUser:async function(_id){
        // console.log(_id)
        // window.location.href = '/admin/edituser/?id=' + _id;
        
          this.$router.push({ path: 'edituser', query: {id:_id}})
      },

      onChangeRole(){
        // console.log(this.userType)
        if (this.userType == 'default'){
          return;
        }
        else if (this.userType == 'user'){
          this.changeToUser();
        }
        else if (this.userType == 'admin'){
          this.changeToAdmin();
        }
        else if (this.userType == 'agent'){
          this.changeToAgent();
        }
      },
      changeToUser:async function(){
        if (selecetedList.length <= 0)
          return;
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.changeUserTo(selecetedList[i].id, 1);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      changeToAgent:async function(){
        if (selecetedList.length <= 0)
          return;
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.changeUserTo(selecetedList[i].id, 4);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },
      changeToAdmin:async function(){
        if (selecetedList.length <= 0)
          return;
        for (let i = 0; i < selecetedList.length; i++) {
          const res = await api.changeUserTo(selecetedList[i].id, 2048);
          // console.log(res, selecetedList[i].id)
        }
        this.$emit('listUpdate');
      },

      
      onFilter(){
        // console.log(this.filterType)
        if (this.filterType == 'default'){
          this.$emit('listFilter', 'Default');
          return;
        }
        else if (this.filterType == 'pending'){
          this.$emit('listFilter', 'Pending');
        }
        else if (this.filterType == 'aproved'){
          this.$emit('listFilter', 'Approved');
        }
        else if (this.filterType == 'denied'){
          this.$emit('listFilter', 'Denied');
        }
      },

      onFilterCount(){
        this.$emit('listFilterCount', this.filterRoomCount);
      },

      handleTableChange(pagination, filters, sorter) {
        // console.log(pagination);
        // console.log(filters);
        // console.log(sorter);
        this.userListOrder(sorter.field, sorter.order);
      },
      userListOrder(_field, _order){
        // this.$emit('listOrder', _field, _order);
        // // console.log("sorter.order--",_field,_order);
          if (_order == "ascend"){
            this.userlist.sort(function(a,b) {return (a[_field] > b[_field]) ? 1 : ((b[_field] > a[_field]) ? -1 : 0);} );
          }
          else if(_order == "descend"){
            this.userlist.sort(function(a,b) {return (a[_field] < b[_field]) ? 1 : ((b[_field] < a[_field]) ? -1 : 0);} );
          }
          else if (_field != undefined){
            this.userlist.sort(function(a,b) {return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0);} );
          }
      }
  }
};
</script>