<template>
    <div>
        <a-form :form="formSelectCount">
            <!-- <a-form-item label="Room Count" v-if="false">
                <a-input-number style="width:100%"
                v-decorator="['roomcount', { initialValue: currentInfo.searchRoomCount,rules: [{ required: true, message: 'Please input Room Count!' }] }]"
                />
            </a-form-item> -->
            <a-form-item label="Adult Count">
                <a-input-number style="width:100%"
                v-decorator="['adultcount', { initialValue: currentInfo.searchAdultCount,rules: [{ required: true, message: 'Please input Adult Count!' }] }]"
                />
            </a-form-item>
            <a-form-item label="Children Count">
                <a-input-number @change="countChanged" style="width:100%"
                v-decorator="['childrencount', {initialValue: currentInfo.searchChildCount, rules: [{ required: true, message: 'Please input Children Count!' }] }]"
                />
            </a-form-item>
            <a-form-item v-for="_index in childCountInput" :key="_index" :label="`Children Age ${_index}`">
                <a-input-number style="width:100%"
                v-decorator="['childrenage' + _index, {initialValue: currentInfo.searchChildAge[_index - 1] != undefined? currentInfo.searchChildAge[_index - 1]:0, rules: [{ required: true, message: 'Please input Children Age!' }] }]"
                />
            </a-form-item>
            <a-form-item>
                <div style="display:flex">
                    <a-button style="margin-left:auto;margin-right:auto" type="primary" @click="handleCountOk">
                        OK
                    </a-button>
                </div>
            </a-form-item>
        </a-form>
    </div>
</template>
<script>
export default {
    
    data() {
        return {

            formSelectCount:this.$form.createForm(this),
            currentInfo:{
                searchAdultCount: 0,
                searchChildCount: 0,
                searchChildAge: []
            },
            childCountInput: 0,
        }
    },
    props: {
      dialogData: Object
    },
    mounted(){
        this.currentInfo = {
            searchAdultCount: 0,
            searchChildCount: 0,
            searchChildAge: []
        }
        this.childCountInput = 0; 
        if (this.dialogData.searchRoomInfo.length > this.dialogData.searchRoomInfoID){
            this.currentInfo = this.dialogData.searchRoomInfo[this.dialogData.searchRoomInfoID];
            this.childCountInput = this.currentInfo.searchChildCount;
        }
    },
    
    beforeMount() {
        
    },
    created(){
        
    },
    methods: {
        
        countChanged(_count){
            this.childCountInput = _count;
        },
        handleCountOk(){
            // this.visibleSearchCountPicker = false;
            this.formSelectCount.validateFields((err, values) => {
                if (!err) {
                    if (values.adultcount <= 0){
                        this.$message.error('The adult count must be more than 0!');
                        return;
                    }
                    this.visibleSearchCountPicker = false;
                    let childAgeList = [];
                    for(let i = 1; i <= values.childrencount; i++){
                        childAgeList.push(values['childrenage' + i]);
                    }
                    const _info = {
                        // searchRoomCount: values.roomcount,
                        searchAdultCount: values.adultcount,
                        searchChildCount: values.childrencount,
                        searchChildAge: childAgeList,
                        searchRoomInfoID: this.dialogData.searchRoomInfoID
                    }
                    
                    this.$emit('getCountInfo', _info);
                }
            });
        },
    }
}
</script>